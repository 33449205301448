import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import HouseToken from "../sdk/houseToken";
import { Commitment, ComputeBudgetProgram, Connection, Finality, Keypair, PublicKey, Transaction, VersionedTransaction } from "@solana/web3.js";
import PlayerToken from "../sdk/playerToken";
import { HouseContext } from "./HouseContext";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { NetworkContext } from "./NetworkContext";
import { ProgramContext } from "./ProgramContext";
import { confirmTransaction, toVersionedTransaction } from "../utils/solana/utils";
import { useLocalStorage } from "@solana/wallet-adapter-react";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { SessionAuthorityContext } from "./SessionAuthorityContext";
import { IPlayerMeta } from "./PlayerContext";
import { MIN_LAMPORTS_AUTO_SIGNER } from "../sdk/constants";
import { awaitCommitToBaseLayer } from "../sdk/utils";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";


export interface IPlayerTokenContext {
  playerToken: PlayerToken | undefined;
  playerTokenLoaded: boolean;
  playerTokens: PlayerToken[] | undefined;
  playerTokenByMint: Map<string, PlayerToken> | undefined
  loadPlayerToken: (houseToken?: HouseToken, wallet?: PublicKey) => Promise<void>
  loadPlayerTokens: () => Promise<void>
  initAndDeposit: (sessionAuthority?: Keypair, sessionAuthorityLamports?: number, depositAmount?: number) => Promise<string | undefined>
  initAndDelegate: (maxSessionLengthSeconds: number, instanceToDelegate: number, sessionAuthority?: Keypair, sessionAuthorityLamports?: number, depositAmount?: number, updateMessage?: Function) => Promise<string | undefined>
  processWithdrawal: (amountBasis: number, updateMessage?: Function) => Promise<string | undefined>
  updateSessionAuthority: (maxSessionLengthSeconds: number, sessionAuthority: PublicKey, sessionAuthorityLamports: number) => Promise<string | undefined>
  withdrawFundsAndEndSession: (updateMessage?: Function) => Promise<string>
  setPlayerMeta: Function;
  playerMeta: IPlayerMeta | undefined;
  hasNoPlayerTokenState: boolean;
}

export const PlayerTokenContext = createContext<IPlayerTokenContext>({} as IPlayerTokenContext);

interface Props {
  children: any;
}

export const PlayerTokenProvider = ({ children }: Props) => {

  const { solanaRpc } = useContext(WrappedWalletContext)
  const { client, recentBlockhash, erClient } = useContext(NetworkContext)
  const { meta } = useContext(ProgramContext)
  const { signerKp, allowsAutoSigning, allowsAutoDeposit, setAllowsAutoSigning, lamportBalance, signerPublicKey } = useContext(SessionAuthorityContext)

  const { houseToken, houseTokens } = useContext(HouseContext)
  const { walletPubkey } = useContext(WrappedWalletContext)

  // USED TO HOLD ANY DATA NOT HELD ON THE PLAYER ACC STATE
  const [playerMeta, setPlayerMeta] = useLocalStorage("zeebit-player-meta", "");
  const [counter, setCounter] = useState<number>(0);

  const setPlayerMetaWithCounter = useCallback(
    (newPlayerMeta: IPlayerMeta) => {
      setPlayerMeta({...newPlayerMeta, ...playerMeta});
      setCounter(counter + 1);
    },
    [setPlayerMeta, counter, playerMeta],
  );

  // ANY PLAYER TOKENS THEY HAVE
  const [playerTokens, setPlayerTokens] = useState<PlayerToken[]>()
  const [playerTokenLoaded, setPlayerTokenLoaded] = useState<boolean>(false)

  // PLAYER TOKEN FOR SELECTED TOKEN MINT
  const [playerToken, setPlayerToken] = useState<PlayerToken>()
  const [playerTokensLoaded, setPlayerTokensLoaded] = useState<boolean>(false)

  // WS LOGIC TO KEEP PLAYER TOKEN UP TO DATE
  const playerTokenWsId = useRef<number>()
  const playerTokenErWsId = useRef<number>()

  // TO BE USED TO CHECK IF THEY NEED TO SEE REGISTRATION
  // NO PLAYER TOKEN STATE ACCROSS ALL HOUSE TOKENS
  const hasNoPlayerTokenState = useMemo(() => {
    // FIRST CHECK ON PLAYER TOKEN
    if (playerTokenLoaded == false) {
      return false
    }

    const hasPlayerTokenState = playerToken?.stateLoaded && playerToken?.baseState != null

    if (hasPlayerTokenState) {
      return false
    }

    // NEXT CHECK ON ANY OTHER PLAYER TOKENS THEY MAY HAVE
    if (playerTokensLoaded == false) {
      return false
    }

    if (playerTokens == null) {
      return true
    }

    // Check the other player tokens too
    let playerTokensLength = playerTokens.length
    for(let i = 0; i < playerTokensLength; i++) {
      const token = playerTokens[i]

      if (token.stateLoaded == false || token.baseState != null) {
        return false
      }
    }

    return true
  }, [playerToken, playerTokens, playerTokensLoaded, playerTokenLoaded])

  const playerTokenByMint: Map<string, PlayerToken> | undefined = useMemo(() => {
    const tokenMap = playerTokens?.reduce((result, item) => {
      result.set(item.houseToken.tokenMintPubkey.toString(), item)
      return result
    }, new Map<string, PlayerToken>)

    if (playerToken != null && playerToken?.houseToken != null) {
      tokenMap?.set(playerToken?.houseToken.tokenMintPubkey.toString(), playerToken)
    }

    return tokenMap
  }, [playerTokens, playerToken])

  const loadPlayerToken = useCallback(async (hToken?: HouseToken, wallet?: PublicKey) => {
    try {
      const hseToken = hToken || houseToken
      const walletPkey = wallet || walletPubkey
      const pToken = await PlayerToken.load(hseToken, walletPkey);
      setPlayerTokenLoaded(true)
      setPlayerToken(pToken)
    } catch (e) {
      console.warn(`Issue loading the house from chain.`, e);
    } finally {
      setPlayerTokenLoaded(true);
    }
  }, [houseToken, walletPubkey])

  useEffect(() => {
    async function loadPlayerTokenAndStartWsHandler(wallet: PublicKey, hseToken: HouseToken, loadFx: (hToken?: HouseToken | undefined, wallet?: PublicKey | undefined) => Promise<void>, connection: Connection, erConnection: Connection, commitment?: Commitment = "processed") {
      await loadFx(hseToken, wallet)

      if (playerTokenWsId.current != null) {
        try {
          await connection.removeAccountChangeListener(playerTokenWsId.current)
        } catch(err) {
          console.warn({
            err
          })
        }
      }

      if (playerTokenErWsId.current != null) {
        try {
          await erConnection.removeAccountChangeListener(playerTokenErWsId.current)
        } catch(err) {
          console.warn({
            err
          })
        }
      }

      const playerTokenPubkey = PlayerToken.derivePlayerTokenPubkey(hseToken.publicKey, wallet, hseToken.programId)

      playerTokenWsId.current = connection.onAccountChange(playerTokenPubkey, (accInfo, context) => {
        if (accInfo.data != null) {
          const newPlayerToken = PlayerToken.loadFromBuffer(hseToken, wallet, Buffer.from(accInfo.data))
          setPlayerToken(newPlayerToken)
        } else {
          if (houseToken != null && walletPubkey != null) {
            setPlayerToken(new PlayerToken(houseToken, walletPubkey))
          }
        }
      }, { commitment: commitment})

      playerTokenErWsId.current = erConnection.onAccountChange(playerTokenPubkey, (accInfo, context) => {
        if (accInfo.data != null) {
          const newPlayerToken = PlayerToken.loadFromBuffer(hseToken, wallet, undefined, Buffer.from(accInfo.data))
          setPlayerToken(newPlayerToken)
        } else {
          if (houseToken != null && walletPubkey != null) {
            setPlayerToken(new PlayerToken(houseToken, walletPubkey))
          }
        }
      }, { commitment: commitment})
    }

    if (walletPubkey == null) {
      setPlayerToken(undefined)

      if (playerTokenWsId.current != null && client != null) {
        try {
          client.removeAccountChangeListener(playerTokenWsId.current)
        } catch(err) {
          console.warn({
            err
          })
        }
      }

      if (playerTokenErWsId.current != null && erClient != null) {
        try {
          erClient.removeAccountChangeListener(playerTokenErWsId.current)
        } catch(err) {
          console.warn({
            err
          })
        }
      }

      return
    }

    if (houseToken == null || walletPubkey == null || client == null || erClient == null) {
      return;
    }

    loadPlayerTokenAndStartWsHandler(walletPubkey, houseToken, loadPlayerToken, client, erClient)
  }, [houseToken, walletPubkey, loadPlayerToken, client, erClient]);

  const loadPlayerTokens = useCallback(async () => {
    if (houseTokens == null || walletPubkey == null) {
      throw new Error("Missing wallet or house tokens")
    }

    const playerTokenResults = await Promise.allSettled(houseTokens.map((hToken) => {
      return PlayerToken.load(hToken, walletPubkey)
    }))

    const pTokens: PlayerToken[] = []
    playerTokenResults.forEach((result) => {
      if (result.status == "fulfilled") {
        const value = result.value
        const hasState = !!value.baseState || !!value.erState

        if (hasState) {
          pTokens.push(value)
        }
      }
    })

    setPlayerTokens(pTokens)
    setPlayerTokensLoaded(true)
  }, [houseTokens, walletPubkey])

  useEffect(() => {
    if (houseTokens == null) {
      return
    }

    if (walletPubkey == null) {
      setPlayerTokens(undefined)
      return
    }

    loadPlayerTokens()
  }, [loadPlayerTokens, houseTokens, walletPubkey])

  // METHOD TO CREATE A PLAYER TOKEN AND DEPOSIT FUNDS
  const initAndDeposit = useCallback(
    async (
      sessionAuthority?: Keypair,
      sessionAuthorityLamports?: number,
      depositAmount?: number
    ) => {
      if (
        walletPubkey == null
        || playerToken == null
        || solanaRpc == null
      ) {
        console.warn(
          "Issue with wallet pubkey or solana rpc.",
          walletPubkey
        );
        throw new Error("Wallet not connected")
      }
      const tx = new Transaction();

      // INIT PLAYER TOKEN IF DOESNT EXIST
      const playerTokenExists = playerToken?.baseState != null

      if (!playerTokenExists) {
        console.log(`initializeIxn`)
        const initPlayerToken = await playerToken?.initializeIxn();
        tx.add(initPlayerToken);
      }

      if (depositAmount != null && depositAmount > 0) {
        console.log(`depositIxn`)
        const depositIx = await playerToken?.depositIxn(depositAmount)
        tx.add(depositIx);
      }


      if (sessionAuthority != undefined) {
        console.log(`updateSessionAuthorityIxn`)
        tx.add(
          await playerToken.updateSessionAuthorityIxn(
            sessionAuthority.publicKey,
            new Date(Date.now() + 86_400_000),
            sessionAuthorityLamports || 10_000
          )
        );
      }

      // SET FEE PAYER AND RECENT BLOCK
      tx.feePayer = walletPubkey
      tx.recentBlockhash = recentBlockhash.blockhash

      // SIGN WALLET
      let versionedTransaction = await toVersionedTransaction(tx, client, walletPubkey, recentBlockhash)

      versionedTransaction = await solanaRpc.signTransaction(versionedTransaction)

      const sig = await client?.sendRawTransaction(versionedTransaction.serialize(), { skipPreflight: true })

      // NOW LOAD THE NEW PLAYER ACCOUNT
      if (playerTokenExists == false) {
        // NEED TO CONFIRM THE TX BEFORE LOADING STATE
        await confirmTransaction(sig, client, recentBlockhash);
      }

      await loadPlayerToken();

      return sig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      loadPlayerToken
    ],
  );

  const depositApply = useCallback(async (): Promise<string> => {
    if (erClient == null) {
      throw new Error(`ER Client not initialised.`)
    }

    if (playerToken == null) {
      throw new Error(`Player token not initialised.`)
    }

    if (walletPubkey == null) {
      throw new Error(`Wallet not connected.`)
    }

    if (solanaRpc == null) {
      throw new Error(`Solana RPC is null.`)
    }
    const sessionAuthKp = allowsAutoSigning && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)): undefined
    const walletOrAuth = sessionAuthKp != null ? sessionAuthKp.publicKey: walletPubkey

    // const signer = Keypair.fromSecretKey(bs58.decode(signerKp))
    const depositApply = await playerToken.applyDepositTx(walletOrAuth)

    console.log({
      depositApply
    })

    const recentBlock = await erClient.getLatestBlockhash("confirmed")

    // CHECK IF WE NEED TO USE THE SESSION AUTH OR WALLET
    let sig
    
    if (sessionAuthKp == null) {
      sig = await solanaRpc.sendTransaction(depositApply, erClient, walletPubkey, new Map(), recentBlock)
    } else {
        sig = await erClient.sendTransaction(depositApply, [sessionAuthKp])
    }

    console.log(`Deposit Apply On ER -> ${sig}`)
    
    const confirmed = await erClient.confirmTransaction({
      signature: sig,
      blockhash: recentBlock.blockhash,
      lastValidBlockHeight: recentBlock.lastValidBlockHeight
    })

    if (confirmed.value.err != null) {
      throw new Error(`There was an issue applying the deposit. ${confirmed.value.err.toString()}`)
    }

    console.log(`Confirmed Deposit Apply On ER -> ${sig}`)

    return sig
  }, [signerKp, playerToken, erClient, walletPubkey, allowsAutoSigning])

  const closeUpdateSlip = useCallback(async (withConfirmation: boolean = true, commitment: Finality = "finalized"): Promise<string | undefined> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      throw new Error("Player token or wallet pubkey null.")
    }

    // CHECK IF IT NEEDS TO BE CLOSED...
    const hasUpdateSlipToClose = await playerToken.hasUpdateSlipToClose()

    console.log({
      hasUpdateSlipToClose
    })

    if (hasUpdateSlipToClose == false) {
      return
    }

    const tx = await playerToken.closeUpdateSlipTx()
    const recent = await client?.getLatestBlockhash("confirmed")
    
    const signer = allowsAutoSigning == true && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)): undefined
    let sig

    console.log(`closeUpdateSlipTx`, { signer })
    
    if (signer != null) {
      sig = await client?.sendTransaction(tx, [signer], { skipPreflight: true })
    } else {
      sig = await solanaRpc.sendTransaction(tx, client, walletPubkey, meta?.errorByCodeByProgram, recent)
    }

    console.log(`closeUpdateSlipTx `, {sig, signer})

    if (withConfirmation) {
      await client?.confirmTransaction({
        signature: sig,
        blockhash: recent.blockhash,
        lastValidBlockHeight: recent.lastValidBlockHeight
      }, commitment)
    }

    console.log(`closeUpdateSlipTx ${sig}`)

    return sig;
  }, [playerToken, client, walletPubkey, solanaRpc, meta, signerKp, allowsAutoSigning])

  // DELEGATE - USED FOR THE ER FLOW
  const initAndDelegate = useCallback(
    async (
      maxSessionLengthSeconds: number,
      instanceToDelegate: number,
      sessionAuthority?: Keypair,
      sessionAuthorityLamports?: number,
      depositAmount?: number,
      updateMessage?: Function
    ) => {
      // REMOVE WHEN CRANKER WORKING TO CLOSE THESE
      updateMessage?.(`Initializing deposit on Solana…`)
      try {
        await closeUpdateSlip(true, "confirmed")
      } catch (err) {}

      let tx = new Transaction();

      const setHeapLimitIx = ComputeBudgetProgram.requestHeapFrame({
        bytes: 8 * 32 * 1024
      })

      tx.add(setHeapLimitIx)

      // INIT PLAYER TOKEN IF DOESNT EXIST
      const playerTokenExists = playerToken?.state != null

      if (playerTokenExists == false) {
        tx = await PlayerToken.initializeTx([setHeapLimitIx], walletPubkey, houseToken, depositAmount, sessionAuthority?.publicKey, sessionAuthorityLamports, true, instanceToDelegate)
      } else if (depositAmount != null && depositAmount > 0) {
        // LOGIC IF IT ALREADY EXISTS
        tx = await playerToken.depositTx(depositAmount, true)
      }

      tx.feePayer = walletPubkey
      tx.recentBlockhash = recentBlockhash.blockhash
      
      let versionedTx: VersionedTransaction | undefined = await toVersionedTransaction(tx, client, walletPubkey, recentBlockhash)
      

      // CHECK IF WE NEED THIS SIG ALSO
      if (sessionAuthority != null && allowsAutoSigning && playerTokenExists == false) {
        versionedTx?.sign([sessionAuthority])
      }
      
      versionedTx = await solanaRpc.signTransaction(versionedTx)
      
      const sig = await client?.sendRawTransaction(versionedTx.serialize(), { skipPreflight: true })

      updateMessage?.(`Confirming transaction on Solana…`)
      
      // NEED TO CONFIRM THE TX BEFORE LOADING STATE
      const confirmed = await client?.confirmTransaction({
        signature: sig,
        blockhash: recentBlockhash.blockhash,
        lastValidBlockHeight: recentBlockhash.lastValidBlockHeight
      }, "finalized");

      if (confirmed?.value.err != null) {
        throw new Error(`There was an issue with the deposit. ${confirmed.value.err.toString()}`)
      }


      updateMessage?.(`Applying deposit on MagicBlock…`)
      
      const depositApplySig = await depositApply();

      await awaitCommitToBaseLayer(erClient, client, depositApplySig)

      // NO NEED TO CONFIRM THE TXN
      await closeUpdateSlip(false);

      updateMessage?.(`Done`)
      await loadPlayerToken();

      updateMessage?.(undefined)

      return sig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      loadPlayerToken,
      depositApply,
      closeUpdateSlip,
      erClient
    ],
  );

  const applyWithdrawalAndClose = useCallback(async (withdrawAmount: number = 0, updateMessage?: Function): Promise<string> => {
    if (client == null) {
      throw new Error(`ER Client not initialised.`)
    }

    if (playerToken == null) {
      throw new Error(`Player token not initialised.`)
    }

    if (walletPubkey == null) {
      throw new Error(`Wallet not connected.`)
    }

    if (solanaRpc == null) {
      throw new Error(`Solana RPC is null.`)
    }

    updateMessage?.(`Finalizing withdrawal and end session on Solana…`)
    const tx = new Transaction()
    
    const sessionAuth = allowsAutoSigning == true && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)): undefined;

    if (withdrawAmount > 0) {
      const withdrawIx = await playerToken.applyWithdrawalIx(sessionAuth?.publicKey)
      tx.add(withdrawIx)
    }

    const closeIx = await playerToken.closeIx(sessionAuth?.publicKey)

    tx.add(closeIx)

    const recentBlock = await client.getLatestBlockhash("confirmed")
    let sig

    if (sessionAuth == null) {
      sig = await solanaRpc.sendTransaction(tx, client, walletPubkey, new Map(), recentBlock)
    } else {
      tx.feePayer = sessionAuth.publicKey
      tx.recentBlockhash = recentBlock.blockhash

      sig = await client.sendTransaction(tx, [sessionAuth], { skipPreflight: true })
    }

    updateMessage?.(`Confirming transaction on Solana…`)
    
    const confirmed = await client.confirmTransaction({
      signature: sig,
      blockhash: recentBlock.blockhash,
      lastValidBlockHeight: recentBlock.lastValidBlockHeight
    }, "confirmed")

    if (confirmed.value.err != null) {
      throw new Error(`There was an issue with apply withdrawal and close. ${confirmed.value.err.toString()}`)
    }

    updateMessage?.(undefined)

    return sig
  }, [solanaRpc, playerToken, client, walletPubkey, allowsAutoSigning, signerKp])
  
  const withdrawAndUndelegate = useCallback(async (withdrawAmount: number = 0, updateMessage?: Function): Promise<string> => {
    if (erClient == null || client == null) {
      throw new Error(`Client not initialised.`)
    }

    if (playerToken == null) {
      throw new Error(`Player token not initialised.`)
    }

    if (walletPubkey == null) {
      throw new Error(`Wallet not connected.`)
    }

    if (solanaRpc == null) {
      throw new Error(`Solana RPC is null.`)
    }
    const tx = new Transaction()

    updateMessage?.(`Initializing withdrawal and end session on MagicBlock…`)

    const sessionAuth = allowsAutoSigning && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)): undefined

    if (withdrawAmount > 0) {
      const withdrawIx = await playerToken.initializeWithdrawalIx(withdrawAmount, sessionAuth?.publicKey)
      tx.add(withdrawIx)
    }

    const undelegateIx = await playerToken.undelegateIx(sessionAuth?.publicKey)

    tx.add(undelegateIx)

    const recentBlock = await erClient.getLatestBlockhash("confirmed")

    let sig

    if (sessionAuth == null) {
      sig = await solanaRpc.sendTransaction(tx, erClient, walletPubkey, new Map(), recentBlock)
    } else {
      tx.feePayer = sessionAuth.publicKey
      tx.recentBlockhash = recentBlock.blockhash

      sig = await erClient.sendTransaction(tx, [sessionAuth], { skipPreflight: true })
    }
    console.log(`undelegate/withdraw ER -> ${sig}`)

    updateMessage?.(`Confirming transaction on MagicBlock…`)
    
    const confirmed = await erClient.confirmTransaction({
      signature: sig,
      blockhash: recentBlock.blockhash,
      lastValidBlockHeight: recentBlock.lastValidBlockHeight
    }, "confirmed")

    if (confirmed.value.err != null) {
      throw new Error(`Issue with withdraw and undelegate. ${confirmed.value.err.toString()}`)
    }

    console.log(`Awaiting commit on Solana…`)

    updateMessage?.(`Awaiting commit on Solana…`)

    await awaitCommitToBaseLayer(erClient, client, sig, 'confirmed')

    console.log(`Made it to base layer -> ${sig}`)

    const withdrawAndCloseSig = await applyWithdrawalAndClose(withdrawAmount, updateMessage)

    updateMessage?.(undefined)

    return withdrawAndCloseSig
  }, [signerKp, playerToken, erClient, client, walletPubkey, applyWithdrawalAndClose, solanaRpc, signerKp, allowsAutoSigning])

  const updateSessionAuthority = useCallback(
    async (
      maxSessionLengthSeconds: number,
      sessionAuthority: PublicKey,
      sessionAuthorityLamports: number
    ) => {

      const tx = new Transaction();

      const setHeapLimitIx = ComputeBudgetProgram.requestHeapFrame({
        bytes: 8 * 32 * 1024
      })

      tx.add(setHeapLimitIx)


      tx.add(
        await playerToken.updateSessionAuthorityIxn(
          sessionAuthority,
          new Date(Date.now() + Math.round(maxSessionLengthSeconds * 1000)),
          sessionAuthorityLamports || 0
        )
      );

      const sig = await solanaRpc.sendTransaction(
        tx,
        client,
        walletPubkey,
        meta?.errorByCodeByProgram,
        recentBlockhash
      );

      return sig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken
    ],
  );

  const withdraw = useCallback(
    async (
      amountBasis: number
    ) => {
      let recentBlock = await client?.getLatestBlockhash("confirmed")

      const tx = new Transaction()

      if (allowsAutoSigning == true && signerPublicKey != null) {
        // CHECK IF NEEDS AN UPDATE
        const needsUpdate = await playerToken?.needsSessionAuthorityUpdate(signerPublicKey, MIN_LAMPORTS_AUTO_SIGNER, lamportBalance)
        console.log(`updateSessionAuthorityIxn`)

        if (needsUpdate == true) {
          const topUpLamports = lamportBalance - MIN_LAMPORTS_AUTO_SIGNER
          tx.add(
            await playerToken.updateSessionAuthorityIxn(
              signerPublicKey,
              new Date(Date.now() + 86_400_000),
              topUpLamports > 0 ? topUpLamports: 0
            )
          );
        }
      } else if (allowsAutoSigning == false) {
        const needsUpdate = playerToken?.sessionAuthority.toString() != walletPubkey?.toString()

        if (needsUpdate == true) {
          tx.add(
            await playerToken.updateSessionAuthorityIxn(
              walletPubkey,
              new Date(),
              0
            )
          );
        }
      }

      const withdrawIxn = await playerToken.withdrawIxn(amountBasis)

      tx.add(withdrawIxn)
      tx.feePayer = walletPubkey
      tx.recentBlockhash = recentBlock?.blockhash

      let versionedTransaction = await toVersionedTransaction(tx, client, walletPubkey, recentBlock)
      versionedTransaction = await solanaRpc?.signTransaction(versionedTransaction)

      const withdrawSig = await client?.sendRawTransaction(versionedTransaction.serialize(), { skipPreflight: true })
      console.log({
        withdrawSig
      })
      await confirmTransaction(withdrawSig, client, recentBlockhash);
      console.log(`CONFIRMED`, {
        withdrawSig
      })

      return withdrawSig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      allowsAutoSigning,
      signerPublicKey
    ],
  );

  const withdrawFundsAndEndSession = useCallback(async (updateMessage?: Function): Promise<string> => {
    if (playerToken == null || playerToken.state == null) {
      throw new Error("Player Token is null")
    }
    let sig: string = ''

    // BASE predelegate update slip
    updateMessage?.(`Initializing withdrawal and end session on MagicBlock…`)
    sig = await updateSlipPredelegate(true)
    console.log(`updateSlipPredelegateSig -> ${sig}`)

    // UNDELEGATE THE ACCOUNT, WITHDRAW TOKENS
    const amountToWithdraw = playerToken.playBalance
    
    sig = await withdrawAndUndelegate(amountToWithdraw, updateMessage)

    // LOAD THE PLAYER TOKEN ACCOUNT
    await loadPlayerToken()

    updateMessage?.(undefined)

    return sig
  }, [withdrawAndUndelegate, withdraw, playerToken, loadPlayerToken, erClient, client])

  const updateSlipPredelegate = useCallback(async (withConfirmation: boolean = true): Promise<string> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      throw new Error("Player token or wallet pubkey null.")
    }
    
    const recent = await client?.getLatestBlockhash("confirmed")

    // CHECK IF WE SHOULD USE SESSION AUTH OR WALLET
    const sessionAuth = allowsAutoSigning == true && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)): undefined;

    const tx = await playerToken.predelegateUpdateSlipTx(sessionAuth?.publicKey || walletPubkey)
    console.log(`UPDATE SLIP PREDELEGATE`)
    
    let sig
    
    if (sessionAuth == null) {
      sig = await solanaRpc.sendTransaction(tx, client, walletPubkey, meta?.errorByCodeByProgram, recent)
    } else {
      tx.feePayer = sessionAuth.publicKey
      tx.recentBlockhash = recent?.blockhash

      sig = await client?.sendTransaction(tx, [sessionAuth], { skipPreflight: true })
    }
    console.log(`UPDATE SLIP PREDELEGATE ${sig}`)

    if (withConfirmation) {
      const confirmed = await client?.confirmTransaction({
        signature: sig,
        blockhash: recent.blockhash,
        lastValidBlockHeight: recent.lastValidBlockHeight
      }, "finalized")

      if (confirmed?.value.err != null) {
        throw new Error(`Issue predelegating. ${confirmed?.value.err.toString()}`)
      }
    }

    console.log(`CONFIRMED -> UPDATE SLIP PREDELEGATE ${sig}`)

    return sig;
  }, [playerToken, client, walletPubkey, solanaRpc, meta, signerKp, allowsAutoSigning])

  const withdrawInit = useCallback(async (amountBasis: number, withConfirmation: boolean = true): Promise<string> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      throw new Error("Player token or wallet pubkey null.")
    }
    const sessionAuth = allowsAutoSigning && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)): undefined;

    const ix = await playerToken.initializeWithdrawalIx(amountBasis, sessionAuth?.publicKey)
    const tx = new Transaction()
    tx.add(ix)

    console.log(`withdrawInit`)
    const recentBlock = await erClient?.getLatestBlockhash("confirmed")

    let sig

    if (sessionAuth == null) {
      sig = await solanaRpc.sendTransaction(tx, erClient, walletPubkey, meta?.errorByCodeByProgram, recentBlock)
    } else {
      tx.feePayer = sessionAuth.publicKey
      tx.recentBlockhash = recentBlock?.blockhash

      sig = await erClient?.sendTransaction(tx, [sessionAuth], { skipPreflight: true })
    }
    console.log(`withdrawInit ${sig}`)

    if (withConfirmation) {
      const confirmed = await erClient?.confirmTransaction({
        signature: sig,
        blockhash: recentBlock.blockhash,
        lastValidBlockHeight: recentBlock.lastValidBlockHeight
      }, "finalized")

      if (confirmed?.value.err != null) {
        throw new Error(`Issue with withdrawal. ${confirmed.value.err.toString()}`)
      } 
    }

    console.log(`CONFIRMED -> withdrawInit ${sig}`)

    return sig;
  }, [playerToken, erClient, walletPubkey, solanaRpc, meta, allowsAutoSigning, signerKp])

  const withdrawApply = useCallback(async (withConfirmation: boolean = true): Promise<string> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      throw new Error("Player token or wallet pubkey null.")
    }

    const sessionAuth = allowsAutoSigning && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)): undefined;
    
    const ix = await playerToken.applyWithdrawalIx(sessionAuth?.publicKey)
    const tx = new Transaction()
    tx.add(ix)

    console.log(`withdrawApply`)

    const recent = await client?.getLatestBlockhash("confirmed")
    let sig

    if (sessionAuth == null) {
      sig = await solanaRpc.sendTransaction(tx, client, walletPubkey, meta?.errorByCodeByProgram, recent)
    } else {
      tx.feePayer = sessionAuth?.publicKey;
      tx.recentBlockhash = recent?.blockhash
      sig = await client?.sendTransaction(tx, [sessionAuth], { skipPreflight: true })
    }
    console.log(`withdrawApply ${sig}`)

    if (withConfirmation) {
      const confirmed = await client?.confirmTransaction({
        signature: sig,
        blockhash: recent.blockhash,
        lastValidBlockHeight: recent.lastValidBlockHeight
      }, "finalized")

      if (confirmed?.value.err) {
        throw new Error(`Issue applying the withdrawal. ${confirmed?.value.err.toString()}`)
      }
    }

    console.log(`withdrawApply ${sig}`)

    return sig;
  }, [playerToken, client, walletPubkey, solanaRpc, meta, allowsAutoSigning, signerKp])

  const processWithdrawal = useCallback(async (amountBasis: number, updateMessage?: Function) => {
    if (playerToken?.houseToken.isDelegated == true) {
      updateMessage?.(`Preparing for withdrawal on Solana…`)
      try {
        await closeUpdateSlip(true, "confirmed")
      } catch (err) {}

      // // Base: update_slip_predelegate (permissionless, but requires a payer)
      const updateSlipPredelegateSig = await updateSlipPredelegate(true)

      updateMessage?.(`Initializing withdrawal on MagicBlock…`)

      // // ER: player_token_withdraw_initialize
      const withdrawInitSig = await withdrawInit(amountBasis, true)

      updateMessage?.(`Awaiting commit on Solana…`)
      await awaitCommitToBaseLayer(erClient, client, withdrawInitSig)
      

      updateMessage?.(`Finalizing withdrawal on Solana…`)
      // Base: player_token_withdraw_apply (permissionless)
      const withdrawApplySig = await withdrawApply()
      updateMessage?.(`Done`)

      await loadPlayerToken()
      return withdrawApplySig
    } else {
      console.log(`withdraw ${amountBasis}`)
      const withdrawSig = await withdraw(amountBasis)
      await loadPlayerToken()

      return withdrawSig
    }
  }, [playerToken, withdraw, loadPlayerToken, withdrawApply, withdrawInit, updateSlipPredelegate, closeUpdateSlip, erClient, client])

  const { playerValidation } = useContext(ErrorHandlingContext);

  // MINIMUM LAMPORTS CHECK
  useEffect(() => {
    if (allowsAutoDeposit == false && (playerToken == null || playerToken.baseState == null)) {
      playerValidation.addErrorMessage({
        type: ErrorType.NO_PLAYER_TOKEN,
        title: "No Player Token",
        message: "Please start a session and deposit tokens to play.",
      });
    } else {
      playerValidation.removeErrorMessage(ErrorType.NO_PLAYER_TOKEN);
    }
  }, [playerToken, allowsAutoDeposit]);

  // WHEN WE LOAD THE PLAYER TOKEN, WE NEED TO CHECK THE allowsAutoSigning flag is in line with the state
  const loadedPlayerTokenRef = useRef<string>()
  useEffect(() => {
    if (loadedPlayerTokenRef.current == playerToken?.publicKey?.toString()) {
      return
    }

    // IF THERE IS NOTHING ON CHAIN, DO NOTHING
    if (playerToken != null && playerToken?.baseState == null) {
      loadedPlayerTokenRef.current = playerToken?.publicKey.toString()
      return
    }

    const sessionAuthOnChain = playerToken?.sessionAuthority?.toString()
    const walletString = walletPubkey?.toString()

    if (sessionAuthOnChain == null || sessionAuthOnChain == walletString) {
      setAllowsAutoSigning(false)
    } else if (sessionAuthOnChain != null && sessionAuthOnChain != walletString) {
      setAllowsAutoSigning(true)
    }

    loadedPlayerTokenRef.current = playerToken?.publicKey.toString()
  }, [playerToken])

  return (
    <PlayerTokenContext.Provider
      value={useMemo(
        () => ({
          playerToken: playerToken,
          playerTokens: playerTokens,
          playerTokenByMint: playerTokenByMint,
          playerTokenLoaded: playerTokenLoaded,
          loadPlayerToken: loadPlayerToken,
          loadPlayerTokens: loadPlayerTokens,
          initAndDeposit: initAndDeposit,
          initAndDelegate: initAndDelegate,
          processWithdrawal: processWithdrawal,
          updateSessionAuthority: updateSessionAuthority,
          setPlayerMeta: setPlayerMetaWithCounter,
          playerMeta: playerMeta,
          hasNoPlayerTokenState: hasNoPlayerTokenState,
          withdrawFundsAndEndSession: withdrawFundsAndEndSession
        }),
        [
          playerToken,
          playerTokenLoaded,
          loadPlayerToken,
          loadPlayerTokens,
          initAndDeposit,
          initAndDelegate,
          playerTokens,
          playerTokenByMint,
          processWithdrawal,
          updateSessionAuthority,
          setPlayerMeta,
          playerMeta,
          hasNoPlayerTokenState,
          withdrawFundsAndEndSession
        ],
      )}
    >
      {children}
    </PlayerTokenContext.Provider>
  );
};
