import { useContext } from "react";

import { CurrentPlayerAvatar } from "../common";
import { WrappedWalletContext } from "../../contexts";

export const UserDetails = () => {
  const { walletPubkey } = useContext(WrappedWalletContext);

  return (
    <div
      className={`
        cursor-pointer text-gray-300 flex items-center lg:space-x-3 ps-0 rounded
        border-solid border border-gray-600 bg-gray-700 hover:bg-gray-600 active:bg-gray-800 flex-1
      `}
    >
      <div className="flex items-center justify-center">
        <CurrentPlayerAvatar
          playerPublicKey={walletPubkey}
          className="rounded w-10 h-10 aspect-square"
        />
      </div>
    </div>
  );
};
