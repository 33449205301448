export enum ChainType {
  SOLANA = "solana",
  ETHEREUM = "eth",
}

export const defaultChainType = ChainType.SOLANA;

export enum NetworkType {
  DEVNET = "devnet",
  MAINNET = "mainnet",
}

export const defaultNetworkType = NetworkType.DEVNET;

export enum APP_NETWORK_TYPE {
  SOLANA="SOLANA",
  SONIC="SONIC",
  MB_AND_SOLANA="MB_AND_SOLANA"
}
