import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import House from "../house";
import GameSpec from "../gameSpec";
import { ZeebitV2 } from "../../target/types/zeebit_v2";
import PlayerToken from "../playerToken";
import GameInstanceSolo from "../gameInstance";

export default class Tower extends GameSpec {

  constructor(
    house: House,
    // hseTkn: HouseToken,
    gameSpecPubkey: PublicKey,
  ) {
    super(
      house,
      // hseTkn,
      gameSpecPubkey,
    )
    this.gameSpecPubkey = gameSpecPubkey;
  }
  static createNewInstance(
    game: GameSpec,
    gameSpecPubkey: PublicKey,
    playerToken: PlayerToken,
    prevInstance: TowerInstance,
    stateUpdate: any) {
    console.log('createNewState', { prevInstance, stateUpdate });
    console.log('state', stateUpdate);

    const newState = { stateUpdate }
    return new TowerInstance(
      game as Tower,
      gameSpecPubkey,
      playerToken,
      newState
    )
  }
  static async load(
    house: House,
    gameSpecPubkey: PublicKey,
  ) {
    const game = new Tower(
      house,
      gameSpecPubkey,
    )
    await game.loadAllState();
    return game
  }

  static async loadInstance(
    game: GameSpec,
    gameSpecPubkey: PublicKey,
    instancePubkey: PublicKey,
    playerToken: PlayerToken,
    baseInstanceState?: any,
    erInstanceState?: any,
  ) {

    const baseState = baseInstanceState || await game.loadInstanceState(instancePubkey);
    const erState = erInstanceState || await game.loadErInstanceState(instancePubkey);



    if (baseState || erState) {
      return new TowerInstance(
        game as Tower,
        gameSpecPubkey,
        playerToken,
        baseState,
        erState
      )
    }
    return null;
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: {
      riskIdx: number,
      chosenStepIdx: number,
      wagerBasis: number,
      action?: {
        chosenStepIdx: number
      },
      insurance: boolean,
      identifier: PublicKey,
    },
    wager: number,
    clientSeed: number[],
  ) {
    const instanceRequest = {
      tower: { riskIdx: inputs.riskIdx },
    };

    const betRequests = [
      {
        tower: {
          chosenStepIdx: inputs.chosenStepIdx,
          wager: new anchor.BN(inputs.wagerBasis)
        }
      }
    ];

    const actionRequest = inputs.action ? {
      tower: {
        chosenStepIdx: inputs.action.chosenStepIdx
      }
    } : null;
    console.log({ actionRequest, betRequests, instanceRequest });

    const numberOfBets = 1;

    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      actionRequest,
      0,
      clientSeed,
      inputs.identifier,
    );
  }

  get state() {
    // FALL BACK TO BASE STATE
    return this.isDelegated ? (this.erState || this.baseState): this.baseState;
  }

  get gameConfig() {
    return this.state ? this.state.config.blackjack : null;
  }

  get houseEdge(): number {
    if (this.state.config.tower) {
      return Number(this.state.config.tower.edgePerMillion) / 1_000_000
    } else {
      return undefined
    }
  }

  get maxMultiplier(): number {
    return 10 // TODO MAX MULTIPLIER
  }

  potentialPayout(
    wager: number
  ): number {
    return wager * this.maxMultiplier
  }

  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;
    let totalWagerBasis = 0;

    bets.forEach((bet) => {
      const multiplier = bet.multiplier;
      const payoutOnBet = multiplier * bet.wager;
      const probability = bet.probability;

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wagerBasis;
      totalWagerBasis += bet.wagerBasis;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      totalWagerBasis: totalWagerBasis,
      edgePercentage: edgeDollar / totalWagerBasis, // USED IN CALCULATING MAX BET VIA KELLY
    };
  }
}

// TOWER SPECIFIC LOGIC IN HERE
export class TowerInstance extends GameInstanceSolo {

  constructor(
    gameSpec: GameSpec,
    instancePubkey: PublicKey,
    playerToken: PlayerToken,
    baseState: anchor.IdlAccounts<ZeebitV2>["instanceSolo"],
    erState: anchor.IdlAccounts<ZeebitV2>["instanceSolo"]
  ) {
    super(instancePubkey, gameSpec, playerToken, baseState, erState)
  }

  get isPlayersTurn(): boolean {
    return this.state && (Object.keys(this.state.status)[0] == "awaitingPlayerResponse") ? true : false
  }

  get hasSate() {
    return this.state != null
  }
}





