import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import House from "../sdk/house";
import { ProgramContext } from "./ProgramContext";
import {
  HOUSE_STATUS_TAKING_BETS,
  getHousePubkey,
} from "../sdk/constants";
import { BN, Program } from "@coral-xyz/anchor";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import HouseToken from "../sdk/houseToken";
import { BalanceContext } from "./BalanceContext";
import { PublicKey, Transaction } from "@solana/web3.js";
import { NetworkContext } from "./NetworkContext";
import { APP_NETWORK_TYPE } from "../types/chain";
import { ICasinoToken } from "../types/token";
import { confirmTransaction, toVersionedTransaction } from "../utils/solana/utils";
import { WrappedWalletContext } from "./WrappedWalletContext";

export interface IHouseValidation {
  takingBets: boolean;
}

export interface IHouseContext {
  house: House | undefined;
  houseLoaded: boolean;
  loadHouse: (program?: Program) => Promise<void>
  houseToken: HouseToken | undefined;
  houseTokens: HouseToken[] | undefined
  houseTokensLoaded: boolean;
  houseTokenByMint: Map<string, HouseToken> | undefined
  houseTokenByPubkey: Map<string, HouseToken> | undefined
  airdropTokens: (amount: number, tokenMint: PublicKey) => Promise<string>
}

export const HouseContext = createContext<IHouseContext>({} as IHouseContext);

interface Props {
  children: any;
}

export const HouseProvider = ({ children }: Props) => {
  const [house, setHouse] = useState<House>();
  const [houseTokens, setHouseTokens] = useState<HouseToken[]>()

  const [houseLoaded, setHouseLoaded] = useState(false);
  const [houseTokensLoaded, setHouseTokensLoaded] = useState(false);

  const { meta } = useContext(ProgramContext);
  const { selectedTokenMeta } = useContext(BalanceContext)
  const { chain, platformTokens } = useContext(NetworkContext)

  const houseTokenByMint = useMemo(() => {
    return houseTokens?.reduce((result, item) => {
      result.set(item.tokenMintPubkey.toString(), item)

      return result
    }, new Map<string, HouseToken>())
  }, [houseTokens])

  const houseTokenByPubkey = useMemo(() => {
    return houseTokens?.reduce((result, item) => {
      result.set(item.publicKey.toString(), item)

      return result
    }, new Map<string, HouseToken>())
  }, [houseTokens])

  const houseToken = useMemo(() => {
    return houseTokenByMint?.get(selectedTokenMeta.mint)
  }, [houseTokenByMint, selectedTokenMeta]);

  // METRICS USED IN VALIDATIONS - KEEP THE STATUS
  const [validation, setValidation] = useState<IHouseValidation>();

  useEffect(() => {
    if (house == null) {
      return;
    }

    setValidation({
      takingBets: house.status != null && HOUSE_STATUS_TAKING_BETS.includes(house.status)
    });
  }, [house]);

  const loadHouse = useCallback(async (program?: Program, erProgram?: Program, chainIn?: APP_NETWORK_TYPE) => {
    try {
      const baseProgram = program || meta?.zeebitV2Program
      const rollupProgram = erProgram || meta?.zeebitV2ErProgram
      const chainVal = chainIn || chain

      const housePubkey = getHousePubkey(chainVal)

      const house = await House.load(baseProgram, rollupProgram, housePubkey);

      setHouse(house);
    } catch (e) {
      console.warn(`Issue loading the house from chain.`, e);
    } finally {
      setHouseLoaded(true);
    }
  }, [meta?.zeebitV2Program, meta?.zeebitV2ErProgram, chain])

  // LOAD ALL THE HOUSE TOKENS
  useEffect(() => {
    async function loadHouseTokens(hse: House, tokens: ICasinoToken[]) {
      // LOAD ALL HOUSE TOKENS FROM CONFIG FILE
      const houseTokenResults = await Promise.allSettled(tokens.map((token) => {
        return HouseToken.load(hse, new PublicKey(token.pubkey))
      }))

      // USE ANY THAT ARE FOUND
      let hseTokens: HouseToken[] = []

      houseTokenResults.forEach((tokenRes) => {
        if (tokenRes.status == "fulfilled") {
          const hasState = !!tokenRes.value.baseState || !!tokenRes.value.erState

          if (hasState == true) {
            hseTokens.push(tokenRes.value)
          }
        }
      })

      setHouseTokensLoaded(true)
      setHouseTokens(hseTokens)
    }

    if (house == null) {
      return
    }

    loadHouseTokens(house, platformTokens)
  }, [house, platformTokens])

  useEffect(() => {
    if (meta == null || meta.zeebitV2ErProgram == null || meta.zeebitV2Program == null || chain == null) {
      return;
    }

    loadHouse(meta.zeebitV2Program, meta.zeebitV2ErProgram, chain);
  }, [meta, loadHouse, chain]);

  const errorHandling = useContext(ErrorHandlingContext);

  // VALIDATE THE HOUSE STATUS IS OPEN - Disable button, show message above button
  useEffect(() => {
    if (house == null || validation == null) {
      return;
    }

    if (validation.takingBets == false) {
      errorHandling.houseValidation.addErrorMessage({
        type: ErrorType.HOUSE_NOT_ACTIVE,
        title: "House not active",
        message: "The house is not currently taking bets.",
      });
    } else {
      errorHandling.houseValidation.removeErrorMessage(ErrorType.HOUSE_NOT_ACTIVE);
    }
  }, [validation, house]);

  const { recentBlockhash, client } = useContext(NetworkContext)
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext)
  const airdropTokens = useCallback(async (amount: number, tokenMint: PublicKey): Promise<string> => {
    if (house == null || recentBlockhash == null || client == null || walletPubkey == null || solanaRpc == null) throw new Error("House, client, wallet or recentBlockhash is null")

    const tx = new Transaction()
    const ix = await HouseToken.airdropTokensIx(house, amount, walletPubkey, tokenMint)
    tx.add(ix)
    tx.feePayer = walletPubkey
    tx.recentBlockhash = recentBlockhash.blockhash
    let versionedTransaction = await toVersionedTransaction(tx, client, walletPubkey, recentBlockhash)
    versionedTransaction = await solanaRpc.signTransaction(versionedTransaction)
    
    console.log(`SENDING TRANSACTION TO AIRDROP TOKENS`)
    const sig = await client?.sendRawTransaction(versionedTransaction.serialize(), { skipPreflight: true })
    console.log(`Got the Txn Signature back -> ${sig}`)
    await confirmTransaction(sig, client, recentBlockhash);
    console.log(`confirmed transaction -> ${sig}`)

    return sig;
  }, [house, recentBlockhash, client, walletPubkey, solanaRpc])

  return (
    <HouseContext.Provider
      value={useMemo(
        () => ({
          house: house,
          houseLoaded: houseLoaded,
          loadHouse: loadHouse,
          houseToken: houseToken,
          houseTokens: houseTokens,
          houseTokensLoaded: houseTokensLoaded,
          houseTokenByMint: houseTokenByMint,
          airdropTokens: airdropTokens,
          houseTokenByPubkey: houseTokenByPubkey
        }),
        [house, houseLoaded, loadHouse, houseToken, houseTokensLoaded, houseTokenByMint, houseTokens, airdropTokens, houseTokenByPubkey],
      )}
    >
      {children}
    </HouseContext.Provider>
  );
};
