import React from "react";
import SVG from "react-inlinesvg";
import { twMerge } from "tailwind-merge";
import { PublicKey } from "@solana/web3.js";

import { AvatarType } from "../../../hooks/useUserMeta";
import { generatePlayerAvatar } from "./utils";

export interface IAvatarProps {
  playerPublicKey?: PublicKey;
  playerAvatarKey?: PublicKey;
  className?: string;
  width?: number;
  height?: number;
  shouldGenerateAvatar?: boolean;
}

export const PlayerAvatar: React.FC<IAvatarProps> = ({
  playerPublicKey,
  playerAvatarKey,
  width,
  height,
  className,
  shouldGenerateAvatar
}) => {
  let avatarMeta = generatePlayerAvatar(playerPublicKey)

  const imageProps = {
    src: avatarMeta.value,
    width: width,
    height: height,
    className: `bg-animated-gradient-gray-700-gray-800 ${className}`
  };

  return (
    imageProps.src.length
      ? (
        <>
          {
            avatarMeta?.type == AvatarType.SVG_STRING
              ? (<SVG {...imageProps} />)
              : (<img {...imageProps} />)
          }
        </>
      ) : (
        <div
          className={twMerge(
            width ? `w-[${width}px]` : "",
            height ? `h-[${height}px]` : "",
            imageProps.className
          )}
        />
      )
  );
};