import React, { FC, useState } from "react";
import { BASE_BUTTON_CLASSES } from "../../../styles/commonClasses";
import { twMerge } from "tailwind-merge";

export interface TabsProps {
  tabs: ({
    name: string;
    label: string;
    disable?: boolean;
  } | null)[];
  onTabSelect: React.Dispatch<React.SetStateAction<string>>;
  activeTab: string;
  variant?: "modal" | "common";
  classes?: {
    wrapper?: string;
    tab?: string;
  };
}

const Tabs: FC<TabsProps> = ({ tabs, variant = "modal", activeTab, classes, onTabSelect }) => {
  //   const [currentTab, setCurrentTab] = useState<string>(tabs[0].name);
  const notNullTabs = tabs.filter((tab) => tab !== null);
  if (notNullTabs.length <= 1) return null;
  return (
    <div className={twMerge("flex", classes?.wrapper)}>
      {notNullTabs.map(({ name, label, disable }) => {
        if (variant === "modal") {
          return (
            <button
              key={name}
              onClick={() => onTabSelect(name)}
              disabled={disable}
              className={twMerge(
                BASE_BUTTON_CLASSES,
                `flex w-full cursor-pointer content-center justify-center border-y-2 px-3 py-3 first:rounded-s-md first:border-s-2 last:rounded-e-md last:border-e-2 sm:px-6 
                        ${
                          activeTab === name
                            ? "border-gray-50 bg-gray-50 text-gray-800 "
                            : "border-gray-600 bg-gray-800 text-gray-50 hover:bg-gray-700 disabled:bg-gray-800 disabled:text-gray-400"
                        }
                `,
                classes?.tab,
              )}
            >
              {label}
            </button>
          );
        }
      })}
    </div>
  );
};

export default Tabs;
