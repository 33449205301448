import { SideBarMobile } from "../left-nav/SideBarMobile";
import { SCROLLBAR_CLASSES } from "../../styles/commonClasses";
import { UserProfileWidget } from "../common/widget/UserProfileWidget";
import { useContext, useState, Fragment, useMemo } from "react";
import { truncatePubkey } from "../../utils/string/string";
import Icon from "../common/icon/Icon";
import CopyIcon from "../../assets/icons/copy.svg";
import ZeebrosIcon from "../../assets/zeebros/zeebros_z.png";

import NavItem from "../common/navigation-item/NavItem";
import { NavContext, ProfileTab } from "../../contexts/NavContext";
import { useNavigate } from "react-router-dom";
// import { NftStakingContext } from "../../contexts/NftStakingContext";
// import { ZeebrosPageTabs } from "../../pages/ZeebrosPage";
import { WrappedWalletContext, BrowserPreferenceContext } from "../../contexts";

interface IUserProfileMobile {
  panelOpen: boolean;
}

export function UserProfileMobile({ panelOpen }: IUserProfileMobile) {
  // const { setCurrentTab } = useContext(NftStakingContext)
  const { disconnect, walletPubkey } = useContext(WrappedWalletContext);
  const [copiedPubkey, setCopiedPubkey] = useState(false);
  const { toggleUserProfilePanel } = useContext(NavContext);

  const copiedWithReset = () => {
    setCopiedPubkey(true);

    setTimeout(() => {
      setCopiedPubkey(false);
    }, 1500);
  };

  const { language } = useContext(BrowserPreferenceContext);

  const navigate = useNavigate();
  const { setProfileTab } = useContext(NavContext);
  const NAVIGATIONS = useMemo(() => {
    return [
      // {
      //   iconName: "user",
      //   name: "Profile",
      //   onClick: () => {
      //     setProfileTab(ProfileTab.DASHBOARD);
      //     navigate(`/profile`);
      //     toggleUserProfilePanel();
      //   },
      //   active: true,
      // },
      // {
      //   iconName: "calendar",
      //   name: "Rewards",
      //   onClick: () => {
      //     setProfileTab(ProfileTab.REWARDS_CALENDAR);
      //     navigate(`/profile`);
      //     toggleUserProfilePanel();
      //   },
      //   active: true,
      // },
      // {
      //   iconName: "link",
      //   name: "Referrals",
      //   onClick: () => {
      //     setProfileTab(ProfileTab.REFERRALS);
      //     navigate(`/profile`);
      //     toggleUserProfilePanel();
      //   },
      //   active: false,
      // },
      // {
      //   iconName: "settings",
      //   name: "Settings",
      //   onClick: () => {
      //     setProfileTab(ProfileTab.SETTINGS);
      //     navigate(`/profile`);
      //     toggleUserProfilePanel();
      //   },
      //   active: true,
      // },
    ];
  }, [language]);

  return (
    <SideBarMobile sidebarOpen={panelOpen}>
      <div className={`flex grow flex-col overflow-y-auto ${SCROLLBAR_CLASSES}`}>
        <nav className={`flex flex-col gap-y-5`}>
          {/* USER WIDGET */}
          {/*<UserProfileWidget />*/}

          {/* COPY PUBKEY */}
          <div className="flex p-0 flex-col w-full items-start">
            <div
              className={`flex self-stretch h-[48px] justify-center items-center gap-x-1 bg-gray-700 rounded-md font-sm ${
                copiedPubkey ? "" : "cursor-pointer"
              }`}
              onClick={() => {
                if (copiedPubkey == true) {
                  return;
                }

                const wallet = walletPubkey?.toString() || "";
                navigator.clipboard.writeText(wallet);
                copiedWithReset();
              }}
            >
              {copiedPubkey ? (
                <div className="text-gray-200">Address Copied!</div>
              ) : (
                <Fragment>
                  <div className="text-sm">{truncatePubkey(walletPubkey?.toString() || "", 4)}</div>
                  <div>{<Icon size={"lg"} icon={<CopyIcon />} />}</div>
                </Fragment>
              )}
            </div>
          </div>
          {/* NAVIGATIONS WITHIN APP */}
          <ul role="list" className="flex flex-1 flex-col gap-y-2">
            {NAVIGATIONS.map((navigation, index) => {
              return (
                <NavItem
                  isSoon={navigation.active == false}
                  isSelected={false}
                  isTopLevel
                  key={navigation.name}
                  iconName={navigation.iconName}
                  collapsed={false}
                  title={navigation.name}
                  onClick={() => navigation.onClick()}
                />
              );
            })}
            {/*<NavItem*/}
            {/*  isSoon={false}*/}
            {/*  isSelected={false}*/}
            {/*  isTopLevel*/}
            {/*  key={'zeebros-nft'}*/}
            {/*  icon={<Icon icon={<img src={ZeebrosIcon} className="drop-shadow-white-glow" />} size="lg" />}*/}
            {/*  collapsed={false}*/}
            {/*  title={'My Zeebros'}*/}
            {/*  onClick={() => {*/}
            {/*    // setCurrentTab(ZeebrosPageTabs.MyZeebros)*/}
            {/*    navigate(`/zeebros`)*/}
            {/*    toggleUserProfilePanel();*/}
            {/*  }}*/}
            {/*/>*/}
            <NavItem
              isSelected={false}
              isTopLevel
              key={"Sign Out"}
              iconName={"log_out"}
              collapsed={false}
              title={"Sign Out"}
              onClick={() => {
                disconnect();
                navigate(`/`);
                toggleUserProfilePanel();
              }}
            />
          </ul>
        </nav>
      </div>
    </SideBarMobile>
  );
}
