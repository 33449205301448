import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useState} from "react";
import { WALLET_ADAPTERS } from "@web3auth/base";
import { twMerge } from "tailwind-merge";

import { BaseModal } from "./BaseModal";
import Icon from "../../components/common/icon/Icon";
import GoogleIcon from "../../assets/icons/google-colored.svg";
import TwitterIcon from "../../assets/icons/twitter-colored.svg";
import DiscordIcon from "../../assets/icons/discoard-colored.svg";
import { Loading } from "./Loading";
import { WrappedWalletContext } from "../../contexts";
import { NetworkContext } from "../../contexts";
import { getEnabledWalletsForChain } from "../../constants/web3Wallet";
import { SonicContext } from "../../contexts";
import { NetworksSwitcher } from "../left-nav/NetworksSwitcher";
import IconFont from "../common/iconFont/IconFont";
import { truncatePubkey } from "../../utils/string/string";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Button } from "../common";

export enum WalletButtonType {
  CONNECTED = "connected",
  ACTIVE = "active",
  UNSUPPORTED = "unsupported",
  SUPPORTED = "supported",
  SWITCH_NETWORK = "switch_network",
}

interface IOnboardingModalProps {
  open: boolean;
  closeModal: (value: boolean) => void;
}

// MODAL USED TO CONNECT OR REGISTER WALLET
const ConnectionModal: React.FC<IOnboardingModalProps>  = ({
  open,
  closeModal,
}) => {

  const showTestFeatures = localStorage.getItem('zeebit-test-features');

  const { loggedIn, connect, select, disconnect, walletPubkey } = useContext(WrappedWalletContext);
  const { chain, chainDisplayName } = useContext(NetworkContext);
  const { wallets, wallet } = useContext(WrappedWalletContext);
  const { logout } = useContext(SonicContext);

  const [isLoading, setIsLoading] = useState(false);

  const [
    shouldShowAllDetectedWallets, setShouldShowAllDetectedWallets
  ] = useState(false);
  const [
    shouldShowAllSupportedWallets, setShouldShowAllSupportedWallets
  ] = useState(false);
  const [
    shouldShowAllWeb3Wallets, setShouldShowAllWeb3Wallets
  ] = useState(false);

  const enabledWalletsOnChain = useMemo(() => {
    return getEnabledWalletsForChain(chain)
  }, [chain])

  const handleLoginWithLoading = useCallback(
    async (walletAdapter: string, provider?: string) => {
      setIsLoading(true);

      try {
        if (provider == null) {
          select(walletAdapter)

          return
        }

        await connect(walletAdapter, provider);
      } catch (err) {
        console.warn("Issue logging into social wallet.", err);
        setIsLoading(false);
      }
    },
    [connect, select],
  );

  useEffect(() => {
    if (loggedIn && isLoading) {
      setIsLoading(false);
    }
  }, [loggedIn]);

  const createWalletsBlock = useCallback(
    (title: string, content: ReactNode, shouldShowAllWallets: boolean, visibilitySwitchHandler?: Function) => (
    <div className="flex w-full flex-col gap-y-3 self-stretch mt-6">
      <div className="flex items-center gap-x-2 justify-between">
        <div className="flex text-sm text-gray-300 font-normal">{title}</div>
        {
          visibilitySwitchHandler
          && (
            <Button
              size="sm"
              variant="gray"
              className="md:hidden h-6 text-xs text-gray-200 font-normal mr-3"
              onClick={() => visibilitySwitchHandler(!shouldShowAllWallets)}
            >
              {shouldShowAllWallets ? "GO BACK" : "SEE ALL"}
            </Button>
          )
        }
      </div>
      <div
        className={twMerge(
          "flex gap-3 flex-wrap w-full",
          shouldShowAllWallets ? "" : "[&>*:nth-child(n+3)]:hidden",
          "md:[&>*:nth-child(n+3)]:flex"
        )}
      >
        {content}
      </div>
    </div>
  ), []);

  const web3Wallets = [
    {icon: <GoogleIcon/>, walletName: "Google", walletKey: "google"},
    {icon: <TwitterIcon/>, walletName: "Twitter", walletKey: "twitter"},
    {icon: <DiscordIcon/>, walletName: "Discord", walletKey: "discord"},
  ];
  const detectedWallets = wallets
    .filter(wallet => (wallet.readyState === "Installed"));
  const supportedWallets = wallets
    .filter(wallet => (
      wallet.readyState !== "Installed"
      && enabledWalletsOnChain.includes(wallet.adapter.name)
    ));

  const web3WalletsList = useMemo(() => createWalletsBlock(
    "Connect with Socials",
    <>
      {
        web3Wallets
          .map(walletButtonData => (
            <WalletButton
            key={walletButtonData.walletName}
            {...walletButtonData}
            onClick={async () => {
              if (loggedIn) {
                await disconnect();
              }
              await handleLoginWithLoading(WALLET_ADAPTERS.OPENLOGIN, walletButtonData.walletKey);
              closeModal(true);
            }}
          />
        ))
      }
  </>,
  shouldShowAllWeb3Wallets,
  web3Wallets.length > 2 ? setShouldShowAllWeb3Wallets : undefined
), [
  createWalletsBlock,
    handleLoginWithLoading,
    shouldShowAllWeb3Wallets,
    loggedIn,
    disconnect,
    closeModal
  ]);

  const detectedWalletsList = useMemo(() => createWalletsBlock(
    "Detected",
    <>
      {
        detectedWallets
          .map(detectedWallet => (
            {
              icon: (
                <Icon
                  inheritFill={false}
                  inheritStroke={false}
                  size="md"
                  icon={<img src={detectedWallet.adapter.icon} alt={detectedWallet.adapter.name} />}
                />
              ),
              key: detectedWallet.adapter.name,
              walletName: detectedWallet.adapter.name,
              type: !enabledWalletsOnChain?.includes(detectedWallet.adapter.name)
                ? WalletButtonType.UNSUPPORTED
                : detectedWallet.adapter.name === wallet?.adapter?.name && loggedIn ? WalletButtonType.CONNECTED : WalletButtonType.ACTIVE,
              onClick: detectedWallet.adapter.name === wallet?.adapter?.name && loggedIn
                ? async () => {
                  await disconnect();
                  // TODO: Sonic rewards logout should be here
                  // logout?.();
                }
                : async () => {
                  if (loggedIn) {
                    await disconnect();
                    // TODO: Sonic rewards logout should be here
                    // logout?.();
                  }
                  await handleLoginWithLoading(detectedWallet.adapter.name);
                  closeModal(true);
                }
            }
          )).sort(walletButtonData => (
            walletButtonData.type === WalletButtonType.UNSUPPORTED ? 1 : -1
          ))
          .map(walletButtonData => (<WalletButton {...walletButtonData} />))
      }
    </>,
    shouldShowAllDetectedWallets,
    detectedWallets.length > 2 ? setShouldShowAllDetectedWallets : undefined

  ), [
    createWalletsBlock,
    enabledWalletsOnChain,
    handleLoginWithLoading,
    loggedIn,
    shouldShowAllDetectedWallets,
    detectedWallets,
    wallet?.adapter?.name,
    disconnect,
    closeModal
  ]);

  const supportedWalletsList = useMemo(() => createWalletsBlock(
    "Other Supported Wallets",
    <>
      {
        supportedWallets
          .map(wallet => (
            {
              icon: (
                <Icon
                  inheritFill={false}
                  inheritStroke={false}
                  size="md"
                  icon={<img src={wallet.adapter.icon} alt={wallet.adapter.name} />}
                />
              ),
              key: wallet.adapter.name,
              walletName: wallet.adapter.name,
              type: WalletButtonType.SUPPORTED
            }
          )).map(walletButtonData => (<WalletButton {...walletButtonData} />))
      }
    </>,
    shouldShowAllSupportedWallets,
    supportedWallets.length > 2 ? setShouldShowAllSupportedWallets : undefined
  ), [
    createWalletsBlock,
    shouldShowAllSupportedWallets,
    supportedWallets
  ]);

  return (
    <BaseModal
      open={open}
      withX
      hideModal={closeModal}
      classes={{
        dialog: `
          p-0 bg-gray-700 relative
          [&>div>.close-button]:absolute [&>div>.close-button]:top-4.5 [&>div>.close-button]:right-4
          [&>div>.close-button>div]:text-[22px] [&>div>.close-button]:z-50
        `,
      }}
    >
      {isLoading ? (
        <Loading title="Loading" message="please wait a few seconds..."/>
      ) : (
        <div
          data-id="connect-modal"
          className={twMerge(
            "flex w-[90vw] md:w-[80vw] gap-y-3 max-w-[700px] min-h-[500px] pb-20 md:pb-0",
            "flex-col md:flex-row"
          )}
        >
          {
            showTestFeatures
            && (
              <div
                className={twMerge(
                  "flex flex-col px-3 md:px-5 mt-5 md:my-5 relative bg-gray-700 z-20",
                  "border-r border-gray-500 gap-y-4 justify-between",
                )}
              >
                <div className="flex flex-col gap-y-4">
                  <div className="flex font-semibold text-gray-50">
                    <div className="capitalize text-lg">Networks</div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <NetworksSwitcher />
                    <div className="hidden md:flex text-gray-400 whitespace-nowrap font-semibold pl-4">
                      More coming soon
                    </div>
                  </div>
                </div>

                <div className="hidden md:flex flex-col gap-y-3 font-semibold">
                  <div className="flex text-gray-300 font-normal">Current network</div>
                  <div className="flex gap-x-2 items-center">
                    <IconFont name="globe" size="xl" className="mb-0.5"/>
                    {chainDisplayName}
                  </div>
                  <div
                    className={twMerge(
                      "flex items-center gap-x-2",
                      !loggedIn ? "text-gray-400" : ""
                    )}
                  >
                    {
                      loggedIn
                        // TODO: Need to add wallet icon here
                        ? truncatePubkey(walletPubkey?.toString() || "", 4)
                        : (
                          <>
                            <IconFont name="wallet" size="xl" className="mb-0.5"/>
                            Not Connected
                          </>
                        )
                    }
                  </div>
                </div>
              </div>
            )
          }
          <div className="flex flex-col w-full p-3 pr-0 md:p-5 pb-2 md:pb-10 relative bg-gray-700 z-10">
            <div className="flex font-semibold text-gray-50">
              <div className="capitalize text-lg">Select Wallet</div>
            </div>
            <div className="flex md:hidden flex-col ">
              {
                !shouldShowAllWeb3Wallets
                && !shouldShowAllSupportedWallets
                && wallets.length > 0
                && detectedWalletsList
              }
              {
                !shouldShowAllSupportedWallets
                && !shouldShowAllDetectedWallets
                && web3WalletsList
              }
              {
                !shouldShowAllWeb3Wallets
                && !shouldShowAllDetectedWallets
                && supportedWallets.length > 0
                && supportedWalletsList
              }
            </div>
            <div className="hidden md:flex flex-col ">
              {wallets.length > 0 && detectedWalletsList}
              {web3WalletsList}
              {supportedWallets.length > 0 && supportedWalletsList}
            </div>
          </div>

          <div className="flex md:hidden flex-col gap-y-1.5 text-sm pl-3 absolute bottom-3">
            <div className="flex gap-x-1.5 font-normal">
              <div className="flex text-gray-300">Current network:</div>
              <div className="flex items-center">{chainDisplayName}</div>
            </div>

            <div className="flex gap-x-1.5 font-normal">
              <div className="flex text-gray-300">Current Wallet:</div>
              <div className="flex items-center">
                {
                  loggedIn
                    ? truncatePubkey(walletPubkey?.toString() || "", 4)
                    : "Not Connected"
                }
              </div>
            </div>

            <div
              className={twMerge(
                "flex items-center gap-x-2",
                !loggedIn ? "text-gray-400" : ""
              )}
            >

            </div>
          </div>
        </div>
      )}
    </BaseModal>
  );
}

export default ConnectionModal;

export interface IWalletButtonProps {
  type?: WalletButtonType,
  walletName: string,
  icon: ReactNode,
  onClick?: () => void
}

const WalletButton: React.FC<IWalletButtonProps> = ({
  type = WalletButtonType.ACTIVE,
  walletName,
  icon,
  onClick = () => {}
}) => {
  const subHeaderText = {
    [WalletButtonType.ACTIVE]: "",
    [WalletButtonType.SUPPORTED]: "",
    [WalletButtonType.CONNECTED]: "CONNECTED",
    [WalletButtonType.UNSUPPORTED]: "UNSUPPORTED",
    [WalletButtonType.SWITCH_NETWORK]: "SWITCH NETWORK",
  }[type];
  const [buttonText, setButtonText] = useState(subHeaderText);

  return (
    <button
      className={twMerge(
        "flex rounded-md h-16 md:h-20 p-3 justify-center items-center min-w-[144px] w-[calc(50%-12px)] md:w-auto",
        "bg-gray-600 hover:bg-gray-500 [&>*>.sub-header]:font-normal [&>*>.sub-header]:text-xs",
        [WalletButtonType.ACTIVE, WalletButtonType.SUPPORTED].includes(type) ? "" : "border-2",
        type === WalletButtonType.CONNECTED ? "border-brand-pink-regular" : "",
        type === WalletButtonType.SWITCH_NETWORK ? "border-gray-50" : "",
        type === WalletButtonType.UNSUPPORTED ? "border-gray-500 text-gray-400 bg-gray-700" : "",
        [WalletButtonType.SUPPORTED, WalletButtonType.UNSUPPORTED].includes(type) ? " cursor-not-allowed" : "",
      )}
      disabled={[WalletButtonType.SUPPORTED, WalletButtonType.UNSUPPORTED].includes(type)}
      onClick={onClick}
      onMouseEnter={type === WalletButtonType.CONNECTED ? () => setButtonText("DISCONNECT") : () => {}}
      onMouseLeave={() => setButtonText(subHeaderText)}
    >
      <div className="flex-col gap-y-2.5">
        <div
          className={twMerge(
            "flex w-full justify-center gap-x-1.5 [&>svg]:h-4.5 font-semibold items-center",
            "text-sm md:text-base"
          )}
        >
          {icon}
          <div className="flex-grow-0">{walletName}</div>
        </div>
        <div className="sub-header">
          {buttonText}
        </div>
      </div>

    </button>
  )
};
