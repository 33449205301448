import React, { FC, useContext, useEffect, useState } from "react";

import { ModalProps } from "./types";
import { useRankMeta } from "../../hooks/useRankMeta";
import Icon from "../common/icon/Icon";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import BareXpLoad from "../animations/xp-load/BareXpLoad";
import { Tabs } from "../../modals/wallet/Tabs";
import {
  Accordion, BaseModal, AccordionItem, CurrentPlayerAvatar
} from "../common";
import StarIcon from "../../assets/icons/star.svg";
import rewardsList from "../../config/rewards_list.json";
import { RankIcon } from "../rewards/RankIcon";
import { NavContext, ProfileTab } from "../../contexts/NavContext";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";

export enum RankTabs {
  RANK = "My Rank",
  RANK_LIST = "Rank Benefits",
  REWARD_LIST = "Reward List",
}

interface IUserRankModalProps extends ModalProps {
  navigate: Function;
  selectedReward?: string;
  selectedRankModalTab?: RankTabs | undefined;
  setSelectedReward?:  React.Dispatch<React.SetStateAction<string | undefined>>;
}

const replaceStringVariables = (stringWithVariables: string, replacements: { [key: string]: any }): any[] => (
  stringWithVariables
    ?.split("{{")
    .map(subStr => subStr.split("}}"))
    .flat()
    // Ignore commented variables, where "**" - comment sign. Example {{**var_name}}
    .filter(replacement => !replacement?.includes("**"))
    .map(replacement => replacements[replacement] || <span key={replacement} dangerouslySetInnerHTML={{__html: replacement}}/>)
);

const UserRankModal: FC<IUserRankModalProps> = ({
  visible,
  selectedReward,
  selectedRankModalTab,
  setSelectedReward,
  hideModal,
  navigate
}) => {
  const { setProfileTab } = useContext(NavContext);
  const { loggedIn } = useContext(WrappedWalletContext);
  const { rankMeta, platformRanks } = useRankMeta();
  const [ selectedTab, setSelectedTab] = useState<RankTabs>(RankTabs.RANK);

  useEffect(() => {
    if (selectedRankModalTab === RankTabs.RANK_LIST) {
      setSelectedTab(RankTabs.RANK_LIST);
    } else if (selectedReward || !loggedIn) {
      setSelectedTab(selectedReward ? RankTabs.REWARD_LIST : RankTabs.RANK_LIST);
    } else {
      setSelectedTab(RankTabs.RANK);
    }
  }, [visible]);

  const ranksItems: AccordionItem[] = platformRanks
    .slice(1) // Remove "Unranked" rank
    .map(rank => ({
      key: rank.name,
      title: (
        <div className={`flex items-center`}>
          <RankIcon
            rankName={rank.name === "Mythril" ? rank.name : `${rank.name} III`}
            size="xxl"
            className="mr-1"
            defaultIcon={
              <RankIcon
                rankName="Unranked"
                size="xxl"
              />
            }
          />
          &nbsp;
          {`${rank.name} ${ rank.name !== "Mythril" ? "I-III" : "" }`}
        </div>
      ),
      content: (
        <div className="flex flex-col gap-y-1">
          <div
            className={`
              flex items-center font-normal
              ${rank.benefit.defaultRakebackRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}
          >
            <div style={{...(!rank.benefit.defaultRakebackRatePerThousand && {filter: "grayscale()"})}}>
              <Icon
                iconUrl="/static/ranks-and-rewards/Rakeback.png"
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
            </div>
            {
              formatZeebitNumber(rank.benefit.defaultRakebackRatePerThousand / 1000, NumberType.PROBABILITY)
            } % Rakeback Rate
          </div>
          <div
            className={`
              flex items-center font-normal
              ${rank.benefit.rakebackBoostPerThousand ? "text-gray-50" : "text-gray-300"}
            `}
          >
            <div style={{...(!rank.benefit.rakebackBoostPerThousand && {filter: "grayscale()"})}}>
              <Icon
                iconUrl="/static/ranks-and-rewards/Rakeback-Boost.png"
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
            </div>
            {
              formatZeebitNumber(rank.benefit.rakebackBoostPerThousand / 1000, NumberType.PROBABILITY)
            } % Rakeback Boost
          </div>
          <div
            className={`
              flex items-center font-normal
              ${rank.benefit.levelUpBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}
          >
            <div style={{...(!rank.benefit.levelUpBonusAccrualRatePerThousand && {filter: "grayscale()"})}}>
              <Icon
                iconUrl="/static/ranks-and-rewards/Level-Up.png"
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
            </div>
            {
              formatZeebitNumber(rank.benefit.levelUpBonusAccrualRatePerThousand / 1000, NumberType.PROBABILITY)
            } % Level Up Bonus Rate
          </div>
          <div
            className={`
              flex items-center font-normal
              ${rank.benefit.monthlyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}
          >
            <div style={{...(!rank.benefit.monthlyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}>
              <Icon
                iconUrl="/static/ranks-and-rewards/Monthly.png"
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
            </div>
            {
              formatZeebitNumber(rank.benefit.monthlyBonusAccrualRatePerThousand / 1000, NumberType.PROBABILITY)
            } % Monthly Bonus Allocation
          </div>
          <div
            className={`
              flex items-center font-normal
              ${rank.benefit.weeklyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}
          >
            <div style={{...(!rank.benefit.weeklyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}>
              <Icon
                iconUrl="/static/ranks-and-rewards/Weekly.png"
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
            </div>
            {
              formatZeebitNumber(rank.benefit.weeklyBonusAccrualRatePerThousand / 1000, NumberType.PROBABILITY)
            } % Weekly Bonus Allocation
          </div>
          <div
            className={`
              flex items-center font-normal
              ${rank.benefit.dailyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}

          >
            <div style={{...(!rank.benefit.dailyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}>
              <Icon
                iconUrl="/static/ranks-and-rewards/Daily.png"
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
            </div>

            {
              formatZeebitNumber(rank.benefit.dailyBonusAccrualRatePerThousand / 1000, NumberType.PROBABILITY)
            } % Daily Bonus Allocation
          </div>
        </div>
      ),
    }));

  const replacements = {
    ranks_tab_link: (
      <a key="ranks_tab_link" className="cursor-pointer" onClick={() => setSelectedTab(RankTabs.RANK_LIST)}>ranks</a>
    ),
    zeebros_page_link: (
      <a
        key="zeebros_page_link"
        className="cursor-pointer"
        onClick={() => {
          navigate(`/zeebros`);
          hideModal();
        }}
      >
        Zeebro NFT
      </a>
    ),
    rakeback_boost_accodion_tab: (
      <a
        key="rakeback_boost_accodion_tab"
        className="cursor-pointer"
        onClick={() => {
          setSelectedReward?.("Rakeback-Boost");
          setTimeout(() => setSelectedReward?.(undefined), 1000);
        }}
      >
        Rakeback Boost
      </a>
    ),
    referrals_link: (
      <a
        key="referrals_link"
        className="cursor-pointer"
        onClick={() => {
          navigate(`/profile`);
          setProfileTab?.(ProfileTab.REFERRALS);
          hideModal();
        }}
      >
        Referrals tab
      </a>
    ),
    daily_bonus_closest_rank: rewardsMeta?.merged?.daily?.minimumRank?.name || "Amber I",
    weekly_bonus_closest_rank: rewardsMeta?.merged?.weekly?.minimumRank?.name || "Amber I",
    monthly_bonus_closest_rank: rewardsMeta?.merged?.monthly?.minimumRank?.name || "Amber I",
  };

  const rewardsListOrder = [
    "Rakeback",
    "Rakeback-Boost",
    "Level-Up",
    "Monthly",
    "Weekly",
    "Daily",
    "Referrals-Benefit"
  ];

  const rewardItems: AccordionItem[] = rewardsListOrder
    .map(rewardKey => rewardsList.find(rewardObj => rewardObj.key === rewardKey))
    .map(reward => ({
      key: reward.key,
      title: (
        <div className={`flex items-center`}>
          <Icon
            iconUrl={reward.iconUrl}
            className="w-[24px] h-[24px] bg-gray-900 border-2 border-gray-900 mr-1 rounded"
          />
          {reward.title}
        </div>
      ),
      content: (
        <div key={reward.key} className="flex w-full flex-col items-center">
          <img
            src={reward.imageUrl}
            alt={`${reward.key} image`}
            className={`mx-auto w-[280px]`}
          />
          <div className={`flex mt-1 mb-3 text-brand-off-white text-[24px]`}>{reward.title}</div>
          <div className={`inline-flex text-base font-normal text-gray-300`}>
            <div>
              {replaceStringVariables(reward?.description || "", replacements)}
            </div>
          </div>
        </div>
      ),
  }));

  return (
    <BaseModal
      title="Ranking & Benefits"
      open={visible}
      onClose={hideModal}
      classes={{
        dialog: `w-[342px] sm:max-w-[520px] bg-gray-800`
      }}
    >
      <div
        data-id="user-rank-modal"
        className={`
          flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative
        `}
      >
        <div className="w-full z-10">
          <Tabs
            selectedTab={selectedTab}
            updateSelectedTab={setSelectedTab}
            tabs={
              loggedIn ? RankTabs : [RankTabs.RANK_LIST, RankTabs.REWARD_LIST]
            }
            classes={{
              activeTabClasses: "bg-white text-gray-800",
              tabClasses: "font-semibold items-center text-center"
            }}
          />
        </div>

        <div
          className={`
            flex flex-col overflow-y-auto w-full pr-3 -mr-3
            ${SCROLLBAR_CLASSES_BLACK}
          `}
        >
          {
            selectedTab === RankTabs.RANK
            && (
              <>
                <div className="flex flex-col items-center gap-y-2 w-full absolute top-16 sm:top-44 z-10 mr-2">
                  <div className="mb-2 h-[62px]">
                    <CurrentPlayerAvatar
                      className="rounded-md"
                      playerAvatarKey={playerAccount?.avatar}
                      playerPublicKey={playerAccount?.ownerPubkey}
                      height={62}
                      width={62}
                    />
                  </div>
                  <div className={`flex gap-y-2`}>
                    <RankIcon
                      rankName={rankMeta?.currentRank?.name}
                      size="xxl"
                      defaultIcon={
                        <Icon
                          className="text-gold-200 shrink-0"
                          size="xxl"
                          inheritFill
                          inheritStroke={false}
                          icon={<StarIcon/>}
                        />
                      }
                    />
                    <div className={`text-[30px] leading-8 font-semibold ml-1`}>{rankMeta?.currentRank.name}</div>
                  </div>
                  <div className={`flex font-semibold`}>
                    {`${formatZeebitNumber(
                      playerAccount?.xpAllTime,
                      NumberType.XP_AMOUNT,
                    )} / ${formatZeebitNumber(rankMeta?.nextRank.threshold || 0, NumberType.XP_AMOUNT)} XP`}
                  </div>
                  <div className={`text-md font-normal text-gray-300`}>
                    Next Rank: {rankMeta?.nextRank.name}
                  </div>
                </div>
                <div className={`flex w-[125%] sm:w-full p-0 sm:py-10 relative -top-8 sm:-top-16 -mb-12 sm:-mb-28 -left-8 sm:left-0 z-10`}>
                  <BareXpLoad endValue={rankMeta?.percentage || 0} total={100}/>
                </div>

                <div className="flex flex-col gap-y-1 items-center bg-gray-600 rounded w-full py-3">
                  <div className={`flex items-center font-normal text-gray-300 text-sm`}>CURRENT REWARDS</div>
                  <div
                    className={`
                      flex items-center font-normal
                      ${rankMeta?.currentRank.benefit.defaultRakebackRatePerThousand ? "text-gray-50" : "text-gray-300"}
                    `}
                  >
                    <div style={{...(!rankMeta?.currentRank.benefit.defaultRakebackRatePerThousand && {filter: "grayscale()"})}}>
                      <Icon
                        iconUrl="/static/ranks-and-rewards/Rakeback.png"
                        size="xl"
                        className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
                      />
                    </div>
                    {
                      formatZeebitNumber((rankMeta?.currentRank.benefit.defaultRakebackRatePerThousand || 0)/1000, NumberType.PROBABILITY)
                    } % Rakeback Rate
                  </div>
                  <div
                    className={`
                      flex items-center font-normal
                      ${rankMeta?.currentRank.benefit.monthlyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
                    `}
                  >
                    <div style={{...(!rankMeta?.currentRank.benefit.monthlyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}>
                      <Icon
                        iconUrl="/static/ranks-and-rewards/Monthly.png"
                        size="xl"
                        className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
                      />
                    </div>
                    {
                      formatZeebitNumber((rankMeta?.currentRank.benefit.monthlyBonusAccrualRatePerThousand || 0)/1000, NumberType.PROBABILITY)
                    } % Monthly Bonus Allocation
                  </div>
                  <div
                    className={`
                      flex items-center font-normal
                      ${rankMeta?.currentRank.benefit.weeklyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
                    `}
                  >
                    <div style={{...(!rankMeta?.currentRank.benefit.weeklyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}>
                      <Icon
                        iconUrl="/static/ranks-and-rewards/Weekly.png"
                        size="xl"
                        className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
                      />
                    </div>
                    {
                      formatZeebitNumber((rankMeta?.currentRank.benefit.weeklyBonusAccrualRatePerThousand || 0)/1000, NumberType.PROBABILITY)
                    } % Weekly Bonus Allocation
                  </div>
                  <div
                    className={`
                      flex items-center font-normal
                      ${rankMeta?.currentRank.benefit.dailyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
                    `}
                  >
                    <div style={{...(!rankMeta?.currentRank.benefit.dailyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}>
                      <Icon
                        iconUrl="/static/ranks-and-rewards/Daily.png"
                        size="xl"
                        className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
                      />
                    </div>
                    {
                      formatZeebitNumber((rankMeta?.currentRank.benefit.dailyBonusAccrualRatePerThousand || 0)/1000, NumberType.PROBABILITY)
                    } % Daily Bonus Allocation
                  </div>
                </div>
              </>
            )
          }
          {
            selectedTab === RankTabs.RANK_LIST
            && <Accordion items={ranksItems} />
          }
          {
            selectedTab === RankTabs.REWARD_LIST
            && <Accordion items={rewardItems} selectedItem={selectedReward} />
          }
        </div>
      </div>
    </BaseModal>
  );
};

export default UserRankModal;
