import * as anchor from "@coral-xyz/anchor";
import { ZeebitV2 } from "./zeebitV2";
import { PublicKey, Keypair, TransactionInstruction, Transaction, Connection, TransactionError, Commitment } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddressSync } from '@solana/spl-token';
import {
    createCommitInstruction,
    createUndelegateInstruction,
    DelegateAccounts,
    DELEGATION_PROGRAM_ID,
} from "@magicblock-labs/delegation-program";
import HouseToken from "./houseToken";
import { sleep } from "../utils/time/sleep";

export default class PlayerToken {

    private _houseToken: HouseToken;
    private _playerTokenPubkey: PublicKey;
    private _ownerPubkey: PublicKey;
    private _erState: anchor.IdlAccounts<ZeebitV2>["playerToken"] | undefined;
    private _baseState: anchor.IdlAccounts<ZeebitV2>["playerToken"] | undefined;
    private _stateLoaded: boolean

    // EVENT PARSERS
    private _baseParser: anchor.EventParser
    private _erParser: anchor.EventParser

    // COMMITMENT
    _commitmentLevel: Commitment

    constructor(
        houseToken: HouseToken,
        ownerPubkey: PublicKey,
        commitment?: Commitment = "processed",
        baseState?: anchor.IdlAccounts<ZeebitV2>["playerToken"],
        erState?: anchor.IdlAccounts<ZeebitV2>["playerToken"]
    ) {
        this._stateLoaded = false;
        this._houseToken = houseToken;
        this._ownerPubkey = ownerPubkey;
        this._playerTokenPubkey = PlayerToken.derivePlayerTokenPubkey(houseToken.publicKey, ownerPubkey, houseToken.programId);

        // SET THE PARSERS
        this._baseParser = new anchor.EventParser(
            houseToken.baseProgram.programId,
            new anchor.BorshCoder(houseToken.baseProgram.idl),
        );

        this._erParser = new anchor.EventParser(
            houseToken.erProgram.programId,
            new anchor.BorshCoder(houseToken.erProgram.idl),
        );

        this._commitmentLevel = commitment

        this._baseState = baseState
        this._erState = erState
    };

    static async load(
        houseToken: HouseToken,
        ownerPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const playerToken = new PlayerToken(
            houseToken,
            ownerPubkey,
        )

        await playerToken.loadBaseState(commitmentLevel);

        if (houseToken.isDelegated == true) {
            await playerToken.loadErState(commitmentLevel);
        }

        playerToken._stateLoaded  = true

        return playerToken
    };

    static loadFromBuffer(houseToken: HouseToken, ownerPubkey: PublicKey, baseAccountBuffer?: Buffer, erAccountBuffer?: Buffer) {
        const playerToken = new PlayerToken(houseToken, ownerPubkey);

        playerToken._baseState = baseAccountBuffer != null ? houseToken.baseProgram.coder.accounts.decode("playerToken", baseAccountBuffer): undefined;
        playerToken._erState = erAccountBuffer != null ? houseToken.erProgram.coder.accounts.decode("playerToken", erAccountBuffer): undefined;

        return playerToken;
      }



    async loadBaseState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.baseProgram.account.playerToken.fetchNullable(
            this._playerTokenPubkey,
            commitmentLevel
        );
        if (state) {
            this._baseState = state;
        }
        return
    }

    async loadErState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.erProgram.account.playerToken.fetchNullable(
            this._playerTokenPubkey,
            commitmentLevel
        );

        if (state) {
            this._erState = state;
        }
        return
    }
    static deriveInstanceSoloPubkey(
        playerTokenPubkey: PublicKey,
        instanceIdx: number,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("instance_solo"),
                playerTokenPubkey.toBuffer(),
                new anchor.BN(instanceIdx).toBuffer("le", 1)
            ],
            programId
        );
        return pk
    };

    static deriveInstanceMultiPubkey(
        gameSpecPubkey: PublicKey,
        instanceIdx: number,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("instance_multi"),
                gameSpecPubkey.toBuffer(),
                new anchor.BN(instanceIdx).toArrayLike(Buffer, 'le', 1)

            ],
            programId
        );
        return pk
    };

    static deriveInstanceTokenMultiPubkey(
        instanceTokenPubkey: PublicKey,
        gameSpecTokenPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("instance_token_multi"),
                instanceTokenPubkey.toBuffer(),
                gameSpecTokenPubkey.toBuffer(),
            ],
            programId
        );
        return pk
    };
    static derivePlayerTokenPubkey(
        houseTokenPubkey: PublicKey,
        ownerPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("player_token"),
                houseTokenPubkey.toBuffer(),
                ownerPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    static derivePlayerTokenAccountPubkey(
        ownerPubkey: PublicKey,
        tokenMintPubkey: PublicKey,
    ): PublicKey {
        return getAssociatedTokenAddressSync(
            tokenMintPubkey,
            ownerPubkey,
            false
        );
    };


    static deriveInstancePubkey(
        playerTokenPubkey: PublicKey,
        instanceNumber: number,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("instance_solo"),
                playerTokenPubkey.toBuffer(),
                new anchor.BN(instanceNumber).toArrayLike(Buffer, 'le', 1)
            ],
            programId
        );
        return pk
    };

    async needsSessionAuthorityUpdate(sessionAuthority: PublicKey, minLamportBalance: number = 10_000, lamportBalance?: number) {
        // CHECK SAME SESSION AUTH
        if (this.sessionAuthority == null || sessionAuthority.toString() != this.sessionAuthority.toString()) {
            return true
        }

        // CHECK NOT EXPIRED
        const now = new Date()
        const nowMs = now.getTime()
        const sessionAuthorityExpiresMs = Number(this?.baseState.sessionAuthorityExpires) * 1000

        if (nowMs > sessionAuthorityExpiresMs) {
            return true
        }

        // CHECK HAS LAMPORTS
        if (lamportBalance != null) {
            if (minLamportBalance > lamportBalance) {
                return true
            }
        } else {
            const accData = await this.baseProgram.provider.connection.getAccountInfo(this.sessionAuthority, "processed")
            if (accData == null || accData.lamports < minLamportBalance) {
                return true
            }
        }

        return false
    }

    get sessionAuthority() {
        return this.baseState?.sessionAuthority
    }

    get stateLoaded() {
        return this._stateLoaded;
    }

    get hasNoState() {
        return this.stateLoaded && this.baseState == null
    }

    get sessionAuthorityExpiryDate() {
        return this?.baseState?.sessionAuthorityExpires != null ? new Date(Number(this?.baseState.sessionAuthorityExpires) * 1000) : undefined
    }

    get ownerPubkey() {
        return this._ownerPubkey
    }

    get baseParser() {
        return this._baseParser
    }

    get erParser() {
        return this._erParser
    }

    get baseProgram() {
        return this._houseToken.baseProgram
    }

    get erProgram() {
        return this._houseToken.erProgram
    }

    get programId() {
        return this.baseProgram.programId
    }

    get publicKey() {
        return this._playerTokenPubkey
    }

    get houseToken() {
        return this._houseToken
    }

    get baseState() {
        return this._baseState
    }

    get erState() {
        return this._erState
    }

    get delegationStatus(): string {
        return this.baseState?.delegationStatus ? Object.keys(this.baseState.delegationStatus)[0] : null;
    }

    get isDelegated() {
        return this.delegationStatus ? ("delegated" == this.delegationStatus ? true : false) : false;
    }

    get isReadyToUndelegate() {
        return this.delegationStatus ? ("readyToUndelegate" == this.delegationStatus ? true : false) : false;
    }

    get playBalance() {
        const baseBasis = !!this?.baseState ? (Number(this?.baseState.availableBalance) + Number(this?.baseState.lockedBalance)) : 0
        // const erBasis = !!selectedMerged?.playerToken?.erState ? (Number(selectedMerged?.playerToken?.erState.availableBalance) + Number(selectedMerged?.playerToken?.erState.lockedBalance)) : 0

        // const basis = selectedMerged?.playerToken.isDelegated ? erBasis : baseBasis  // TODO - CHECK THIS
        return baseBasis
    }

    static async initializeTx(
        owner: PublicKey,
        houseToken: HouseToken,
        depositAmount: number,
        sessionAuthority?: PublicKey,
        sessionAuthorityLamports?: number,
    ): Promise<Transaction> {
        const tx = new Transaction()
        const playerToken = new PlayerToken(houseToken, owner);

        const ix = await houseToken.baseProgram.methods.playerTokenInit(
            {}
        ).accounts({
            payer: playerToken.baseProgram.provider.publicKey,
            owner: playerToken.baseProgram.provider.publicKey,
            houseToken: playerToken.houseToken.publicKey,
            playerToken: playerToken.publicKey,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()

        tx.add(ix)

        if (depositAmount > 0) {
            tx.add(
                await playerToken.depositIxn(depositAmount)
            );
        }
        if (sessionAuthority != undefined) {
            tx.add(
                await playerToken.updateSessionAuthorityIxn(
                    sessionAuthority,
                    new Date(Date.now() + 86_400_000),
                    sessionAuthorityLamports || 0
                )
            );
        }

        return tx
    };

    static async initializeIxn(
        houseToken: HouseToken,
        owner: PublicKey
    ): Promise<TransactionInstruction> {
        const playerToken = PlayerToken.derivePlayerTokenPubkey(houseToken.publicKey, owner, houseToken.programId)

        return await houseToken.baseProgram.methods.playerTokenInit(
            {}
        ).accounts({
            payer: owner,
            owner: owner,
            houseToken: houseToken.publicKey,
            playerToken: playerToken,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();
    };

    async initializeIxn(): Promise<TransactionInstruction | undefined> {
        const houseToken = this.houseToken
        const owner = this._ownerPubkey

        return PlayerToken.initializeIxn(houseToken, owner)
    };

    static async depositIxn(
        houseToken: HouseToken,
        owner: PublicKey,
        depositAmount: number,
    ): Promise<anchor.web3.TransactionInstruction> {
        const playerToken = PlayerToken.derivePlayerTokenPubkey(houseToken.publicKey, owner, houseToken.programId)
        const tokenAccountPubkey = getAssociatedTokenAddressSync(houseToken.tokenMintPubkey, owner, false);
        const vaultPubkey = HouseToken.deriveHouseTokenVaultPubkey(houseToken.bankPublicKey, houseToken.tokenMintPubkey);

        return await houseToken.baseProgram.methods.playerTokenDeposit({
            depositAmount: new anchor.BN(depositAmount),
        }).accounts({
            payer: owner,
            owner: owner,
            houseTokenBank: houseToken.bankPublicKey,
            houseToken: houseToken.publicKey,
            playerToken: playerToken,
            tokenMint: houseToken.tokenMintPubkey,
            tokenAccount: tokenAccountPubkey,
            vault: vaultPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()
    };

    async depositIxn(
        depositAmount: number,
    ): Promise<anchor.web3.TransactionInstruction> {
        const houseToken = this.houseToken
        const owner = this._ownerPubkey

        return PlayerToken.depositIxn(houseToken, owner, depositAmount)
    };

    async withdrawIxn(
        withdrawalAmount: number,
    ): Promise<anchor.web3.TransactionInstruction> {
        const tokenAccountPubkey = getAssociatedTokenAddressSync(this.houseToken.tokenMintPubkey, this._ownerPubkey, false);
        const vaultPubkey = HouseToken.deriveHouseTokenVaultPubkey(this.houseToken.bankPublicKey, this.houseToken.tokenMintPubkey);
        return await this.baseProgram.methods.playerTokenWithdraw({
            withdrawalAmount: new anchor.BN(withdrawalAmount),
        }).accounts({
            payer: this._ownerPubkey,
            owner: this._ownerPubkey,
            houseToken: this.houseToken.publicKey,
            houseTokenBank: this.houseToken.bankPublicKey,
            playerToken: this.publicKey,
            tokenMint: this.houseToken.tokenMintPubkey,
            vault: vaultPubkey,
            tokenAccount: tokenAccountPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId,
            updateSlip: null
        }).instruction()
    };

    static async updateSessionAuthorityIxn(
        owner: PublicKey,
        houseToken: HouseToken,
        sessionAuthorityPubkey: PublicKey,
        validUntil: Date,
        lamportTopUp: number
    ): Promise<anchor.web3.TransactionInstruction> {
        const playerToken = PlayerToken.derivePlayerTokenPubkey(houseToken.publicKey, owner, houseToken.programId)

        return await houseToken.baseProgram.methods.playerTokenUpdateSessionAuthority({
            validUntil: new anchor.BN(Math.floor(Number(validUntil) / 1000)),
            lamportTopUp: new anchor.BN(lamportTopUp)
        }).accounts({
            sessionAuthority: sessionAuthorityPubkey,
            owner: owner,
            playerToken: playerToken,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()
    };

    async updateSessionAuthorityIxn(
        sessionAuthorityPubkey: PublicKey,
        validUntil: Date,
        lamportTopUp: number
    ): Promise<anchor.web3.TransactionInstruction> {
        const houseToken = this.houseToken
        const owner = this._ownerPubkey

        const playerToken = PlayerToken.derivePlayerTokenPubkey(houseToken.publicKey, owner, houseToken.programId)

        return await houseToken.baseProgram.methods.playerTokenUpdateSessionAuthority({
            validUntil: new anchor.BN(Math.floor(Number(validUntil) / 1000)),
            lamportTopUp: new anchor.BN(lamportTopUp)
        }).accounts({
            payer: owner,
            sessionAuthority: sessionAuthorityPubkey,
            owner: owner,
            playerToken: playerToken,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()
    };

    async triggerCommitIx(
        payer: PublicKey
    ) {
        return createCommitInstruction({
            payer: payer,
            delegatedAccount: this.publicKey,
        });
    }

    async delegateIxns(
        maxSessionLengthSeconds: number,
        instanceToDelegate: number
    ): Promise<TransactionInstruction[]> {
        let ixns: TransactionInstruction[] = []

        const {
            delegationPda,
            delegationMetadata,
            bufferPda,
            commitStateRecordPda,
            commitStatePda,
        } = DelegateAccounts(
            this.publicKey,
            this.programId
        );

        for (let i = 0; i < instanceToDelegate; i++) {
            ixns.push(await this.delegateInstanceIxn(
                this._ownerPubkey,
                maxSessionLengthSeconds,
                i
            ));
        }

        const ix = await this.baseProgram.methods.playerTokenDelegate({
            maxSessionLength: new anchor.BN(maxSessionLengthSeconds)
        }).accounts({
            payer: this._ownerPubkey,
            authority: this._ownerPubkey,
            houseToken: this.houseToken.publicKey,
            playerToken: this.publicKey,
            buffer: bufferPda,
            delegationRecord: delegationPda,
            delegationMetadata: delegationMetadata,
            ownerProgram: this.baseProgram.programId,
            delegationProgram: DELEGATION_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()

        ixns.push(ix)

        return ixns
    };


    async delegateInstanceIxn(
        owner: PublicKey,
        maxSessionLengthSeconds: number,
        instanceIdx?: number,
    ): Promise<TransactionInstruction> {
        if (instanceIdx == undefined) {
            instanceIdx = this._baseState.allocatedInstanceAccounts;
        };
        const instancePubkey = PlayerToken.deriveInstanceSoloPubkey(this.publicKey, instanceIdx, this.houseToken.programId);
        const {
            delegationPda,
            delegationMetadata,
            bufferPda,
            commitStateRecordPda,
            commitStatePda,
        } = DelegateAccounts(
            instancePubkey,
            this.programId
        );
        return await this.baseProgram.methods.instanceSoloDelegate({
            instanceIdx: instanceIdx,
            maxSessionLength: new anchor.BN(maxSessionLengthSeconds)
        }).accounts({
            payer: owner,
            authority: owner,
            houseToken: this.houseToken.publicKey,
            playerToken: this.publicKey,
            instanceSolo: instancePubkey,
            buffer: bufferPda,
            delegationRecord: delegationPda,
            delegationMetadata: delegationMetadata,
            ownerProgram: this.baseProgram.programId,
            delegationProgram: DELEGATION_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();
    };

    async closeInstanceIxn(
        payer: PublicKey,
        owner: PublicKey,
        instanceIdx: number,
    ): Promise<TransactionInstruction> {
        const instancePubkey = PlayerToken.deriveInstanceSoloPubkey(this.publicKey, instanceIdx, this.houseToken.programId);
        return await this.baseProgram.methods.instanceSoloClose({
            instanceIdx: instanceIdx,
        }).accounts({
            payer: payer,
            owner: owner,
            playerToken: this.publicKey,
            instanceSolo: instancePubkey,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();
    };

    async undelegateInstanceIxn(
        owner: PublicKey,
        instanceIdx: number,
        payer: PublicKey
    ): Promise<TransactionInstruction> {
        const instancePubkey = PlayerToken.deriveInstanceSoloPubkey(this.publicKey, instanceIdx, this.houseToken.programId);

        const {
            delegationPda,
            delegationMetadata,
            bufferPda,
            commitStateRecordPda,
            commitStatePda,
        } = DelegateAccounts(
            instancePubkey,
            this.programId
        );
        return await this.baseProgram.methods.instanceSoloUndelegate({
            instanceIdx: instanceIdx,
        }).accounts({
            payer: payer,
            owner: owner,
            playerToken: this.publicKey,
            instanceSolo: instancePubkey,
            buffer: bufferPda,
            delegationRecord: delegationPda,
            delegationMetadata: delegationMetadata,
            ownerProgram: this.programId,
            delegationProgram: DELEGATION_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();
    };

    // UNDELEGATE PLAYER TOKEN
    async undelegateIxns(sessionAuth?: Keypair): Promise<TransactionInstruction[]> {
        const sessionOrOwner = sessionAuth != null ? sessionAuth.publicKey : this.ownerPubkey
        let ixns = []

        const {
            delegationPda,
            delegationMetadata,
            bufferPda,
            commitStateRecordPda,
            commitStatePda
        } = DelegateAccounts(
            this.publicKey,
            this.programId
        );

        const ix = await this.baseProgram.methods.playerTokenUndelegate(
            {}
        ).accounts({
            authority: sessionOrOwner,
            payer: sessionOrOwner,
            houseToken: this.houseToken.publicKey,
            playerToken: this.publicKey,
            buffer: bufferPda,
            delegationRecord: delegationPda,
            delegationMetadata: delegationMetadata,
            ownerProgram: this.baseProgram.programId,
            delegationProgram: DELEGATION_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()

        ixns.push(ix)

        const instancesToUndelegate = this.baseState.allocatedInstanceAccounts;

        var postInstructions: anchor.web3.TransactionInstruction[] = [];

        postInstructions.push(
            createUndelegateInstruction(
                {
                    payer: sessionOrOwner,
                    delegatedAccount: this.publicKey,
                    ownerProgram: this.programId,
                    reimbursement: sessionOrOwner, // TODO: Where should this go?
                },
                new PublicKey(DELEGATION_PROGRAM_ID)
            ) as anchor.web3.TransactionInstruction
        );

        // Add 2 ixns per instance to be closed 
        for (let i = 0; i < instancesToUndelegate; i++) {
            const instanceIdx = instancesToUndelegate - i - 1;
            const instancePubkey = PlayerToken.deriveInstanceSoloPubkey(this.publicKey, instanceIdx, this.houseToken.programId);
            postInstructions.push(
                await this.undelegateInstanceIxn(this._ownerPubkey, instanceIdx, sessionOrOwner)
            );
            postInstructions.push(
                createUndelegateInstruction(
                    {
                        payer: sessionOrOwner,
                        delegatedAccount: instancePubkey,
                        ownerProgram: this.programId,
                        reimbursement: this._ownerPubkey, // TODO: Where should this go?
                    },
                    new PublicKey(DELEGATION_PROGRAM_ID)
                )
            )

            postInstructions.push(
                await this.closeInstanceIxn(sessionOrOwner, this.ownerPubkey, instanceIdx)
            );
        };
        postInstructions.push(
            await this.confirmUndelegatedIxn()
        );

        ixns.push(...postInstructions)

        return ixns

    };

    confirmUndelegatedIxn(): Promise<anchor.web3.TransactionInstruction> {
        return this.baseProgram.methods.playerTokenConfirmUndelegated(
            {}
        ).accounts({
            playerToken: this.publicKey,
        }).instruction()
    };


    // Ephemeral rollup actions

    async placeBetIx(
        owner: PublicKey,
        amount: number,
        sessionAuthority?: Keypair
    ): Promise<TransactionInstruction> {
        // TODO: Replace with better logic
        // Probably some sort of internal counter and view of gameInstances open?
        var instanceIdx = 0;
        try {
            instanceIdx = this._erState.activeInstanceAccounts;
        } catch {
            // Do nothing
        }

        const instancePubkey = PlayerToken.deriveInstancePubkey(this.publicKey, instanceIdx, this.programId);

        return await this.erProgram.methods.placeBet({
            instanceIdx: instanceIdx,
            amount: new anchor.BN(amount)
        }).accounts({
            authority: sessionAuthority ? sessionAuthority.publicKey : owner,
            playerToken: this.publicKey,
            houseToken: this.houseToken.publicKey,
            instance: instancePubkey,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction()

    };

    async settleBetIx(
        won: boolean
    ): Promise<TransactionInstruction> {

        // TODO: Replace with better logic
        // Probably some sort of internal counter and view of gameInstances open?
        var instanceIdx = 0;
        try {
            instanceIdx = this._erState.activeInstanceAccounts - 1;
        } catch {
            // Do nothing
        }
        const instancePubkey = PlayerToken.deriveInstancePubkey(this.publicKey, instanceIdx, this.programId);

        return await this.erProgram.methods.settleBet({
            won: won
        }).accounts({
            playerToken: this.publicKey,
            houseToken: this.houseToken.publicKey,
            instance: instancePubkey,
            // systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();
    };

    async endSessionOnErIxn(
        sessionAuthority?: Keypair
    ): Promise<TransactionInstruction> {

        return await this.erProgram.methods.playerTokenEndSessionEr(
            {}
        ).accounts({
            authority: sessionAuthority != null ? sessionAuthority.publicKey : this.ownerPubkey,
            playerToken: this.publicKey,
        }).instruction()
    };

    // METHODS DEALING WITH THE HISTORY
    subscribeToGameEvents(
        client: Connection,
        pubkeyFilter: PublicKey, // THIS IS THE GAME
        onGameInstanceOrRoundCreatedEvent: Function,
        onBetSoloCreatedEvent: (args: { [key: string]: any, signature: string }) => Promise<void> | void,
        onBetSoloUpdateEvent: (args: { [key: string]: any, signature: string }) => void,
        onBetSoloResultedEvent: Function,
        onGameInstanceResultEvent: Function,
        onGameInstanceUpdateEvent: (args: { [key: string]: any, signature: string }) => void,
        onGameInstanceClosedEvent: Function,
        onError?: Function
    ) {

        // TODO - ADD FILTER FOR THE GAME HERE
        const handleLogs = async (logs: {
            err: TransactionError | null;
            logs: string[];
            signature: string;
        }, context: {
            slot: number;
        }) => {
            if (logs.err != null) {
                console.error('Error in Game WS Listener', logs.err)
                onError?.(logs.err)
                return
            }

            const events = this.baseParser.parseLogs(logs.logs);
            const signature = logs.signature;

            for (let event of events) {
                console.log('subscribeToGameEvents', event);

                if (event.name == "GameInstanceCreated") {
                    if (onGameInstanceOrRoundCreatedEvent) {
                        onGameInstanceOrRoundCreatedEvent({ ...event.data, signature });
                    }
                } else if (event.name == "BetSoloCreated") {
                    if (onBetSoloCreatedEvent) {
                        await onBetSoloCreatedEvent({ ...event.data, signature });
                    }
                } else if (event.name == "GameInstanceResulted") {
                    if (onGameInstanceResultEvent) {
                        onGameInstanceResultEvent({ ...event.data, signature });
                    }
                } else if (event.name == "BetSoloSettled") {
                    if (onBetSoloResultedEvent) {
                        onBetSoloResultedEvent({ ...event.data, signature });
                    }
                }
                else if (event.name == "BetSoloUpdate") {
                    if (onBetSoloUpdateEvent) {
                        onBetSoloUpdateEvent({ ...event.data, signature })
                    }
                } else if (event.name == "GameInstanceUpdate") {
                    if (onGameInstanceUpdateEvent) {
                        onGameInstanceUpdateEvent({ ...event.data, signature })
                    }
                }
                else if (event.name == "GameInstanceClosed") {
                    if (onGameInstanceClosedEvent) {
                        onGameInstanceClosedEvent({ ...event.data, signature })
                    }
                }
                else {
                    console.warn(`Not a known event --> `, event)
                }
            }
        };

        const playerTokenPubkey = this.publicKey

        return client.onLogs(playerTokenPubkey, handleLogs, this._commitmentLevel);
    }

    async subscribeToGameEventsPolling(
        client: Connection,
        gameInstancePubkey: PublicKey,
        onGameInstanceOrRoundCreatedEvent: Function,
        onBetSoloCreatedEvent: (args: { [key: string]: any, signature: string }) => Promise<void>,
        onBetSoloUpdateEvent: (args: { [key: string]: any, signature: string }) => void,
        onBetSoloResultedEvent: Function,
        onGameInstanceResultEvent: Function,
        onGameInstanceUpdateEvent: (args: { [key: string]: any, signature: string }) => void,
        onGameInstanceClosedEvent: Function,
        onError: Function,
        lastSignature: string,
        timeoutS: number = 20
    ) {
        // TODO - NEED TO ADD FILTER ON GAME HERE
        const handleLogs = async (txMeta: anchor.web3.ParsedTransactionWithMeta | null, signature: string) => {
            if (txMeta == null || txMeta.meta == null || txMeta.meta.logMessages == null) {
                return
            }

            const events = this.baseParser.parseLogs(txMeta.meta.logMessages);

            for (let event of events) {
                console.log('subscribeToGameEventsPolling', event);
                if (event.name == "GameInstanceCreated") {
                    if (onGameInstanceOrRoundCreatedEvent) {
                        onGameInstanceOrRoundCreatedEvent({ ...event.data, signature });
                    }
                } else if (event.name == "BetSoloCreated") {
                    if (onBetSoloCreatedEvent) {
                        await onBetSoloCreatedEvent({ ...event.data, signature });
                    }
                } else if (event.name == "GameInstanceResulted") {
                    if (onGameInstanceResultEvent) {
                        onGameInstanceResultEvent({ ...event.data, signature });
                    }
                } else if (event.name == "BetSoloSettled") {
                    if (onBetSoloResultedEvent) {
                        onBetSoloResultedEvent({ ...event.data, signature });
                    }
                } else if (event.name == "BetSoloUpdate") {
                    if (onBetSoloUpdateEvent) {
                        onBetSoloUpdateEvent({ ...event.data, signature })
                    }
                } else if (event.name == "GameInstanceUpdate") {
                    if (onGameInstanceUpdateEvent) {
                        onGameInstanceUpdateEvent({ ...event.data, signature })
                    }
                } else if (event.name == "GameInstanceClosed") {
                    if (onGameInstanceClosedEvent) {
                        onGameInstanceClosedEvent({ ...event.data, signature })
                    }
                } else {
                    console.warn(`Not a known event --> `, event)
                }
            }
        };

        const playerTokenPubkey = this.publicKey

        console.log(`STARTING POLLING`, {
            client,
            playerTokenPubkey: playerTokenPubkey.toString(),
            pubkeyFilter: gameInstancePubkey.toString()
        })

        // VARS USED IN WHILE LOOP
        let isFinished = false // HARD STOP
        let cycles = 0 // CHECK ON MAX CYCLES
        let stageOfCycle = 0 // WHERE IN CYCLE ARE WE - 0 = Looking for game instance, 1 = get logs for instance created, 2 = get logs for bet results 

        while (isFinished == false) {
            cycles += 1

            if (cycles > timeoutS) {
                onError("Too Many Tries To Get Game Result")

                return
            }

            if (stageOfCycle == 0) {
                // GET LOGS FOR INITIAL TX SIG
                const logs = await client.getParsedTransaction(lastSignature, { commitment: 'confirmed', maxSupportedTransactionVersion: 0 })

                if (logs == null) {
                    sleep(1_000)

                    continue
                } else if (logs.meta?.err != null) {
                    isFinished = true
                    return
                } else {
                    const signatures = await client.getSignaturesForAddress(playerTokenPubkey, {
                        until: lastSignature,
                    }, 'confirmed')

                    if (signatures == null || signatures.length == 0) {
                        handleLogs(logs, lastSignature)
                        stageOfCycle = 1

                        await sleep(1000)

                        continue
                    } else {
                        const parsedTxsWithMeta = await client.getParsedTransactions(signatures.map((signature) => {
                            return signature.signature
                        }), {
                            commitment: 'confirmed',
                            maxSupportedTransactionVersion: 0
                        })

                        parsedTxsWithMeta.forEach((parsedTx, index) => {
                            if (parsedTx == null || parsedTx.meta?.err != null) {
                                return
                            }

                            const signature = signatures[index].signature

                            handleLogs(parsedTx, signature)
                        })

                        isFinished = true
                    }
                }
            }

            if (stageOfCycle == 1) {
                // GOT THE ACCOUNT DATA
                // TIME TO GET SIGNATURES FOR ACCOUNT - WILL RESULT WILL BE IN THE NEXT SIG AFTER ONE PASSED...
                const signatures = await client.getSignaturesForAddress(gameInstancePubkey, {
                    until: lastSignature,
                }, 'confirmed')
                if (signatures.length == 0) {
                    await sleep(1000)

                    continue
                }

                const parsedTxsWithMeta = await client.getParsedTransactions(signatures.map((signature) => {
                    return signature.signature
                }), {
                    commitment: 'confirmed',
                    maxSupportedTransactionVersion: 0
                })

                parsedTxsWithMeta.forEach((parsedTx, index) => {
                    if (parsedTx == null || parsedTx.meta?.err != null) {
                        return
                    }

                    const signature = signatures[index].signature

                    handleLogs(parsedTx, signature)
                })

                isFinished = true
            }
        }
    }

    async closeOnLogsWsConnection(client: Connection, wsId: number) {
        try {
            await client.removeOnLogsListener(wsId);
        } catch (err) {
            console.warn("Issue closing GameSpec socket", err);
        }
    }

    static async loadPlayerTokenStateByPubkey(playerTokenPubkeys: PublicKey[], baseProgram: anchor.Program, erProgram: anchor.Program, commitment: Commitment = "processed") {
        // LOAD THE BASE STATES
        const baseStates = await baseProgram.account.playerToken.fetchMultiple(
            playerTokenPubkeys,
            commitment
        );
        // LOAD THE ER STATES
        const erStates = await erProgram.account.playerToken.fetchMultiple(
            playerTokenPubkeys,
            commitment
        );

        const playerTokenByPubkey = new Map<string, any>()

        playerTokenPubkeys.forEach((ptPubkey, index) => {
            const baseState = baseStates[index]
            const erState = erStates[index]

            playerTokenByPubkey.set(ptPubkey.toString(), {
                playerToken: ptPubkey,
                baseState: baseState,
                erState: erState
            })
        })

        return playerTokenByPubkey
    }
}
