import { FC, useContext } from "react";
import { twMerge } from "tailwind-merge";
import { TopBannerContext } from "../../contexts/TopBannerContext";

export interface TopBannerProps {}

const TopBanner: FC<TopBannerProps> = ({}) => {
  const { message } = useContext(TopBannerContext);
  if (message) {
    return (
      <div
        className={twMerge(
          `h-top-banner-height md:flex hidden z-40 relative p-1.5 w-full`,
          `justify-center items-center gap-x-1.5 bg-brand-purple-gradient-1 relative`
        )}
      >
        {message}
      </div>
    );
  }
};

export default TopBanner;
