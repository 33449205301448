import React, { FC, useContext, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useModal } from "react-modal-hook";

import { ModalProps } from "../modals/types";
import { BaseModal, Button, Dropdown } from "../common";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import IconFont from "../common/iconFont/IconFont";
import { NetworkContext } from "../../contexts";
import { APP_NETWORK_TYPE } from "../../types/chain";

const getChainIcon = (chain: APP_NETWORK_TYPE) => {
  switch (chain) {
    case APP_NETWORK_TYPE.SOLANA:
      return "/static/networks-icons/solana.png";
    case APP_NETWORK_TYPE.SONIC:
      return "/static/networks-icons/sonic.png";
    case APP_NETWORK_TYPE.MB_AND_SOLANA:
      return "/static/networks-icons/magic-block.png";
  }
};

const networksData: {
  [key in APP_NETWORK_TYPE]: {
    name: string,
    icon: string,
    hoverIcon?: string
  }
} = {
  [APP_NETWORK_TYPE.SOLANA]: {
    name: "Solana",
    icon: "/static/networks-icons/sol-white.png",
    hoverIcon: "/static/networks-icons/sol-black.png"
  },
  [APP_NETWORK_TYPE.SONIC]: {
    name: "Sonic",
    icon: "/static/networks-icons/sonic.png"
  },
  [APP_NETWORK_TYPE.MB_AND_SOLANA]: {
    name: "Magic Block",
    icon: "/static/networks-icons/solana.png"
  },
};

const ENABLED_NETWORKS = [APP_NETWORK_TYPE.SOLANA, APP_NETWORK_TYPE.SONIC, APP_NETWORK_TYPE.MB_AND_SOLANA]

export const NetworksSwitcher: FC = () => {
  const { chain, setChain } = useContext(NetworkContext);
  const [previousChain, setPreviousChain] = useState<APP_NETWORK_TYPE>(chain);

  const icon = useMemo(() => {
    return getChainIcon(chain);
  }, [chain]);

  const previousIcon = useMemo(() => {
    return getChainIcon(previousChain);
  }, [previousChain]);

  const [showNetworksSwitcherModal, hideNetworksSwitcherModal] = useModal(
    ({ in: open }) => (
      <NetworksSwitcherModal
        visible={open}
        hideModal={() => {
          setPreviousChain(chain);
          hideNetworksSwitcherModal();
        }}
        selectedNetwork={chain}
        icon={icon}
        previousIcon={previousIcon}
      />
    ),
    [chain, icon, previousIcon]
  );

  const handleNetworkChange = (networkName: APP_NETWORK_TYPE) => {
    setChain(networkName);
    showNetworksSwitcherModal();
  };

  const networksOptions = ENABLED_NETWORKS
    .map(networkType => ({
      name: networksData[networkType].name,
      value: networkType,
      icon: networksData[networkType].icon,
      selectedIcon: networksData[networkType].hoverIcon || networksData[networkType].icon
    }));
  const selectedNetworkOption = networksOptions
    .find((networkType) => networkType.value === (chain as APP_NETWORK_TYPE));

  return (
    <>
      <div className="hidden md:flex flex-col gap-y-1.5">
        {
          ENABLED_NETWORKS.map((networkType) => (
            <div className="flex w-full">
              <Button
                disabled={chain === networkType}
                className={twMerge(
                  "w-full justify-start",
                  chain === networkType ? "disabled:bg-gray-50 disabled:hover:bg-gray-50" : ""
                )}
                size="md"
                variant="secondary-gray"
                onClick={() => handleNetworkChange(networkType)}
              >
                <div className="flex w-full items-center gap-x-1.5">
                  <img
                    src={
                      chain === networkType
                        ? (networksData[networkType].hoverIcon || networksData[networkType].icon)
                        : networksData[networkType].icon
                    }
                    alt={`${networksData[networkType].name} logo`}
                    className="w-3"
                  />
                  <div className="font-semibold">
                  <span className={chain === networkType ? "text-gray-900" : ""}>
                    {networksData[networkType].name}
                  </span>
                  </div>
                </div>
              </Button>
            </div>
          ))
        }
      </div>
      <div className="flex md:hidden flex-col gap-y-1.5">
        <Dropdown
          items={[
            ...networksOptions,
            {
              name: "More coming soon",
              value: "",
              isDisabled: true,
              className: "[&>button>*>*]:text-gray-300"
            }
          ]}
          initialSelectedItem={selectedNetworkOption}
          onSelect={networkType => handleNetworkChange(networkType.value as APP_NETWORK_TYPE)}
          classes={{
            input: twMerge(
              "[&>*>.trigger-button-icon]:flex pl-4 pr-2 bg-gray-50 h-12",
              "[&>*>*]:text-gray-900 [&>*>*]:font-semibold [&>*>*]:text-lg"
            ),
            menu: "top-1 w-full [&>*>*>*]:pb-2 [&>*>*>*>*>*]:font-semibold [&>*>*>*>*>*]:text-lg"
          }}
          containerStyles="w-full sm:w-full"
        />
      </div>
    </>
  );
};

interface INetworksSwitcherModalProps extends ModalProps {
  selectedNetwork: APP_NETWORK_TYPE;
  icon: string;
  previousIcon: string;
}

export const NetworksSwitcherModal: FC<INetworksSwitcherModalProps> = ({
  visible,
  hideModal,
  selectedNetwork,
  icon,
  previousIcon,
}) => {
  return (
    <BaseModal
      open={visible}
      onClose={hideModal}
      classes={{
        dialog: `w-[342px] sm:max-w-[360px] bg-gray-700 p-5`,
      }}
      withHeading={false}
    >
      <div
        data-id="session-expiring-modal"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col items-center font-normal gap-y-2">
            <div className="flex font-semibold text-xl">
              Network Switch to {selectedNetwork}
            </div>
            <div className="flex relative justify-between w-3/4">
              <div
                className={twMerge(
                  "flex w-20 aspect-square bg-gray-800 rounded-full border",
                  " relative z-10 border-gray-500 items-center justify-center"
                )}
              >
                <img src={previousIcon} alt="Solana logo" className="h-8" />
              </div>
              <div className="flex relative z-10 items-center justify-center top-1/2">
                <IconFont
                  className="text-gray-50 font-bold bg-purple-500 w-6 h-6 rounded-full"
                  name="arrow_right"
                />
              </div>
              <div
                className={twMerge(
                  "flex w-20 aspect-square bg-gray-800 rounded-full border",
                  "relative z-10 border-gray-500 items-center justify-center"
                )}
              >
                <img src={icon} alt="chain logo" className="h-8" />
              </div>
              <div className="flex absolute w-1/2 h-0 border-t border-gray-500 top-1/2 left-1/2 -translate-x-1/2 z-0" />
            </div>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <div className="flex gap-x-3">
              <Button
                variant="gray"
                className="w-full"
                size="sm"
                disabled={true}
              >
                Read more
              </Button>
              <Button
                variant="primary"
                className="w-full"
                size="sm"
                onClick={() => {
                  hideModal();
                }}
              >
                Play
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
