import React, { PropsWithChildren, useContext } from "react";
import { TopBannerContext } from "../../contexts/TopBannerContext";

interface ISidebarDesktop extends PropsWithChildren {
  leftBarIsOpen: boolean;
}

export function SidebarDesktop({ children, leftBarIsOpen }: ISidebarDesktop) {
  const { message } = useContext(TopBannerContext);
  return (
    <div
      className={`side-bar-desktop hidden bg-gray-800 md:fixed mt-4 pb-3 px-3 md:inset-y-0 md:z-30 md:flex ${
        leftBarIsOpen ? "w-left-bar-width" : "w-left-bar-collapsed-width"
      } flex-col transition-all justify-between
      ${message ? "pt-top-bar-and-banner-height" : "pt-top-bar-height"}
      `}
    >
      {children}
    </div>
  );
}
