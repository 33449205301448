import {
  memo,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { ModalProps } from "../../../modals/types";
import Tabs from "../../../common/tabs/Tabs";
import { BaseModal, Button, IconFont, Scrollbar } from "../../../common";
import { WrappedWalletContext, SonicContext } from "../../../../contexts";
import { useModal } from "react-modal-hook";
import OpenBoxModal from "../OpenBoxModal";
import { MultiRewardBadge, RewordBadge } from "../commonComponents";
import CheckInTab from "./CheckInTab";
import MilestonesTab from "./MilestonesTab";
import ZeebitQuestsTab from "./ZeebitQuestsTab";
import sonicRing from "../../../../static/sonic-rewards/sonic-ring.png";
import sonicBox from "../../../../static/sonic-rewards/sonic-box.png";
import zeebitPoint from "../../../../static/zeebit-point-icon.png";

export enum RewardsModalTabs {
  CheckIn = "check-in",
  TXMilestones = "tx-milestones",
  ZeebitQuests = "zeebit-quests",
}

export type TStage = {
  claimed: boolean;
  rewards: number;
  quantity: number;
  key: number;
};
export interface RewardsModalProps extends ModalProps {
  tab: RewardsModalTabs;
}

const RewardsModal: FC<RewardsModalProps> = ({ visible, hideModal, tab }) => {
  const [currentTab, setCurrentTab] = useState<string>(tab);
  const { walletPubkey } = useContext(WrappedWalletContext);
  const {
    ringAmount,
    mysteryBoxAmount,
    token,
    refreshInfo,
    playerZeebitPoints,
  } = useContext(SonicContext);

  const [showBoxModal, hideBoxModal] = useModal(
    ({ in: open }): JSX.Element => (
      <OpenBoxModal visible={open} hideModal={hideBoxModal} />
    ),
    []
  );
  useEffect(() => {
    if (!!walletPubkey && !!token) {
      refreshInfo();
    }
  }, [walletPubkey, token]);

  const CommonTabContent: FC<PropsWithChildren> = ({ children }) => {
    return (
      <div className="flex flex-col items-center gap-2">
        <div className="md:grid hidden grid-cols-3 grid-rows-1 gap-3 w-full">
          <RewordBadge
            title="Sonic Rings"
            key="Sonic Rings"
            iconUrl={sonicRing}
            value={String(ringAmount)}
            classes={{
              button: "border-amber-700 bg-sonic-rewards-gradient",
              title: "text-amber-400",
            }}
          />
          <RewordBadge
            title="Mysteryboxes"
            key="Mysteryboxes"
            iconUrl={sonicBox}
            value={String(mysteryBoxAmount)}
            classes={{
              button: "border-amber-700 bg-sonic-rewards-gradient",
              title: "text-amber-400",
            }}
          />
          <RewordBadge
            title="Zeebit Points"
            key="Zeebit Points"
            iconUrl={zeebitPoint}
            value={String(playerZeebitPoints)}
            classes={{
              button: "border-pink-700 bg-zeebit-points-gradient",
              title: "text-[#FC78FF]",
            }}
          />
        </div>
        <div className="md:hidden flex flex-col gap-2 w-full">
          <MultiRewardBadge
            title="Zeebit Points"
            value={String(playerZeebitPoints)}
            iconUrl={zeebitPoint}
            classes={{
              button: "border-pink-700 bg-zeebit-points-gradient",
              title: "text-[#FC78FF]",
            }}
          />
          <MultiRewardBadge
            title="Sonic Rewards"
            value={String(ringAmount)}
            iconUrl={sonicRing}
            secondValue={String(mysteryBoxAmount)}
            secondIconUrl={sonicBox}
            classes={{
              button: "border-amber-700 bg-sonic-rewards-gradient",
              title: "text-amber-400",
            }}
          />
        </div>
        {mysteryBoxAmount > 0 ? (
          <Button
            variant="gray"
            classes={{
              wrapper: "bg-rewards-gold-gradient w-full font-bold",
              inner: "items-baseline",
            }}
            onClick={() => showBoxModal()}
            icon={<IconFont name="gift" size="xl" />}
          >
            Open Mysteryboxes
          </Button>
        ) : null}

        {children}
      </div>
    );
  };

  const content = useMemo(
    () => (
      <div className={`relative max-h-[542px]`}>
        {currentTab === RewardsModalTabs.CheckIn ? (
          <CommonTabContent>
            <CheckInTab />
          </CommonTabContent>
        ) : null}
        {currentTab === RewardsModalTabs.TXMilestones ? (
          <CommonTabContent>
            <MilestonesTab />
          </CommonTabContent>
        ) : null}
        {currentTab === RewardsModalTabs.ZeebitQuests ? (
          <CommonTabContent>
            <ZeebitQuestsTab />
          </CommonTabContent>
        ) : null}
      </div>
    ),
    [currentTab, ringAmount, mysteryBoxAmount]
  );

  return (
    <BaseModal
      open={visible}
      onClose={hideModal}
      title="Zeebit & Sonic Rewards"
      classes={{ dialog: "bg-gray-700 sm:max-w-[720px]" }}
      closeOutClick={false}
    >
      <div className="flex flex-wrap gap-y-4">
        <Tabs
          classes={{ wrapper: "self-stretch w-full" }}
          activeTab={currentTab}
          onTabSelect={setCurrentTab}
          tabs={[
            {
              name: RewardsModalTabs.CheckIn,
              label: "Check-in",
            },
            {
              name: RewardsModalTabs.TXMilestones,
              label: "TX Milestones",
            },
            {
              name: RewardsModalTabs.ZeebitQuests,
              label: "Zeebit Quests",
            },
          ]}
        />
        <Scrollbar handleColor="var(--gray-600)">{content}</Scrollbar>
      </div>
    </BaseModal>
  );
};

export default RewardsModal;
