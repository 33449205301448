import React, { FC, useContext, useMemo, useState } from "react";
import { useModal } from "react-modal-hook";
import { twMerge } from "tailwind-merge";

import IconButton from "../common/button/IconButton";
import Button from "../common/button/Button";
import IconUrl from "../common/icon/Icon";
import TooltipLang, { langIcon } from "../common/tooltip/TooltipLang";
import { supportedLanguages } from "../../utils/config/utils";
import { AverLanguage } from "../../types/language";
import {
  DISCORD_URL,
  GITBOOK_URL,
  MEDIUM_URL,
  TWITTER_URL,
} from "../../constants/socials";
import { useUserMeta } from "../../hooks/useUserMeta";
import IconFont from "../common/iconFont/IconFont";
import { useUserOverlayModal } from "../../hooks/modals/useUserOverlayModal";
import SettingsModal, { SettingsTab } from "../modals/SettingsModal";
import { AudioContext, HealthContext, NetworkContext, WrappedWalletContext } from "../../contexts";
import NetworkStatus from "./NetworkStatus";
import RewardsBar from "../sonic/sonic-rewards/RewardsBar";
import { APP_NETWORK_TYPE } from "../../types/chain";

export interface BarFooterProps {
  sidebarOpen: boolean;
  className?: string;
  toggleLeftBar: (value?: boolean | undefined) => void;
  displayProfile?: boolean;
  showNetworkStatus?: boolean;
}

const BarFooter: FC<BarFooterProps> = ({
  sidebarOpen,
  className,
  toggleLeftBar,
  displayProfile,
  showNetworkStatus,
}) => {
  //MOCK START

  const [lang, setLang] = useState<AverLanguage>(AverLanguage.ENGLISH);
  //MOCK END

  const { solanaHealthy, isOnline } = useContext(HealthContext);
  const connected = useMemo(() => {
    return isOnline == true && solanaHealthy == true;
  }, [solanaHealthy, isOnline]);

  const { volume, setVolume, musicVolume, setMusicVolume } =
    useContext(AudioContext);
  const { chain } = useContext(NetworkContext);

  const [showSettingModal, hideSettingModal] = useModal(
    ({ in: open }): JSX.Element => (
      <SettingsModal
        tab={SettingsTab.NETWORK}
        visible={open}
        hideModal={hideSettingModal}
        volume={volume}
        setVolume={setVolume}
        musicVolume={musicVolume}
        setMusicVolume={setMusicVolume}
      />
    ),
    [volume, setVolume, musicVolume, setMusicVolume]
  );

  const currentLang = useMemo(() => supportedLanguages(lang), [lang]);
  const { loggedIn, isWeb3AuthWallet } = useContext(WrappedWalletContext);
  const { avatar, playerAccountPublicKey, playerAccountOwnerPublicKey } =
    useUserMeta();
  const { getOpenUserOverlayModalButton } = useUserOverlayModal();

  return (
    <div className="flex flex-col gap-3">
      <div
        className={twMerge(
          "mt-3 flex w-full flex-col-reverse gap-2 md:flex-col",
          className,
          !sidebarOpen ? "absolute bottom-0 left-0 px-3 pb-3" : ""
        )}
      >
        {sidebarOpen ? (
          <>
            {/* {loggedIn && displayProfile ? <UserProfileWidget /> : ""} */}
            <div data-id="sonic-rewards" className="flex flex-col w-full gap-2">
              {
                loggedIn
                && chain === APP_NETWORK_TYPE.SONIC
                && <RewardsBar className="hidden md:flex" />
              }
            </div>
            <div className="flex w-full gap-2">
              <IconButton
                onClick={() => {
                  window.open(TWITTER_URL);
                }}
                inheritStroke={false}
                inheritFill
                size="social"
                iconName="twitter"
                className="w-full"
              />
              <IconButton
                onClick={() => {
                  window.open(DISCORD_URL);
                }}
                inheritStroke={false}
                inheritFill
                size="social"
                iconName="discord"
                className="w-full"
              />
              <IconButton
                onClick={() => {
                  window.open(MEDIUM_URL);
                }}
                inheritStroke={false}
                inheritFill
                size="social"
                iconName="medium"
                className="w-full"
              />
              <IconButton
                onClick={() => {
                  window.open(GITBOOK_URL);
                }}
                inheritStroke={false}
                inheritFill
                size="social"
                iconName="gitbook"
                className="w-full"
              />
            </div>

            <div className={`flex w-full gap-2 [&>*]:grow`}>
              <div className="basis-1">
                <TooltipLang setLang={setLang}>
                  <Button
                    className="h-[40px] w-full !font-normal"
                    variant="secondary-gray"
                    size="xs"
                    icon={
                      <IconUrl
                        inheritStroke={false}
                        iconUrl={langIcon(currentLang?.code)}
                        size="lg"
                      />
                    }
                  >
                    {currentLang?.displayName}
                  </Button>
                </TooltipLang>
              </div>
              <div className="basis-1">
                <Button
                  className="h-[40px] w-full !font-normal"
                  variant="secondary-gray"
                  size="xs"
                  onClick={showSettingModal}
                  icon={<IconFont name="settings" size="lg" />}
                >
                  Settings
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* {loggedIn
            ? getOpenUserOverlayModalButton(
                playerAccountPublicKey || playerAccountOwnerPublicKey,
                <div
                  data-id="expanded-menu-user-icon"
                  className="overflow-hidden rounded-md"
                  onClick={() => {
                    if (!sidebarOpen) {
                      toggleLeftBar();
                    }
                  }}
                >
                  <CurrentPlayerAvatar
                    playerAvatarKey={avatar}
                    playerPublicKey={
                      playerAccountOwnerPublicKey || playerAccountPublicKey
                    }
                    className="rounded me-1"
                    width={36}
                    height={36}
                  />
                </div>
              )
            : ""} */}

            <TooltipLang setLang={setLang}>
              <IconButton
                inheritStroke={false}
                inheritFill
                size="social"
                icon={
                  <IconUrl
                    inheritStroke={false}
                    iconUrl={langIcon(currentLang?.code)}
                    size="md"
                  />
                }
                className="w-full"
              />
            </TooltipLang>
            <IconButton
              size="social"
              onClick={showSettingModal}
              icon={<IconFont name="settings" size="lg" />}
              className="w-full"
            />
          </>
        )}
      </div>
      {showNetworkStatus ? <NetworkStatus connected={connected} /> : null}{" "}
    </div>
  );
};

export default BarFooter;
