import { useModal } from "react-modal-hook";

import {
  DISCORD_URL,
  GITBOOK_FAIRNESS_URL,
  // GITBOOK_PRIVACY_URL,
  GITBOOK_RESPONSIBLE_GAMING_URL,
  GITBOOK_VERIFIABLE_RANDOMNESS_URL,
  SMART_CONTRACT_AUDIT_URL,
} from "../../constants/socials";
import {
  SEC_3_HOMEPAGE,
  SWITCHBOARD_HOMEPAGE,
  ZEEBIT_DOCS_URL,
} from "../../constants/constants";
import { TermsModal } from "../../modals/TermsModal";

import SolanaLogo from "../../assets/icons/solana.svg";
import SonicLogo from "../../assets/icons/sonic-logo.svg";
import mgLogo from "../../static/networks-icons/magic-block-text.png";

// import SwitchboardLogo from "../../assets/partners-logos/switchboard-logo.svg";
// import Sec3Logo from "../../assets/partners-logos/sec3-logo.svg";
import BeGambleAwareOrgLogo from "../../assets/icons/be-gamble-aware-org-logo.svg";
import TwitterIcon from "../../assets/socials/twitter.svg";
import DiscoardIcon from "../../assets/socials/discord.svg";
import MIcon from "../../assets/socials/medium.svg";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import { useContext } from "react";
import { NetworkContext } from "../../contexts";
import { APP_NETWORK_TYPE } from "../../types/chain";
import { ImageStatic } from "../common/image/ImageStatic";

export function Footer() {
  const { chain } = useContext(NetworkContext);
  const [showTermsModal, hideTermsModal] = useModal(
    ({ in: open }): JSX.Element => (
      <TermsModal open={open} closeModal={hideTermsModal} />
    ),
    []
  );

  const infoItems = [
    {
      name: "Community Rules",
      onClick: () => {
        showTermsModal();
      },
    },
    {
      name: "Fairness",
      onClick: () => {
        window.open(GITBOOK_FAIRNESS_URL);
      },
    },
    {
      name: "Verifiable Randomness",
      onClick: () => {
        window.open(GITBOOK_VERIFIABLE_RANDOMNESS_URL);
      },
    },
    // TODO:  Hide for a while according to the lawyers review
    // {
    //   name: "Privacy Policy",
    //   onClick: () => {
    //     window.open(GITBOOK_PRIVACY_URL);
    //   },
    // },
    {
      name: "Responsible Gaming",
      onClick: () => {
        window.open(GITBOOK_RESPONSIBLE_GAMING_URL);
      },
    },
    {
      name: "Smart Contract Audit",
      onClick: () => {
        window.open(SMART_CONTRACT_AUDIT_URL);
      },
    },
  ];

  const communityItems = [
    {
      name: (
        <div className="flex justify-start">
          <div className="h-6 @2xl:h-5 w-6 @2xl:w-5 -mt-0.5 mr-5 @2xl:mr-1.5 [&>svg]:fill-current">
            <TwitterIcon className="[&>svg]:fill-current" />
          </div>
          <span className="hidden @xl:inline">Twitter</span>
        </div>
      ),
      onClick: () => {
        window.open("https://twitter.com/Zeebitio");
      },
    },
    {
      name: (
        <div className="flex justify-start">
          <div className="h-7 @2xl:h-5 w-7 @2xl:w-5 -mt-0.5 mr-5 @2xl:mr-1.5 [&>svg]:fill-current">
            <DiscoardIcon className="[&>svg]:fill-current" />
          </div>
          <span className="hidden @xl:inline">Discord</span>
        </div>
      ),
      onClick: () => {
        window.open(DISCORD_URL);
      },
    },
    {
      name: (
        <div className="flex justify-start">
          <div className="h-6 @2xl:h-5 w-6 @2xl:w-5 -mt-0.5 mr-5 @2xl:mr-1.5 [&>svg]:fill-current">
            <MIcon className="[&>svg]:fill-current" />
          </div>
          <span className="hidden @xl:inline">Blog</span>
        </div>
      ),
      onClick: () => {
        window.open("https://medium.com/@Zeebit");
      },
    },
  ];

  const helpItems = [
    {
      name: "Support Channel",
      onClick: () => {
        window.open(DISCORD_URL);
      },
    },
    {
      name: "Gitbook Docs",
      onClick: () => {
        window.open(ZEEBIT_DOCS_URL);
      },
    },
  ];
  const MBLogo = (
    <ImageStatic
      url={mgLogo}
      classes={{
        wrapper: "w-auto h-full shrink-0",
        image: "w-auto max-w-max",
      }}
    />
  );
  return (
    <div
      data-id="zeebit-footer"
      className="@container pt-6 @2xl:pt-16 @2xl:mb-10 text-gray-300 font-normal max-w-7xl mx-auto scroll-fix "
    >
      <div className="grid @2xl:grid-cols-7 gap-5 scroll-fix ">
        <div className="col col-span-4 order-last @2xl:order-first text-center @2xl:text-left mt-5 @2xl:mt-0 text-xs">
          <img
            src={getS3StaticFolderUrl("/static/zeebit_logo.svg")}
            className=""
            alt="Zeebit logo"
          />
          <div className="max-w-[33rem] mt-5 mx-auto @2xl:mx-0 text-left">
            Zeebit is a fully on-chain, decentralized and non-custodial
            protocol. All bets and transfers take place directly between your
            wallet and the protocol, and results and settlement are verifiable
            fair. Your funds are never held or controlled by any individual or
            organization.
          </div>
          <div
            data-id="user-notification"
            className="flex flex-col justify-center @2xl:justify-start"
          >
            <div className="flex mt-4 items-center justify-center @2xl:justify-start">
              <div className="p-1.5 bg-gray-700 rounded mr-2 font-semibold">
                18+
              </div>
              <div>Individuals under the age of 18 are prohibited.</div>
            </div>
            <div className="flex mt-4 justify-center @2xl:justify-start">
              <a href="http://begambleaware.org/" target="_blank">
                <BeGambleAwareOrgLogo />
              </a>
            </div>
          </div>
        </div>
        <div className="col col-span-2 @2xl:col-span-1 text-left mb-3">
          <FooterMenu title={"Information"} items={infoItems} />
        </div>
        <div className="col col-span-2 @2xl:col-span-1 text-left mb-3">
          <FooterMenu title={"Help & Support"} items={helpItems} />
          <div
            data-id="community-mobile"
            className="@xl:hidden [&>div>ul]:flex [&>div>ul]:space-y-0"
          >
            <FooterMenu items={communityItems} />
          </div>
        </div>
        <div className="hidden @xl:block col-span-1 text-left mb-3">
          <FooterMenu title={"Community"} items={communityItems} />
        </div>
      </div>
      {/* Divider */}
      <div className="flex w-full border-t border-gray-600 my-6" />
      {/* Second row */}
      <div
        data-id="partners-block"
        className="flex gap-5 text-xs flex-col @2xl:flex-row"
      >
        <div className="col-span-4 mx-auto @2xl:mx-0">
          <div className="flex">
            <a
              href="https://www.solana.com/"
              target="_blank"
              className="flex flex-col @2xl:flex-row no-underline"
            >
              <div className="flex items-center justify-center @2xl:justify-start whitespace-nowrap">
                Powered by
              </div>
              <div
                className={`
                  [&>svg]:h-full [&>svg>*]:h-full [&>svg]:fill-current
                  w-auto h-5 ml-0 @2xl:ml-3 mt-3 @2xl:mt-0 transition-all
                  text-white
                `}
              >
                {chain === APP_NETWORK_TYPE.SOLANA ? <SolanaLogo /> : null}
                {chain === APP_NETWORK_TYPE.SONIC ? <SonicLogo /> : null}
                {chain === APP_NETWORK_TYPE.MB_AND_SOLANA ? MBLogo : null}
              </div>
            </a>
          </div>
        </div>
        <div className=" my-auto @2xl:col-span-3 col-span-4 mx-auto @2xl:mx-0 @2xl:my-auto text-center w-full flex-col @2xl:flex-row justify-end">
          {/* <div className="flex mr-0 @md:mr-2 items-center justify-center @2xl:justify-start mb-3 @2xl:mb-0">
            Made possible by
          </div>
          <div className="flex [&>div>svg]:fill-current text-gray-300 justify-center">
            <div
              className="border-r border-gray-600 pr-2 mr-2 text-white cursor-pointer"
              onClick={() => {
                window.open(SWITCHBOARD_HOMEPAGE);
              }}
            >
              <SwitchboardLogo />
            </div>
            <div
              className="border-r border-gray-600 pr-2 mr-2 text-white cursor-pointer min-w-[80px]"
              onClick={() => {
                window.open(SEC_3_HOMEPAGE);
              }}
            >
              <Sec3Logo className="max-w-full" />
            </div>
            <img
              className="h-5 cursor-pointer max-w-full"
              onClick={() => {
                window.open(HELLO_MOON_HOMEPAGE);
              }}
              src={HelloMoonLogo}
              alt="Hello Moon logo"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

function FooterMenu({ title, items }) {
  return (
    <>
      <div className="text-gray-50 text-sm font-semibold mb-3 ">{title}</div>
      <div className="mx-auto">
        <ul className="space-y-1">
          {items.map((item, idx) => (
            <li
              key={item.name + idx}
              className="hover:text-gray-300 transition-all cursor-pointer "
            >
              <div
                onClick={item.onClick}
                className="flex justify-start text-sm"
              >
                {item.name}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
