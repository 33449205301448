import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useModal } from "react-modal-hook";
import { twMerge } from "tailwind-merge";

import { ModalProps } from "./types";
import Icon from "../common/icon/Icon";
import { BaseModal, Button, Dropdown, IconFont, Tooltip } from "../common";
import Input from "../common/input/Input";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import FormItem from "../common/form-item/FormItem";
import { AggregatedBalancesContext } from "../../contexts/AggregatedBalancesContext";
import { ToasterContext } from "../../contexts/ToasterContext";
import IconUrl from "../common/icon/Icon";
import InputButton from "../common/input/InputButton";
import { CheckBox, CheckboxSize } from "../common/check-box/check-box";
import { truncatePubkey } from "../../utils/string/string";
import { AwaitingModal } from "./AwaitingModal";
import { BalanceContext } from "../../contexts/BalanceContext";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import { PlayerTokenContext } from "../../contexts/PlayerTokenContext";
import { confirmTransaction } from "../../utils/solana/utils";
import { NetworkContext } from "../../contexts/NetworkContext";
import { HouseContext } from "../../contexts/HouseContext";
import { Keypair } from "@solana/web3.js";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import Tabs from "../common/tabs/Tabs";
import { WrappedWalletContext } from "../../contexts";
import { BASE_TOAST_CONFIG, BaseToast } from "../toast/BaseToast";
import { AutoSigningNeedsMoreSolModal } from "../../modals/auto-sign/AutoSigningNeedsMoreSolModal";

export enum IFundOrWithdrawModalTabs {
  DEPOSIT = "Deposit",
  WITHDRAW = "Withdraw",
}

interface IAddFundsAndPlayModalProps extends ModalProps {
  walletModalSelectedTab?: IFundOrWithdrawModalTabs;
};

export const AddFundsAndPlayModal: FC<IAddFundsAndPlayModalProps> = ({
  visible,
  hideModal,
  walletModalSelectedTab = IFundOrWithdrawModalTabs.DEPOSIT
}) => {
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { setSelectedTokenMeta, selectedTokenMeta } = useContext(BalanceContext);
  const selectedMerged = useMemo(() => {
    return mergedTokens?.find((token) => {
      return token.context?.pubkey == selectedTokenMeta.mint
    })
  }, [mergedTokens, selectedTokenMeta])
  const playBalance = useMemo(() => {
    if (selectedMerged?.playerToken == null) {
      return 0
    }

    return selectedMerged.playerToken?.playBalance / Math.pow(10, selectedMerged.context?.decimals || 6)
  }, [selectedMerged])

  const walletBalance = useMemo(() => {
    if (selectedMerged?.wallet == null) {
      return 0
    }
    return selectedMerged?.wallet.uiAmount;
  }, [selectedMerged])
  const [
    currentTab, setCurrentTab
  ] = useState<IFundOrWithdrawModalTabs>(walletModalSelectedTab);

  useEffect(() => {
    setCurrentTab(walletModalSelectedTab);
  }, [walletModalSelectedTab]);

  const toast = useContext(ToasterContext)

  const tokensDropdownItems = mergedTokens?.map(token => ({
    value: token?.context?.symbol,
    data: token,
    key: token?.context?.symbol,
    name: `${token?.context?.name} (${token?.context?.symbol})`,
  })) || [];

  const [tokensAmountValue, setTokensAmountValue] = useState(0);
  const {
    signerKp, allowsAutoSigning, setAllowsAutoSigning, lamportBalance
  } = useContext(SessionAuthorityContext)
  const { initAndDeposit, loadPlayerToken, initAndDelegate, processWithdrawal } = useContext(PlayerTokenContext)

  const [showAwaitingModal, hideAwaitingModal] = useModal(
    ({ in: open }) => (
      <AwaitingModal
        visible={open}
        hideModal={hideAwaitingModal}
        mainText="Waiting for signature"
        secondaryText="Please sign permission"
      />
    ),
    [allowsAutoSigning],
  );

  // ENSURE ONLY SHOWN WHEN NOT WEB3 AUTH WALLET
  const [showAutoSigningAgreementModal, hideAutoSigningAgreementModal] = useModal(
    ({ in: open }) => (
      <AutoSigningAgreementModal
        visible={open}
        hideModal={hideAutoSigningAgreementModal}
        onAutoSigningApprove={() => setAllowsAutoSigning(true)}
        onModalsClose={hideModal}
      />
    ),
    [setAllowsAutoSigning],
  );

  const [showAutoSigningNeedsMoreSolModal, hideAutoSigningNeedsMoreSolModal] = useModal(
    ({ in: open }) => (
      <AutoSigningNeedsMoreSolModal
        visible={open}
        hideModal={() => {
          hideAutoSigningNeedsMoreSolModal();
        }}
      />
    ),
    []
  );

  const [actionLoading, setActionLoading] = useState(false)
  const { client, recentBlockhash } = useContext(NetworkContext)
  const { houseToken } = useContext(HouseContext)

  const handleDepositClick = useCallback(async () => {
    try {
      setActionLoading(true)

      const basis = tokensAmountValue * Math.pow(10, selectedMerged?.context?.decimals || 6)
      const sessionKp = allowsAutoSigning == true ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined

      const sig = houseToken?.isDelegated
        ? await initAndDelegate(86_400, 1, sessionKp, 20_000_000, basis)
        : await initAndDeposit(sessionKp, 20_000_000, basis);

      // CONFIRM THE TX
      await confirmTransaction(sig, client, recentBlockhash, "confirmed");

      toast(
        <BaseToast
          message={`Successfully deposited ${tokensAmountValue} ${selectedMerged?.context?.name}.`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      // LOAD THE PLAYER TOKENS
      await loadPlayerToken();

      // HIDE MODAL
      hideModal();
    } catch (err) {
      console.error({ err });
      toast(
        <BaseToast
          message={`There was an issue depositing tokens.`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    } finally {
      setActionLoading(false);
    }
  }, [
    initAndDeposit,
    tokensAmountValue,
    selectedMerged,
    client,
    recentBlockhash,
    loadPlayerToken,
    signerKp,
    initAndDelegate,
    allowsAutoSigning
  ]);

  const handleWithdraw = useCallback(async () => {
    try {
      setActionLoading(true)

      // CALC AMOUNT TO WITHDRAW
      const decimals = selectedMerged?.context?.decimals || 6
      const withdrawAmountBasis = tokensAmountValue * Math.pow(10, decimals)
      const sig = await processWithdrawal(withdrawAmountBasis);

      toast(
        <BaseToast
          message={`Successfully withdrew ${tokensAmountValue} ${selectedMerged?.context?.name}.`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      
      hideModal();
    } catch (err) {
      console.error({ err });
      toast(
        <BaseToast
          message={`There was an issue withdrawing tokens.`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    } finally {
      setActionLoading(false);
    }
  }, [selectedMerged, processWithdrawal, client, recentBlockhash, tokensAmountValue, selectedMerged]);

  useEffect(() => {
    setTokensAmountValue(0);
    setCurrentTab(walletModalSelectedTab);
  }, [visible]);

  const amountError =
    Number(currentTab === IFundOrWithdrawModalTabs.DEPOSIT ? walletBalance : playBalance) < tokensAmountValue
      ? "Insufficient funds"
      : "";
  const solGasFee = "0.00005";

  const { isWeb3AuthWallet, walletPubkey } = useContext(WrappedWalletContext)

  useEffect(() => {
    setTokensAmountValue(0);
  }, [selectedTokenMeta?.mint]);

  return (
    <BaseModal
      open={visible}
      withHeading={false}
      onClose={() => {
        hideModal();
        hideAutoSigningAgreementModal();
      }}
      classes={{
        dialog: `w-[342px] sm:max-w-[420px] bg-gray-800 p-5`
      }}
    >
      <div
        data-id="add-funds-and-play"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col items-center font-normal gap-y-1">
            <Tabs
              classes={{ wrapper: "self-stretch w-full" }}
              activeTab={currentTab}
              onTabSelect={setCurrentTab}
              tabs={[
                {
                  name: IFundOrWithdrawModalTabs.DEPOSIT,
                  label: IFundOrWithdrawModalTabs.DEPOSIT,
                }, {
                  name: IFundOrWithdrawModalTabs.WITHDRAW,
                  label: IFundOrWithdrawModalTabs.WITHDRAW,
                }
              ]}
            />
          </div>
          <div className="flex w-full flex-col gap-y-4">
            <FormItem
              className="self-stretch [&>label]:font-normal"
              label="Token"
            >
              <Dropdown
                items={tokensDropdownItems}
                classes={{
                  input: `h-12 bg-gray-900 hover:bg-gray-500 [&[data-headlessui-state="open"]]:bg-gray-900`,
                  menu: "top-1 bg-gray-500 px-2 w-full",
                }}
                containerStyles="w-full sm:w-full"
                triggerButton={
                  ({ open }) => (
                    <div
                      className="flex items-center justify-between relative w-full px-2"
                    >
                      <div className="flex items-center cursor-pointer w-full">
                        {selectedTokenMeta != null && tokensDropdownItems.find((token) => {
                          return token.data.context?.pubkey == selectedTokenMeta.mint
                        }) != null ? <>
                          <IconUrl
                            iconUrl={selectedMerged?.context?.imageDarkPng}
                            className="w-5 h-5 mr-2 mb-0.5"
                          />
                          <div className="flex flex-col gap-y-0.5">
                            <div className="text-base font-semibold">
                              {`${selectedMerged?.context?.name} (${truncatePubkey(selectedTokenMeta?.mint || '', 3)})`}
                            </div>
                          </div>
                        </> : ''}
                      </div>
                      <IconFont
                        className={`transition-all ${open ? "rotate-180" : ""} text-[24px]`}
                        name="arrow_down"
                      />
                    </div>
                  )
                }
                itemComponent={
                  ({ item }) => {
                    return (
                      <button
                        className="flex w-full bg-gray-500 rounded-lg hover:bg-gray-400 items-center p-2"
                        onClick={() => {

                          setSelectedTokenMeta({
                            mint: item.data.context.pubkey,
                            decimals: item.data.context.decimals
                          });
                        }}
                      >
                        <IconUrl
                          iconUrl={item?.data?.context.imageDarkPng}
                          className="w-5 h-5 mr-2 flex-shrink-0 mt-0.5"
                        />
                        <div className="flex flex-col gap-y-0.5 relative top-0.5 text-left">
                          <div className="flex justify-start text-base font-semibold capitalize">
                            {item.name}
                          </div>
                        </div>
                      </button>
                    );
                  }}
              />
            </FormItem>

            <FormItem
              className="self-stretch [&>label]:font-normal"
              label="Amount"
              rightLabel={
                <div className="flex font-semibold">
                  Available:
                  &nbsp;{
                    formatZeebitNumber(
                      currentTab === IFundOrWithdrawModalTabs.DEPOSIT ? walletBalance : playBalance,
                      NumberType.TOKEN_AMOUNT
                    )
                  }
                  &nbsp;{selectedMerged?.context?.symbol}
                </div>
              }
              error={amountError}
            >
              <Input
                classes={{
                  label: amountError ? "" : "border-none",
                  input: "py-3",
                }}
                name="token-amount"
                variant="game"
                type="number"
                // isPreview={isPreview}
                // disabled={disabled || isPreview}
                step={0.1}
                // min={minWager?.toString() || ""}
                error={amountError}
                value={String(tokensAmountValue)}
                onChange={(e) => {
                  setTokensAmountValue(Number(e.target.value))
                }}
                leftInfo={<Icon iconUrl={selectedMerged?.context?.imageDarkPng} className="mb-[2px] mr-[5px] [&>img]:rounded-full" />}
                rightInfo={
                  <div className="ml-[5px] flex gap-1 [&>button]:w-11">
                    <InputButton
                      onClick={() => {
                        setTokensAmountValue(Number(tokensAmountValue / 2))
                      }}
                    >
                      Half
                    </InputButton>
                    <InputButton
                      onClick={() => {
                        setTokensAmountValue(Number(
                          currentTab === IFundOrWithdrawModalTabs.DEPOSIT ? walletBalance : playBalance
                        ))
                      }}
                    >
                      Max
                    </InputButton>
                  </div>
                }
              />
            </FormItem>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <div className="flex-col text-gray-200 text-sm gap-1 bg-gray-600 rounded-md p-2 font-normal">
              <div className="flex justify-between">
                <div>In Play Wallet:</div>
                <div>{formatZeebitNumber(playBalance, NumberType.TOKEN_AMOUNT)} {selectedMerged?.context?.symbol}</div>
              </div>
              <div className="flex justify-between">
                <div>After {currentTab === IFundOrWithdrawModalTabs.DEPOSIT ? "Deposit" : "Withdrawal"}:</div>
                <div>
                  {
                    currentTab === IFundOrWithdrawModalTabs.DEPOSIT
                      ? formatZeebitNumber(playBalance + tokensAmountValue, NumberType.TOKEN_AMOUNT)
                      : formatZeebitNumber(playBalance - tokensAmountValue, NumberType.TOKEN_AMOUNT)
                  }
                  &nbsp;{selectedMerged?.context?.symbol}
                </div>
              </div>
              <div className="flex justify-between">
                <div>Gas fee:</div>
                <div>{solGasFee} SOL</div>
              </div>
            </div>
            {/* ONLY ALLOW AUTO SIGNING IF NOT WEB3AUTH WALLET */}
            {
              walletPubkey != null
              && isWeb3AuthWallet == false
              && currentTab === IFundOrWithdrawModalTabs.DEPOSIT
              && (
                <div className="flex items-start self-stretch text-gray-200">
                  <label className="flex gap-x-2">
                    <div className="self-center">
                      <CheckBox
                        size={CheckboxSize.sm}
                        checked={allowsAutoSigning}
                        setChecked={(isChecked) => {
                          if (
                            isChecked
                            && (!lamportBalance || lamportBalance < 5000)
                          ) {
                            showAutoSigningNeedsMoreSolModal();
                          } else {
                            setAllowsAutoSigning(isChecked);
                          }
                        }}
                      />
                    </div>

                    <div className="flex font-normal text-sm items-center">
                      <span>Approve ‘Auto-signing’ experience</span>&nbsp;
                      <Tooltip content={"Tooltip content"}>
                        <IconFont name="information" size="md" />
                      </Tooltip>
                    </div>
                  </label>
                </div>
              )
            }
            <Button
              variant="primary"
              className={twMerge(
                "w-full",
                amountError || tokensAmountValue <= 0 ? "bg-none" : ""
              )}
              size="sm"
              isLoading={actionLoading}
              disabled={amountError || tokensAmountValue <= 0}
              onClick={currentTab === IFundOrWithdrawModalTabs.DEPOSIT ? handleDepositClick : handleWithdraw}
            //   () => {
            //   if (!isAutoSigningApproved) {
            //     showAutoSigningAgreementModal();
            //   } else {
            //     showAwaitingModal();
            //     hideModal();
            //   }
            // }
            >
              {currentTab}
            </Button>
            <Button
              variant="primary"
              className="w-full bg-none border-2 border-gray-50 hover:after:border-gray-800"
              size="sm"
              onClick={hideModal}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

interface IAutoSigningAgreementModalProps extends ModalProps {
  onAutoSigningApprove: Function;
  onModalsClose: Function;
}

export const AutoSigningAgreementModal: FC<IAutoSigningAgreementModalProps> = ({
  visible,
  hideModal,
  onAutoSigningApprove,
  onModalsClose
}) => {
  const [shouldHide, setShouldHide] = useState(false);

  return (
    <BaseModal
      open={visible}
      onClose={() => {
        hideModal();
        onModalsClose();
      }}
      classes={{
        dialog: `w-[342px] sm:max-w-[420px] bg-gray-800 p-5`
      }}
      withHeading={false}
    >
      <div
        data-id="auto-signing-agreement"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col items-center font-normal gap-y-1">
            <div className="flex font-semibold text-xl">Auto-signing</div>
            <div className="flex text-center text-gray-200">
              Without ‘Auto-signing’ you will be required to manually sign transactions each time you bet. Are you sure you don’t need it?
            </div>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <div
              className={twMerge(
                "flex items-start self-stretch text-gray-200",
              )}
            >
              <label className="flex gap-x-2">
                <div className="self-center">
                  <CheckBox
                    size={CheckboxSize.sm}
                    checked={shouldHide}
                    setChecked={setShouldHide}
                  />
                </div>

                <div className="flex font-normal text-sm items-center">
                  Don't show again
                </div>
              </label>
            </div>
            <div className="flex gap-x-3">
              <Button
                variant="gray"
                className="w-full h-10"
                size="sm"
                onClick={() => {
                  onAutoSigningApprove();
                  onModalsClose();
                  hideModal();
                }}
              >
                Use Auto-signing
              </Button>
              <Button
                variant="primary"
                className="w-full h-10"
                size="sm"
                onClick={() => {

                }}
              >
                Continue Without
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
