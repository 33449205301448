import React, {FC, useCallback, useContext, useMemo, useState} from "react";

import { BaseModal } from "./BaseModal";
import { Tabs } from "./wallet/Tabs";
import { AggregatedBalancesContext, IMergedToken } from "../contexts/AggregatedBalancesContext";
import Icon from "../components/common/icon/Icon";
import { Loading } from "./Loading";
import  {ModalProps } from "../components/modals/types";
import { useTokenAirdrop } from "../hooks/useTokenAirdrop";
import { getS3StaticFolderUrl } from "../utils/config/utils";
import Button from "../components/common/button/Button";
import Input from "../components/common/input/Input";
import { BASE_TOAST_CONFIG, BaseToast } from "../components/toast/BaseToast";
import { ToasterContext, WrappedWalletContext, NetworkContext, BalanceContext } from "../contexts";
import CopyIcon from "../assets/icons/copy.svg";
import LinkIcon from "../assets/icons/link.svg";

export enum AirdropModalTab {
  SOL = "SOL Faucet",
  USDC = "USDC Faucet"
}

interface IAirdropModalProps extends ModalProps {};

export const AirdropModal: FC<IAirdropModalProps> = ({
  visible,
  hideModal
}) => {
  const [
    selectedTab, setSelectedTab
  ] = useState<AirdropModalTab>(AirdropModalTab.SOL)

  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { walletPubkey } = useContext(WrappedWalletContext);

  const [loading, setLoading] = useState(false);

  const { handleAirdrop } = useTokenAirdrop();

  const handleTokenAirdrop = useCallback((tokenSymbol: string) => {
    return handleAirdrop(
      mergedTokens?.find(walletToken => walletToken?.context?.symbol === tokenSymbol),
      () => setLoading(true),
      () => setLoading(false),
      () => {
        setLoading(false);
        hideModal();
      },
    );
  }, [handleAirdrop, mergedTokens, hideModal, setLoading]);

  const toast = useContext(ToasterContext);

  const copyLinkToClipboard = useCallback(
    (toCopy: string) => {
      window.navigator.clipboard.writeText(toCopy);
      toast(
        <BaseToast
          icon={<Icon size="lg" icon={<LinkIcon />} />}
          type={"success"}
          message={"Successfully copied wallet pubkey to clipboard."}
        />,
        BASE_TOAST_CONFIG,
      );
    },
    [history, toast],
  );

  return (
    <BaseModal
      open={visible}
      hideModal={hideModal}
    >
      {loading == true ? (
        <Loading message="Loading" title="Please wait..." />
      ) : (
        <div className="flex w-[85vw] md:w-[460px] flex-col items-start gap-y-3 bg-gray-800">
          <Tabs
            selectedTab={selectedTab}
            updateSelectedTab={setSelectedTab}
            tabs={AirdropModalTab}
            classes={{
              activeTabClasses: "bg-white text-gray-800",
              tabClasses: "font-semibold"
            }}
          />
          {
            selectedTab == AirdropModalTab.SOL
            && (
              <div className="flex flex-col w-full items-center gap-y-3">
                <div className="flex w-full mt-3">
                  <img src={getS3StaticFolderUrl("/static/sonic-testnet-example.png")} className="flex w-full" />
                </div>

                <div
                  className="flex pt-3 flex-col items-center gap-y-2.5 self-stretch text-center"
                >
                  <div className="text-xl font-semibold">You’ll need SOL to play</div>
                  <div className="subtitle text-base font-normal text-gray-200">
                    Visit the Sonic Testnet Faucet to get SOL.
                    Copy your wallet address from below and paste is in the input.
                    Make sure you have set the network to api.testnet.sonic.game
                  </div>
                </div>

                <Input
                  name={"sol-fund-address"}
                  type="string"
                  variant="regular"
                  classes={{
                    label: "font-sm font-normal",
                    wrapper: "self-stretch relative",
                  }}
                  placeholder={walletPubkey?.toString()}
                  value={walletPubkey?.toString()}
                  rightInfo={
                    <>
                      <button
                        className="absolute w-full h-full top-0 left-0"
                        onClick={() => {
                          copyLinkToClipboard(walletPubkey?.toString());
                        }}
                      />
                      <div
                        className={`pe-1 cursor-pointer`}
                        onClick={() => {
                          copyLinkToClipboard(walletPubkey?.toString());
                        }}
                      >
                        <Icon icon={<CopyIcon/>} size="lg"/>
                      </div>
                    </>
                  }
                />

                <div className="flex items-start gap-x-3 self-stretch mt-3">
                  <Button
                    variant="primary"
                    className="w-full bg-none border-2 border-gray-50 hover:after:border-gray-800"
                    size="sm"
                    onClick={hideModal}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={false}
                    className="w-full"
                    variant="secondary"
                    size="sm"
                    onClick={() => window.open('https://faucet.sonic.game/#/?network=testnet', '_blank')}
                  >
                    Get SOL
                  </Button>
                </div>
              </div>
            )
          }
          {
            selectedTab == AirdropModalTab.USDC
            && (
              <div className="flex flex-col w-full items-center gap-y-3">
              <div className="flex w-[160px] mt-3">
                <img src={getS3StaticFolderUrl("/static/ranks-and-rewards/collectible-coins.png")}/>
              </div>

              <div
                className="flex pt-4 flex-col items-center gap-y-1.5 self-stretch text-center"
              >
                <div className="text-xl font-semibold">Airdrop Testnet USDC</div>
                <div className="subtitle text-base font-normal text-gray-200">
                  You can top-up your balance of Testnet USDC anytime you run our.
                </div>
              </div>


              <div className="flex items-start gap-x-3 self-stretch mt-3">
                <Button
                  variant="primary"
                  className="w-full bg-none border-2 border-gray-50 hover:after:border-gray-800"
                  size="sm"
                  onClick={hideModal}
                >
                  Close
                </Button>
                <Button
                  disabled={false}
                  className="w-full"
                  variant="primary"
                  size="sm"
                  onClick={() => handleTokenAirdrop("USDC")}
                >
                  Claim Tesnet USDC
                </Button>
              </div>
            </div>
            )
          }
        </div>
      )}
    </BaseModal>
  );
}
