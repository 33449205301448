import { useContext, useMemo } from "react";
import { getRankMeta } from "../utils/config/ranks";
import { PlayerContext } from "../contexts/PlayerContext";
import Player from "../sdk/playerAccount";

export const useRankMeta = (currentPlayer?: Player) => {
  const { playerAccount } = useContext(PlayerContext);
  const player = currentPlayer ?? playerAccount;
  const ranksBenefitsObj = undefined

  const sortedPlatformRanks = Object.values((ranksBenefitsObj || {}))
    .sort((a, b) => a.threshold - b.threshold);

  const rank = useMemo(() => {
    if (player?.xpAllTime != null) {
      return {
        rank: player.rank,
        xpAllTime: player.xpAllTime,
      };
    }

    return {
      rank: 0,
      xpAllTime: 0,
    };
  }, [player]);

  const rankMeta = undefined

  return {
    rankMeta: rankMeta,
    rank: rank,
    platformRanks: sortedPlatformRanks,
  };
};
