import { Card } from "../commonComponents";

const ZeebitQuestsTab = ({}) => {
  const coverList = [
    "/static/game-cards/cointoss_ComingSoon.svg",
    "/static/game-cards/crash_ComingSoon.svg",
    "/static/game-cards/keno_ComingSoon.svg",
    "/static/game-cards/limbo_ComingSoon.svg",
    "/static/game-cards/mines_ComingSoon.svg",
    "/static/game-cards/zeebroSlots_ComingSoon.svg",
    "/static/game-cards/plinko_ComingSoon.svg",
  ];
  return (
    <Card
      title={
        <div className="text-xl font-bold w-full text-center">
          Earn Zeebit Points for every game you play
        </div>
      }
    >
      <div className="flex mx-auto relative">
        {coverList.map((url, idx) => (
          <img
            src={url}
            className="relative [&:not(:first-child)]:-ms-9 w-[120px] rounded-lg shadow-[-8px_0_12px_0_rgba(56,57,88,0.8)]"
          />
        ))}
      </div>
    </Card>
  );
};

export default ZeebitQuestsTab;
