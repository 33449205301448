import React, { FC, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useTimer } from "react-timer-hook";

import { ModalProps } from "./types";
import { BaseModal, Button } from "../common";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import { CheckBox, CheckboxSize } from "../common/check-box/check-box";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const ZEEBIT_SHOULD_HIDE_SESSION_EXPIRING_MODAL =
  "zeebit-should-hide-session-expiring-modal";

interface ISessionExpiringModalProps extends ModalProps {
  expirationDate?: Date
}

export const SessionExpiringModal: FC<ISessionExpiringModalProps> = ({
  visible,
  hideModal,
  expirationDate,
}) => {
  const [
    shouldHideSessionExpiringModal, setShouldHideSessionExpiringModal
  ] = useLocalStorage(ZEEBIT_SHOULD_HIDE_SESSION_EXPIRING_MODAL, false);

  const sessionExpirationTimer = useTimer({
    expiryTimestamp: new Date(expirationDate || ""),
    onExpire: hideModal,
  });

  return (
    <BaseModal
      open={visible}
      onClose={hideModal}
      classes={{
        dialog: `w-[342px] sm:max-w-[420px] bg-gray-800 p-5`
      }}
      withHeading={false}
    >
      <div
        data-id="session-expiring-modal"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col items-center font-normal gap-y-1">
            <div className="flex font-semibold text-xl">USDC Session Expiring</div>
            <div className="flex text-center text-gray-200">
              USDC play session will expire in {sessionExpirationTimer.seconds} seconds. Please sign again to continue playing.
            </div>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <div
              className={twMerge(
                  "flex items-start self-stretch text-gray-200",
              )}
            >
              <label className="flex gap-x-2">
                <div className="self-center">
                  <CheckBox
                    size={CheckboxSize.sm}
                    checked={shouldHideSessionExpiringModal}
                    setChecked={setShouldHideSessionExpiringModal}
                  />
                </div>

                <div className="flex font-normal text-sm items-center">
                  Don't show again
                </div>
              </label>
            </div>
            <div className="flex flex-col gap-y-3">
              <Button
                variant="primary"
                className="w-full h-10"
                size="sm"
                onClick={hideModal}
              >
                Keep playing
              </Button>
              <Button
                variant="gray"
                className="w-full h-10"
                size="sm"
                onClick={hideModal}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};