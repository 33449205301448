import { twMerge } from "tailwind-merge";

export enum BannerType {
  WARNING = "warning",
  INFO = "info",
}

interface IBanner {
  children: any;
  className?: string;
  type?: BannerType;
  onClick?: Function
}

export const Banner = ({ type = BannerType.WARNING, className, children, onClick }: IBanner) => {
  return (
    <div
      className={twMerge(
        `flex p-3 w-full justify-center items-center gap-x-1.5 ${
          type == BannerType.WARNING ? "bg-gray-600" : "bg-brand-purple-gradient-1"
        }`,
        className,
      )}
      {
        ...(onClick ? { role:"button", onClick } : {})
      }
    >
      {children}
    </div>
  );
};
