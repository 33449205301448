import { useContext, useEffect, useRef, useState } from "react";
import { Button, IconFont } from "../../../common";
import { TStage } from "./RewardsModal";
import { ToasterContext, WrappedWalletContext } from "../../../../contexts";
import { SonicContext } from "../../../../contexts/SonicContext";
import {
  claimMilestoneRewards,
  getMilestoneDailyInfo,
} from "../../../../data/sonic/rewards";
import { useQuery, useMutation } from "@tanstack/react-query";
import { BoxToast } from "./BoxToast";
import { BASE_TOAST_CONFIG } from "../../../toast/BaseToast";
import { Card, FoldingCard, LoadingOverlay } from "../commonComponents";
import gsap from "gsap";

const MilestonesTab = ({ }) => {
  const { walletPubkey } = useContext(WrappedWalletContext);
  const {
    token,
    networkId,
    showSonicNotification,
    setShowSonicNotification,
    refreshInfo,
  } = useContext(SonicContext);
  const toast = useContext(ToasterContext);

  const [txsAmount, setTxsAmount] = useState(0);
  const [isRewardClaiming, setIsRewardClaiming] = useState(false);
  const defaultState = {
    claimed: false,
    rewards: 0,
    quantity: 0,
    key: 1,
  };
  const [stage1Info, setStage1Info] = useState(defaultState);
  const [stage2Info, setStage2Info] = useState(defaultState);
  const [stage3Info, setStage3Info] = useState(defaultState);

  const [claimStage, setClaimStage] = useState<TStage | null>(null);

  const {
    data: dataMilestoneDailyInfo,
    isLoading: loadingMilestoneDailyInfo,
    refetch: refetchMilestoneDailyInfo,
  } = useQuery({
    queryKey: ["queryMilestoneDailyInfo", walletPubkey],
    queryFn: () => getMilestoneDailyInfo({ token, networkId }),
    enabled: !!token,
  });

  const mutationClaimRewards = useMutation({
    mutationFn: () => {
      setIsRewardClaiming(true);

      return claimMilestoneRewards({ token, stage: claimStage?.key, networkId })
    },
    onSuccess: async ({ data, status }) => {
      if (data.claimed) {
        // const currentStageKey = Object.keys(stageList)[claimStage - 1];
        // setStageList({
        //   ...stageList,
        //   ...{
        //     [currentStageKey]: {
        //       ...stageList[currentStageKey],
        //       ...{ claimed: true },
        //     },
        //   },
        // });
        let rewards = claimStage?.rewards;
        // if (
        //   isInWalletCampaignTime(networkId) &&
        //   hasExtraWalletBonus(wallet, networkId)
        // ) {
        //   rewards++;
        // }

        toast(
          <BoxToast
            amount={rewards}
            message='"TX Milestone" task completed.'
          />,
          BASE_TOAST_CONFIG
        );
        await refreshInfo();
        setClaimStage(null);
        // toast({
        //   title: '"TX Milestone" task completed.',
        //   description: (
        //     <p className="block">
        //       You completed {stageList[currentStageKey].quantity} transactions
        //       milestone today and received{" "}
        //       <span className="inline-flex items-center text-[#FBB042]">
        //         {rewards} x mystery boxes
        //         <Gift color="#FBB042" className="w-3 h-3 mx-[2px]" />
        //       </span>
        //       . Open it in the navbar to exchange for rings.
        //     </p>
        //   ),
        // });
      }
    },
    onSettled: () => setIsRewardClaiming(false)
  });
  const unableToClaim = (stageInfo: TStage) =>
    stageInfo.claimed && txsAmount < stageInfo.quantity;

  const handleClaimGifts = (stageInfo: TStage) => {
    if (unableToClaim(stageInfo)) {
      return;
    }
    setClaimStage(stageInfo);
    mutationClaimRewards.mutate();
    // trackClick({ text: "TX Milestone" });
  };

  useEffect(() => {
    if (showSonicNotification) {
      setShowSonicNotification(false);
    }
  }, [showSonicNotification]);

  useEffect(() => {
    if (!!token) {
      refetchMilestoneDailyInfo();
    }
  }, []);

  useEffect(() => {
    if (dataMilestoneDailyInfo?.data) {
      const {
        total_transactions,
        stage_info: { stage_1, stage_2, stage_3 },
      } = dataMilestoneDailyInfo.data;
      setTxsAmount(total_transactions);
      setStage1Info({ ...stage_1, key: 1 });
      setStage2Info({ ...stage_2, key: 2 });
      setStage3Info({ ...stage_3, key: 3 });
    }
  }, [dataMilestoneDailyInfo]);

  return (
    <>
      <Card
        title={
          <div className="flex justify-between items-center w-full">
            <div className="text-xl font-bold">
              You have made <span className="text-[#FC78FF]">{txsAmount}</span>{" "}
              transactions today.
            </div>
            <CountdownToUtcMidnight />
          </div>
        }
      >
        <>
          <LoadingOverlay isLoading={loadingMilestoneDailyInfo} />
          <MilestonesLine
            txs={txsAmount}
            stage1Info={stage1Info}
            stage2Info={stage2Info}
            stage3Info={stage3Info}
            handleClaimGifts={handleClaimGifts}
            isRewardClaiming={isRewardClaiming}
          />
        </>
      </Card>
      <FoldingCard
        title="Rules"
        content={
          <div className=" font-normal">
            <ul className="list-disc flex flex-col gap-3 ms-3">
              <li>
                Any task and on-chain interaction will generate a corresponding
                transaction record, abbreviated as tx.
              </li>
              <li>
                Accumulate the corresponding number of tx to earn rewards.
              </li>
              <li>
                Claim rewards manually by clicking the button below. Unclaimed
                rewards will be forfeited at the end of the day(UTC Time).
              </li>
              <li>
                <div className="flex flex-col gap-2">
                  Rewards Detail:
                  <ol className="flex flex-col gap-2">
                    <li>
                      a. Complete 10 transactions to earn{" "}
                      <span className="text-amber-400">
                        2x Ring Mystery Boxes.
                      </span>
                    </li>
                    <li>
                      b. Complete 50 transactions to earn{" "}
                      <span className="text-amber-400">
                        4x Ring Mystery Boxes.
                      </span>
                    </li>
                    <li>
                      c. Complete 100 transactions to earn{" "}
                      <span className="text-amber-400">
                        6x Ring Mystery Boxes.
                      </span>
                    </li>
                  </ol>
                </div>
              </li>
            </ul>
          </div>
        }
      />
    </>
  );
};

const MilestonesLine = ({
  txs,
  stage1Info,
  stage2Info,
  stage3Info,
  handleClaimGifts,
  isRewardClaiming
}: {
  txs: number;
  stage1Info: TStage;
  stage2Info: TStage;
  stage3Info: TStage;
  handleClaimGifts: (stageInfo: TStage) => void;
  isRewardClaiming: boolean
}) => {
  const lineRef = useRef<HTMLInputElement | null>(null);
  const stage1Ref = useRef<HTMLInputElement | null>(null);
  const stage2Ref = useRef<HTMLInputElement | null>(null);
  const stage3Ref = useRef<HTMLInputElement | null>(null);

  const startValue = 0;
  const maxValue = 110;
  const maxWidth = 645;
  const minWidth = 15;

  const [isStage1, setState1] = useState(false);
  const [isStage2, setState2] = useState(false);
  const [isStage3, setState3] = useState(false);

  const addEffect = (target) => {
    gsap.timeline().to(target, {
      transformOrigin: "50%",
      scale: 1,
      yoyo: true,
      ease: "easy.out",
      duration: 0.5,
    });
  };

  useEffect(() => {
    if (!stage1Info.quantity || !stage2Info.quantity || !stage3Info.quantity)
      return;

    const stage1x = (maxWidth / maxValue) * stage1Info.quantity;
    const stage2x = (maxWidth / maxValue) * stage2Info.quantity;
    const stage3x = (maxWidth / maxValue) * stage3Info.quantity;

    const startWidth = (maxWidth / maxValue) * startValue + minWidth;
    const lineWidth = (maxWidth / maxValue) * txs + minWidth;
    const endWidth = lineWidth > maxWidth ? maxWidth : lineWidth;

    const relativeDuration =
      Math.floor(((endWidth - startWidth) / maxWidth) * 30) / 10;

    const duration = endWidth === 0 ? 0 : relativeDuration;
    const ease =
      endWidth + maxWidth / 10 > maxWidth ? "easy.out" : "elastic.out(0.2,0.1)";

    const tl =
      txs > 0
        ? gsap.timeline().fromTo(
          lineRef.current,
          {
            attr: { x2: startWidth },
          },
          {
            attr: { x2: endWidth },
            ease: ease,
            duration: duration,
            onUpdate: () => {
              let anim = gsap.getProperty(lineRef.current, "x2");
              const width = anim?.animVal?.value;

              if (width >= stage1x && txs >= stage1Info.quantity) {
                addEffect(stage1Ref.current);
                setState1(true);
              }
              if (width >= stage2x && txs >= stage2Info.quantity) {
                addEffect(stage2Ref.current);
                setState2(true);
              }
              if (width >= stage3x && txs >= stage3Info.quantity) {
                addEffect(stage3Ref.current);
                setState3(true);
              }
            },
          }
        )
        : null;
    return () => {
      tl?.clear();
    };
  }, [txs, stage1Info, stage2Info, stage3Info]);
  stage2Info;
  const button = (
    stageRewarded: boolean,
    isStage: boolean,
    label: string,
    action: () => void,
    isLoading: boolean
  ) => {
    return (
      <Button
        onClick={() => action()}
        variant={stageRewarded ? "secondary" : !isStage ? "gray" : "primary"}
        disabled={!isStage || stageRewarded}
        classes={{ wrapper: !stageRewarded && !isStage ? "bg-gray-700" : "" }}
        isLoading={isLoading}
      >
        <div className="flex gap-2 items-baseline">
          <span>{stageRewarded ? "Claimed" : label}</span>
          <IconFont name="gift" className="hidden md:flex" />
        </div>
      </Button>
    );
  };
  const label = {
    fill: "var(--gray-50)",
    fontSize: "20px",
    fontWeight: "600",
  };
  return (
    <div className="w-full flex flex-col gap-5">
      <svg
        className="-mx-[15px]"
        width="calc(100% + 30px)"
        height="60"
        viewBox="0 0 665 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 30C12 26.6863 14.6863 24 18 24H646C649.314 24 652 26.6863 652 30C652 33.3137 649.314 36 646 36H18C14.6863 36 12 33.3137 12 30Z"
          fill="#1B182B"
        />
        <g filter="url(#filter0_d_16703_16724)">
          {/* <path
            d="M12 30C12 26.6863 14.6863 24 18 24H472C475.314 24 478 26.6863 478 30C478 33.3137 475.314 36 472 36H18C14.6863 36 12 33.3137 12 30Z"
            fill="url(#paint0_linear_16703_16724)"
          /> */}
          {txs > 0 ? (
            <line
              ref={lineRef}
              x1={minWidth}
              y1="30"
              x2={minWidth}
              y2="30"
              strokeWidth="13"
              stroke="url(#paint0_linear_16703_16724)"
              stroke-linecap="round"
            />
          ) : null}
        </g>
        <g ref={stage1Ref} scale={0.8}>
          <circle
            cx="83"
            cy="30"
            r="30"
            fill={isStage1 ? "#7830D4" : "#22213B"}
          />
          <circle
            cx="83"
            cy="30"
            r="22"
            fill={isStage1 ? "#FC78FF" : "#383958"}
          />
          <text x="83" y="37" style={label} textAnchor="middle">
            {stage1Info.quantity}
          </text>
        </g>
        <g ref={stage2Ref} scale={0.8}>
          <circle
            cx="333"
            cy="30"
            r="30"
            fill={isStage2 ? "#7830D4" : "#22213B"}
          />
          <circle
            cx="333"
            cy="30"
            r="22"
            fill={isStage2 ? "#FC78FF" : "#383958"}
          />
          <text x="333" y="37" style={label} textAnchor="middle">
            {stage2Info.quantity}
          </text>
        </g>
        <g ref={stage3Ref} scale="0.8">
          <circle
            cx="583"
            cy="30"
            r="30"
            fill={isStage3 ? "#7830D4" : "#22213B"}
          />
          <circle
            cx="583"
            cy="30"
            r="22"
            fill={isStage3 ? "#FC78FF" : "#383958"}
          />
          <text x="583" y="37" style={label} textAnchor="middle">
            {stage3Info.quantity}
          </text>
        </g>
        <defs>
          <filter
            id="filter0_d_16703_16724"
            x="0"
            y="12"
            width="665"
            height="36"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.988235 0 0 0 0 0.470588 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_16703_16724"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_16703_16724"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_16703_16724"
            x1="12"
            y1="24"
            x2="665"
            y2="60"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#A550FF" />
            <stop offset="1" stop-color="#FC78FF" />
          </linearGradient>
        </defs>
      </svg>
      <div className="flex justify-between gap-5">
        {button(
          stage1Info.claimed,
          isStage1,
          `Claim x ${stage1Info.rewards}`,
          () => handleClaimGifts(stage1Info),
          isRewardClaiming
        )}
        {button(
          stage2Info.claimed,
          isStage2,
          `Claim x ${stage2Info.rewards}`,
          () => handleClaimGifts(stage2Info),
          isRewardClaiming
        )}
        {button(
          stage3Info.claimed,
          isStage3,
          `Claim x ${stage3Info.rewards}`,
          () => handleClaimGifts(stage3Info),
          isRewardClaiming
        )}
      </div>
    </div>
  );
};

const CountdownToUtcMidnight: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<string>("00:00:00");

  // Function to calculate the time left until the next UTC midnight
  const calculateTimeLeft = (): string => {
    const now = new Date();

    // Calculate the next UTC midnight
    const nextUtcMidnight = new Date(Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate() + 1,
      0, 0, 0
    ));

    // Calculate the difference in milliseconds
    const timeDifference = nextUtcMidnight.getTime() - now.getTime();

    // Calculate hours, minutes, and seconds remaining
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDifference / 1000) % 60);

    // Return the formatted string in hh:mm:ss
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  // Update the countdown every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-[#979AB6]">{`Daily Reset in: ${timeLeft}`}</div>
  );
};

export default MilestonesTab;
