import { web3 } from "@coral-xyz/anchor";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

import {
  GameStatus,
  GameTokenStatus,
  HouseStatus,
  TokenStatus,
} from "./enums";
import { APP_NETWORK, ENV_NAME } from "../utils/env/env";
import { defaultNetwork } from "../utils/chain/network";
import { IS_MAINNET } from "../utils/solana/rpc";
import { APP_NETWORK_TYPE } from "../types/chain";
import { getPlatformTokens } from "../utils/config/utils";

export const APPROXIMATE_MS_PER_SLOT = 400;

// DEVNET PROGRAMS
export const RANDOM_PROGRAM_PUBKEY_DEVNET = new PublicKey(
  "CxukdqqPpsxeXECDVGSK43YZ7RSeDDreHW4ZMwXKNHXv",
);

// MAINNET PROGRAMS
export const RANDOM_PROGRAM_PUBKEY_MAINNET = new PublicKey(
  "ZEEX2PvFMBN1bZX6RoqFdexTCjr4FiHGqtjMHBEx1E7",
);

// PROGRAMS
export const RANDOM_PROGRAM_PUBKEY =
  ENV_NAME == "MAINNET" ? RANDOM_PROGRAM_PUBKEY_MAINNET : RANDOM_PROGRAM_PUBKEY_DEVNET;

export const HERMES_ENDPOINT = "https://hermes.pyth.network";

// HOUSE
const HOUSE_PUBKEY_DEVNET_SOLANA = new PublicKey("Gn2xY7WwaJXQ15WFmCKxwg8WsqXkExEXM5w9vHF2vNjp");
const HOUSE_PUBKEY_MAINNET_SOLANA = new PublicKey("Gn2xY7WwaJXQ15WFmCKxwg8WsqXkExEXM5w9vHF2vNjp");

// SONIC CONSTANTS
const HOUSE_PUBKEY_TESTNET_SONIC = new PublicKey("Gn2xY7WwaJXQ15WFmCKxwg8WsqXkExEXM5w9vHF2vNjp");
const HOUSE_PUBKEY_MAINNET_SONIC = new PublicKey("Gn2xY7WwaJXQ15WFmCKxwg8WsqXkExEXM5w9vHF2vNjp");

export const getHousePubkey = (chain: APP_NETWORK_TYPE) => {
  switch (chain) {
    case APP_NETWORK_TYPE.SONIC:
      return ENV_NAME == "MAINNET" ? HOUSE_PUBKEY_MAINNET_SONIC : HOUSE_PUBKEY_TESTNET_SONIC
    case APP_NETWORK_TYPE.SOLANA:
      return ENV_NAME == "MAINNET" ? HOUSE_PUBKEY_MAINNET_SOLANA : HOUSE_PUBKEY_DEVNET_SOLANA
    case APP_NETWORK_TYPE.MB_AND_SOLANA:
      return ENV_NAME == "MAINNET" ? HOUSE_PUBKEY_MAINNET_SOLANA : HOUSE_PUBKEY_DEVNET_SOLANA
    default:
      throw new Error(`Not a known chain config ${chain}`)
  }
}

// USDC MINT
const TOKEN_MINT_PUBKEY_DEVNET_SOLANA = new PublicKey(
  "4iyHGPGrmz9L3QhMkef4xJWBLMaQztceDa4KkyCD4wFU",
);
const TOKEN_MINT_PUBKEY_MAINNET_SOLANA = new PublicKey(
  "4iyHGPGrmz9L3QhMkef4xJWBLMaQztceDa4KkyCD4wFU",
);
const TOKEN_MINT_PUBKEY_TESTNET_SONIC = new PublicKey(
  "5cNgerbfdDF2oDyygHdZKkpEJs5TZHfsWqzYRxTtiuij",
);
const TOKEN_MINT_PUBKEY_MAINNET_SONIC = new PublicKey(
  "5cNgerbfdDF2oDyygHdZKkpEJs5TZHfsWqzYRxTtiuij",
);

export const getTokenMint = (chain: APP_NETWORK_TYPE) => {
  const tokensForEnv = getPlatformTokens(defaultNetwork, chain)

  const token = tokensForEnv.find((token) => {
    return token.isBase == true
  })

  return token?.pubkey
}

const TOKEN_MINT_PUBKEY_DEVNET = APP_NETWORK == APP_NETWORK_TYPE.SONIC ? TOKEN_MINT_PUBKEY_TESTNET_SONIC : TOKEN_MINT_PUBKEY_DEVNET_SOLANA
const TOKEN_MINT_PUBKEY_MAINNET = APP_NETWORK == APP_NETWORK_TYPE.SONIC ? TOKEN_MINT_PUBKEY_MAINNET_SONIC : TOKEN_MINT_PUBKEY_MAINNET_SOLANA

export const TOKEN_MINT_PUBKEY =
  ENV_NAME == "MAINNET" ? TOKEN_MINT_PUBKEY_MAINNET : TOKEN_MINT_PUBKEY_DEVNET;

export const UNIX_DAY_IN_SECONDS = 86_400;

export const DEFAULT_CONFIRM_OPTIONS = {
  skipPreflight: true,
  commitment: "processed",
  preflightCommitment: "processed",
  // maxRetries: 10,
  // minContextSlot:
} as web3.ConfirmOptions;

export const HOUSE_STATUS_TAKING_BETS: HouseStatus[] = [
  HouseStatus.Active,
  HouseStatus.OutFlowsSuspended,
];
export const HOUSE_TOKEN_STATUS_TAKING_BETS: TokenStatus[] = [
  TokenStatus.Active,
  TokenStatus.OutFlowsSuspended,
];
export const GAME_STATUS_TAKING_BETS: GameStatus[] = [GameStatus.Active];
export const TOKEN_STATUS_TAKING_BETS: GameTokenStatus[] = [GameTokenStatus.Active];
// --- ADDITIONAL FROM POC ---

export const RPC_ENDPOINT_URL_MAGICBLOCK = "https://zeebit.magicblock.app";
export const RPC_ENDPOINT_WS_MAGICBLOCK = "wss://zeebit.magicblock.app:8900";

// DEVNET PROGRAMS
export const ZEEBIT_V2_PROGRAM_PUBKEY_DEVNET = new PublicKey('xwgn8tjsF7XMeooCKu5pUK63F4Ntqk2ihrUSEEjbk79');
export const ZEEBIT_V2_PROGRAM_PUBKEY_MAINNET = new PublicKey('xwgn8tjsF7XMeooCKu5pUK63F4Ntqk2ihrUSEEjbk79');

export const ZEEBIT_V2_PROGRAM = IS_MAINNET ? ZEEBIT_V2_PROGRAM_PUBKEY_MAINNET : ZEEBIT_V2_PROGRAM_PUBKEY_DEVNET;


export const NEXT_PUBLIC_DOMAIN = "https://odyssey.sonic.game";
export const NEXT_PUBLIC_API_DOMAIN = "https://odyssey-api-beta.sonic.game";

export const LAMPORTS_FOR_INSTANCE_ACC = 0.004 * LAMPORTS_PER_SOL;
export const LAMPORTS_FOR_PLAYER_TOKEN_ACC = 0.00215064 * LAMPORTS_PER_SOL;
export const MIN_LAMPORTS_AUTO_SIGNER = 15_000;
export const LAMPORT_TOPUP_AUTO_SIGNER = 100_000;