import { PublicKey } from "@solana/web3.js";
import GameSpec from "./gameSpec";
import * as anchor from '@coral-xyz/anchor'
import { ZeebitV2 } from './zeebitV2'
import PlayerToken from "./playerToken";

export default class GameInstanceSolo {

    private _instancePubkey: PublicKey;
    private _gameSpec: GameSpec;
    private _playerToken: PlayerToken;
    private _erState: anchor.IdlAccounts<ZeebitV2>["instanceSolo"] | undefined;
    private _baseState: anchor.IdlAccounts<ZeebitV2>["instanceSolo"] | undefined;

    constructor(
        instanceSoloPubkey: PublicKey,
        gameSpec: GameSpec,
        playerToken: PlayerToken,
        baseState?: any,
        erState?: any
    ) {
        this._gameSpec = gameSpec;
        this._instancePubkey = instanceSoloPubkey;
        this._playerToken = playerToken;
        this._baseState = baseState;
        this._erState = erState;
    }

    async loadState() {
        const baseState = await this._gameSpec.loadInstanceState(this._instancePubkey);
        const erState = await this._gameSpec.loadErInstanceState(this._instancePubkey);

        this._baseState = baseState
        this._erState = erState
    }

    static async load(instancePubkey: PublicKey, gameSpec: GameSpec, playerToken: PlayerToken) {
        const instanceSolo = new GameInstanceSolo(instancePubkey, gameSpec, playerToken)
        await instanceSolo.loadState();

        return instanceSolo;
    }

    get state() {
        return this.isDelegated ? this.erState: this.baseState
    }

    get baseState() {
        return this._baseState;
    }

    get erState() {
        return this._erState;
    }

    get isDelegated() {
        return this.gameSpec.isDelegated
    }

    get instancePubkey() {
        return this._instancePubkey;
    }

    get gameSpec() {
        return this._gameSpec;
    }

    get playerToken() {
        return this._playerToken;
    }

    get hasState() {
        return this.baseState != null
    }

    get identifier() {
        return this.baseState?.identifier;
      }
}