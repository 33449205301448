import { createContext, useMemo, useEffect } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const ZEEBIT_APP_VOLUME = "zeebit-app-volume";
export const ZEEBIT_MUSIC_VOLUME = "zeebit-music-volume";
export interface IAudioContext {
  volume: string;
  setVolume: React.Dispatch<React.SetStateAction<string>>;
  musicVolume: string;
  setMusicVolume: React.Dispatch<React.SetStateAction<string>>;
}

export const AudioContext = createContext<IAudioContext>({} as IAudioContext);

interface Props {
  children: React.ReactNode;
}

export const AudioProvider = ({ children }: Props) => {
  const [volume, setVolume] = useLocalStorage(ZEEBIT_APP_VOLUME, "0.25");
  const [musicVolume, setMusicVolume] = useLocalStorage(
    ZEEBIT_MUSIC_VOLUME,
    "0.1"
  );

  useEffect(() => {
    if (typeof volume !== "number") {
      setVolume("0.25");
    }
    if (typeof musicVolume !== "number") {
      setMusicVolume("0.1");
    }
  }, []);

  return (
    <AudioContext.Provider
      value={useMemo(
        () => ({
          volume,
          setVolume,
          musicVolume,
          setMusicVolume,
        }),
        [volume, setVolume, musicVolume, setMusicVolume]
      )}
    >
      {children}
    </AudioContext.Provider>
  );
};
