import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";
import IconFont from "../../components/common/iconFont/IconFont";
import { twMerge } from "tailwind-merge";

interface Props {
  open: boolean;
  hideModal: (value: boolean) => void;
  children: any;
  classes?: {
    parent?: string;
    dialog?: string;
  };
  withX?: boolean;
}

export function BaseModal({
  open,
  hideModal,
  withX = false,
  children,
  classes = {
    dialog: "px-4 pb-4 pt-5 sm:p-6 bg-gray-800",
    parent: "p-4 sm:p-0",
  },
}: Props) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={hideModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-950 opacity-60 transition-opacity" />
        </Transition.Child>

        <div className={`fixed inset-0 z-50 w-screen overflow-y-auto`}>
          <div
            className={`flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ${
              classes?.parent != null ? classes.parent : ""
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  `relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8`,
                  classes?.dialog,
                )}
              >
                {withX ? (
                  <div className="flex max-h-[var(--modal-header-height)] items-center justify-end">
                    <div className="close-button text-gray-50" role="button" onClick={hideModal}>
                      <IconFont size="lg" name="close_md" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
