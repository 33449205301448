import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useModal } from "react-modal-hook";
import { twMerge } from "tailwind-merge";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { ModalProps } from "./types";
import { BaseModal, Button, IconFont } from "../common";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import {
  formatZeebitNumber,
  NumberType,
} from "../../utils/currency/formatting";
import { AutoSigningAgreementModal, IFundOrWithdrawModalTabs } from "./AddFundsAndPlayModal";
import {
  AggregatedBalancesContext,
  IMergedToken,
} from "../../contexts/AggregatedBalancesContext";
import { AirdropModal } from "../../modals/AirdropModal";
import { useTokenAirdrop } from "../../hooks/useTokenAirdrop";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import RewardsModal, {RewardsModalTabs} from "../sonic/sonic-rewards/rewards-modal/RewardsModal";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { AutoSigningModal } from "../../modals/auto-sign/AutoSigningModal";
import { TransferToAutoSignBalanceModal } from "./TransferToAutoSignBalanceModal";
import { WrappedWalletContext, BalanceContext } from "../../contexts";
import { AutoSigningNeedsMoreSolModal } from "../../modals/auto-sign/AutoSigningNeedsMoreSolModal";

interface ISelectTokenModalProps extends ModalProps {
  showFundsAndPlayModal: Function;
}

export const ZEEBIT_SHOULD_HIDE_AUTO_SIGNING_MODAL =
  "zeebit-should-hide-auto-signing-modal";

export const SelectTokenModal: FC<ISelectTokenModalProps> = ({
  visible,
  hideModal,
  showFundsAndPlayModal,
}) => {
  const { setSelectedTokenMeta, selectedTokenMeta } =
    useContext(BalanceContext);
  const {
    allowsAutoSigning , setAllowsAutoSigning, withdrawSol, lamportBalance
  } = useContext(SessionAuthorityContext);
  const [isAutoSignSolWithdrawing, setIsAutoSignSolWithdrawing] = useState(false)
  const { isWeb3AuthWallet } = useContext(WrappedWalletContext);

  const [shouldHideAutoSigningModal, setShouldHideAutoSigningModal] =
    useLocalStorage(ZEEBIT_SHOULD_HIDE_AUTO_SIGNING_MODAL, false);

  const [showAutoSigningModal, hideAutoSigningModal] = useModal(
    ({ in: open }) => (
      <AutoSigningModal
        visible={open}
        hideModal={hideAutoSigningModal}
        shouldHideAutoSigningModal={shouldHideAutoSigningModal}
        setShouldHideAutoSigningModal={setShouldHideAutoSigningModal}
        showFundsAndPlayModal={showFundsAndPlayModal}
      />
    ),
    [setShouldHideAutoSigningModal, shouldHideAutoSigningModal, showFundsAndPlayModal]
  );

  const [showSonicRewardsModal, hideSonicRewardsModal] = useModal(
    ({ in: open }): JSX.Element => (
      <RewardsModal
        tab={RewardsModalTabs.CheckIn}
        visible={open}
        hideModal={hideSonicRewardsModal}
      />
    ),
    []
  );

  const [showTransferToAutoSignBalanceModal, hideTransferToAutoSignBalanceModal] = useModal(
    ({ in: open }) => (
      <TransferToAutoSignBalanceModal
        visible={open}
        hideModal={() => {
          hideTransferToAutoSignBalanceModal();
        }}
      />
    ),
    []
  );

  const [showAutoSigningNeedsMoreSolModal, hideAutoSigningNeedsMoreSolModal] = useModal(
    ({ in: open }) => (
      <AutoSigningNeedsMoreSolModal
        visible={open}
        hideModal={() => {
          hideAutoSigningNeedsMoreSolModal();
        }}
      />
    ),
    []
  );

  // ENSURE ONLY SHOWN WHEN NOT WEB3 AUTH WALLET
  const [showAutoSigningAgreementModal, hideAutoSigningAgreementModal] =
    useModal(
      ({ in: open }) => (
        <AutoSigningAgreementModal
          visible={open}
          hideModal={hideAutoSigningAgreementModal}
          onAutoSigningApprove={() => setAllowsAutoSigning(true)}
          onModalsClose={hideModal}
        />
      ),
      [setAllowsAutoSigning]
    );

  // MERGED TOKENS FOR THE LIST
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const tokens = useMemo(() => {
    return mergedTokens?.map((token) => {
      const playBalanceBasis = token.playerToken?.baseState?.availableBalance
        ? Number(token.playerToken?.baseState?.availableBalance)
        : 0;
      const playBalanceUi =
        playBalanceBasis / Math.pow(10, token.context?.decimals || 6);
      const status = token.playerToken != null ? "active" : "ready";

      return {
        ...token,
        icon: token.context?.imageDarkSvg,
        name: token.context?.name,
        walletBalance: token.wallet?.uiAmount,
        playBalance: playBalanceUi,
        status: status,
      };
    });
  }, [mergedTokens]);

  const [selectedToken, setSelectedToken] = useState<IMergedToken | undefined>();

  useEffect(() => {
    if (selectedTokenMeta != null && selectedTokenMeta.mint != selectedToken?.context?.pubkey) {
      const updatedSelectedToken = tokens?.find(
        (token) => token?.context?.pubkey === selectedTokenMeta?.mint
      );

      setSelectedToken(updatedSelectedToken)
    }
  }, [selectedTokenMeta])

  const withdrawDisabled = useMemo(() => {
    return selectedToken == null || selectedToken.playerToken == null || (selectedToken.playerToken.playBalance || 0) <= 0;
  }, [selectedToken]);

  const depositDisabled = useMemo(() => {
    return selectedToken == null || selectedToken.wallet == null || (selectedToken.wallet.uiAmount || 0) <= 0;
  }, [selectedToken]);

  const [showAirdropModal, hideWalletModal] = useModal(
    ({ in: open }) => (
      <AirdropModal visible={open} hideModal={hideWalletModal} />
    ),
    []
  );

  const { handleAirdrop } = useTokenAirdrop();
  const [
    airdroppingToken, setAirdroppingToken
  ] = useState<string | null>(null);

  return (
    <BaseModal
      title="Balances"
      open={visible}
      onClose={() => {
        hideModal();
        hideAutoSigningAgreementModal();
      }}
      classes={{
        dialog: `w-[342px] sm:max-w-[580px] bg-gray-700 p-5`,
      }}
    >
      <div
        data-id="select-token-modal"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col items-center gap-y-2">
            <div
              className={twMerge(
                "flex w-full text-gray-300 font-normal text-sm pl-7.5 pr-5",
                "[&>div]:flex [&>div]:justify-center"
              )}
            >
              <div className="start w-1/3 [&.start]:justify-start">TOKEN</div>
              <div className="w-1/3">WALLET BALANCE</div>
              <div className="w-1/3">PLAY BALANCE</div>
            </div>
            <div
              className={twMerge(
                "flex flex-col overflow-y-auto w-full pr-3 bg-gray-800 rounded-lg p-3 min-h-60",
                SCROLLBAR_CLASSES_BLACK
              )}
            >
              {tokens?.map((item) => (
                <button
                  key={item.context?.pubkey}
                  className={twMerge(
                    "flex w-full rounded-md p-3 [&>div]:flex [&>div]:justify-center",
                    "hover:bg-gray-700 items-center border-2 border-transparent h-[64px]",
                    item.context?.pubkey === selectedToken?.context?.pubkey
                      ? " border-gray-50"
                      : ""
                  )}
                  onClick={() => {
                    setSelectedToken(item);
                    setSelectedTokenMeta({
                      mint: item?.context?.pubkey || "",
                      decimals: item?.context?.decimals || 6,
                    });
                  }}
                >
                  <div className="start w-1/3 [&.start]:justify-start items-center">
                    <img
                      src={item.icon}
                      className="w-[24px] h-[24px] rounded-full mr-1.5 relative -top-0.5"
                      alt="token"
                    />
                    {item.context?.symbol || item.name}
                  </div>
                  <div className="w-1/3">
                    {formatZeebitNumber(
                      item.walletBalance || 0,
                      NumberType.TOKEN_AMOUNT_WITH_PRECISION
                    )}
                  </div>
                  <div className="w-1/3">
                    {item.playBalance ? (
                      formatZeebitNumber(
                        item.playBalance,
                        NumberType.TOKEN_AMOUNT_WITH_PRECISION
                      )
                    ) : (
                      <Button
                        disabled={!selectedToken || !!airdroppingToken}
                        variant="secondary"
                        className="w-full"
                        size="sm"
                        isLoading={item?.context?.symbol === airdroppingToken}
                        onClick={async () => {
                          if (!item.walletBalance) {
                            if (!item.walletBalance && item?.context?.symbol === "SOL") {
                              showAirdropModal();
                              hideModal();
                            } else if (item?.context?.symbol === "RINGS") {
                              showSonicRewardsModal();

                              hideModal();
                            } else {
                              setAirdroppingToken(item?.context?.symbol);

                              await handleAirdrop(
                                mergedTokens?.find(
                                  walletToken => walletToken?.context?.symbol === item?.context?.symbol
                                )
                              );

                              setAirdroppingToken(null);
                            }
                          } else {
                            setSelectedTokenMeta({
                              mint: item?.context?.pubkey || "",
                              decimals: item?.context?.decimals || 6,
                            });
                            showFundsAndPlayModal();
                            hideModal();
                          }
                        }}
                      >
                        {
                          !item.walletBalance
                            ? item?.context?.symbol !== "RINGS" ? `Get ${item?.context?.symbol}` : "Earn RINGS"
                            : "Deposit"
                        }
                      </Button>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <div className="flex w-full gap-x-3">
              <Button
                disabled={depositDisabled || !!airdroppingToken}
                variant="secondary"
                className="w-full"
                size="md"
                onClick={() => {
                  setSelectedTokenMeta({
                    mint: selectedToken?.context?.pubkey || "",
                    decimals: selectedToken?.context?.decimals || 6,
                  });
                  showFundsAndPlayModal(IFundOrWithdrawModalTabs.DEPOSIT);
                  hideModal();
                }}
              >
                <div className="flex">
                  <IconFont name="download" size="lg" />
                  &nbsp; Add {selectedToken?.context?.symbol}
                </div>
              </Button>
              <Button
                variant="secondary"
                className="w-full"
                size="md"
                onClick={() => {
                  setSelectedTokenMeta({
                    mint: selectedToken?.context?.pubkey || "",
                    decimals: selectedToken?.context?.decimals || 6
                  });
                  showFundsAndPlayModal(IFundOrWithdrawModalTabs.WITHDRAW);
                  hideModal();
                }}
                disabled={withdrawDisabled || !!airdroppingToken}
              >
                <div className="flex">
                  <IconFont name="withdraw" size="lg" />
                  &nbsp; Withdraw {selectedToken?.context?.symbol}
                </div>
              </Button>
            </div>
          </div>

          <div className="flex w-full rounded-lg bg-gray-600 py-3 px-4 gap-4 items-center">
            <div className={allowsAutoSigning ? "flex w-1/12" : "flex w-1/5"}>
              <img src={getS3StaticFolderUrl("/static/auto-signing-icon.png")} className="flex w-full"/>
            </div>
            <div className="flex flex-col text-sm gap-1">
              <div className="font-semibold">
                {allowsAutoSigning ? "Auto-Signing Balance" : "Auto-Signing"}
              </div>
              <div className="font-normal text-gray-200">
                {
                  allowsAutoSigning
                    ? `${Number(lamportBalance || 0) / LAMPORTS_PER_SOL} SOL`
                    : "Play without signing each bet transaction. Requires SOL and funds in ‘Play Balance’."
                }
              </div>
            </div>
            {
              !isWeb3AuthWallet
              && (
                <div className="flex items-center">
                  {
                    allowsAutoSigning
                      ? (<div className="flex gap-x-2">
                        <Button
                          disabled={isAutoSignSolWithdrawing}
                          variant="gray"
                          className="w-full bg-gray-500"
                          size="md"
                          isLoading={false}
                          onClick={() => {
                            showTransferToAutoSignBalanceModal();
                            hideModal();
                          }}
                        >
                          <div className="whitespace-nowrap">Top Up</div>
                        </Button>
                        <Button
                          disabled={isAutoSignSolWithdrawing}
                          variant="gray"
                          className="w-full bg-gray-500"
                          size="md"
                          isLoading={isAutoSignSolWithdrawing}
                          onClick={async () => {
                            setIsAutoSignSolWithdrawing(true);

                            await withdrawSol();

                            setIsAutoSignSolWithdrawing(false);
                            setAllowsAutoSigning(false);
                          }}
                        >
                          <div className="whitespace-nowrap">Withdraw</div>
                        </Button>
                        <Button
                          disabled={isAutoSignSolWithdrawing}
                          variant="gray"
                          className="w-full bg-gray-500"
                          size="md"
                          isLoading={false}
                          onClick={() => setAllowsAutoSigning(false)}
                        >
                          <div className="whitespace-nowrap">Turn off</div>
                        </Button>
                      </div>)
                      : (<>
                        <Button
                          disabled={false}
                          variant="gray"
                          className="w-full bg-gray-500"
                          size="md"
                          isLoading={false}
                          onClick={() => {
                            if (!lamportBalance || lamportBalance < 5000) {
                              showAutoSigningNeedsMoreSolModal();
                            } else {
                              showAutoSigningModal();
                            }
                            hideModal();
                          }}
                        >
                          <div className="whitespace-nowrap">Turn on</div>
                        </Button>
                      </>)
                  }
                </div>
              )
            }

          </div>
        </div>
      </div>
    </BaseModal>
  );
};
