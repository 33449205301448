import crypto from "crypto-js";
import { getNetworkUrl } from "./utils";
import { NEXT_PUBLIC_API_DOMAIN } from "../../sdk/constants";

export const fetchBasicInfo = async ({ address, source, networkId }: any) => {
    console.log('fetchBasicInfo', {
        address, source, networkId, url: `${NEXT_PUBLIC_API_DOMAIN}${getNetworkUrl(
            networkId
        )}/auth/sonic/challenge?${new URLSearchParams({
            wallet: address,
            wallet_source: source,
        })}`
    });

    const message = `${address}-${source}`;
    console.log({ message });
    let xSign
    try {
        xSign = crypto.createHash("sha256").update(message).digest("base64");
    } catch (error) {
        console.warn(error);
    }

    console.log({ xSign });

    const response = await fetch(
        `${NEXT_PUBLIC_API_DOMAIN}${getNetworkUrl(
            networkId
        )}/auth/sonic/challenge?${new URLSearchParams({
            wallet: address,
            wallet_source: source,
        })}`,
        {
            headers: {
                "x-sign": xSign,
            },
        }
    );
    console.log('fetchBasicInfo', response);

    return response.json();
};

export const fetchAuthorize = async ({
    address,
    address_encoded,
    signature,
    networkId,
}: any) => {
    const response = await fetch(
        `${NEXT_PUBLIC_API_DOMAIN}${getNetworkUrl(
            networkId
        )}/auth/sonic/authorize`,
        {
            headers: {
                "content-type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                address,
                address_encoded,
                signature,
            }),
        }
    );
    return response.json();
};

export const fetchLogout = async ({ token, networkId }: any) => {
    const response = await fetch(
        `${NEXT_PUBLIC_API_DOMAIN}${getNetworkUrl(
            networkId
        )}/auth/logout`,
        {
            headers: {
                Authorization: token,
            },
            method: "GET",
        }
    );
    return response.json();
};

export const getUserRewardInfo = async ({ token, networkId }: any) => {
    const response = await fetch(
        `${NEXT_PUBLIC_API_DOMAIN}${getNetworkUrl(
            networkId
        )}/user/rewards/info`,
        {
            headers: {
                Authorization: token,
            },
            method: "GET",
        }
    );
    return response.json();
};

export const getNotificationRecords = async ({ token, networkId }: any) => {
    const response = await fetch(
        `${NEXT_PUBLIC_API_DOMAIN}${getNetworkUrl(
            networkId
        )}/user/rewards/history`,
        {
            headers: {
                Authorization: token,
            },
            method: "GET",
        }
    );
    return response.json();
};

export const inviteUser = async ({ token, code, networkId }: any) => {
    const response = await fetch(
        `${NEXT_PUBLIC_API_DOMAIN}${getNetworkUrl(
            networkId
        )}/user/referral`,
        {
            headers: {
                Authorization: token,
                "content-type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                invitation_code: code,
            }),
        }
    );
    return response.json();
};

export const getReferralInfo = async ({ token, networkId }: any) => {
    const response = await fetch(
        `${NEXT_PUBLIC_API_DOMAIN}${getNetworkUrl(
            networkId
        )}/user/referral/info`,
        {
            headers: {
                Authorization: token,
            },
            method: "GET",
        }
    );
    return response.json();
};