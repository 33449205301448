import { useMemo } from "react";
import { IconFont } from "../../../common";
import { BaseToast } from "../../../toast/BaseToast";

export interface IBoxReward {
  amount?: number;
}

export const CoinToast = ({ amount }: IBoxReward) => {
  const meta = useMemo(() => {
    return {
      title: "You've received",
      message: (
        <div className="flex items-baseline gap-x-1 text-amber-400">
          <div className="font-normal">{`x${amount || 0} coins`}</div>
        </div>
      ),
    };
  }, [amount]);
  return (
    <BaseToast
      title={meta.title}
      message={meta.message}
      type={"warning"}
      iconName="gift"
    />
  );
};
