import { useMemo } from "react";
import IconFont, { IconName } from "../common/iconFont/IconFont";
import IconButton from "../common/button/IconButton";
import { ToastOptions, CloseButtonProps, ToastContentProps } from "react-toastify";
import Button from "../common/button/Button";
import {twMerge} from "tailwind-merge";

export const BASE_TOAST_CONFIG: ToastOptions = {
  autoClose: 3000,
  hideProgressBar: true,
  pauseOnHover: false,
  draggable: false,
  bodyClassName: "p-0",
  className: "p-0 bg-inherit",
  style: {},
};

export interface IBaseToast extends ToastContentProps {
  title?: any;
  message: any;
  type: "success" | "error" | "warning" | "regular";
  icon?: React.ReactNode;
  iconName?: IconName;
  closeLabel?: string;
  className?: string;
}

export const BaseToast = ({
  type,
  icon,
  iconName,
  title,
  message,
  closeLabel,
  closeToast,
  className
}: IBaseToast) => {
  const toastIcon = useMemo((): React.ReactNode | undefined => {
    if (!!icon) {
      return icon;
    }
    if (!!iconName) {
      return <IconFont name={iconName} size="xl" />;
    }
    if (type == "success") {
      return <IconFont size="xl" name="correct" />;
    } else if (type == "warning") {
      return <IconFont size="xl" name="alert" />;
    } else if (type == "error") {
      return <IconFont size="xl" name="error" />;
    }
  }, [type, icon]);

  return (
    <div
      className={twMerge(
        "flex w-[340px] p-3 items-start gap-x-3 bg-gray-700 rounded-lg shadow-brand-regular text-white",
        className
      )}
    >
      {toastIcon ? <ToastIcon type={type} icon={toastIcon} /> : ""}

      <div className="flex pr-2 flex-col justify-center items-start flex-1">
        {title && (
          <div className={`${message ? "font-bold text-gray-50" : "font-normal text-gray-200"}`}>
            {title}
          </div>
        )}
        {message && <div className="text-sm font-normal text-gray-200 self-stretch">{message}</div>}
      </div>
      {closeLabel ? (
        <Button size="sm" variant="gray" onClick={closeToast}>
          {closeLabel}
        </Button>
      ) : (
        <IconButton iconName="close_md" className="material-icons" onClick={closeToast} />
      )}
    </div>
  );
};

export interface IToastIcon {
  type: "success" | "error" | "warning" | "regular";
  icon: any;
}

export const ToastIcon = ({ type, icon }: IToastIcon) => {
  const colorClasses = useMemo(() => {
    if (type == "success") {
      return "bg-response-success text-gray-50";
    } else if (type == "error") {
      return "bg-rose-500 text-gray-50";
    } else if (type == "warning") {
      return "bg-response-warning text-gray-50";
    } else {
      return "bg-global-bg text-gray-200";
    }
  }, [type]);

  return (
    <div className={`flex p-2 items-start gap-x-[10px] rounded-md ${colorClasses}`}>{icon}</div>
  );
};
