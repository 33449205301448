import { FC, useContext, useEffect, useState } from "react";
import { ModalProps } from "../../modals/types";
import { BaseModal, Button } from "../../common";
import { useMutation } from "@tanstack/react-query";

import { SonicContext } from "../../../contexts/SonicContext";
import { ToasterContext, WrappedWalletContext } from "../../../contexts";
import { ImageStatic } from "../../common/image/ImageStatic";
import { getMysteryboxTx, openMysterybox } from "../../../data/sonic/rewards";
import { Transaction } from "@solana/web3.js";
import {
  confirmTransaction,
  sendLegacyTransaction,
} from "../../../data/lib/transactions";
import { BoxToast } from "./rewards-modal/BoxToast";
import { BASE_TOAST_CONFIG, BaseToast } from "../../toast/BaseToast";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import sonicBox from "../../../static/sonic-rewards/sonic-box.png";
import { CoinToast } from "./rewards-modal/CoinToast";

let txHash = "";

const OpenBoxModal: FC<ModalProps> = ({ visible, hideModal }) => {
  const { walletPubkey } = useContext(WrappedWalletContext);

  const { connection } = useConnection();
  const { publicKey, wallet, signTransaction } = useWallet();
  const toast = useContext(ToasterContext);

  const { ringAmount, mysteryBoxAmount, token, networkId, refreshInfo } =
    useContext(SonicContext);

  const [openAll, setOpenAll] = useState(false);
  const [isOpeningMysterybox, setIsOpeningMysterybox] = useState(false);

  const handleOpenMysterybox = () => {
    if (isOpeningMysterybox) {
      return;
    }
    setIsOpeningMysterybox(true);
    mutationBuildTx.mutate();
  };

  const handleConfirm = () => {
    const currentAmount = openAll ? mysteryBoxAmount : 1;
    if (currentAmount > 1) {
      // onOpenRecordModal();
      // onClose();
    } else {
      handleOpenMysterybox();
    }
  };

  const mutationBuildTx = useMutation({
    mutationKey: ["buildMysteryboxTx", walletPubkey],
    mutationFn: () => getMysteryboxTx({ token, networkId }),
    onSuccess: async ({ data }) => {
      const transactionString = data.hash;
      triggerTransaction(transactionString);
    },
  });

  const mutationOpenMysteryBox = useMutation({
    mutationKey: ["openMysteryBox", walletPubkey],
    mutationFn: () =>
      openMysterybox({
        token,
        hash: txHash,
        networkId,
      }),
    onSuccess({ data, status }) {
      if (data.success) {
        refreshInfo();
        // setMysteryBoxOpenAmount(data.amount);
        toast(<CoinToast amount={data.amount} />, BASE_TOAST_CONFIG);

        // onOpenResultModal();
        hideModal();
      }
    },
    onError: () => {
      toast(
        <BaseToast
          type="error"
          message="Oops! There's a little hiccup on server!"
        />,
        BASE_TOAST_CONFIG
      );
    },
    onSettled: () => {
      setIsOpeningMysterybox(false);
    },
  });

  const triggerTransaction = async (transactionString: string) => {
    if (!publicKey || !signTransaction) {
      console.error("Wallet not connected");
      return;
    }

    try {
      const tx = Transaction.from(Buffer.from(transactionString, "base64"));
      const { txid, slot } = await sendLegacyTransaction(
        connection,
        // @ts-ignore
        wallet?.adapter,
        tx,
        "processed"
      );

      if (!txid) {
        throw new Error("Could not retrieve transaction hash");
      }

      txHash = txid;

      const result = await confirmTransaction(connection, txHash, "confirmed");

      mutationOpenMysteryBox.mutate();
    } catch (error) {
      console.error("Transaction failed:", error);
      setIsOpeningMysterybox(false);
      toast(
        <BaseToast type="error" message="Transaction failed" />,
        BASE_TOAST_CONFIG
      );
    }
  };

  useEffect(() => {
    if (!!walletPubkey && !!token) {
      refreshInfo();
    }
  }, [walletPubkey, token]);

  return (
    <BaseModal
      withHeading={false}
      open={visible}
      onClose={hideModal}
      classes={{ dialog: "bg-gray-700 sm:max-w-[400px]" }}
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-4 items-center">
          <ImageStatic url={sonicBox} classes={{ wrapper: "w-15 h-25" }} />
          <div className="text-xl font-bold text-center">Open Mystery Box</div>
        </div>
        {false > 1 ? (
          <div className="flex flex-col gap-3">
            <Button
              variant={openAll ? "gray" : "secondary"}
              disabled={!openAll}
              className={!openAll ? "disabled:bg-gray-50" : undefined}
              onClick={() => setOpenAll(false)}
            >
              Open One
            </Button>
            <Button
              variant={!openAll ? "gray" : "secondary"}
              disabled={openAll}
              className={openAll ? "disabled:bg-gray-50" : undefined}
              onClick={() => setOpenAll(true)}
            >
              Open All
            </Button>
          </div>
        ) : null}
        <div className="flex flex-col gap-3">
          <Button
            variant="primary"
            onClick={() => {
              handleConfirm();
            }}
            isLoading={isOpeningMysterybox}
            disabled={isOpeningMysterybox}
          >
            Open
          </Button>
          <Button variant="gray" onClick={() => hideModal()}>
            Cancel
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default OpenBoxModal;
