import React, { FC, useContext } from "react";
import { useModal } from "react-modal-hook";

import { BaseModal } from "../BaseModal";
import { ModalProps } from "../../components/modals/types";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import Button from "../../components/common/button/Button";
import { AggregatedBalancesContext } from "../../contexts";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import { TransferToAutoSignBalanceModal } from "../../components/modals/TransferToAutoSignBalanceModal";

interface IAutoSigningNeedsMoreSolModalProps extends ModalProps {
  onFundSuccess: Function
};

export const AutoSigningNeedsMoreSolModal: FC<IAutoSigningNeedsMoreSolModalProps> = ({
  visible,
  hideModal,
  onFundSuccess
}) => {
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { setAllowsAutoSigning } = useContext(SessionAuthorityContext);

  const [showTransferToAutoSignBalanceModal, hideTransferToAutoSignBalanceModal] = useModal(
    ({ in: open }) => (
      <TransferToAutoSignBalanceModal
        visible={open}
        hideModal={() => {
          hideTransferToAutoSignBalanceModal();
        }}
        onFundSuccess={onFundSuccess}
      />
    ),
    [mergedTokens]
  );

  return (
    <BaseModal
      open={visible}
      hideModal={hideModal}
    >
      <div className="flex w-[85vw] md:w-[460px] flex-col items-start gap-y-3 bg-gray-800">
        <div className="flex flex-col w-full items-center gap-y-3">
          <div className="flex w-1/3 mt-3">
            <img src={getS3StaticFolderUrl("/static/auto-signing-icon.png")} className="flex w-full"/>
          </div>

          <div
            className="flex pt-3 flex-col items-center gap-y-2.5 self-stretch text-center"
          >
            <div className="text-xl font-semibold">Auto-Signing needs more SOL</div>
            <div className="subtitle text-base font-normal text-gray-200">
              To keep playing without signing each bet please add a little more SOL for gas to Auto-Signing.
            </div>
          </div>

          <div className="flex flex-col items-start gap-y-3 self-stretch mt-3">
            <Button
              disabled={false}
              className="w-full"
              variant="primary"
              size="md"
              onClick={() => {
                showTransferToAutoSignBalanceModal();
                hideModal();
              }}
            >
              Add more SOL
            </Button>
            <Button
              variant="primary"
              className="w-full bg-none border-2 border-gray-50 hover:after:border-gray-800"
              size="md"
              onClick={() => {
                setAllowsAutoSigning(false);
                hideModal();
              }}
            >
              Stop Auto-Signing and sign manually
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}
