import { createContext, useEffect, useMemo } from "react";
import { PublicKey } from "@solana/web3.js";
import SolanaRpc from "../utils/solana/rpc";
import { useSolWeb3 } from "../hooks/web3/useSolWeb3";

export interface ISocialWalletContext {
  // SOLANA
  loggedIn: boolean;
  loginSocial: (wallet: string, provider?: string) => Promise<void>;
  logoutSocial: () => Promise<void>;
  solanaRpc: SolanaRpc | undefined;
  walletPubkey: PublicKey | null;
}

export const SocialWalletContext = createContext<ISocialWalletContext>({} as ISocialWalletContext);

interface Props {
  children: any;
}

export const SocialWalletProvider = ({ children }: Props) => {
  // WANT TO SWITCH HERE WEB3 AUTH OR REGULAR WALLET ADAPTER...
  const { loggedIn, connect, disconnect, rpc, walletAddress, web3Auth } = useSolWeb3();

  return (
    <SocialWalletContext.Provider
      value={useMemo(
        () => ({
          loggedIn: loggedIn,
          loginSocial: connect,
          logoutSocial: disconnect,
          solanaRpc: rpc,
          walletPubkey: walletAddress,
        }),
        [loggedIn, connect, disconnect, rpc, walletAddress],
      )}
    >
      {children}
    </SocialWalletContext.Provider>
  );
};
