import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import IconFont from "../common/iconFont/IconFont";
import Tooltip from "../common/tooltip/Tooltip";
import { twMerge } from "tailwind-merge";
import { CasinoSwitch, ICasinoSwitchProps } from "../common/switch/switch";
import FormItem, { FormItemProps } from "../common/form-item/FormItem";
import { CheckBox, CheckboxSize } from "../common/check-box/check-box";
import Zeebro from "../../sdk/nftStaking/zeebro";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import Icon, { IconSizes } from "../common/icon/Icon";
import VictoryLeafsLeft from "../../assets/icons/victory-leafs-left.svg";
import VictoryLeafsRight from "../../assets/icons/victory-leafs-right.svg";
import Button from "../common/button/Button";
import TensorTextIcon from "../../assets/icons/tensor-text.svg";
import MagicEdenTextIcon from "../../assets/icons/magic-eden-text.svg";
import SniperTextIcon from "../../assets/icons/sniper-text.svg";
import {
  MAGIC_EDEN_ZEEBROS_URL,
  SNIPER_ZEEBROS_URL,
  TENSOR_ZEEBROS_URL,
} from "../../constants/socials";
import DropdownInput, { DropdownInputProps } from "../common/dropdown-input/DropdownInput";

export const marketPlaceButtons = [
  { icon: <MagicEdenTextIcon />, url: MAGIC_EDEN_ZEEBROS_URL },
  { icon: <SniperTextIcon />, url: SNIPER_ZEEBROS_URL },
  { icon: <TensorTextIcon />, url: TENSOR_ZEEBROS_URL },
];
import Trait from "../../sdk/nftStaking/trait";

export enum ExpectancyDirection {
  Up = "up",
  UpLots = "upLots",
  Down = "down",
  DownLots = "downLots",
  None = "none",
}

export enum WinPlace {
  First = 1,
  Second = 2,
  Third = 3,
}

export enum AttributeTypes {
  Background = 0,
  "Eyes & Accessories" = 1,
  Headwear = 2,
  Clothing = 3,
  Mouth = 4,
}
export interface IAttribute {
  id: string;
  type: AttributeTypes;
  name: string;
  rarity: number;
  url: string;
  staked: boolean;
  rackeback?: number;
  expectancy?: number;
}
export interface PriceTagsProps {
  size: "md" | "sm";
  direction: ExpectancyDirection;
  difference: number;
}

export const WinPlaceLabel = ({ place, className }: { place: WinPlace; className?: string }) => {
  const colorClass = useMemo(() => {
    switch (place) {
      case WinPlace.First:
        return "text-leader-gold";
      case WinPlace.Second:
        return "text-leader-silver";
      case WinPlace.Third:
        return "text-leader-bronze";
      default:
        return "";
    }
  }, [place]);
  const label = useMemo(() => {
    switch (place) {
      case WinPlace.First:
        return "First";
      case WinPlace.Second:
        return "Second";
      case WinPlace.Third:
        return "Third";
      default:
        return "";
    }
  }, [place]);
  return <div className={twMerge(`text-sm ${colorClass}`, className)}>{label}</div>;
};

export const WinPlaceTag = ({
  place,
  className,
  children,
}: {
  place: WinPlace;
  className?: string;
  children?: any;
}) => {
  const colorClass = useMemo(() => {
    return (
      {
        [WinPlace.First]: {
          color: "text-leader-gold",
          shadow: "shadow-gold-glow-xl",
          background:
            "linear-gradient(-45deg, #F5D565, #F1CD50, #FFF7DB, #F5D565, #EECB4E, #FFFAEA, #F3CE4B)",
        },
        [WinPlace.Second]: {
          color: "text-leader-silver",
          shadow: "shadow-silver-glow-xl",
          background:
            "linear-gradient(-45deg, #98A1B7, #98A1B7, #E2E4E7, #CDD1D9, #98A1B7, #E5E6E7, #9DA6B9)",
        },
        [WinPlace.Third]: {
          color: "text-leader-bronze",
          shadow: "shadow-bronze-glow-xl",
          background:
            "linear-gradient(-45deg, rgba(139,72,32,1) 4%, rgba(207,136,71,1) 17%, rgba(139,72,32,1) 30%, rgba(207,136,71,1) 52%, rgba(139,72,32,1) 85%)",
        },
      }[place] || { color: "", shadow: "", background: "" }
    );
  }, [place]);
  const label = useMemo(() => {
    switch (place) {
      case WinPlace.First:
        return "1";
      case WinPlace.Second:
        return "2";
      case WinPlace.Third:
        return "3";
      default:
        return "";
    }
  }, [place]);
  const content = typeof children === "function" ? children(colorClass) : children;

  return (
    <div
      className={twMerge(
        `flex h-[23px] w-[35px] items-center justify-center rounded-md bg-gray-700 px-[4.5px] py-[5.5px] text-xs ${colorClass.color}`,
        className,
      )}
    >
      <VictoryLeafsLeft />
      {children ? content : <div className="mx-1">{label}</div>}
      <VictoryLeafsRight />
    </div>
  );
};

export const PriceTags: FC<PriceTagsProps> = ({ size = "md", direction, difference }) => {
  const sizeClasses = () => {
    if (size === "sm") return "rounded-sm px-1 text-[10px]";
    if (size === "md") return "rounded-md px-1.5 text-xs";
  };
  const directionClasses = () => {
    if (direction === ExpectancyDirection.Up) return "bg-teal-700";
    if (direction === ExpectancyDirection.UpLots)
      return "bg-teal-500 [box-shadow:0px_0px_12px_0px_rgba(86,181,166,0.50)]";
    if (direction === ExpectancyDirection.Down) return "bg-rose-800";
    if (direction === ExpectancyDirection.DownLots)
      return "bg-rose-600 [box-shadow:0px_0px_12px_0px_rgba(207,54,76,0.50)]";
    return "bg-gray-400";
  };
  return (
    <div
      className={`flex items-center justify-center text-gray-50 ${sizeClasses()} ${directionClasses()}`}
    >
      {direction === "up" ||
      direction === "upLots" ||
      direction === "down" ||
      direction === "downLots" ? (
        <IconFont
          name={direction === "up" || direction === "upLots" ? "arrow_over" : "arrow_under"}
          size={size === "md" ? "md" : "xs"}
        />
      ) : null}
      <span className={size === "sm" ? "py-[1px]" : "py-[4.5px]"}>{`${difference}%`}</span>
    </div>
  );
};

export enum StakingStatus {
  Staked = "staked",
  Unstaked = "unstaked",
  Missed = "missed",
  Claimable = "claimable",
}

export enum RarityType {
  Common = 100,
  Rare = 30,
  Epic = 10,
  Legendary = 5,
}
export interface RarityTagProps {
  rarity: number;
  className?: string;
}

export const RarityTag: FC<RarityTagProps> = ({ rarity, className }) => {
  const rarityClasses = () => {
    if (rarity < RarityType.Legendary) return "bg-amber-500";
    if (rarity < RarityType.Epic) return "bg-purple-500";
    if (rarity < RarityType.Rare) return "bg-teal-500";
    return "bg-gray-500";
  };
  return (
    <div
      className={`flex h-4 items-center justify-center rounded-sm px-1 text-[10px] text-gray-50 ${rarityClasses()} ${className}`}
    >
      <span className="uppercase">{`${rarity}%`}</span>
    </div>
  );
};

export interface StakingStatusTagProps {
  status: StakingStatus;
  className?: string;
}

export const StakingStatusTag: FC<StakingStatusTagProps> = ({ status, className }) => {
  const statusClasses = () => {
    const classes: string[] = [];
    if (status === StakingStatus.Staked || status === StakingStatus.Claimable)
      classes.push("bg-emerald-500");
    if (status === StakingStatus.Unstaked || status === StakingStatus.Missed)
      classes.push("bg-gray-500");
    if (status === StakingStatus.Staked || status === StakingStatus.Unstaked)
      classes.push("w-[94px]");
    if (status === StakingStatus.Claimable || status === StakingStatus.Missed)
      classes.push("w-[84px]");
    return classes.join(" ");
  };
  return (
    <div
      className={`flex items-center justify-center gap-1 rounded-md px-2 py-1 text-xs text-gray-50 transition-all ${statusClasses()} ${className}`}
    >
      {(status === StakingStatus.Staked || status === StakingStatus.Unstaked) && (
        <IconFont name={status === StakingStatus.Unstaked ? "error" : "correct"} size="xs" />
      )}
      <span className="uppercase">{status}</span>
    </div>
  );
};
export interface ZeebroCollectionItemProps {
  id: string;
  status: StakingStatus;
  nftImageUrl: string;
  title: string;
  winnings: number;
  missed: number;
  nominated: { name: string };
  traitStaked: number;
  totalWithTrait: number;
  expectancy: number;
  expectancyDifference: number;
  expectancyDirection: ExpectancyDirection;
  className?: string;
  zeebro: Zeebro;
  nftMint: string;
  rakebackRate: number;
  isLoading: boolean;
  onClick: () => void;
}

export const ZeebroGridItem: FC<ZeebroCollectionItemProps> = ({
  status,
  title,
  nftImageUrl,
  winnings,
  nominated,
  traitStaked,
  totalWithTrait,
  expectancy,
  expectancyDirection,
  expectancyDifference,
  className,
  isLoading,
  onClick,
}) => {
  if (isLoading) {
    return (
      <div
        className={twMerge(`flex w-[221px] flex-col gap-2 rounded-lg bg-gray-800 p-2 `, className)}
      >
        <div
          className="bg-animated-gradient-gray-700-gray-800 aspect-square w-full overflow-hidden rounded-lg 
        bg-cover"
        ></div>
        <div className="bg-animated-gradient-gray-700-gray-800 h-[18px] w-full rounded-lg" />
        <div className="h-[1px] w-full " />
        <div className="bg-animated-gradient-gray-700-gray-800 h-[15px] w-full rounded-lg" />
        <div className="bg-animated-gradient-gray-700-gray-800 h-[15px] w-full rounded-lg" />
      </div>
    );
  }
  return (
    <div
      className={twMerge(
        `flex w-[221px] cursor-pointer flex-col gap-2 rounded-lg bg-gray-700 p-2 text-gray-50 hover:bg-gray-600 active:bg-gray-700`,
        className,
      )}
      onClick={onClick}
    >
      <Image url={nftImageUrl} classes={{ wrapper: "relative w-full" }}>
        <StakingStatusTag status={status} className="absolute bottom-2 left-2" />
      </Image>
      {/* <div
        style={{ "--image-url": `url(${nftImageUrl})` }}
        className="relative aspect-square  overflow-hidden rounded-lg bg-[image:var(--image-url)] bg-cover"
      >
      </div> */}
      <h5 className="text-sm">{title}</h5>
      <div className="h-[1px] w-full bg-gray-600" />
      <div className="flex flex-col gap-0.5">
        <div className="flex items-center justify-between">
          <span className="text-xs font-normal text-gray-300">Winnings:</span>
          <TokenAmount token="usdc" amount={winnings} />
        </div>
        {nominated?.name != null ? (
          <div className="flex items-center justify-between">
            <span className="text-xs font-normal text-gray-300">Nominated:</span>
            <span className="text-xs">{nominated?.name}</span>
          </div>
        ) : (
          ""
        )}
        {traitStaked != null ? (
          <div className="flex items-center justify-between">
            <span className="text-xs font-normal text-gray-300">Trait Staked:</span>
            <span className="text-xs">{`${formatZeebitNumber(totalWithTrait > 0 && traitStaked != null ? traitStaked / totalWithTrait : 0, NumberType.PROBABILITY)}%`}</span>
          </div>
        ) : null}
        <div className="flex items-center justify-between">
          <span className="flex items-center gap-1 text-xs font-normal text-gray-300">
            <Tooltip content={expectancyTooltipContent}>
              <IconFont name="information" />
            </Tooltip>
            Expectancy:
          </span>
          <div className="flex items-center gap-1">
            <TokenAmount token="usdc" amount={expectancy} />
            <PriceTags
              size="sm"
              direction={expectancyDirection}
              difference={expectancyDifference}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export interface WinItemProps {
  id: string;
  status?: StakingStatus;
  nftImageUrl: string;
  place?: WinPlace;
  nftName: string;
  winnings?: number;
  distributed?: number;
  winners?: number;
  date: string;
  traitImgUrl: string;
  winAmount?: number;
  className?: string;
  onClick?: () => void;
  winner?: string | ReactNode;
  payout?: string | ReactNode;
  staked?: string | ReactNode;
}

export const WinGridItem: FC<WinItemProps> = ({
  status,
  nftName,
  place,
  nftImageUrl,
  winnings,
  winner,
  payout,
  staked,
  distributed,
  winners,
  date,
  traitImgUrl,
  className,
  onClick,
}) => {
  return (
    <div
      className={twMerge(
        `flex w-[221px] cursor-pointer flex-col gap-2 rounded-lg bg-gray-700 p-2 text-gray-50 hover:bg-gray-600 active:bg-gray-700 ${status === StakingStatus.Missed ? "text-gray-400" : ""}`,
        className,
      )}
      onClick={onClick}
    >
      <Image url={nftImageUrl} classes={{ wrapper: "relative w-full rounded" }}>
        <StakingStatusTag status={status} className="absolute bottom-2 left-2" />
        {traitImgUrl && (
          <Image
            url={traitImgUrl}
            classes={{ wrapper: "absolute bottom-2 right-2  h-[50px] max-w-[50px] " }}
          />
        )}
        {place && <WinPlaceTag place={place} className="absolute left-2 top-2" />}
      </Image>
      {/*<div*/}
      {/*  style={{ "--image-url": `url(${nftImageUrl})` }}*/}
      {/*  className="relative aspect-square w-full overflow-hidden rounded-lg bg-[image:var(--image-url)] bg-cover"*/}
      {/*>*/}
      {/*  <StakingStatusTag status={status} className="absolute bottom-2 left-2" />*/}
      {/*  {traitImgUrl && (*/}
      {/*    <div*/}
      {/*      style={{ "--image-url": `url(${traitImgUrl})` }}*/}
      {/*      className="absolute bottom-2 right-2 aspect-square h-[50px] w-full max-w-[50px] overflow-hidden rounded-lg bg-[image:var(--image-url)] bg-cover"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*  {place && <WinPlaceTag place={place} className="absolute left-2 top-2" />}*/}
      {/*</div>*/}
      <div className="flex w-full items-center justify-between">
        <span className="w-full text-sm">{nftName}</span>
      </div>
      <div className="h-[1px] w-full bg-gray-500" />
      <div className="flex flex-col gap-1">
        {distributed && (
          <div className="flex items-center justify-between">
            <span className="text-xs font-normal text-gray-300">Distributed:</span>
            <span className="text-xs">{distributed}</span>
          </div>
        )}
        {winnings && (
          <div className="flex items-center justify-between">
            <span className="text-xs font-normal text-gray-300">Winnings:</span>
            <TokenAmount token="usdc" amount={winnings} />
          </div>
        )}
        {payout && (
          <div className="flex items-center justify-between">
            <span className="text-xs font-normal text-gray-300">Payout:</span>
            <span className="text-xs">{payout}</span>
          </div>
        )}
        {staked && (
          <div className="flex items-center justify-between">
            <span className="text-xs font-normal text-gray-300">Staked:</span>
            <span className="text-xs">{staked}</span>
          </div>
        )}
        {winner && (
          <div className="flex items-center justify-between">
            <span className="text-xs font-normal text-gray-300">Winner:</span>
            <span className="text-xs">{winner}</span>
          </div>
        )}
        {winners >= 0 && (
          <div className="flex items-center justify-between">
            <span className="text-xs font-normal text-gray-300">Winners:</span>
            <span className="text-xs">{winners}</span>
          </div>
        )}
        <div className="flex items-center justify-between">
          <span className="text-xs font-normal text-gray-300">Date:</span>
          <span className="text-xs">{date}</span>
        </div>
      </div>
    </div>
  );
};
interface ZeebroWinningsItemProps {
  title: string;
  subTitle?: string;
  value: string | React.ReactNode;
  subValue?: string | React.ReactNode;
  progress?: number;
}
export const ZeebroWinningsItem: FC<ZeebroWinningsItemProps> = ({
  title,
  subTitle,
  value,
  subValue,
  progress,
}) => {
  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-600 px-3 py-2">
      <div className="flex items-center justify-between">
        <span className="text-sm ">{`${title}:`}</span>
        <span className="text-sm">{value}</span>
      </div>
      {subTitle != null && subValue != null ? (
        <div className="flex items-center justify-between text-sm font-normal text-gray-300">
          <span>{`${subTitle}:`}</span>
          {subValue}
        </div>
      ) : (
        ""
      )}
      {progress != null ? (
        <div className="mt-1 h-1.5 w-full rounded-full bg-gray-800">
          <div
            style={{ width: `${progress}%` }}
            className="z-10 h-full rounded-full bg-brand-mixed-gradient shadow-brand-purple-sm"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
interface ZeebroWinExpectancyBarProps {
  direction: ExpectancyDirection;
  difference: number;
  fiatExpectancy: number;
}
export const expectancyTooltipContent = (
  <div className="text-center">
    <p>
      Expectancy shows the value that a Zeebro with a given Trait can expect to win based on the
      traits it has, the likelihood of that trait being selected for one of today's winning traits,
      and the number of other Zeebros with these traits which are staked.
    </p>
    <p>
      Next to the expectancy, we display the relative value as compared to the population -- i.e. a
      +10% value highlights that Zeebros with a particular Trait has an expected distribution 10%
      above average.
    </p>
    <p>
      Expectancy is calculated differently before and after the Attribute has been selected for the
      daily cycle.
    </p>
    <p>
      For more information on how expectancy is calculated, see the{" "}
      <a href="#">Zeebros Staking docs</a>.
    </p>
  </div>
);
export const ZeebroWinExpectancyBar: FC<ZeebroWinExpectancyBarProps> = ({
  fiatExpectancy,
  difference,
  direction,
}) => {
  const colorClass = () => {
    if (direction === ExpectancyDirection.UpLots) return "bg-teal-700";
    if (direction === ExpectancyDirection.Up) return "bg-teal-800";
    if (direction === ExpectancyDirection.DownLots) return "bg-rose-700";
    if (direction === ExpectancyDirection.Down) return "bg-rose-900";
    return "bg-gray-500";
  };

  return (
    <Tooltip content={expectancyTooltipContent}>
      <div
        className={`flex w-full items-center justify-between rounded-md p-[10px] ${colorClass()}`}
      >
        <span className="text-sm">Expectancy:</span>
        <div className="flex items-center gap-2">
          <TokenAmount token="usdc" amount={fiatExpectancy} />
          <PriceTags size="md" difference={difference} direction={direction} />
        </div>
      </div>
    </Tooltip>
  );
};

export interface ZeebroAttributeItemProps extends IAttribute {
  className?: string;
  onClick?: () => void;
}

export const ZeebroAttributeItem: FC<ZeebroAttributeItemProps> = ({
  type,
  name,
  rarity,
  staked,
  rackeback,
  url,
  className,
  onClick,
}) => {
  return (
    <button
      onClick={() => onClick?.()}
      className={twMerge(
        `flex  gap-2 rounded-md bg-gray-600 p-2
        ${onClick ? "hover:bg-gray-500r cursor-pointer" : "cursor-default"}`,
        className,
      )}
    >
      {url ? <Image url={url} classes={{ wrapper: "h-full min-h-full" }} /> : null}
      <div className="relative flex w-full flex-col items-start justify-between gap-1">
        <div className="flex w-full items-center justify-between">
          <span className="text-xs font-normal uppercase leading-3 text-gray-300">
            {AttributeTypes[type]}
          </span>
          <RarityTag rarity={rarity} />
        </div>
        <div className="flex w-full max-w-full items-center justify-between gap-2">
          <span className="shrink truncate">{name}</span>
          {staked ? (
            <span className="whitespace-nowrap text-xs font-normal">{`${staked}% Staked`}</span>
          ) : null}
          {rackeback ? (
            <span className="whitespace-nowrap text-xs font-normal">{`+${rackeback}% Rackeback`}</span>
          ) : null}
        </div>
      </div>
    </button>
  );
};

export interface StakingModalSwitchProps extends Omit<ICasinoSwitchProps, "label">, FormItemProps {
  title: string;
  description: string | React.ReactNode;
}

export const StakingModalSwitch: FC<StakingModalSwitchProps> = ({
  title,
  description,
  checked,
  label,
  setChecked,
  rightLabel,
}) => {
  return (
    <FormItem label={label} rightLabel={<span className="font-normal">{rightLabel}</span>}>
      <div
        data-id="StakingModalSwitch"
        className="flex cursor-pointer items-center justify-between rounded-lg border border-gray-300 bg-gray-600 p-3 hover:bg-gray-500"
        onClick={() => setChecked((prev) => !prev)}
      >
        <div className="flex flex-col justify-between gap-1">
          <div className="text-sm">{title}</div>
          <div className="text-sm font-normal text-gray-200">{description}</div>
        </div>
        <CasinoSwitch checked={checked} onChange={() => setChecked((prev) => !prev)} />
      </div>
    </FormItem>
  );
};

export interface WarningFormItemProps {
  isUserUnderstand: boolean;
  message: string | React.ReactNode;
  setUserUnderstand: (value: React.SetStateAction<boolean>) => void;
}

export const WarningFormItem: FC<WarningFormItemProps> = ({
  isUserUnderstand,
  message,
  setUserUnderstand,
}) => {
  return (
    <div className="flex items-center gap-3 rounded-md bg-amber-800 p-3">
      <CheckBox
        size={CheckboxSize.md}
        checked={isUserUnderstand}
        setChecked={() => setUserUnderstand((u) => !u)}
        classes={{ checkbox: "border-amber-700", checkboxChecked: "bg-gray-900" }}
        label={<div className="text-sm font-normal">{message}</div>}
      />
    </div>
  );
};

export interface NftInfoBlockProps {
  imgUrl: string;
  attributes: IAttribute[];
}

export const NftInfoBlock: FC<NftInfoBlockProps> = ({ imgUrl, attributes }) => {
  return (
    <div className="flex flex-col items-center gap-3 sm:flex-row">
      <Image url={imgUrl} classes={{ wrapper: "h-[184px] max-w-[184px]" }} />
      <div className="grid-col-1 grid w-full grid-rows-3 gap-2 sm:grid-cols-2">
        {attributes.map((att) => (
          <ZeebroAttributeItem {...att} url={undefined} className="h-full" />
        ))}
      </div>
    </div>
  );
};

export interface FallBackInputProps extends DropdownInputProps {
  currentNftImgUrl: string;
  defaultTitle: string | React.ReactNode;
  defaultDescription: string | React.ReactNode;
  selectedDescription: string | React.ReactNode;
  formItemLabel: string;
  formItemRightLabel: string;
}

export const FallBackInput: FC<FallBackInputProps> = ({
  options,
  selectedValue,
  onSelect,
  currentNftImgUrl,
  defaultTitle,
  defaultDescription,
  selectedDescription,
  formItemLabel,
  formItemRightLabel,
}) => {
  return (
    <FormItem label={formItemLabel} rightLabel={formItemRightLabel}>
      <DropdownInput
        options={options}
        selectedValue={selectedValue}
        classes={{ button: "w-full" }}
        selectedLabel={
          <div className="flex cursor-pointer items-center gap-3">
            <Image url={selectedValue?.imgUrl} classes={{ wrapper: " h-[40px] min-h-full" }} />
            <div className="flex flex-col text-left">
              <div className="text-sm">{`${selectedValue?.title} selected`}</div>
              <div className="text-sm font-normal text-gray-200">{selectedDescription}</div>
            </div>
          </div>
        }
        onSelect={(option) => onSelect(option)}
        defaultLabel={
          <div className="flex items-center gap-3">
            <div
              style={{
                "--image-url": `url(${currentNftImgUrl})`,
              }}
              className="aspect-square h-[40px] min-h-full shrink-0 grow overflow-hidden rounded-lg bg-[image:var(--image-url)] bg-cover"
            />
            <div className="flex flex-col text-left">
              <div className="text-sm">{defaultTitle}</div>
              <div className="text-sm font-normal text-gray-200">{defaultDescription}</div>
            </div>
          </div>
        }
      />
    </FormItem>
  );
};

export interface GeneralTraitInfoItemProps {
  trait: Trait;
  attributeId: number;
  className?: string;
  onClick: () => void;
}

export const GeneralTraitInfoItem: FC<GeneralTraitInfoItemProps> = ({
  attributeId,
  trait,
  className,
  onClick,
}) => {

  const mostCommonTraitPercentage = trait.attribute.probabilityOfMostCommonTrait;
  const stakeBarWidth =
    (trait.proportionPopulation / mostCommonTraitPercentage) * trait.proportionStaked * 100;

  const populationBarWidth = (trait.proportionPopulation / mostCommonTraitPercentage) * 100;
  return (
    <button
      className={twMerge(
        "flex cursor-pointer flex-col gap-2 rounded-md bg-gray-600 p-3 hover:bg-gray-600",
        className,
      )}
      onClick={() => onClick()}
    >
      <div className="flex w-full items-center gap-3">
        <Image url={trait.imageUrl} classes={{ wrapper: "max-w-[50px] h-[50px]" }} />
        <div className="flex w-full flex-col gap-1.5">
          <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
            <div className="flex items-center gap-1.5 sm:flex-row">
              <span className="text-lg">{trait.name}</span>
              <RarityTag
                rarity={formatZeebitNumber(trait.proportionPopulation, NumberType.PROBABILITY)}
              />
            </div>
            <div className="flex items-center gap-1.5">
              <span className="text-sm font-normal text-gray-300">Today’s Expectancy:</span>
              <TokenAmount
                token="usdc"
                amount={formatZeebitNumber(trait.expectedValueDollar, NumberType.DOLLAR_AMOUNT)}
              />
              <Tooltip content={expectancyTooltipContent}>
                <PriceTags
                  size="sm"
                  direction={trait.expectancyDirection}
                  difference={formatZeebitNumber(
                    trait.expectancyDifference,
                    NumberType.PROBABILITY,
                  )}
                />
              </Tooltip>
            </div>
          </div>
          <div className="flex items-center gap-1.5">
            <span className="text-sm font-normal text-gray-300">Distributed:</span>
            <TokenAmount
              token="usdc"
              amount={formatZeebitNumber(trait.pastRewardsAmountUI, NumberType.DOLLAR_AMOUNT)}
            />
          </div>
        </div>
      </div>
      <div className="relative h-[20px] w-full rounded bg-gray-800 text-xs">
        <div
          className="absolute left-0 z-[2] flex h-full items-center justify-end rounded bg-purple-400 px-2"
          style={{
            width: `${stakeBarWidth}%`,
          }}
        >
          {trait.proportionStaked ? (
            <Tooltip content="% of supply staked">
              <span
                className={stakeBarWidth < 10 ? "-mr-[55px]" : ""}
              >{`${formatZeebitNumber(trait.proportionStaked, NumberType.PROBABILITY)}%`}</span>
            </Tooltip>
          ) : null}
        </div>

        <div
          className="absolute left-0 z-[1] flex h-full items-center justify-end rounded bg-purple-600 px-2"
          style={{ width: `${populationBarWidth}%` }}
        >
          <Tooltip content="Supply % within attribute group">
            <span
              className={populationBarWidth < 20 ? "-mr-[55px]" : ""}
            >{`${formatZeebitNumber(trait.proportionPopulation, NumberType.PROBABILITY)}%`}</span>
          </Tooltip>
        </div>
      </div>
    </button>
  );
};

interface PositionCellProps {
  name: string;
  place?: WinPlace;
  imgUrl: string;
  subValue?: string;
}
export const positionCell = ({ imgUrl, place, name, subValue }: PositionCellProps) => {
  return (
    <div className="flex items-center justify-center gap-3 truncate">
      <Image url={imgUrl} classes={{ wrapper: "h-[40px] min-h-full" }} />
      <div className="flex flex-col truncate">
        <div className="truncate">{name}</div>
        {place ? <div>{<WinPlaceLabel place={place} />}</div> : null}
        {subValue ? <div className="text-sm text-gray-300">{subValue}</div> : null}
      </div>
    </div>
  );
};
export const stakedCell = ({ staked, stakedDate }: { staked: boolean; stakedDate?: string }) => {
  return (
    <Tooltip content={staked && stakedDate ? `Staked ${stakedDate}` : null}>
      <div
        className={`flex h-[16px] w-[16px] items-center justify-center rounded-sm ${staked ? "bg-emerald-500" : "bg-gray-500"}`}
      >
        <IconFont weight="bold" size="xs" name={staked ? "correct" : "error"} />
      </div>
    </Tooltip>
  );
};

export const positionStakedCell = ({
  imgUrl,
  place,
  subValue,
  name,
  staked,
  stakedDate,
  hideStatusOnDesktop,
}: PositionCellProps & { staked: boolean; stakedDate?: string; hideStatusOnDesktop?: boolean }) => (
  <div className="relative flex items-center gap-2 truncate">
    <div
      className={` ${hideStatusOnDesktop ? "@md/table:hidden" : ""} absolute bottom-0.5 left-0.5 @md/table:static`}
    >
      {stakedCell({ staked, stakedDate })}
    </div>
    {positionCell({ imgUrl, place, name, subValue })}
  </div>
);

export const twoValuesCell = ({
  value,
  subValue,
}: {
  value: ReactNode | string;
  subValue: ReactNode | string;
}) => (
  <div className="flex flex-col gap-0.5 truncate">
    <div className="flex items-center justify-start gap-1 truncate">{value}</div>
    <div className="truncate text-sm text-gray-300">{subValue}</div>
  </div>
);

export const headerCellWithTooltip = (label: string, tooltip?: string) => (
  <div className="flex justify-center gap-1">
    {label}
    {
      tooltip
      && <Tooltip content={tooltip}>
            <IconFont name="information" />
        </Tooltip>
    }
  </div>
);
interface PayoutCellProps {
  payout: number;
  staked: boolean;
  tokenIconUrl: string;
}
export const payoutCell = ({ payout, tokenIconUrl, staked }: PayoutCellProps) => ({
  classes: "table-cell",
  value: (
    <div className={`flex flex-col items-end ${!staked ? "text-gray-300" : ""}`}>
      <div className="flex gap-1 font-bold">
        <Icon size="sm" iconUrl={tokenIconUrl} />
        {`${payout}`}
      </div>
      <div className="flex gap-1 font-normal">{staked ? "Awarded" : "Missed"}</div>
    </div>
  ),
});

export interface BlockWithHeaderProps {
  title: string;
  titleSuffix?: string | number;
  titleRight?: React.ReactNode;
  className?: string;
}

export const BlockWithHeader: FC<PropsWithChildren<BlockWithHeaderProps>> = ({
  title,
  titleSuffix,
  titleRight,
  children,
  className,
}) => {
  return (
    <div className={twMerge("flex flex-col gap-3 rounded-lg bg-gray-800 p-5 pt-4", className)}>
      {(title || titleSuffix || titleRight) && (
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-3">
            {title && <span className="text-lg">{title}</span>}
            {titleSuffix && <span className="text-lg text-gray-300">{titleSuffix}</span>}
          </div>
          {titleRight}
        </div>
      )}
      {children}
    </div>
  );
};

export interface ClaimableWinningsBannerProps {
  isDisabled: boolean;
  winnings: number;
  className?: string;
  onClick: () => void;
  loading: boolean;
}

export const ClaimableWinningsBanner: FC<ClaimableWinningsBannerProps> = ({
  isDisabled,
  winnings,
  className,
  onClick,
  loading,
}) => {
  return (
    <div
      className={twMerge(
        `relative flex min-h-[250px] flex-col overflow-hidden rounded-lg ${winnings ? "bg-brand-purple-gradient-1" : "bg-gray-800"}`,
        className,
      )}
    >
      <Image
        url={
          winnings ? "/static/zeebros/USDC-win-banner.png" : "/static/zeebros/USDC-banner-gray.png"
        }
        classes={{
          wrapper: "absolute bottom-0 h-[50%] w-full justify-self-end bg-none",
          image: "bg-no-repeat",
        }}
      />
      {winnings != null && winnings > 0 ? (
        <div className="flex h-full flex-col items-center justify-between p-6 font-bold">
          <span className="text-center text-lg">You’ve Claimable Winnings!</span>
          <div className="flex flex-col">
            <span className="text-center text-3xl">{`$${formatZeebitNumber(winnings, NumberType.DOLLAR_AMOUNT)}`}</span>

            {/* <div className="flex items-center gap-1 text-3xl text-purple-300">
              {`≈`}
              <img src="/static/tokens/usdc.png" width={20} height={20} className="h-5 w-5" />
              {`${winnings}`}
            </div> */}
          </div>
          <Button
            isLoading={loading}
            disabled={isDisabled == true}
            className="text-purple-700"
            variant="secondary"
            onClick={onClick}
          >
            Claim Winnings
          </Button>
        </div>
      ) : (
        <div className="flex h-full flex-col items-center justify-center gap-[10px] p-6 font-bold">
          <span className="text-center text-lg">No Claimable Winnings</span>
          <span className="text-center font-normal text-gray-300">Best of luck tomorrow!</span>
        </div>
      )}
    </div>
  );
};
interface AchievementBlockProps {
  title: string;
  imgs: { imgUrl: string }[];
  className?: string;
}
const AchievementBlock: FC<AchievementBlockProps> = ({ title, imgs = [], className }) => {
  if (!imgs) return null;
  const [img1, img2, img3, ...rest] = imgs;
  const imagesToShow = [img1, img2, img3].filter((i) => !!i);

  const bubble = ({ imgUrl, num }: { imgUrl?: string; num?: number }) => (
    <div
      style={{
        "--image-url": `url(${imgUrl})`,
      }}
      className={`flex  h-[30px] w-[30px] items-center justify-center rounded-full text-xs font-normal 
      ${imgUrl ? "bg-[image:var(--image-url)]" : "bg-gray-500"} bg-cover bg-center bg-no-repeat [&~*]:-ml-1`}
    >
      {num ? `+${num}` : ""}
    </div>
  );

  return (
    <div className={twMerge("flex flex-col gap-2 rounded-md bg-gray-700 px-4 py-3", className)}>
      <div className="text-xs font-normal uppercase text-gray-300">{title}</div>
      <div className="flex items-center gap-3">
        <span className="text-2xl">{imgs.length}</span>
        <div className="flex items-center">
          {imagesToShow.length ? imagesToShow.map(bubble) : ""}
          {rest && rest.length > 0 && bubble({ imgUrl: undefined, num: rest.length })}
        </div>
      </div>
    </div>
  );
};
export interface StakingDashboardProps {
  wins: number;
  totalWinnings: number;
  missedByUnstaking: number;
  claimedWinnings: number;
  zeebrossOwned: { imgUrl: string }[];
  zeebrossStaked: { imgUrl: string }[];
  jackpotWins: { imgUrl: string }[];
  traitWins: { imgUrl: string }[];
}

export const StakingDashboard: FC<StakingDashboardProps> = ({
  wins,
  totalWinnings,
  missedByUnstaking,
  claimedWinnings,
  zeebrossOwned,
  zeebrossStaked,
  jackpotWins,
  traitWins,
}) => {
  return (
    <div className="grid grid-cols-4 gap-3 @container ">
      {/* HIDDEN UNTIL DATA READY */}
      {/* <div className="col-span-4 flex flex-col gap-2 rounded-md bg-gray-700 px-4 py-3">
        <div className="flex gap-10">
          <div className="flex flex-col gap-1.5">
            <span className="text-xs font-normal uppercase text-gray-300">WINS</span>
            <span className="text-2xl">{wins}</span>
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-xs font-normal uppercase text-gray-300">TOTAL WINNINGS</span>
            <span className="text-2xl">{`$${totalWinnings}`}</span>
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-xs font-normal uppercase text-gray-300">
              MISSED BY UNSKTAKING
            </span>
            <span className="text-2xl">{`$${missedByUnstaking}`}</span>
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-xs font-normal uppercase text-gray-300">CLAIMED WINNINGS</span>
            <span className="text-2xl">{`$${claimedWinnings}`}</span>
          </div>
        </div>
        {claimedWinnings && totalWinnings ? (
          <div className="relative flex h-[24px] w-full items-center justify-end rounded bg-gray-800 px-2 text-xs">
            <div
              className="absolute left-0 z-[1] flex h-full items-center justify-end rounded bg-purple-600 px-2"
              style={{ width: `${(claimedWinnings / totalWinnings) * 100}%` }}
            >
              {`$${claimedWinnings}`}
            </div>
            <span className="text-gray-400">{`$${totalWinnings}`}</span>
          </div>
        ) : null}
      </div> */}
      <AchievementBlock
        title="Zeebros Owned"
        imgs={zeebrossOwned}
        className="col-span-4 @sm:col-span-2 @2xl:col-span-1"
      />
      <AchievementBlock
        title="Zeebros staked"
        imgs={zeebrossStaked}
        className="col-span-4 @sm:col-span-2 @2xl:col-span-1"
      />
      <AchievementBlock
        title="JACKPOT WINS"
        imgs={jackpotWins}
        className="col-span-4 @sm:col-span-2 @2xl:col-span-1"
      />
      <AchievementBlock
        title="TRAIT WINS"
        imgs={traitWins}
        className="col-span-4 @sm:col-span-2 @2xl:col-span-1"
      />
    </div>
  );
};

export interface ImageProps {
  url: string;
  classes?: {
    wrapper?: string;
    image?: string;
  };
}

export const Image: FC<PropsWithChildren<ImageProps>> = ({ url, classes, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleLoad = () => {
    // console.log({ event });
    setIsLoading(false);
  };

  useEffect(() => {
    if (imgRef && imgRef.current && imgRef.current.getAttribute("listener") !== "true") {
      imgRef.current.addEventListener("load", handleLoad, { once: true });
    }
    return () => {
      if (imgRef && imgRef.current) {
        handleLoad();
        imgRef.current.removeEventListener("load", handleLoad);
      }
    };
  }, [imgRef.current]);

  return (
    <div
      className={twMerge(
        "bg-animated-gradient-gray-700-gray-800 aspect-square shrink-0 grow overflow-hidden rounded-lg",
        classes?.wrapper,
      )}
    >
      <img src={url} ref={imgRef} className="hidden" />
      {!isLoading ? (
        <div
          style={{
            "--image-url": `url(${url})`,
          }}
          className={twMerge("h-full w-full bg-[image:var(--image-url)] bg-cover", classes?.image)}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export interface TokenAmountProps {
  token: string;
  amount: number | string;
  iconSize?: IconSizes;
}

export const TokenAmount: FC<TokenAmountProps> = ({ token, amount, iconSize = "xs" }) => {
  return (
    <div className="flex items-center gap-1">
      <Icon size={iconSize} iconUrl={`/static/tokens/${token}.png`} className="mb-1" />
      <span className="text-sm">{amount}</span>
    </div>
  );
};
