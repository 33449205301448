import React, { FC, useContext, useState } from "react";
import { useModal } from "react-modal-hook";

import HamburgerIcon from "../../assets/icons/hamburger.svg";
import { Button } from "../common/button/Button";
import { WalletDropdown } from "./WalletDropdown";
import { NavContext } from "../../contexts/NavContext";
import { WalletTab } from "../../modals/WalletModal";
import { useWalletModal } from "../../hooks/modals/useWalletModal";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";
import {
  AddFundsAndPlayModal,
  IFundOrWithdrawModalTabs,
} from "../modals/AddFundsAndPlayModal";
import { SelectTokenModal } from "../modals/SelectTokenModal";
import { AggregatedBalancesContext } from "../../contexts/AggregatedBalancesContext";
import { NetworkContext } from "../../contexts/NetworkContext";
import { useNavigate } from "react-router-dom";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import ConnectionModal from "../modals/ConnectionModal";
import { UserDropdown } from "./UserDropdown";
import IconFont from "../common/iconFont/IconFont";
import { AirdropModal } from "../../modals/AirdropModal";

export const TopNav: FC<{}> = () => {
  const navigate = useNavigate();
  const { loggedIn, disconnect } = useContext(WrappedWalletContext);
  const { toggleLeftPanel } = useContext(NavContext);
  const { setWalletTab, showWalletModal } = useWalletModal();

  const [walletModalSelectedTab, setWalletModalSelectedTab] =
    useState<IFundOrWithdrawModalTabs>(IFundOrWithdrawModalTabs.DEPOSIT);

  const [showConnectionModal, hideConnectionModal] = useModal(
    ({ in: open }) => (
      <ConnectionModal
        open={open}
        closeModal={hideConnectionModal}
      />
    ),
    []
  );

  const { mergedTokens } = useContext(AggregatedBalancesContext);

  const [showFundsAndPlayModal, hideFundsAndPlayModal] = useModal(
    ({ in: open }) => (
      <AddFundsAndPlayModal
        visible={open}
        hideModal={() => {
          hideFundsAndPlayModal();
        }}
        walletModalSelectedTab={walletModalSelectedTab}
      />
    ),
    [mergedTokens, walletModalSelectedTab]
  );

  const [showFundTokenModal, hideFundTokenModal] = useModal(
    ({ in: open }) => (
      <SelectTokenModal
        visible={open}
        hideModal={hideFundTokenModal}
        showFundsAndPlayModal={(selectedTab: IFundOrWithdrawModalTabs) => {
          setWalletModalSelectedTab(selectedTab);
          showFundsAndPlayModal();
        }}
      />
    ),
    []
  );

  const [showAirdropModal, hideWalletModal] = useModal(
    ({ in: open }) => (
      <AirdropModal visible={open} hideModal={hideWalletModal} />
    ),
    []
  );

  const { chainDisplayName } = useContext(NetworkContext);

  return (
    <ul className="flex justify-between 222 h-top-bar-height bg-gray-800 px-4 py-0 items-center relative z-40 shadow-black-regular">
      {/* HAMBURGER AND LOGO */}
      <li className="text-white flex align-center">
        <div
          role="button"
          className="hidden sm:block h-6 w-6 my-auto"
          onClick={() => toggleLeftPanel()}
        >
          <HamburgerIcon />
        </div>
        {/* TODO - REPLACE WITH LOGO */}
        <div
          className="flex align-center cursor-pointer ml-3"
          onClick={() => {
            navigate(``);
          }}
        >
          <div className="">
            <div className="hidden sm:block w-[170px]">
              <img src={getS3StaticFolderUrl("/static/zeebit-beta-full.svg")} />
            </div>

            <img
              src={getS3StaticFolderUrl("/static/zeebit-beta-mobile.svg")}
              className="flex sm:hidden w-[90px]"
            />
          </div>
        </div>
      </li>

      {/* USERS TOKENS AND WALLET DROPDOWN */}
      <li className="hidden sm:block">
        {loggedIn && (
          <div className="flex gap-x-2">
            <WalletDropdown
              openGasModal={() => {
                setWalletTab(WalletTab.GAS);
                showWalletModal();
              }}
              setWalletModalOpen={showFundTokenModal}
            />
            <Button
              size={"md"}
              variant={!loggedIn ? "primary" : "gray"}
              className="h-10"
              children={
                <div className="flex gap-x-2 items-center">
                  <IconFont name="download" size="xl" className="mb-0.5" />
                  Faucet
                </div>
              }
              onClick={showAirdropModal}
            />
          </div>
        )}
      </li>
      {/* CONNECT, REGISTER, OR PROFILE */}
      <li className="flex items-center gap-2">
        {/* <RingPopover /> */}
        <Button
          size={"md"}
          variant={!loggedIn ? "primary" : "gray"}
          children={
            !loggedIn ? (
              "Connect"
            ) : (
              <div className="flex gap-x-2 items-center">
                <IconFont name="globe" size="xl" className="mb-0.5" />
                {chainDisplayName}
              </div>
            )
          }
          onClick={showConnectionModal}
        />
        {loggedIn && (
          <div className="hidden sm:block">
            <UserDropdown />
          </div>
        )}
      </li>
    </ul>
  );
};
