import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GameSpec from "../sdk/gameSpec";
import { HouseContext } from "./HouseContext";
import { ProgramContext } from "./ProgramContext";
import House from "../sdk/house";
import { GameType } from "../sdk/enums";
import {
  Keypair,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import { IPlatformGame } from "../types/game";
import { PlayerContext } from "./PlayerContext";
import { NetworkContext } from "./NetworkContext";
import { BalanceContext } from "./BalanceContext";
import { GAME_STATUS_TAKING_BETS, LAMPORT_TOPUP_AUTO_SIGNER, MIN_LAMPORTS_AUTO_SIGNER } from "../sdk/constants";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { useNavigate } from "react-router";
import { WrappedWalletContext } from "./WrappedWalletContext";
import CoinFlip from "../sdk/games/CoinFlip";
import { PlayerTokenContext } from "./PlayerTokenContext";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import BlackJack, { BlackjackInstance } from "../sdk/games/BlackJack";
import PlayerToken from "../sdk/playerToken";
import Slide from "../sdk/games/Slide";
import Plinko from "../sdk/games/Plinko";
import Limbo from "../sdk/games/Limbo";
import Dice from "../sdk/games/Dice";
import GameInstanceSolo from "../sdk/gameInstance";
import { SessionAuthorityContext } from "./SessionAuthorityContext";
import SlotsThree from "../sdk/games/SlotsThree";
import { useLocation } from "react-router-dom";
import Baccarat from "../sdk/games/Baccarat";
import { handleSendTransactionWithSigners } from "../utils/solana/utils";
import Jackpot from "../sdk/games/Jackpot";
import Tower, { TowerInstance } from "../sdk/games/Tower";

export interface IGameSpecValidation {
  takingBets: boolean;
}
export type GameInstance = BlackjackInstance | GameInstanceSolo;

interface ResultModalData {
  title?: string;
  multiplier?: number;
  payout?: number;
  isModalVisible?: boolean;
  hideTimeout?: number;
  tokenIcon?: string;
  modalBgColor?: string;
  shouldHideBackground?: boolean;
  className?: string;
  onModalClose?: () => void;
}
export interface IGameContext {
  gameSpec: GameSpec | undefined;
  gameInstance: BlackjackInstance | null;
  setGameInstance: React.Dispatch<React.SetStateAction<GameInstance | null>>
  loadGameInstance: () => void | Promise<void>;
  updateGameInstance: (
    gameInstance: GameInstance | null,
    betUpdate?: object,
    instanceUpdate?: object | null
  ) => void;
  gameSpecLoaded: boolean;
  gameConfig: IPlatformGame | undefined;
  initAndBet: (
    inputs: object,
    wager: number,
    clientSeed: Buffer,
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function,
    onUserRejected?: Function,
    secondsBeforeExpiry?: number,
    identifierPubkey?: PublicKey
  ) => Promise<string | undefined>;
  setResultModalData: (obj: ResultModalData) => void;
  resetResultModalData: Function;
  resultModalData: ResultModalData;
  gameInstancePubkey: PublicKey | null;
  setGameInstancePubKey: React.Dispatch<React.SetStateAction<PublicKey | null>>

}

export const GameContext = createContext<IGameContext>({} as IGameContext);

export const WIN_MODAL_ANIMATION_DURATION = 200;

export const loadAssociatedGameSpec = async (
  gameType: GameType,
  hse: House,
  gsPubkey: PublicKey
): Promise<GameSpec> => {
  switch (gameType) {
    case GameType.CoinFlip:
      const coinFlip = await CoinFlip.load(hse, gsPubkey);
      return coinFlip;
    case GameType.BlackJack:
      const blackJack = await BlackJack.load(hse, gsPubkey);
      return blackJack;
    case GameType.Slide:
      const slide = await Slide.load(hse, gsPubkey);
      return slide;
    case GameType.Jackpot:
      const jackpot = await Jackpot.load(hse, gsPubkey);
      return jackpot;
    case GameType.Tower:
      const tower = await Tower.load(hse, gsPubkey);
      return tower;
    case GameType.Plinko:
      const plinko = await Plinko.load(hse, gsPubkey);
      return plinko;
    case GameType.Limbo:
      const limbo = await Limbo.load(hse, gsPubkey);
      return limbo;
    case GameType.Dice:
      const dice = await Dice.load(hse, gsPubkey);
      return dice;
    case GameType.SlotsThree:
      const slots = await SlotsThree.load(hse, gsPubkey);
      return slots;
    case GameType.Baccarat:
      const baccarat = await Baccarat.load(hse, gsPubkey);
      return baccarat;
    default:
      throw new Error(`UNKNOWN GAME SPEC: ${gameType}`);
  }
};

export const loadAssociatedGameInstance = async (
  game: GameSpec,
  gameType: GameType,
  gsPubkey: PublicKey,
  playerToken?: PlayerToken,
  instancePubkey?: PublicKey,
  baseInstanceState?: any,
  erInstanceState?: any
) => {
  if (!instancePubkey) {
    return;
  }


  if (playerToken == null) {
    return
  }

  switch (gameType) {
    case GameType.BlackJack:
      const blackJackInstance = await BlackJack.loadInstance(
        game,
        gsPubkey,
        instancePubkey,
        playerToken,
        baseInstanceState,
        erInstanceState
      );
      return blackJackInstance;
    case GameType.Tower:
      const towerInstance = await Tower.loadInstance(
        game,
        gsPubkey,
        instancePubkey,
        playerToken,
        baseInstanceState,
        erInstanceState
      );
      return towerInstance;
    default:
      return GameInstanceSolo.load(instancePubkey, game, playerToken);
  }
};

export const initAssociatedGameInstance = (
  game: GameSpec,
  gameType: GameType,
  playerToken?: PlayerToken,
  instancePubkey?: PublicKey,
  baseState?: any,
  erState?: any
) => {
  switch (gameType) {
    case GameType.BlackJack:
      const blackJackInstance = new BlackjackInstance(
        game as BlackJack,
        instancePubkey,
        playerToken,
        baseState,
        erState
      );
      return blackJackInstance;
    case GameType.Tower:
        const towerInstance = new TowerInstance(
          game as Tower,
          instancePubkey,
          playerToken,
          baseState,
          erState
        );
        return towerInstance;
    default:
      console.log(
        `INSTANCE CLASS FOR FOLLOWING TYPE DOESN'T EXIST: ${gameType}`
      );
      return null;
  }
};

interface Props {
  gameSpecPubkeyString: string | undefined;
  children: any;
}

export const GameProvider = ({ gameSpecPubkeyString, children }: Props) => {
  const [gameSpec, setGameSpec] = useState<GameSpec>();
  const [gameInstance, setGameInstance] = useState<GameInstance | null>(null);
  const [gameInstancePubkey, setGameInstancePubKey] = useState<PublicKey | null>(null);

  // WILL BE NULL FOR ALL EXCEPT WHEN INSTANCE MUTLI OPEN
  const [gameInstanceMultiTokens, setGameInstanceMultiTokens] =
    useState<any[]>();

  const [gameSpecLoaded, setGameSpecLoaded] = useState(false);
  const { house, houseToken } = useContext(HouseContext);
  const { meta } = useContext(ProgramContext);

  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext);
  const { playerMeta, counter } = useContext(PlayerContext);
  const { client, recentBlockhash, networkCounter, erClient } =
    useContext(NetworkContext);
  const { signerKp, allowsAutoSigning, allowsAutoDeposit, lamportBalance } = useContext(
    SessionAuthorityContext
  );
  const { playerToken, loadPlayerToken, loadPlayerTokens } = useContext(PlayerTokenContext);

  const gameSpecPubkey = useMemo(() => {
    if (gameSpecPubkeyString == null) {
      return;
    }

    return new PublicKey(gameSpecPubkeyString);
  }, [gameSpecPubkeyString]);

  const { platformGames } = useContext(NetworkContext);

  const gameConfig = useMemo(() => {
    if (gameSpecPubkeyString == null) {
      return;
    }

    return platformGames.find((game) => {
      return game.gameSpecPubkey == gameSpecPubkeyString;
    });
  }, [gameSpecPubkeyString, platformGames]);

  const navigate = useNavigate();

  const loadGameInstance = async () => {
    if (!gameSpec || !playerToken || !playerToken?.state) {
      setGameInstance(null);
      return;
    }

    // 
    const instancePubkey = PlayerToken.deriveInstancePubkey(
      playerToken?.publicKey,
      0,
      playerToken?.baseProgram.programId
    );
    const gi = await loadAssociatedGameInstance(
      gameSpec,
      gameConfig.type,
      gameSpecPubkey,
      playerToken,
      instancePubkey
    );
    setGameInstance(gi);
  };
  const updateGameInstance = (
    gameInstance: GameInstance,
    betUpdate?: object,
    instanceUpdate?: object
  ) => {
    console.warn(`UPDATE GAME INSTANCE`, { gameInstance, betUpdate, instanceUpdate })
    if (
      !gameSpec ||
      !playerToken ||
      instanceUpdate === null ||
      !gameInstance ||
      !playerToken?.baseState
    ) {
      setGameInstance(null);
      return;
    }

    const instancePubkey = PlayerToken.deriveInstancePubkey(
      playerToken?.publicKey,
      0,
      playerToken?.baseProgram.programId
    );

    let newState: any;

    if (gameInstance && betUpdate) {
      const { betIdx, ...update } = betUpdate;
      const updatedBet = { ...gameInstance?.state?.bets[betIdx], ...update };
      newState = { ...gameInstance?.state };
      newState.bets[betIdx] = updatedBet;
      // console.log("UPDATE GAME INSTANCE PARTIALLY (bets)", { newState });
    }
    if (gameInstance && instanceUpdate) {
      newState = instanceUpdate
    }
    if (newState) {

      const gi = initAssociatedGameInstance(
        gameSpec,
        gameConfig.type,
        playerToken,
        instancePubkey,
        newState,
        undefined
      );

      console.log({
        gi
      })
      setGameInstance(gi);
    } else {
      setGameInstance(gameInstance);
    }
  };

  const loadedGameSpecPubkey = useRef<string>()

  useEffect(() => {
    async function loadGameInstanceState(gs: GameSpec, gameType: GameType, gsPubkey: PublicKey, pt: PlayerToken) {
      
      const instancePubkey = PlayerToken.deriveInstancePubkey(
        pt.publicKey,
        0,
        pt.baseProgram.programId
    );
      // load game instance
      const gi = await loadAssociatedGameInstance(
        gs,
        gameType,
        gsPubkey,
        pt,
        instancePubkey
      );

      setGameInstance(gi);
    }
    async function loadGameSpec(
      hse: House,
      gsPubkey: PublicKey,
      gameType: GameType
    ) {
      try {
        console.log("loadGameSpec", {
          gameType,
          gsPubkey: gsPubkey.toString(),
          house: hse,
          houseToken: houseToken,
          playerToken: playerToken,
        });
        const gs = await loadAssociatedGameSpec(gameType, hse, gsPubkey);
        setGameSpec(gs);

        if (playerToken != null) {
          await loadGameInstanceState(gs, gameType, gsPubkey, playerToken)
        } else {
          setGameInstance(null)
        }
        
        setGameSpecLoaded(true);
        loadedGameSpecPubkey.current = gsPubkey.toString()
      } catch (e) {
        console.warn(`Error loading the game spec.`, e);
        navigate(`/`);
      }
    }


    if (
      house == null ||
      meta == null ||
      gameSpecPubkey == null ||
      gameConfig == null
    ) {
      return;
    }

    // CHECK IF WE ALREADY HAVE THE GAME SPEC
    if (gameSpecPubkey.toString() == loadedGameSpecPubkey.current) {
      if (playerToken != null && gameSpec != null) {
        loadGameInstanceState(gameSpec, gameConfig.type as GameType, gameSpecPubkey, playerToken)
      }

      
      return
    }

    setGameSpec(undefined)
    setGameInstance(undefined)
    loadedGameSpecPubkey.current = undefined

    console.log(`<---- LOADING GAME SPEC AND INSTANCES ----->`)
    // TODO - GET PUBKEY AND TYPE FROM GAME_ID
    loadGameSpec(house, gameSpecPubkey, gameConfig.type as GameType);
  }, [house, houseToken, meta, gameSpecPubkey, gameConfig, playerToken]);

  // EITHER INIT AND BET SOLO OR INIT AND BET MULTI
  const initAndBet = useCallback(
    async (
      inputs: object,
      wager: number,
      clientSeed: Buffer,
      onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
      onErrorCallback?: Function,
      onUserRejected?: Function,
      secondsBeforeExpiry?: number
    ) => {
      if (walletPubkey == null || solanaRpc == null || gameSpec == null) {
        console.warn(
          "Issue with wallet pubkey or solana rpc.",
          walletPubkey,
          solanaRpc,
          gameSpec
        );
        return;
      }

      try {
        const sessionAuthorityKp =
          allowsAutoSigning == true
            ? Keypair.fromSecretKey(bs58.decode(signerKp))
            : undefined;

        const tx = new Transaction();

        // CHECK IF AUTO DEPOSIT ENABLED, AND WE NEED TO DEPOSIT TO THE PLAYER TOKEN ACCOUNT
        const playBalance = playerToken?.playBalance || 0;
        let needsWalletSigner = false;
        let creatingPlayerToken = false;

        if (allowsAutoDeposit) {
          if (playerToken?.baseState == null) {
            // NEED TO INIT
            const initIxn = await playerToken?.initializeIxn()
            tx.add(initIxn)

            needsWalletSigner = true
            creatingPlayerToken = true
          }

          if (allowsAutoDeposit == true && wager > playBalance) {
            const neededToDeposit = wager - playBalance;
            const depositIx = await playerToken.depositIxn(neededToDeposit);
            tx.add(depositIx);
            needsWalletSigner = true;
          }

          //CHECK IF WE NEED TO UPDATE THE SESSION AUTHORITY
          if (sessionAuthorityKp != undefined) {
            const needsUpdate = await playerToken?.needsSessionAuthorityUpdate(sessionAuthorityKp.publicKey, MIN_LAMPORTS_AUTO_SIGNER, lamportBalance)
            const lamportDifference = MIN_LAMPORTS_AUTO_SIGNER - lamportBalance

            if (needsUpdate) {
              tx.add(
                await playerToken.updateSessionAuthorityIxn(
                  sessionAuthorityKp.publicKey,
                  new Date(Date.now() + 86_400_000),
                  lamportDifference > 0 ? LAMPORT_TOPUP_AUTO_SIGNER : 0
                )
              );

              needsWalletSigner = true
            }
          } else if (allowsAutoSigning == false) {
            const needsUpdate = playerToken?.sessionAuthority != null && (playerToken?.sessionAuthority?.toString() != walletPubkey.toString())

            if (needsUpdate) {
              tx.add(
                await playerToken.updateSessionAuthorityIxn(
                  walletPubkey,
                  new Date(),
                  0
                )
              );

              needsWalletSigner = true
            }
          }
        }

        // ACTUAL WALLET WILL NEED TO SIGN IF DEPOSIT/INIT REQUIRED
        const usingSessionKeypair =
          sessionAuthorityKp != null && needsWalletSigner == false;
        const ownerOrAuth = usingSessionKeypair
          ? sessionAuthorityKp.publicKey
          : walletPubkey;

        const identifier = gameInstance?.identifier;

        // INIT GAME AND PLACE BET
        const initAndBetIx = gameSpec.isSolo
          ? await gameSpec.soloBetIx(
            ownerOrAuth,
            playerToken,
            inputs,
            wager,
            clientSeed,
            identifier
          )
          : await gameSpec.multiBetIx(
            ownerOrAuth,
            playerToken,
            inputs,
            wager,
            clientSeed,
            identifier
          );

        tx.add(initAndBetIx);

        // CLIENT TO USE
        let sig: string;

        if (playerToken?.houseToken.isDelegated == true) {
          // GET SIGNER FROM LOCAL STORAGE
          // TODO - ADD TOGGLE ON USING LOCAL KEYPAIR -- APPROVE AUTO
          const recent = await erClient?.getLatestBlockhash("confirmed");

          tx.recentBlockhash = recent?.blockhash;
          tx.feePayer = ownerOrAuth;

          sig = usingSessionKeypair
            ? await handleSendTransactionWithSigners(tx, erClient, sessionAuthorityKp.publicKey, [sessionAuthorityKp], recent)
            : await solanaRpc.sendTransaction(
              tx,
              erClient,
              walletPubkey,
              meta?.errorByCodeByProgram,
              recent?.blockhash
            );
        } else {
          const recent = await client?.getLatestBlockhash("confirmed");
          tx.recentBlockhash = recent?.blockhash;
          tx.feePayer = ownerOrAuth;

          sig = usingSessionKeypair
            ? await handleSendTransactionWithSigners(tx, client, sessionAuthorityKp.publicKey, [sessionAuthorityKp], recent)
            : await solanaRpc.sendTransaction(
              tx,
              client,
              walletPubkey,
              meta?.errorByCodeByProgram,
              recent?.blockhash
            );
        }

        console.error(`GOT THE SIG ${sig}`, { cl: client })

        if (onSuccessfulSendCallback) {
          onSuccessfulSendCallback(sig);
        }

        // IF CREATING THE PLAYER TOKEN, LOAD IT FOR CONTEXT
        if (creatingPlayerToken == true) {
          if (playerToken?.houseToken.isDelegated == true) {
            await erClient?.confirmTransaction(sig, "confirmed");
          } else {
            await client?.confirmTransaction(sig, "confirmed");
          }

          await loadPlayerToken();
        }

        return sig;
      } catch (err) {
        // PUT IN FOR CRUDE TESTING OF ISSUES, TO BE REMOVED
        console.log({
          err
        })
        if (err == null || err?.message == "User rejected the request." || err?.name == "WalletSignTransactionError") {
          if (onUserRejected != null) {
            onUserRejected();
          }
          return;
        }

        console.warn("Error placing bet", err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      }
    },
    [
      solanaRpc,
      walletPubkey,
      counter,
      gameSpec,
      client,
      erClient,
      signerKp,
      lamportBalance,
      meta,
      house,
      playerMeta,
      recentBlockhash,
      networkCounter,
      playerToken,
      loadPlayerToken,
      loadPlayerTokens,
      allowsAutoSigning,
      allowsAutoDeposit,
    ]
  );

  // VALIDATIONS
  const { selectedTokenMeta } = useContext(BalanceContext);
  const [validation, setValidation] = useState<IGameSpecValidation>();

  useEffect(() => {
    // ONLY VALIDATE IF GAME SPEC AND SELECTED TOKEN IN CONTEXT
    if (gameSpec == null || selectedTokenMeta == null) {
      return;
    }

    setValidation({
      takingBets:
        gameSpec.status != null &&
        GAME_STATUS_TAKING_BETS.includes(gameSpec.status),
    });
  }, [gameSpec, selectedTokenMeta]);

  const { gameValidation } = useContext(ErrorHandlingContext);
  useEffect(() => {
    if (gameSpec == null || validation == null) {
      return;
    }

    if (validation.takingBets == false) {
      gameValidation.addErrorMessage({
        type: ErrorType.GAME_NOT_ACTIVE,
        title: "Game not active",
        message: "The game is not currently taking bets.",
      });
    } else {
      gameValidation.removeErrorMessage(ErrorType.GAME_NOT_ACTIVE);
    }
  }, [gameSpec, validation]);

  const resultModalDefaultData = {
    title: "Payout",
    isModalVisible: false,
    multiplier: 0,
    payout: 0,
    hideTimeout: 3000,
    tokenIcon: "usdc",
    shouldHideBackground: false,
    className: "",
    onModalClose: () => { },
  };
  const [resultModalData, setResultModalData] = useState<ResultModalData>(
    resultModalDefaultData
  );
  const location = useLocation();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (resultModalData?.isModalVisible) {
      const id = Math.random() * 10;
      timeoutId = setTimeout(
        () => {
          setResultModalData((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));

          setTimeout(() => {
            resultModalData.onModalClose?.();
            setResultModalData(resultModalDefaultData);
          }, WIN_MODAL_ANIMATION_DURATION);
        },
        (resultModalData.hideTimeout || resultModalDefaultData.hideTimeout) -
        WIN_MODAL_ANIMATION_DURATION
      );
    }
    return () => {
      if (resultModalData?.isModalVisible && timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [resultModalData, setResultModalData]);

  useEffect(() => {
    setResultModalData(resultModalDefaultData);
  }, [location]);

  return (
    <GameContext.Provider
      value={useMemo(
        () => ({
          gameSpec: gameSpec,
          gameInstance: gameInstance,
          updateGameInstance: updateGameInstance,
          loadGameInstance: loadGameInstance,
          gameSpecLoaded: gameSpecLoaded,
          gameConfig: gameConfig,
          initAndBet: initAndBet,
          setResultModalData: (newResultModalData: ResultModalData) => {
            setResultModalData((prevResultModalData) => ({
              ...prevResultModalData,
              ...newResultModalData,
            }));
          },
          resetResultModalData: () =>
            setResultModalData(resultModalDefaultData),
          resultModalData,
          gameInstancePubkey,
          setGameInstancePubKey,
          setGameInstance: setGameInstance
        }),
        [
          gameSpec,
          gameConfig,
          initAndBet,
          gameInstance,
          gameSpecLoaded,
          resultModalData,
          gameInstancePubkey,
          setGameInstancePubKey
        ]
      )}
    >
      {children}
    </GameContext.Provider>
  );
};