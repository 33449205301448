import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AnchorProvider, Idl, Program } from "@coral-xyz/anchor";
import { NetworkContext } from "./NetworkContext";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import {
  RANDOM_PROGRAM_PUBKEY,
  ZEEBIT_V2_PROGRAM,
} from "../sdk/constants";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { IdlErrorCode } from "@project-serum/anchor/dist/cjs/idl";
import { IDL } from '../../src/sdk/zeebitV2'
import { getRpcReadEndpoint } from "../utils/env/env";
import { APP_NETWORK_TYPE } from "../types/chain";
export interface IProgramContext {
  meta: IProgramMeta | undefined;
}
export interface IProgramMeta {
  anchorProvider: AnchorProvider | undefined;
  zeebitV2Program: Program | undefined;
  zeebitV2ErProgram: Program | undefined;
  randomnessProgram: Program | undefined;
  errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>;
}

export const ProgramContext = createContext<IProgramContext>({} as IProgramContext);

interface Props {
  children: any;
}

const toProgramIdlErrorByCode = (program: Program<Idl> | undefined): Map<number, IdlErrorCode> => {
  return (
    program?.idl?.errors?.reduce((result, item) => {
      result.set(item.code, item);

      return result;
    }, new Map<number, IdlErrorCode>()) || new Map()
  );
};

export const ProgramProvider = ({ children }: Props) => {
  const { client, erClient } = useContext(NetworkContext);
  const [meta, setMeta] = useState<IProgramMeta>();

  useEffect(() => {
    async function loadProgramMeta() {
      try {
        const baseClientUrl = getRpcReadEndpoint(APP_NETWORK_TYPE.SOLANA)
        const baseClient = new Connection(baseClientUrl, {
          commitment: "processed",
        })

        const baseProvider = new AnchorProvider(baseClient, new NodeWallet(new Keypair()), {
          commitment: baseClient.commitment,
          preflightCommitment: baseClient.commitment,
          skipPreflight: false,
        });
        
        const provider = new AnchorProvider(client, new NodeWallet(new Keypair()), {
          commitment: client.commitment,
          preflightCommitment: client.commitment,
          skipPreflight: false,
        });
        const erProvider = new AnchorProvider(erClient, new NodeWallet(new Keypair()), {
          commitment: erClient.commitment,
          preflightCommitment: erClient.commitment,
          skipPreflight: false,
        });

        const zeebitV2Program = new Program(IDL, ZEEBIT_V2_PROGRAM, provider)
        const zeebitV2ErProgram = new Program(IDL, ZEEBIT_V2_PROGRAM, erProvider)

        const randomnessPro = await loadProgram(baseProvider, RANDOM_PROGRAM_PUBKEY);

        const errorByCodeByProgram = [zeebitV2Program, randomnessPro].reduce(
          (result, item) => {
            if (item != null) {
              result.set(item?.programId.toString(), toProgramIdlErrorByCode(item));
            }

            return result;
          },
          new Map<string, Map<number, IdlErrorCode>>(),
        );

        setMeta({
          anchorProvider: provider,
          randomnessProgram: randomnessPro,
          zeebitV2Program: zeebitV2Program,
          zeebitV2ErProgram: zeebitV2ErProgram,
          errorByCodeByProgram: errorByCodeByProgram,
        });
      } catch (e) {
        console.warn(`Issue loading the program meta.`, e);
      }
    }

    async function loadProgram(provider: AnchorProvider, programPubkey: PublicKey) {
      try {
        const idl = await Program.fetchIdl(programPubkey, provider);
        const program = new Program(idl, programPubkey, provider);

        return program;
      } catch (e) {
        console.warn(`Issue loading program.`, { e }, programPubkey.toString());
      }
    }

    if (client == null) {
      return;
    }

    loadProgramMeta();
  }, [client]);

  return (
    <ProgramContext.Provider
      value={useMemo(
        () => ({
          meta: meta,
        }),
        [meta],
      )}
    >
      {children}
    </ProgramContext.Provider>
  );
};
