import Game from "../gameSpec";
import * as anchor from "@coral-xyz/anchor";
import { Commitment, PublicKey } from "@solana/web3.js";

import House from "../house";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";
import PlayerToken from "../playerToken";

export type RouletteBet = {
  type: RouletteBetType;
  idx: number | Column | Dozen | RedBlack | OddEven | LowHigh;
  wager: number;
  wagerBasis: number;
};

export enum RouletteBetType {
  STRAIGHT = "straight",
  STREET = "street",
  COLUMN = "column",
  DOZEN = "dozen",
  RED_BLACK = "redBlack",
  ODD_EVEN = "oddEven",
  LOW_HIGH = "lowHigh",
}

export enum Column {
  FIRST = 0,
  SECOND = 1,
  THIRD = 2,
}

export enum Dozen {
  FIRST = 0,
  SECOND = 1,
  THIRD = 2,
}

export enum RedBlack {
  RED = 0,
  BLACK = 1,
}

export enum OddEven {
  ODD = 0,
  EVEN = 1,
}

export enum LowHigh {
  LOW = 0,
  HIGh = 1,
}

export interface RouletteInputs {
  maxBets: number,
  bets: {
    type: string;
    idx: string;
    wager: number; wagerBasis: number;
  }[],
}

export default class Roulette extends Game {
  constructor(
    house: House,
    // hseTkn: HouseToken,
    gameSpecPubkey: PublicKey
  ) {
    super(house, gameSpecPubkey);
  }

  static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateUpdates: boolean = false) {
    const game = new Roulette(house, gameSpecPubkey);
    console.log({ game });

    await game.loadAllState(commitmentLevel, loadChildState, trackStateUpdates)
    return game;
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: RouletteInputs,
    wager: number,
    clientSeed: number[]
  ) {
    const instanceRequest = {
      roulette: {},
    };

    var betsPrepared: any[] = [];
    inputs.bets.forEach((bet) => {
      betsPrepared.push({
        selection: {
          [bet.type]: {
            idx: bet.idx,
          },
        },
        wager: new anchor.BN(bet.wagerBasis),
      });
    });

    // const betRequests = {
    //   roulette: {
    //     bets: betsPrepared,
    //   },
    // };

    const betRequests = inputs.bets.map((bet) => (
      {
        roulette: {
          selection: {
            [bet.type]: {
              idx: bet.idx,
            },
          },
          wager: new anchor.BN(bet.wagerBasis),
        }
      }
    ));

    const numberOfBets = betsPrepared.length;

    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      null,
      0,
      clientSeed
    );
  }
  get state() {
    return this.baseState
  }
  get gameConfig() {
    return this.state ? this.state.config.roulette : null;
  }
  get maxNumberOfBets() {
    return this.gameConfig ? this.gameConfig.maxBets : null;
  }

  get isAmerican() {
    return this.gameConfig ? this.gameConfig.isAmerican : null;
  }

  getCellsForSelection(
    betType: RouletteBetType,
    idx: number | Column | Dozen | RedBlack | OddEven | LowHigh,
  ): number[] {
    switch (betType) {
      case RouletteBetType.STRAIGHT: {
        return [idx];
      }
      case RouletteBetType.STREET: {
        switch (idx) {
          case 0: {
            return [1, 2, 3];
          }
          case 1: {
            return [4, 5, 6];
          }
          case 2: {
            return [7, 8, 9];
          }
          case 3: {
            return [10, 11, 12];
          }
          case 4: {
            return [13, 14, 15];
          }
          case 5: {
            return [16, 17, 18];
          }
          case 6: {
            return [19, 20, 21];
          }
          case 7: {
            return [22, 23, 24];
          }
          case 8: {
            return [25, 26, 27];
          }
          case 9: {
            return [28, 29, 30];
          }
          case 10: {
            return [31, 32, 33];
          }
          case 11: {
            return [34, 35, 36];
          }
        }
      }
      case RouletteBetType.COLUMN: {
        switch (idx) {
          case 0: {
            return [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];
          }
          case 1: {
            return [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
          }
          case 2: {
            return [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36];
          }
        }
      }
      case RouletteBetType.DOZEN: {
        switch (idx) {
          case 0: {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          }
          case 1: {
            return [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
          }
          case 2: {
            return [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
          }
        }
      }
      case RouletteBetType.RED_BLACK: {
        switch (idx) {
          case 0: {
            return [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
          }
          case 1: {
            return [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];
          }
        }
      }
      case RouletteBetType.ODD_EVEN: {
        switch (idx) {
          case 0: {
            return [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35];
          }
          case 1: {
            return [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36];
          }
        }
      }
      case RouletteBetType.LOW_HIGH: {
        switch (idx) {
          case 0: {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
          }
          case 1: {
            return [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
          }
        }
      }
      default:
        return [];
    }
  }

  // TODO - UPDATE MULTIPLIER
  getMultiplier(inputs: object): number {
    return 10;
  }

  getProbability(inputs: object): number {
    return 0.1;
  }

  getMultiplierForBetType(betType: RouletteBetType): number {
    switch (betType) {
      case RouletteBetType.STRAIGHT:
        return 36.0;
      case RouletteBetType.STREET:
        return 12.0;
      case RouletteBetType.COLUMN:
        return 3.0;
      case RouletteBetType.DOZEN:
        return 3.0;
      case RouletteBetType.RED_BLACK:
        return 2.0;
      case RouletteBetType.ODD_EVEN:
        return 2.0;
      case RouletteBetType.LOW_HIGH:
        return 2.0;
      default:
        throw new Error("Unknown bet type for roulette multiplier.");
    }
  }

  getProbabilityForBetType(betType: RouletteBetType, isAmerican: boolean = false): number {
    switch (betType) {
      case RouletteBetType.STRAIGHT:
        return isAmerican ? 1 / 38 : 1 / 37;
      case RouletteBetType.STREET:
        return isAmerican ? 3 / 38 : 3 / 37;
      case RouletteBetType.COLUMN:
        return isAmerican ? 12 / 38 : 12 / 37;
      case RouletteBetType.DOZEN:
        return isAmerican ? 12 / 38 : 12 / 37;
      case RouletteBetType.RED_BLACK:
        return isAmerican ? 18 / 38 : 18 / 37;
      case RouletteBetType.ODD_EVEN:
        return isAmerican ? 18 / 38 : 18 / 37;
      case RouletteBetType.LOW_HIGH:
        return isAmerican ? 18 / 38 : 18 / 37;
      default:
        console.warn("UNKNOWN BET TYPE", betType);
        throw new Error("Unknown bet type for roulette probability.");
    }
  }

  getTotalWagerForBets(bets: RouletteBet[]): number {
    const factor = Math.pow(10, 6);
    const unroundedWager = bets.map((bet) => bet.wager).reduce((a, c) => a + c, 0);

    return Math.round(unroundedWager * factor) / factor;
  }

  getMaxPayoutForBets(bets: RouletteBet[]): number {
    var payoutGrid = Array<number>(38).fill(0);
    bets.forEach((bet) => {
      let payout = this.getMultiplierForBetType(bet.type) * bet.wager;
      let cells = this.getCellsForSelection(bet.type, Number(bet.idx));
      cells.forEach((i) => {
        payoutGrid[i] += payout;
      });
    });
    return Math.max(...payoutGrid);
  }

  getBetMetas(bets: object[]) {
    const isAmerican = false; // TODO - GET FROM GAME CONFIG
    let totalPayout = this.getMaxPayoutForBets(bets);
    let totalWager = this.getTotalWagerForBets(bets);
    let totalProfit = totalPayout - totalWager;
    let dollarEdge = bets.reduce((result, bet) => {
      const probability = this.getProbabilityForBetType(bet.type, isAmerican);
      const multiplier = this.getMultiplierForBetType(bet.type);
      const dollarOnBet = (1 - probability * multiplier) * bet.wager;
      result += dollarOnBet;

      return result;
    }, 0);

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      dollarEdge: dollarEdge,
      edgePercentage: dollarEdge / totalWager,
    };
  }
}
