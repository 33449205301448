import IconButton from "../common/button/IconButton";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { WrappedWalletContext, NavContext } from "../../contexts";

export const BottomNav = () => {
  const navigate = useNavigate();
  const {
    leftPanelOpen,
    toggleLeftPanel,
    closeAllBars,
    toggleUserProfilePanel,
    userProfilePanelOpen,
  } = useContext(NavContext);
  const { loggedIn } = useContext(WrappedWalletContext);

  return (
    <div className="z-40 flex fixed md:hidden w-full bottom-0 h-top-bar-height bg-gray-800 items-center justify-between px-4 bottom-nav-shadow">
      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="hamburger_sm"
        onClick={() => {
          if (leftPanelOpen == true) {
            toggleLeftPanel(false);
          } else {
            closeAllBars();
            toggleLeftPanel(true);
          }
        }}
      />
      {loggedIn ? (
        <IconButton
          onClick={() => {
            if (userProfilePanelOpen == true) {
              toggleUserProfilePanel(false);
            } else {
              closeAllBars();
              toggleUserProfilePanel(true);
            }
          }}
          className="bg-gray-800"
          size="lg"
          iconName="user"
        />
      ) : (
        ""
      )}
      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="home"
        onClick={() => {
          closeAllBars();
          navigate(``);
        }}
      />
    </div>
  );
};
