export type ZeebitV2 = {
  "version": "0.1.0",
  "name": "zeebit_v2",
  "instructions": [
    {
      "name": "airdrop",
      "accounts": [
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "AirdropArgs"
          }
        }
      ]
    },
    {
      "name": "houseInitialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "oracleList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseInitArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenInitialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenInitArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenUpdate",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenUpdateArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenBankUpdate",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenBankUpdateArgs"
          }
        }
      ]
    },
    {
      "name": "houseOracleListAddOrUpdate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "oracleList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "oracle",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseOracleListAddOrUpdateArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenInit",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenInitArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenClose",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenCloseArgs"
          }
        }
      ]
    },
    {
      "name": "lpInit",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpInitArgs"
          }
        }
      ]
    },
    {
      "name": "gameSpecInit",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "GameSpecInitArgs"
          }
        }
      ]
    },
    {
      "name": "gameSpecConfig",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "GameSpecConfigArgs"
          }
        }
      ]
    },
    {
      "name": "gameSpecUpdate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "GameSpecUpdateArgs"
          }
        }
      ]
    },
    {
      "name": "gameSpecTokenInit",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "GameSpecTokenInitArgs"
          }
        }
      ]
    },
    {
      "name": "lpDeposit",
      "accounts": [
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpDepositArgs"
          }
        }
      ]
    },
    {
      "name": "lpWithdrawal",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpWithdrawalArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenDeposit",
      "accounts": [
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenDepositArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenWithdraw",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenWithdrawArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenUpdateSessionAuthority",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "sessionAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenUpdateSessionAuthorityArgs"
          }
        }
      ]
    },
    {
      "name": "soloPlay",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SoloPlayArgs"
          }
        }
      ]
    },
    {
      "name": "soloAction",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SoloActionArgs"
          }
        }
      ]
    },
    {
      "name": "soloRespond",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "oracleList",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SoloRespondArgs"
          }
        }
      ]
    },
    {
      "name": "soloVoid",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SoloVoidArgs"
          }
        }
      ]
    },
    {
      "name": "multiPlay",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instanceToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "MultiPlayArgs"
          }
        }
      ]
    },
    {
      "name": "multiRespond",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "oracleList",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "MultiRespondArgs"
          }
        }
      ]
    },
    {
      "name": "multiSettle",
      "accounts": [
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instanceToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "MultiSettleArgs"
          }
        }
      ]
    },
    {
      "name": "syncAccounts",
      "accounts": [],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SyncAccountsArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenDelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The pda to delegate"
          ]
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "instanceSoloDelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instanceSolo",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InstanceSoloDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "instanceSoloClose",
      "accounts": [
        {
          "name": "owner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instanceSolo",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InstanceSoloCloseArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenDelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "updateSlipPredelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "relatedAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "UpdateSlipPredelegateArgs"
          }
        }
      ]
    },
    {
      "name": "updateSlipUndelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "UpdateSlipUpdelegateArgs"
          }
        }
      ]
    },
    {
      "name": "updateSlipClose",
      "accounts": [
        {
          "name": "rentRecipient",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "UpdateSlipCloseArgs"
          }
        }
      ]
    },
    {
      "name": "lpWithdrawInitialize",
      "accounts": [
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "liquidityProvider",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpWithdrawInitializeArgs"
          }
        }
      ]
    },
    {
      "name": "lpWithdrawApply",
      "accounts": [
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rentRecipient",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpWithdrawApplyArgs"
          }
        }
      ]
    },
    {
      "name": "lpDepositInitialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpDepositInitializeArgs"
          }
        }
      ]
    },
    {
      "name": "lpDepositApply",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpDepositApplyArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenDepositInitialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenDepositInitializeArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenDepositApply",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenDepositApplyArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenWithdrawInitialize",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenWithdrawInitializeArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenWithdrawApply",
      "accounts": [
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rentRecipient",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenWithdrawApplyArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenFreezeOrUndelegate",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenFreezeOrUndelegateArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenFreezeOrUndelegate",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenFreezeOrUndelegateArgs"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "gameSpecToken",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gameSpec",
            "type": "publicKey"
          },
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "tokenMint",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "GameSpecTokenStatus"
            }
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "availableInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "activeInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "nextInstanceAccountIdx",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "gameSpec",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "game",
            "type": {
              "defined": "Game"
            }
          },
          {
            "name": "status",
            "type": {
              "defined": "GameSpecStatus"
            }
          },
          {
            "name": "specType",
            "type": {
              "defined": "GameSpecType"
            }
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "updateNonce",
            "type": "u64"
          },
          {
            "name": "availableInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "activeInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "nextInstanceAccountIdx",
            "type": "u8"
          },
          {
            "name": "pairedHouseTokens",
            "type": "u16"
          },
          {
            "name": "minWagerInHouseTokenUnits",
            "type": "u64"
          },
          {
            "name": "maxBetsPerInstanceToken",
            "type": "u8"
          },
          {
            "name": "maxBetsPerPlaceIxn",
            "type": "u8"
          },
          {
            "name": "maxBetsPerSettleIxn",
            "type": "u8"
          },
          {
            "name": "maxTokensPerInstance",
            "type": "u8"
          },
          {
            "name": "maxPlayersPerToken",
            "type": "u8"
          },
          {
            "name": "inactivityTimeoutSeconds",
            "type": "u16"
          },
          {
            "name": "callbackRespondDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "callbackExpireDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "callbackSettleDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "interval",
            "type": {
              "defined": "GameSpecInterval"
            }
          },
          {
            "name": "config",
            "type": {
              "defined": "GameSpecConfig"
            }
          }
        ]
      }
    },
    {
      "name": "houseOracleList",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "approvedOracles",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "houseTokenBank",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "tokenMint",
            "type": "publicKey"
          },
          {
            "name": "vault",
            "type": "publicKey"
          },
          {
            "name": "lpIsPermissioned",
            "type": "bool"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "activeLps",
            "type": "u64"
          },
          {
            "name": "activePlayerTokens",
            "type": "u64"
          },
          {
            "name": "balance",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "houseToken",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "tokenMint",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "HouseTokenStatus"
            }
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "incrementUnit",
            "type": "u64"
          },
          {
            "name": "outstandingLpTokens",
            "type": "u64"
          },
          {
            "name": "activeBets",
            "type": "u16"
          },
          {
            "name": "availableBalance",
            "type": "u64"
          },
          {
            "name": "lockedBalance",
            "type": "u64"
          },
          {
            "name": "playerBalance",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "house",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u64"
          },
          {
            "name": "stateVersion",
            "type": "u64"
          },
          {
            "name": "oracleList",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "HouseStatus"
            }
          },
          {
            "name": "countTokens",
            "type": "u16"
          },
          {
            "name": "countGames",
            "type": "u16"
          }
        ]
      }
    },
    {
      "name": "instanceMulti",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gameSpec",
            "type": "publicKey"
          },
          {
            "name": "identifier",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "InstanceStatus"
            }
          },
          {
            "name": "interactionNonce",
            "type": "u16"
          },
          {
            "name": "openInstanceTokens",
            "type": "u8"
          },
          {
            "name": "openBets",
            "type": "u16"
          },
          {
            "name": "oracleInfo",
            "type": {
              "defined": "OracleInfo"
            }
          },
          {
            "name": "risk",
            "type": {
              "defined": "GameInstanceRisk"
            }
          },
          {
            "name": "result",
            "type": {
              "defined": "GameInstanceResult"
            }
          },
          {
            "name": "state",
            "type": {
              "defined": "GameInstanceState"
            }
          }
        ]
      }
    },
    {
      "name": "instanceSolo",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gameSpec",
            "type": "publicKey"
          },
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "playerToken",
            "type": "publicKey"
          },
          {
            "name": "identifier",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "InstanceStatus"
            }
          },
          {
            "name": "interactionNonce",
            "type": "u16"
          },
          {
            "name": "openBets",
            "type": "u16"
          },
          {
            "name": "oracleInfo",
            "type": {
              "defined": "OracleInfo"
            }
          },
          {
            "name": "risk",
            "type": {
              "defined": "GameInstanceRisk"
            }
          },
          {
            "name": "result",
            "type": {
              "defined": "GameInstanceResult"
            }
          },
          {
            "name": "state",
            "type": {
              "defined": "GameInstanceState"
            }
          },
          {
            "name": "bets",
            "type": {
              "vec": {
                "defined": "Bet"
              }
            }
          }
        ]
      }
    },
    {
      "name": "instanceTokenMulti",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "instance",
            "type": "publicKey"
          },
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "gameSpecToken",
            "type": "publicKey"
          },
          {
            "name": "openBets",
            "type": "u16"
          },
          {
            "name": "risk",
            "type": {
              "defined": "GameInstanceRisk"
            }
          },
          {
            "name": "bets",
            "type": {
              "vec": {
                "defined": "Bet"
              }
            }
          }
        ]
      }
    },
    {
      "name": "liquidityProvider",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "lpTokens",
            "type": "u64"
          },
          {
            "name": "netDeposits",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "permission",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "PermissionStatus"
            }
          },
          {
            "name": "isSuperAuthority",
            "type": "bool"
          },
          {
            "name": "reserved0",
            "type": "bool"
          },
          {
            "name": "reserved1",
            "type": "bool"
          },
          {
            "name": "reserved2",
            "type": "bool"
          },
          {
            "name": "reserved3",
            "type": "bool"
          },
          {
            "name": "reserved4",
            "type": "bool"
          },
          {
            "name": "reserved5",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "playerToken",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "platform",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "availableBalance",
            "type": "u64"
          },
          {
            "name": "lockedBalance",
            "type": "u64"
          },
          {
            "name": "activeBets",
            "type": "u16"
          },
          {
            "name": "activeInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "lastActivity",
            "type": "i64"
          },
          {
            "name": "sessionAuthority",
            "type": "publicKey"
          },
          {
            "name": "sessionAuthorityExpires",
            "type": "i64"
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "sessionExpires",
            "type": "i64"
          },
          {
            "name": "allocatedInstanceAccounts",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "updateSlip",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "status",
            "type": {
              "defined": "UpdateStatus"
            }
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "timestamp",
            "type": "i64"
          },
          {
            "name": "rentRecipient",
            "type": "publicKey"
          },
          {
            "name": "update",
            "type": {
              "defined": "Update"
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "SoloVoidArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "MultiPlayArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "instanceIdx",
            "type": "u8"
          },
          {
            "name": "instanceRequest",
            "type": {
              "defined": "GameInstanceRequest"
            }
          },
          {
            "name": "betRequests",
            "type": {
              "vec": {
                "defined": "BetRequest"
              }
            }
          },
          {
            "name": "clientSeed",
            "type": {
              "array": [
                "u8",
                4
              ]
            }
          }
        ]
      }
    },
    {
      "name": "MultiRespondArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "response",
            "type": {
              "defined": "OracleResponse"
            }
          }
        ]
      }
    },
    {
      "name": "MultiSettleArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "manualSettleOrder",
            "type": {
              "option": "bytes"
            }
          }
        ]
      }
    },
    {
      "name": "SoloActionArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "actionRequest",
            "type": {
              "defined": "ActionRequest"
            }
          },
          {
            "name": "clientSeed",
            "type": {
              "array": [
                "u8",
                4
              ]
            }
          },
          {
            "name": "withdrawOnSettle",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "SoloPlayArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "instanceIdx",
            "type": "u8"
          },
          {
            "name": "instanceRequest",
            "type": {
              "defined": "GameInstanceRequest"
            }
          },
          {
            "name": "betRequests",
            "type": {
              "vec": {
                "defined": "BetRequest"
              }
            }
          },
          {
            "name": "clientSeed",
            "type": {
              "array": [
                "u8",
                4
              ]
            }
          },
          {
            "name": "withdrawOnSettle",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "SoloRespondArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "response",
            "type": {
              "defined": "OracleResponse"
            }
          }
        ]
      }
    },
    {
      "name": "TowerPaytable",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "columns",
            "type": "u8"
          },
          {
            "name": "safeSteps",
            "type": "u8"
          },
          {
            "name": "multiplierPerMillion",
            "type": "u32"
          },
          {
            "name": "edgePerMillion",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "KenoMultipliers",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "numSelected",
            "type": "u8"
          },
          {
            "name": "edgePerMillion",
            "type": "u32"
          },
          {
            "name": "multipliersPerMillion",
            "type": {
              "vec": "u32"
            }
          }
        ]
      }
    },
    {
      "name": "CoinFlipMultiplier",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "numCorrect",
            "type": "u8"
          },
          {
            "name": "numTossed",
            "type": "u8"
          },
          {
            "name": "probabilityPerMillion",
            "type": "u32"
          },
          {
            "name": "multiplierPerMillion",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "PlinkoPaytable",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "numRows",
            "type": "u8"
          },
          {
            "name": "difficultyLevel",
            "type": "u8"
          },
          {
            "name": "maxNumBalls",
            "type": "u8"
          },
          {
            "name": "edgePerMillion",
            "type": "u64"
          },
          {
            "name": "multipliersPerMillion",
            "type": {
              "vec": "u32"
            }
          }
        ]
      }
    },
    {
      "name": "BaccaratHandResult",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "playerCards",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          },
          {
            "name": "bankerCards",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          },
          {
            "name": "result",
            "type": {
              "defined": "BaccaratSide"
            }
          }
        ]
      }
    },
    {
      "name": "HouseTokenDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "commitFrequencyMs",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "HouseTokenFreezeOrUndelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "InstanceSoloCloseArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "InstanceSoloDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "instanceIdx",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "LpDepositApplyArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "LpDepositInitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "LpWithdrawApplyArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "LpWithdrawInitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenCloseInstanceSolosArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenDepositApplyArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenDepositInitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenFreezeOrUndelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenWithdrawApplyArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenWithdrawInitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "SyncAccountsArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "UpdateSlipCloseArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "UpdateSlipPredelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "UpdateSlipUpdelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "AirdropArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "GameSpecConfigArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "config",
            "type": {
              "defined": "GameSpecConfig"
            }
          }
        ]
      }
    },
    {
      "name": "GameSpecInitArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "game",
            "type": {
              "defined": "Game"
            }
          },
          {
            "name": "specType",
            "type": {
              "defined": "GameSpecType"
            }
          },
          {
            "name": "interval",
            "type": {
              "defined": "GameSpecInterval"
            }
          },
          {
            "name": "minWagerInHouseTokenUnits",
            "type": "u64"
          },
          {
            "name": "maxBetsPerInstanceToken",
            "type": "u8"
          },
          {
            "name": "maxBetsPerPlaceIxn",
            "type": "u8"
          },
          {
            "name": "maxBetsPerSettleIxn",
            "type": "u8"
          },
          {
            "name": "maxPlayersPerToken",
            "type": "u8"
          },
          {
            "name": "maxTokensPerInstance",
            "type": "u8"
          },
          {
            "name": "inactivityTimeoutSeconds",
            "type": "u16"
          },
          {
            "name": "callbackRespondDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "callbackSettleDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "callbackExpireDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          }
        ]
      }
    },
    {
      "name": "GameSpecTokenInitArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "status",
            "type": {
              "defined": "GameSpecTokenStatus"
            }
          }
        ]
      }
    },
    {
      "name": "GameSpecUpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "status",
            "type": {
              "option": {
                "defined": "GameSpecStatus"
              }
            }
          },
          {
            "name": "interval",
            "type": {
              "option": {
                "defined": "GameSpecInterval"
              }
            }
          },
          {
            "name": "minWagerInHouseTokenUnits",
            "type": {
              "option": "u64"
            }
          },
          {
            "name": "maxBetsPerInstanceToken",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "maxBetsPerPlaceIxn",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "maxBetsPerSettleIxn",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "maxPlayersPerToken",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "maxTokensPerInstance",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "inactivityTimeoutSeconds",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "callbackRespondDiscriminator",
            "type": {
              "option": {
                "array": [
                  "u8",
                  8
                ]
              }
            }
          },
          {
            "name": "callbackSettleDiscriminator",
            "type": {
              "option": {
                "array": [
                  "u8",
                  8
                ]
              }
            }
          },
          {
            "name": "callbackExpireDiscriminator",
            "type": {
              "option": {
                "array": [
                  "u8",
                  8
                ]
              }
            }
          }
        ]
      }
    },
    {
      "name": "HouseInitArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "HouseOracleListAddOrUpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "remove",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "HouseTokenBankUpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "lpIsPermissioned",
            "type": {
              "option": "bool"
            }
          }
        ]
      }
    },
    {
      "name": "HouseTokenInitArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "incrementUnit",
            "type": "u64"
          },
          {
            "name": "lpIsPermissioned",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "HouseTokenUpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "status",
            "type": {
              "option": {
                "defined": "HouseTokenStatus"
              }
            }
          },
          {
            "name": "incrementUnit",
            "type": {
              "option": "u64"
            }
          }
        ]
      }
    },
    {
      "name": "LpDepositArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "LpInitArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "LpWithdrawalArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenCloseArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenDepositArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "depositAmount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenInitArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenUpdateSessionAuthorityArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "validUntil",
            "type": "i64"
          },
          {
            "name": "lamportTopUp",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenWithdrawArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "withdrawalAmount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "BetResult",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "idx",
            "type": "u16"
          },
          {
            "name": "state",
            "type": {
              "defined": "BetState"
            }
          },
          {
            "name": "outcome",
            "type": {
              "defined": "BetStatus"
            }
          },
          {
            "name": "payout",
            "type": "u64"
          },
          {
            "name": "playerLiabilityReleased",
            "type": "u64"
          },
          {
            "name": "houseLiabilityReleased",
            "type": "u64"
          },
          {
            "name": "playerDelta",
            "type": "i128"
          },
          {
            "name": "houseDelta",
            "type": "i128"
          },
          {
            "name": "playerToken",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "Bet",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "idx",
            "type": "u16"
          },
          {
            "name": "status",
            "type": {
              "defined": "BetStatus"
            }
          },
          {
            "name": "playerLocked",
            "type": "u64"
          },
          {
            "name": "houseLocked",
            "type": "u64"
          },
          {
            "name": "state",
            "type": {
              "defined": "BetState"
            }
          },
          {
            "name": "extension",
            "type": {
              "defined": "BetExtension"
            }
          }
        ]
      }
    },
    {
      "name": "CallbackAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "info",
            "type": {
              "defined": "CallbackAccountType"
            }
          }
        ]
      }
    },
    {
      "name": "CallbackInfo",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "discriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "program",
            "type": "publicKey"
          },
          {
            "name": "callbackAccounts",
            "type": {
              "vec": {
                "defined": "CallbackAccount"
              }
            }
          }
        ]
      }
    },
    {
      "name": "DelegationStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undelegated"
          },
          {
            "name": "Delegated"
          },
          {
            "name": "DelegationActive"
          },
          {
            "name": "FrozenForUndelegation"
          },
          {
            "name": "ReadyToUndelegate"
          }
        ]
      }
    },
    {
      "name": "UpdateStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Predelegated"
          },
          {
            "name": "Awaiting"
          },
          {
            "name": "Applied"
          }
        ]
      }
    },
    {
      "name": "PermissionStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Suspended"
          },
          {
            "name": "Active"
          }
        ]
      }
    },
    {
      "name": "HouseStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Frozen"
          },
          {
            "name": "OutFlowsSuspended"
          },
          {
            "name": "InFlowsSuspended"
          }
        ]
      }
    },
    {
      "name": "HouseTokenStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Frozen"
          },
          {
            "name": "OutFlowsSuspended"
          },
          {
            "name": "InFlowsSuspended"
          }
        ]
      }
    },
    {
      "name": "GameSpecStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Frozen"
          },
          {
            "name": "OutFlowsSuspended"
          },
          {
            "name": "InFlowsSuspended"
          }
        ]
      }
    },
    {
      "name": "GameSpecTokenStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Frozen"
          },
          {
            "name": "OutFlowsSuspended"
          },
          {
            "name": "InFlowsSuspended"
          }
        ]
      }
    },
    {
      "name": "InstanceStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "AwaitingPlayerResponse"
          },
          {
            "name": "AwaitingOracleResponse"
          },
          {
            "name": "Settling"
          },
          {
            "name": "Voided"
          },
          {
            "name": "Complete"
          }
        ]
      }
    },
    {
      "name": "BetStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Open"
          },
          {
            "name": "AwaitingPlayerUpdate"
          },
          {
            "name": "Won"
          },
          {
            "name": "Loss"
          },
          {
            "name": "Push"
          },
          {
            "name": "Canceled"
          }
        ]
      }
    },
    {
      "name": "Game",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip"
          },
          {
            "name": "Blackjack"
          },
          {
            "name": "Slide"
          },
          {
            "name": "Plinko"
          },
          {
            "name": "Limbo"
          },
          {
            "name": "Dice"
          },
          {
            "name": "SlotsThree"
          },
          {
            "name": "Baccarat"
          },
          {
            "name": "Roulette"
          },
          {
            "name": "Tower"
          },
          {
            "name": "Jackpot"
          },
          {
            "name": "Keno"
          },
          {
            "name": "CrashMulti"
          },
          {
            "name": "Wheel"
          }
        ]
      }
    },
    {
      "name": "GameSpecConfig",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "max_coins",
                "type": "u8"
              },
              {
                "name": "multipliers",
                "type": {
                  "vec": {
                    "defined": "CoinFlipMultiplier"
                  }
                }
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "insurance_offered",
                "type": "bool"
              },
              {
                "name": "max_splits",
                "type": "u8"
              },
              {
                "name": "dealer_stands_on",
                "type": "u8"
              },
              {
                "name": "blackjack_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "pay_tables",
                "type": {
                  "vec": {
                    "defined": "PlinkoPaytable"
                  }
                }
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              },
              {
                "name": "max_num_spins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "max_num_rolls",
                "type": "u8"
              },
              {
                "name": "range_upper",
                "type": "u16"
              },
              {
                "name": "selection_min",
                "type": "u16"
              },
              {
                "name": "selection_max",
                "type": "u16"
              },
              {
                "name": "selection_increment",
                "type": "u16"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "max_spins",
                "type": "u8"
              },
              {
                "name": "win_lines",
                "type": {
                  "vec": {
                    "array": [
                      "u8",
                      3
                    ]
                  }
                }
              },
              {
                "name": "reels",
                "type": {
                  "array": [
                    "bytes",
                    3
                  ]
                }
              },
              {
                "name": "pay_table",
                "type": {
                  "vec": {
                    "array": [
                      "u32",
                      3
                    ]
                  }
                }
              },
              {
                "name": "includes_wilcard_multiplier_per_thousand",
                "type": "u16"
              },
              {
                "name": "max_multiplier_per_million",
                "type": "u32"
              },
              {
                "name": "edge_per_million",
                "type": "u32"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "player_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "player_edge_per_million",
                "type": "u64"
              },
              {
                "name": "tie_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "tie_edge_per_million",
                "type": "u64"
              },
              {
                "name": "banker_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "banker_edge_per_million",
                "type": "u64"
              },
              {
                "name": "max_num_hands",
                "type": "u8"
              },
              {
                "name": "max_num_bets",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "is_american",
                "type": "bool"
              },
              {
                "name": "max_bets",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "max_rows",
                "type": "u8"
              },
              {
                "name": "pay_tables",
                "type": {
                  "vec": {
                    "defined": "TowerPaytable"
                  }
                }
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "shares_of_pool_per_thousand",
                "type": {
                  "vec": "u16"
                }
              },
              {
                "name": "rake_per_thousand",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "max_bets",
                "type": "u8"
              },
              {
                "name": "table_size",
                "type": "u8"
              },
              {
                "name": "max_num_selected",
                "type": "u8"
              },
              {
                "name": "multipliers",
                "type": {
                  "vec": {
                    "vec": {
                      "defined": "KenoMultipliers"
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "max_spins",
                "type": "u8"
              },
              {
                "name": "reels",
                "type": {
                  "vec": "bytes"
                }
              },
              {
                "name": "symbol_multipliers_per_million",
                "type": {
                  "vec": "u32"
                }
              },
              {
                "name": "reel_edges_per_million",
                "type": {
                  "vec": "u32"
                }
              },
              {
                "name": "reel_max_multiplier_per_million",
                "type": {
                  "vec": "u32"
                }
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameInstanceState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "num_coins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "dealers_face_up_card",
                "type": "u8"
              },
              {
                "name": "next_bet_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "num_rows",
                "type": "u8"
              },
              {
                "name": "difficulty_level",
                "type": "u8"
              },
              {
                "name": "num_balls",
                "type": "u8"
              },
              {
                "name": "pay_table",
                "type": {
                  "defined": "PlinkoPaytable"
                }
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              },
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "num_rolls",
                "type": "u8"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "num_spins",
                "type": "u8"
              },
              {
                "name": "max_lines_per_spin",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "num_hands",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Roulette"
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "pay_table",
                "type": {
                  "defined": "TowerPaytable"
                }
              },
              {
                "name": "max_rows",
                "type": "u8"
              },
              {
                "name": "current_row_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "shares_of_pool_per_thousand",
                "type": {
                  "vec": "u16"
                }
              },
              {
                "name": "rake_per_thousand",
                "type": "u16"
              },
              {
                "name": "denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "risk_level",
                "type": "u8"
              },
              {
                "name": "num_selected",
                "type": "u8"
              },
              {
                "name": "num_bets",
                "type": "u8"
              },
              {
                "name": "table_size",
                "type": "u8"
              },
              {
                "name": "multipliers_per_million",
                "type": {
                  "vec": "u32"
                }
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "reel_idx",
                "type": "u8"
              },
              {
                "name": "reel",
                "type": "bytes"
              },
              {
                "name": "symbol_multipliers_per_million",
                "type": {
                  "vec": "u32"
                }
              },
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameInstanceRisk",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "house_liability_grid",
                "type": {
                  "array": [
                    "u64",
                    38
                  ]
                }
              },
              {
                "name": "grid_maximum_liability",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Jackpot"
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameInstanceResult",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "num_zeros",
                "type": "u8"
              },
              {
                "name": "num_ones",
                "type": "u8"
              },
              {
                "name": "result_vector",
                "type": "bytes"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "dealers_hand",
                "type": {
                  "array": [
                    "u8",
                    11
                  ]
                }
              },
              {
                "name": "dealers_total",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "slot_idxs",
                "type": "bytes"
              },
              {
                "name": "paths",
                "type": {
                  "vec": "bytes"
                }
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "multipliers_per_million",
                "type": {
                  "vec": "u64"
                }
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "rolls",
                "type": {
                  "vec": "u16"
                }
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "grids",
                "type": {
                  "vec": {
                    "array": [
                      {
                        "array": [
                          "u8",
                          3
                        ]
                      },
                      3
                    ]
                  }
                }
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "hands",
                "type": {
                  "vec": {
                    "defined": "BaccaratHandResult"
                  }
                }
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "slot",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "grid",
                "type": {
                  "vec": "bytes"
                }
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "winning_numbers",
                "type": {
                  "vec": "u64"
                }
              },
              {
                "name": "gross_payouts",
                "type": {
                  "vec": "u64"
                }
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "drawn_numbers",
                "type": {
                  "vec": "bytes"
                }
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "selected_slot_idxs",
                "type": "bytes"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "BetState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "side",
                "type": "u8"
              },
              {
                "name": "num_correct",
                "type": "u8"
              },
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "hand",
                "type": {
                  "array": [
                    "u8",
                    11
                  ]
                }
              },
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "action",
                "type": {
                  "defined": "BlackjackAction"
                }
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "ball_idx",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "spin_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "roll_idx",
                "type": "u8"
              },
              {
                "name": "line",
                "type": "u16"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "spin_idx",
                "type": "u8"
              },
              {
                "name": "num_lines",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "hand_idx",
                "type": "u8"
              },
              {
                "name": "side",
                "type": {
                  "defined": "BaccaratSide"
                }
              },
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "selection",
                "type": {
                  "defined": "RouletteSelection"
                }
              },
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "steps",
                "type": "bytes"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "lower_incl",
                "type": "u64"
              },
              {
                "name": "upper_excl",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "bet_idx",
                "type": "u8"
              },
              {
                "name": "chosen_numbers",
                "type": {
                  "array": [
                    "u8",
                    10
                  ]
                }
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "spin_idx",
                "type": "u8"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameInstanceRequest",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "num_coins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Blackjack"
          },
          {
            "name": "Slide"
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "num_rows",
                "type": "u8"
              },
              {
                "name": "difficulty_level",
                "type": "u8"
              },
              {
                "name": "num_balls",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "num_rolls",
                "type": "u8"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "num_hands",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Roulette"
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "risk_idx",
                "type": "u8"
              },
              {
                "name": "row_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Jackpot"
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "risk_level",
                "type": "u8"
              },
              {
                "name": "num_selected",
                "type": "u8"
              },
              {
                "name": "num_bets",
                "type": "u8"
              }
            ]
          },
          {
            "name": "CrashMulti"
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "reel_idx",
                "type": "u8"
              },
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "BetRequest",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "side",
                "type": "u8"
              },
              {
                "name": "num_correct",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "line",
                "type": "u16"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "num_lines",
                "type": "u8"
              },
              {
                "name": "wager_per_spin",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "hand_idx",
                "type": "u8"
              },
              {
                "name": "side",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "selection",
                "type": {
                  "defined": "RouletteSelection"
                }
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "chosen_step_idx",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "chosen_numbers",
                "type": "bytes"
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "spin_idx",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "ActionRequest",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip"
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "bet_idx",
                "type": "u16"
              },
              {
                "name": "action",
                "type": {
                  "defined": "BlackjackAction"
                }
              },
              {
                "name": "insurance",
                "type": {
                  "option": "bool"
                }
              }
            ]
          },
          {
            "name": "Slide"
          },
          {
            "name": "Plinko"
          },
          {
            "name": "Limbo"
          },
          {
            "name": "Dice"
          },
          {
            "name": "SlotsThree"
          },
          {
            "name": "Baccarat"
          },
          {
            "name": "Roulette"
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "chosen_step_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Jackpot"
          },
          {
            "name": "Keno"
          },
          {
            "name": "CrashMulti"
          },
          {
            "name": "Wheel"
          }
        ]
      }
    },
    {
      "name": "BlackjackAction",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Hit"
          },
          {
            "name": "Stand"
          },
          {
            "name": "Split"
          },
          {
            "name": "Double"
          }
        ]
      }
    },
    {
      "name": "BaccaratSide",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Player"
          },
          {
            "name": "Tie"
          },
          {
            "name": "Banker"
          }
        ]
      }
    },
    {
      "name": "PlayingCardSuit",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Clubs"
          },
          {
            "name": "Diamonds"
          },
          {
            "name": "Hearts"
          },
          {
            "name": "Spades"
          }
        ]
      }
    },
    {
      "name": "PlayingCardRank",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Ace"
          },
          {
            "name": "Two"
          },
          {
            "name": "Three"
          },
          {
            "name": "Four"
          },
          {
            "name": "Five"
          },
          {
            "name": "Six"
          },
          {
            "name": "Seven"
          },
          {
            "name": "Eight"
          },
          {
            "name": "Nine"
          },
          {
            "name": "Ten"
          },
          {
            "name": "Jack"
          },
          {
            "name": "Queen"
          },
          {
            "name": "King"
          }
        ]
      }
    },
    {
      "name": "RouletteSelection",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Straight",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Street",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Column",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dozen",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "RedBlack",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "OddEven",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "LowHigh",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "PlayingCardSuit",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Clubs"
          },
          {
            "name": "Diamonds"
          },
          {
            "name": "Hearts"
          },
          {
            "name": "Spades"
          }
        ]
      }
    },
    {
      "name": "PlayingCardRank",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Ace"
          },
          {
            "name": "Two"
          },
          {
            "name": "Three"
          },
          {
            "name": "Four"
          },
          {
            "name": "Five"
          },
          {
            "name": "Six"
          },
          {
            "name": "Seven"
          },
          {
            "name": "Eight"
          },
          {
            "name": "Nine"
          },
          {
            "name": "Ten"
          },
          {
            "name": "Jack"
          },
          {
            "name": "Queen"
          },
          {
            "name": "King"
          }
        ]
      }
    },
    {
      "name": "BetExtension",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Solo"
          },
          {
            "name": "Multi",
            "fields": [
              {
                "name": "player_token",
                "type": "publicKey"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameSpecType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "SoloSimple"
          },
          {
            "name": "SoloInteractive"
          },
          {
            "name": "MultiplayerMultiToken"
          },
          {
            "name": "MultiplayerOneToken"
          }
        ]
      }
    },
    {
      "name": "GameSpecInterval",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "OnDemand"
          },
          {
            "name": "TimeCycle",
            "fields": [
              {
                "name": "offset",
                "type": "i64"
              },
              {
                "name": "interval",
                "type": "u32"
              },
              {
                "name": "min_remaining",
                "type": "u32"
              }
            ]
          },
          {
            "name": "SlotCycle",
            "fields": [
              {
                "name": "offset",
                "type": "i64"
              },
              {
                "name": "interval",
                "type": "u32"
              },
              {
                "name": "min_remaining",
                "type": "u32"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "ResponseTiming",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Asap"
          },
          {
            "name": "Timestamp",
            "fields": [
              {
                "name": "timestamp",
                "type": "i64"
              }
            ]
          },
          {
            "name": "Slot",
            "fields": [
              {
                "name": "slot",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "OracleInfo",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "RandomnessRequest",
            "fields": [
              {
                "name": "hash",
                "type": "publicKey"
              },
              {
                "name": "slot",
                "type": "u64"
              },
              {
                "name": "interaction_nonce",
                "type": "u16"
              },
              {
                "name": "client_seed",
                "type": {
                  "array": [
                    "u8",
                    4
                  ]
                }
              },
              {
                "name": "timing",
                "type": {
                  "defined": "ResponseTiming"
                }
              },
              {
                "name": "padding",
                "type": {
                  "array": [
                    "u8",
                    9
                  ]
                }
              }
            ]
          },
          {
            "name": "RandomnessResult",
            "fields": [
              {
                "name": "result",
                "type": {
                  "array": [
                    "u8",
                    64
                  ]
                }
              }
            ]
          },
          {
            "name": "InactivityVoidRequest",
            "fields": [
              {
                "name": "timing",
                "type": {
                  "defined": "ResponseTiming"
                }
              },
              {
                "name": "padding",
                "type": {
                  "array": [
                    "u8",
                    55
                  ]
                }
              }
            ]
          }
        ]
      }
    },
    {
      "name": "OracleResponse",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Randomness",
            "fields": [
              {
                "name": "randomness",
                "type": {
                  "array": [
                    "u8",
                    64
                  ]
                }
              }
            ]
          },
          {
            "name": "InactivityVoid"
          }
        ]
      }
    },
    {
      "name": "CallbackAccountType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "ReadOnly"
          },
          {
            "name": "Writeable"
          },
          {
            "name": "Program"
          }
        ]
      }
    },
    {
      "name": "Update",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "LpDeposit",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "liquidity_provider",
                "type": "publicKey"
              },
              {
                "name": "additional_balance",
                "type": "u64"
              },
              {
                "name": "additional_lp_tokens",
                "type": "u64"
              }
            ]
          },
          {
            "name": "LpWithdraw",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "liquidity_provider",
                "type": "publicKey"
              },
              {
                "name": "reduced_balance",
                "type": "u64"
              },
              {
                "name": "reduced_lp_tokens",
                "type": "u64"
              }
            ]
          },
          {
            "name": "PlayerTokenDeposit",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "player_token",
                "type": "publicKey"
              },
              {
                "name": "amount",
                "type": "u64"
              }
            ]
          },
          {
            "name": "PlayerTokenWithdraw",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "player_token",
                "type": "publicKey"
              },
              {
                "name": "amount",
                "type": "u64"
              }
            ]
          },
          {
            "name": "HouseTokenStateUpdate",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "status",
                "type": {
                  "defined": "HouseTokenStatus"
                }
              },
              {
                "name": "increment_unit",
                "type": "u64"
              }
            ]
          }
        ]
      }
    }
  ],
  "events": [
    {
      "name": "OracleRequest",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "request",
          "type": {
            "defined": "OracleInfo"
          },
          "index": false
        },
        {
          "name": "callback",
          "type": {
            "defined": "CallbackInfo"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "GameInstanceCreated",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "request",
          "type": {
            "defined": "GameInstanceRequest"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "GameInstanceUpdate",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "interactionNonce",
          "type": "u16",
          "index": false
        },
        {
          "name": "state",
          "type": {
            "defined": "GameInstanceState"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "status",
          "type": {
            "defined": "InstanceStatus"
          },
          "index": false
        },
        {
          "name": "risk",
          "type": {
            "defined": "GameInstanceRisk"
          },
          "index": false
        },
        {
          "name": "openBets",
          "type": "u16",
          "index": false
        }
      ]
    },
    {
      "name": "GameInstanceResulted",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "randomness",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "state",
          "type": {
            "defined": "GameInstanceState"
          },
          "index": false
        },
        {
          "name": "result",
          "type": {
            "defined": "GameInstanceResult"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "status",
          "type": {
            "defined": "InstanceStatus"
          },
          "index": false
        },
        {
          "name": "risk",
          "type": {
            "defined": "GameInstanceRisk"
          },
          "index": false
        },
        {
          "name": "openBets",
          "type": "u16",
          "index": false
        }
      ]
    },
    {
      "name": "GameInstanceClosed",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "BetSoloCreated",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenMint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "owner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "betIdx",
          "type": "u16",
          "index": false
        },
        {
          "name": "bet",
          "type": {
            "defined": "BetState"
          },
          "index": false
        },
        {
          "name": "wager",
          "type": "u64",
          "index": false
        },
        {
          "name": "liability",
          "type": "u64",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "platform",
          "type": "publicKey",
          "index": false
        }
      ]
    },
    {
      "name": "BetSoloUpdate",
      "fields": [
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "interactionNonce",
          "type": "u16",
          "index": false
        },
        {
          "name": "betIdx",
          "type": "u16",
          "index": false
        },
        {
          "name": "status",
          "type": {
            "defined": "BetStatus"
          },
          "index": false
        },
        {
          "name": "state",
          "type": {
            "defined": "BetState"
          },
          "index": false
        },
        {
          "name": "playerLiability",
          "type": "u64",
          "index": false
        },
        {
          "name": "houseLiability",
          "type": "u64",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "BetSoloSettled",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenMint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "owner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "betIdx",
          "type": "u16",
          "index": false
        },
        {
          "name": "bet",
          "type": {
            "defined": "BetState"
          },
          "index": false
        },
        {
          "name": "status",
          "type": {
            "defined": "BetStatus"
          },
          "index": false
        },
        {
          "name": "wager",
          "type": "u64",
          "index": false
        },
        {
          "name": "payout",
          "type": "u64",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "platform",
          "type": "publicKey",
          "index": false
        }
      ]
    },
    {
      "name": "LpInteraction",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenMint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "liquidityProvider",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "owner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenChange",
          "type": "i128",
          "index": false
        },
        {
          "name": "lpShareChange",
          "type": "i128",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "PlayerTokenInteraction",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenMint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "owner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokens",
          "type": "i128",
          "index": false
        },
        {
          "name": "closed",
          "type": "bool",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "UnspecifiedError",
      "msg": "Unspecified Error"
    },
    {
      "code": 6001,
      "name": "DelegationStatusDoesNotPermitAction",
      "msg": "DelegationStatus does not permit this action"
    },
    {
      "code": 6002,
      "name": "StatusDoesNotPermitAction",
      "msg": "Status does not permit this action"
    },
    {
      "code": 6003,
      "name": "AccountNotOwnedByDelegationProgram",
      "msg": "Account is not owned by the Delegation Program"
    },
    {
      "code": 6004,
      "name": "AccountNotOwnedByDelegationProgramOrThisProgram",
      "msg": "Account is not owned by the Delegation Program or This Program"
    },
    {
      "code": 6005,
      "name": "IncorrectUpdateNonceProvided",
      "msg": "Update Nonce provided is not the next in sequence"
    },
    {
      "code": 6006,
      "name": "OutOfSequenceUpdateProvided",
      "msg": "An out of sequence update was provided"
    },
    {
      "code": 6007,
      "name": "AccountContextMismatch",
      "msg": "The account context provided contains a mismatch"
    },
    {
      "code": 6008,
      "name": "SessionDurationBelowMinimumPermitted",
      "msg": "The session duration is below the minimum permitted"
    },
    {
      "code": 6009,
      "name": "UnauthorizedAction",
      "msg": "The ixn was not signed by the player_token's owner or session_authority"
    },
    {
      "code": 6010,
      "name": "CannotUndelegateInCurrentState",
      "msg": "The account cannot be undelegated in it's current state"
    },
    {
      "code": 6011,
      "name": "InsufficientAvailableBalance",
      "msg": "Insufficient available balance to support this action"
    },
    {
      "code": 6012,
      "name": "ReleaseExceedsExistingLockedAmount",
      "msg": "The amount to be released exceeds the amount previously locked"
    },
    {
      "code": 6013,
      "name": "InconsistentNumberOfAllocatedAccountsUndelegated",
      "msg": "The number of allocated accounts for undelegation was not consistent with the record"
    },
    {
      "code": 6014,
      "name": "AlreadyInList",
      "msg": "The record is already in the list"
    },
    {
      "code": 6015,
      "name": "NotInList",
      "msg": "The record is not in the list"
    },
    {
      "code": 6016,
      "name": "InvalidPermissions",
      "msg": "The appropriate permissions were not present"
    },
    {
      "code": 6017,
      "name": "ValidationFailed",
      "msg": "Validations failed"
    },
    {
      "code": 6018,
      "name": "MissingMethodForGame",
      "msg": "Missing a method for this game"
    },
    {
      "code": 6019,
      "name": "MaximumBetsPerInstructionExceeded",
      "msg": "Maximum number of bets per instruction exceeded"
    },
    {
      "code": 6020,
      "name": "InconsistentIxnBetCount",
      "msg": "Specified number of bets and count of bets do not align"
    },
    {
      "code": 6021,
      "name": "OracleNotOnApprovedList",
      "msg": "The responding oracle is not on the approved list"
    },
    {
      "code": 6022,
      "name": "CannotDelegateInCurrentState",
      "msg": "Cannot delegate the account while there are open interactions to be settled against it"
    },
    {
      "code": 6023,
      "name": "RanomnessHasHDoesNotMatch",
      "msg": "The randomness request hash does not match the account pubkey provided"
    },
    {
      "code": 6024,
      "name": "CannotCloseInstanceWithUnsettledBets",
      "msg": "Cannot close an instance with unsettled bets"
    },
    {
      "code": 6025,
      "name": "OutOfSequenceInteraction",
      "msg": "Response from oracle or player out of sequence"
    },
    {
      "code": 6026,
      "name": "FollowUpActionsNotProvider",
      "msg": "The appropriate follow-up actions were not provided"
    },
    {
      "code": 6027,
      "name": "MethodDoesNotApplyToThisTypeOfGame",
      "msg": "This method does not apply to this type of game"
    },
    {
      "code": 6028,
      "name": "BelowMinimumTransactionValue",
      "msg": "The transaction has not reached the minimum value required"
    },
    {
      "code": 6029,
      "name": "BetAlreadySettled",
      "msg": "This bet has already been settled"
    },
    {
      "code": 6030,
      "name": "MaximumTokensPerInstanceReached",
      "msg": "The instance has reached it's maximum number of tokens"
    },
    {
      "code": 6031,
      "name": "MaximumBetsPerInstanceReached",
      "msg": "The instance has reached it's maximum number of bets"
    },
    {
      "code": 6032,
      "name": "MaximumBetsPerInstanceTokenReached",
      "msg": "The instance_token has reached it's maximum number of bets"
    },
    {
      "code": 6033,
      "name": "InstanceActiveWindowEnded",
      "msg": "The instance's active window has ended"
    },
    {
      "code": 6034,
      "name": "InvalidOrPrematureOracleResponse",
      "msg": "Invalid or premature oracle response"
    },
    {
      "code": 6035,
      "name": "AccountNotProvided",
      "msg": "A required account was not provided"
    },
    {
      "code": 6036,
      "name": "TransferAccountsIssue",
      "msg": "Issue with the accounts for transfers"
    },
    {
      "code": 6037,
      "name": "BetOutsideRiskTolerance",
      "msg": "Bet outside risk tolerance"
    },
    {
      "code": 6038,
      "name": "DelegatedInstanceAccountNotEmpty",
      "msg": "The delegated instance account must be empty before undelegation"
    },
    {
      "code": 6039,
      "name": "InvalidIxnForUpdateType",
      "msg": "This it the wrong instruction for this update type"
    },
    {
      "code": 6040,
      "name": "PlayerTokenAccountCannotBeClosed",
      "msg": "The PlayerToken account cannot be closed"
    },
    {
      "code": 6041,
      "name": "InstanceAccountNotEmpty",
      "msg": "The instance account is not empty"
    }
  ]
};

export const IDL: ZeebitV2 = {
  "version": "0.1.0",
  "name": "zeebit_v2",
  "instructions": [
    {
      "name": "airdrop",
      "accounts": [
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "AirdropArgs"
          }
        }
      ]
    },
    {
      "name": "houseInitialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "oracleList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseInitArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenInitialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenInitArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenUpdate",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenUpdateArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenBankUpdate",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenBankUpdateArgs"
          }
        }
      ]
    },
    {
      "name": "houseOracleListAddOrUpdate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "oracleList",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "oracle",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseOracleListAddOrUpdateArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenInit",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenInitArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenClose",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenCloseArgs"
          }
        }
      ]
    },
    {
      "name": "lpInit",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpInitArgs"
          }
        }
      ]
    },
    {
      "name": "gameSpecInit",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "GameSpecInitArgs"
          }
        }
      ]
    },
    {
      "name": "gameSpecConfig",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "GameSpecConfigArgs"
          }
        }
      ]
    },
    {
      "name": "gameSpecUpdate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "GameSpecUpdateArgs"
          }
        }
      ]
    },
    {
      "name": "gameSpecTokenInit",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "GameSpecTokenInitArgs"
          }
        }
      ]
    },
    {
      "name": "lpDeposit",
      "accounts": [
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpDepositArgs"
          }
        }
      ]
    },
    {
      "name": "lpWithdrawal",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpWithdrawalArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenDeposit",
      "accounts": [
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenDepositArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenWithdraw",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenWithdrawArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenUpdateSessionAuthority",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "sessionAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenUpdateSessionAuthorityArgs"
          }
        }
      ]
    },
    {
      "name": "soloPlay",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SoloPlayArgs"
          }
        }
      ]
    },
    {
      "name": "soloAction",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SoloActionArgs"
          }
        }
      ]
    },
    {
      "name": "soloRespond",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "oracleList",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SoloRespondArgs"
          }
        }
      ]
    },
    {
      "name": "soloVoid",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SoloVoidArgs"
          }
        }
      ]
    },
    {
      "name": "multiPlay",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instanceToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "MultiPlayArgs"
          }
        }
      ]
    },
    {
      "name": "multiRespond",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "oracleList",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "MultiRespondArgs"
          }
        }
      ]
    },
    {
      "name": "multiSettle",
      "accounts": [
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "identifier",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "gameSpec",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "gameSpecToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instance",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instanceToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "MultiSettleArgs"
          }
        }
      ]
    },
    {
      "name": "syncAccounts",
      "accounts": [],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "SyncAccountsArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenDelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The pda to delegate"
          ]
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "instanceSoloDelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instanceSolo",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InstanceSoloDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "instanceSoloClose",
      "accounts": [
        {
          "name": "owner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "instanceSolo",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "InstanceSoloCloseArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenDelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenDelegateArgs"
          }
        }
      ]
    },
    {
      "name": "updateSlipPredelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "relatedAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "UpdateSlipPredelegateArgs"
          }
        }
      ]
    },
    {
      "name": "updateSlipUndelegate",
      "accounts": [
        {
          "name": "payer",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "UpdateSlipUpdelegateArgs"
          }
        }
      ]
    },
    {
      "name": "updateSlipClose",
      "accounts": [
        {
          "name": "rentRecipient",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "UpdateSlipCloseArgs"
          }
        }
      ]
    },
    {
      "name": "lpWithdrawInitialize",
      "accounts": [
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "liquidityProvider",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpWithdrawInitializeArgs"
          }
        }
      ]
    },
    {
      "name": "lpWithdrawApply",
      "accounts": [
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rentRecipient",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpWithdrawApplyArgs"
          }
        }
      ]
    },
    {
      "name": "lpDepositInitialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "liquidityProvider",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpDepositInitializeArgs"
          }
        }
      ]
    },
    {
      "name": "lpDepositApply",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "LpDepositApplyArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenDepositInitialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "buffer",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK The temporary buffer account used during delegation"
          ]
        },
        {
          "name": "delegationRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "delegationMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ownerProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "delegationProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenDepositInitializeArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenDepositApply",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenDepositApplyArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenWithdrawInitialize",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "house",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenWithdrawInitializeArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenWithdrawApply",
      "accounts": [
        {
          "name": "updateSlip",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rentRecipient",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "playerToken",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseTokenBank",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false,
          "docs": [
            "CHECK"
          ]
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenWithdrawApplyArgs"
          }
        }
      ]
    },
    {
      "name": "houseTokenFreezeOrUndelegate",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "permission",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "houseToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "HouseTokenFreezeOrUndelegateArgs"
          }
        }
      ]
    },
    {
      "name": "playerTokenFreezeOrUndelegate",
      "accounts": [
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "playerToken",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "magicProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "magicContext",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "args",
          "type": {
            "defined": "PlayerTokenFreezeOrUndelegateArgs"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "gameSpecToken",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gameSpec",
            "type": "publicKey"
          },
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "tokenMint",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "GameSpecTokenStatus"
            }
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "availableInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "activeInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "nextInstanceAccountIdx",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "gameSpec",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "game",
            "type": {
              "defined": "Game"
            }
          },
          {
            "name": "status",
            "type": {
              "defined": "GameSpecStatus"
            }
          },
          {
            "name": "specType",
            "type": {
              "defined": "GameSpecType"
            }
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "updateNonce",
            "type": "u64"
          },
          {
            "name": "availableInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "activeInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "nextInstanceAccountIdx",
            "type": "u8"
          },
          {
            "name": "pairedHouseTokens",
            "type": "u16"
          },
          {
            "name": "minWagerInHouseTokenUnits",
            "type": "u64"
          },
          {
            "name": "maxBetsPerInstanceToken",
            "type": "u8"
          },
          {
            "name": "maxBetsPerPlaceIxn",
            "type": "u8"
          },
          {
            "name": "maxBetsPerSettleIxn",
            "type": "u8"
          },
          {
            "name": "maxTokensPerInstance",
            "type": "u8"
          },
          {
            "name": "maxPlayersPerToken",
            "type": "u8"
          },
          {
            "name": "inactivityTimeoutSeconds",
            "type": "u16"
          },
          {
            "name": "callbackRespondDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "callbackExpireDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "callbackSettleDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "interval",
            "type": {
              "defined": "GameSpecInterval"
            }
          },
          {
            "name": "config",
            "type": {
              "defined": "GameSpecConfig"
            }
          }
        ]
      }
    },
    {
      "name": "houseOracleList",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "approvedOracles",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "houseTokenBank",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "tokenMint",
            "type": "publicKey"
          },
          {
            "name": "vault",
            "type": "publicKey"
          },
          {
            "name": "lpIsPermissioned",
            "type": "bool"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "activeLps",
            "type": "u64"
          },
          {
            "name": "activePlayerTokens",
            "type": "u64"
          },
          {
            "name": "balance",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "houseToken",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "tokenMint",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "HouseTokenStatus"
            }
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "incrementUnit",
            "type": "u64"
          },
          {
            "name": "outstandingLpTokens",
            "type": "u64"
          },
          {
            "name": "activeBets",
            "type": "u16"
          },
          {
            "name": "availableBalance",
            "type": "u64"
          },
          {
            "name": "lockedBalance",
            "type": "u64"
          },
          {
            "name": "playerBalance",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "house",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u64"
          },
          {
            "name": "stateVersion",
            "type": "u64"
          },
          {
            "name": "oracleList",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "HouseStatus"
            }
          },
          {
            "name": "countTokens",
            "type": "u16"
          },
          {
            "name": "countGames",
            "type": "u16"
          }
        ]
      }
    },
    {
      "name": "instanceMulti",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gameSpec",
            "type": "publicKey"
          },
          {
            "name": "identifier",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "InstanceStatus"
            }
          },
          {
            "name": "interactionNonce",
            "type": "u16"
          },
          {
            "name": "openInstanceTokens",
            "type": "u8"
          },
          {
            "name": "openBets",
            "type": "u16"
          },
          {
            "name": "oracleInfo",
            "type": {
              "defined": "OracleInfo"
            }
          },
          {
            "name": "risk",
            "type": {
              "defined": "GameInstanceRisk"
            }
          },
          {
            "name": "result",
            "type": {
              "defined": "GameInstanceResult"
            }
          },
          {
            "name": "state",
            "type": {
              "defined": "GameInstanceState"
            }
          }
        ]
      }
    },
    {
      "name": "instanceSolo",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "gameSpec",
            "type": "publicKey"
          },
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "playerToken",
            "type": "publicKey"
          },
          {
            "name": "identifier",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "InstanceStatus"
            }
          },
          {
            "name": "interactionNonce",
            "type": "u16"
          },
          {
            "name": "openBets",
            "type": "u16"
          },
          {
            "name": "oracleInfo",
            "type": {
              "defined": "OracleInfo"
            }
          },
          {
            "name": "risk",
            "type": {
              "defined": "GameInstanceRisk"
            }
          },
          {
            "name": "result",
            "type": {
              "defined": "GameInstanceResult"
            }
          },
          {
            "name": "state",
            "type": {
              "defined": "GameInstanceState"
            }
          },
          {
            "name": "bets",
            "type": {
              "vec": {
                "defined": "Bet"
              }
            }
          }
        ]
      }
    },
    {
      "name": "instanceTokenMulti",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "instance",
            "type": "publicKey"
          },
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "gameSpecToken",
            "type": "publicKey"
          },
          {
            "name": "openBets",
            "type": "u16"
          },
          {
            "name": "risk",
            "type": {
              "defined": "GameInstanceRisk"
            }
          },
          {
            "name": "bets",
            "type": {
              "vec": {
                "defined": "Bet"
              }
            }
          }
        ]
      }
    },
    {
      "name": "liquidityProvider",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "lpTokens",
            "type": "u64"
          },
          {
            "name": "netDeposits",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "permission",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "house",
            "type": "publicKey"
          },
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "status",
            "type": {
              "defined": "PermissionStatus"
            }
          },
          {
            "name": "isSuperAuthority",
            "type": "bool"
          },
          {
            "name": "reserved0",
            "type": "bool"
          },
          {
            "name": "reserved1",
            "type": "bool"
          },
          {
            "name": "reserved2",
            "type": "bool"
          },
          {
            "name": "reserved3",
            "type": "bool"
          },
          {
            "name": "reserved4",
            "type": "bool"
          },
          {
            "name": "reserved5",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "playerToken",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "houseToken",
            "type": "publicKey"
          },
          {
            "name": "platform",
            "type": "publicKey"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "availableBalance",
            "type": "u64"
          },
          {
            "name": "lockedBalance",
            "type": "u64"
          },
          {
            "name": "activeBets",
            "type": "u16"
          },
          {
            "name": "activeInstanceAccounts",
            "type": "u8"
          },
          {
            "name": "lastActivity",
            "type": "i64"
          },
          {
            "name": "sessionAuthority",
            "type": "publicKey"
          },
          {
            "name": "sessionAuthorityExpires",
            "type": "i64"
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "sessionExpires",
            "type": "i64"
          },
          {
            "name": "allocatedInstanceAccounts",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "updateSlip",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "status",
            "type": {
              "defined": "UpdateStatus"
            }
          },
          {
            "name": "delegationStatus",
            "type": {
              "defined": "DelegationStatus"
            }
          },
          {
            "name": "timestamp",
            "type": "i64"
          },
          {
            "name": "rentRecipient",
            "type": "publicKey"
          },
          {
            "name": "update",
            "type": {
              "defined": "Update"
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "SoloVoidArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "MultiPlayArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "instanceIdx",
            "type": "u8"
          },
          {
            "name": "instanceRequest",
            "type": {
              "defined": "GameInstanceRequest"
            }
          },
          {
            "name": "betRequests",
            "type": {
              "vec": {
                "defined": "BetRequest"
              }
            }
          },
          {
            "name": "clientSeed",
            "type": {
              "array": [
                "u8",
                4
              ]
            }
          }
        ]
      }
    },
    {
      "name": "MultiRespondArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "response",
            "type": {
              "defined": "OracleResponse"
            }
          }
        ]
      }
    },
    {
      "name": "MultiSettleArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "manualSettleOrder",
            "type": {
              "option": "bytes"
            }
          }
        ]
      }
    },
    {
      "name": "SoloActionArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "actionRequest",
            "type": {
              "defined": "ActionRequest"
            }
          },
          {
            "name": "clientSeed",
            "type": {
              "array": [
                "u8",
                4
              ]
            }
          },
          {
            "name": "withdrawOnSettle",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "SoloPlayArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "instanceIdx",
            "type": "u8"
          },
          {
            "name": "instanceRequest",
            "type": {
              "defined": "GameInstanceRequest"
            }
          },
          {
            "name": "betRequests",
            "type": {
              "vec": {
                "defined": "BetRequest"
              }
            }
          },
          {
            "name": "clientSeed",
            "type": {
              "array": [
                "u8",
                4
              ]
            }
          },
          {
            "name": "withdrawOnSettle",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "SoloRespondArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "response",
            "type": {
              "defined": "OracleResponse"
            }
          }
        ]
      }
    },
    {
      "name": "TowerPaytable",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "columns",
            "type": "u8"
          },
          {
            "name": "safeSteps",
            "type": "u8"
          },
          {
            "name": "multiplierPerMillion",
            "type": "u32"
          },
          {
            "name": "edgePerMillion",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "KenoMultipliers",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "numSelected",
            "type": "u8"
          },
          {
            "name": "edgePerMillion",
            "type": "u32"
          },
          {
            "name": "multipliersPerMillion",
            "type": {
              "vec": "u32"
            }
          }
        ]
      }
    },
    {
      "name": "CoinFlipMultiplier",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "numCorrect",
            "type": "u8"
          },
          {
            "name": "numTossed",
            "type": "u8"
          },
          {
            "name": "probabilityPerMillion",
            "type": "u32"
          },
          {
            "name": "multiplierPerMillion",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "PlinkoPaytable",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "numRows",
            "type": "u8"
          },
          {
            "name": "difficultyLevel",
            "type": "u8"
          },
          {
            "name": "maxNumBalls",
            "type": "u8"
          },
          {
            "name": "edgePerMillion",
            "type": "u64"
          },
          {
            "name": "multipliersPerMillion",
            "type": {
              "vec": "u32"
            }
          }
        ]
      }
    },
    {
      "name": "BaccaratHandResult",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "playerCards",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          },
          {
            "name": "bankerCards",
            "type": {
              "array": [
                "u8",
                3
              ]
            }
          },
          {
            "name": "result",
            "type": {
              "defined": "BaccaratSide"
            }
          }
        ]
      }
    },
    {
      "name": "HouseTokenDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "commitFrequencyMs",
            "type": "u32"
          }
        ]
      }
    },
    {
      "name": "HouseTokenFreezeOrUndelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "InstanceSoloCloseArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "InstanceSoloDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "instanceIdx",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "LpDepositApplyArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "LpDepositInitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "LpWithdrawApplyArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "LpWithdrawInitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenCloseInstanceSolosArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenDelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenDepositApplyArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenDepositInitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenFreezeOrUndelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenWithdrawApplyArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenWithdrawInitializeArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "SyncAccountsArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "UpdateSlipCloseArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "UpdateSlipPredelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "UpdateSlipUpdelegateArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "AirdropArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "GameSpecConfigArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "config",
            "type": {
              "defined": "GameSpecConfig"
            }
          }
        ]
      }
    },
    {
      "name": "GameSpecInitArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "game",
            "type": {
              "defined": "Game"
            }
          },
          {
            "name": "specType",
            "type": {
              "defined": "GameSpecType"
            }
          },
          {
            "name": "interval",
            "type": {
              "defined": "GameSpecInterval"
            }
          },
          {
            "name": "minWagerInHouseTokenUnits",
            "type": "u64"
          },
          {
            "name": "maxBetsPerInstanceToken",
            "type": "u8"
          },
          {
            "name": "maxBetsPerPlaceIxn",
            "type": "u8"
          },
          {
            "name": "maxBetsPerSettleIxn",
            "type": "u8"
          },
          {
            "name": "maxPlayersPerToken",
            "type": "u8"
          },
          {
            "name": "maxTokensPerInstance",
            "type": "u8"
          },
          {
            "name": "inactivityTimeoutSeconds",
            "type": "u16"
          },
          {
            "name": "callbackRespondDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "callbackSettleDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "callbackExpireDiscriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          }
        ]
      }
    },
    {
      "name": "GameSpecTokenInitArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "status",
            "type": {
              "defined": "GameSpecTokenStatus"
            }
          }
        ]
      }
    },
    {
      "name": "GameSpecUpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "status",
            "type": {
              "option": {
                "defined": "GameSpecStatus"
              }
            }
          },
          {
            "name": "interval",
            "type": {
              "option": {
                "defined": "GameSpecInterval"
              }
            }
          },
          {
            "name": "minWagerInHouseTokenUnits",
            "type": {
              "option": "u64"
            }
          },
          {
            "name": "maxBetsPerInstanceToken",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "maxBetsPerPlaceIxn",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "maxBetsPerSettleIxn",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "maxPlayersPerToken",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "maxTokensPerInstance",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "inactivityTimeoutSeconds",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "callbackRespondDiscriminator",
            "type": {
              "option": {
                "array": [
                  "u8",
                  8
                ]
              }
            }
          },
          {
            "name": "callbackSettleDiscriminator",
            "type": {
              "option": {
                "array": [
                  "u8",
                  8
                ]
              }
            }
          },
          {
            "name": "callbackExpireDiscriminator",
            "type": {
              "option": {
                "array": [
                  "u8",
                  8
                ]
              }
            }
          }
        ]
      }
    },
    {
      "name": "HouseInitArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "HouseOracleListAddOrUpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "remove",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "HouseTokenBankUpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "lpIsPermissioned",
            "type": {
              "option": "bool"
            }
          }
        ]
      }
    },
    {
      "name": "HouseTokenInitArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "incrementUnit",
            "type": "u64"
          },
          {
            "name": "lpIsPermissioned",
            "type": "bool"
          }
        ]
      }
    },
    {
      "name": "HouseTokenUpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "status",
            "type": {
              "option": {
                "defined": "HouseTokenStatus"
              }
            }
          },
          {
            "name": "incrementUnit",
            "type": {
              "option": "u64"
            }
          }
        ]
      }
    },
    {
      "name": "LpDepositArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "LpInitArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "LpWithdrawalArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenCloseArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenDepositArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "depositAmount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenInitArgs",
      "type": {
        "kind": "struct",
        "fields": []
      }
    },
    {
      "name": "PlayerTokenUpdateSessionAuthorityArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "validUntil",
            "type": "i64"
          },
          {
            "name": "lamportTopUp",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "PlayerTokenWithdrawArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "withdrawalAmount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "BetResult",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "idx",
            "type": "u16"
          },
          {
            "name": "state",
            "type": {
              "defined": "BetState"
            }
          },
          {
            "name": "outcome",
            "type": {
              "defined": "BetStatus"
            }
          },
          {
            "name": "payout",
            "type": "u64"
          },
          {
            "name": "playerLiabilityReleased",
            "type": "u64"
          },
          {
            "name": "houseLiabilityReleased",
            "type": "u64"
          },
          {
            "name": "playerDelta",
            "type": "i128"
          },
          {
            "name": "houseDelta",
            "type": "i128"
          },
          {
            "name": "playerToken",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "Bet",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "idx",
            "type": "u16"
          },
          {
            "name": "status",
            "type": {
              "defined": "BetStatus"
            }
          },
          {
            "name": "playerLocked",
            "type": "u64"
          },
          {
            "name": "houseLocked",
            "type": "u64"
          },
          {
            "name": "state",
            "type": {
              "defined": "BetState"
            }
          },
          {
            "name": "extension",
            "type": {
              "defined": "BetExtension"
            }
          }
        ]
      }
    },
    {
      "name": "CallbackAccount",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "info",
            "type": {
              "defined": "CallbackAccountType"
            }
          }
        ]
      }
    },
    {
      "name": "CallbackInfo",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "discriminator",
            "type": {
              "array": [
                "u8",
                8
              ]
            }
          },
          {
            "name": "program",
            "type": "publicKey"
          },
          {
            "name": "callbackAccounts",
            "type": {
              "vec": {
                "defined": "CallbackAccount"
              }
            }
          }
        ]
      }
    },
    {
      "name": "DelegationStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undelegated"
          },
          {
            "name": "Delegated"
          },
          {
            "name": "DelegationActive"
          },
          {
            "name": "FrozenForUndelegation"
          },
          {
            "name": "ReadyToUndelegate"
          }
        ]
      }
    },
    {
      "name": "UpdateStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Predelegated"
          },
          {
            "name": "Awaiting"
          },
          {
            "name": "Applied"
          }
        ]
      }
    },
    {
      "name": "PermissionStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Suspended"
          },
          {
            "name": "Active"
          }
        ]
      }
    },
    {
      "name": "HouseStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Frozen"
          },
          {
            "name": "OutFlowsSuspended"
          },
          {
            "name": "InFlowsSuspended"
          }
        ]
      }
    },
    {
      "name": "HouseTokenStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Frozen"
          },
          {
            "name": "OutFlowsSuspended"
          },
          {
            "name": "InFlowsSuspended"
          }
        ]
      }
    },
    {
      "name": "GameSpecStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Frozen"
          },
          {
            "name": "OutFlowsSuspended"
          },
          {
            "name": "InFlowsSuspended"
          }
        ]
      }
    },
    {
      "name": "GameSpecTokenStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "Frozen"
          },
          {
            "name": "OutFlowsSuspended"
          },
          {
            "name": "InFlowsSuspended"
          }
        ]
      }
    },
    {
      "name": "InstanceStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Active"
          },
          {
            "name": "AwaitingPlayerResponse"
          },
          {
            "name": "AwaitingOracleResponse"
          },
          {
            "name": "Settling"
          },
          {
            "name": "Voided"
          },
          {
            "name": "Complete"
          }
        ]
      }
    },
    {
      "name": "BetStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "Open"
          },
          {
            "name": "AwaitingPlayerUpdate"
          },
          {
            "name": "Won"
          },
          {
            "name": "Loss"
          },
          {
            "name": "Push"
          },
          {
            "name": "Canceled"
          }
        ]
      }
    },
    {
      "name": "Game",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip"
          },
          {
            "name": "Blackjack"
          },
          {
            "name": "Slide"
          },
          {
            "name": "Plinko"
          },
          {
            "name": "Limbo"
          },
          {
            "name": "Dice"
          },
          {
            "name": "SlotsThree"
          },
          {
            "name": "Baccarat"
          },
          {
            "name": "Roulette"
          },
          {
            "name": "Tower"
          },
          {
            "name": "Jackpot"
          },
          {
            "name": "Keno"
          },
          {
            "name": "CrashMulti"
          },
          {
            "name": "Wheel"
          }
        ]
      }
    },
    {
      "name": "GameSpecConfig",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "max_coins",
                "type": "u8"
              },
              {
                "name": "multipliers",
                "type": {
                  "vec": {
                    "defined": "CoinFlipMultiplier"
                  }
                }
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "insurance_offered",
                "type": "bool"
              },
              {
                "name": "max_splits",
                "type": "u8"
              },
              {
                "name": "dealer_stands_on",
                "type": "u8"
              },
              {
                "name": "blackjack_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "pay_tables",
                "type": {
                  "vec": {
                    "defined": "PlinkoPaytable"
                  }
                }
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              },
              {
                "name": "max_num_spins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "max_num_rolls",
                "type": "u8"
              },
              {
                "name": "range_upper",
                "type": "u16"
              },
              {
                "name": "selection_min",
                "type": "u16"
              },
              {
                "name": "selection_max",
                "type": "u16"
              },
              {
                "name": "selection_increment",
                "type": "u16"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "max_spins",
                "type": "u8"
              },
              {
                "name": "win_lines",
                "type": {
                  "vec": {
                    "array": [
                      "u8",
                      3
                    ]
                  }
                }
              },
              {
                "name": "reels",
                "type": {
                  "array": [
                    "bytes",
                    3
                  ]
                }
              },
              {
                "name": "pay_table",
                "type": {
                  "vec": {
                    "array": [
                      "u32",
                      3
                    ]
                  }
                }
              },
              {
                "name": "includes_wilcard_multiplier_per_thousand",
                "type": "u16"
              },
              {
                "name": "max_multiplier_per_million",
                "type": "u32"
              },
              {
                "name": "edge_per_million",
                "type": "u32"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "player_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "player_edge_per_million",
                "type": "u64"
              },
              {
                "name": "tie_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "tie_edge_per_million",
                "type": "u64"
              },
              {
                "name": "banker_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "banker_edge_per_million",
                "type": "u64"
              },
              {
                "name": "max_num_hands",
                "type": "u8"
              },
              {
                "name": "max_num_bets",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "is_american",
                "type": "bool"
              },
              {
                "name": "max_bets",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "max_rows",
                "type": "u8"
              },
              {
                "name": "pay_tables",
                "type": {
                  "vec": {
                    "defined": "TowerPaytable"
                  }
                }
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "shares_of_pool_per_thousand",
                "type": {
                  "vec": "u16"
                }
              },
              {
                "name": "rake_per_thousand",
                "type": "u16"
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "max_bets",
                "type": "u8"
              },
              {
                "name": "table_size",
                "type": "u8"
              },
              {
                "name": "max_num_selected",
                "type": "u8"
              },
              {
                "name": "multipliers",
                "type": {
                  "vec": {
                    "vec": {
                      "defined": "KenoMultipliers"
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "max_spins",
                "type": "u8"
              },
              {
                "name": "reels",
                "type": {
                  "vec": "bytes"
                }
              },
              {
                "name": "symbol_multipliers_per_million",
                "type": {
                  "vec": "u32"
                }
              },
              {
                "name": "reel_edges_per_million",
                "type": {
                  "vec": "u32"
                }
              },
              {
                "name": "reel_max_multiplier_per_million",
                "type": {
                  "vec": "u32"
                }
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameInstanceState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "num_coins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "dealers_face_up_card",
                "type": "u8"
              },
              {
                "name": "next_bet_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "num_rows",
                "type": "u8"
              },
              {
                "name": "difficulty_level",
                "type": "u8"
              },
              {
                "name": "num_balls",
                "type": "u8"
              },
              {
                "name": "pay_table",
                "type": {
                  "defined": "PlinkoPaytable"
                }
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              },
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "num_rolls",
                "type": "u8"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "num_spins",
                "type": "u8"
              },
              {
                "name": "max_lines_per_spin",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "num_hands",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Roulette"
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "pay_table",
                "type": {
                  "defined": "TowerPaytable"
                }
              },
              {
                "name": "max_rows",
                "type": "u8"
              },
              {
                "name": "current_row_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "shares_of_pool_per_thousand",
                "type": {
                  "vec": "u16"
                }
              },
              {
                "name": "rake_per_thousand",
                "type": "u16"
              },
              {
                "name": "denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "risk_level",
                "type": "u8"
              },
              {
                "name": "num_selected",
                "type": "u8"
              },
              {
                "name": "num_bets",
                "type": "u8"
              },
              {
                "name": "table_size",
                "type": "u8"
              },
              {
                "name": "multipliers_per_million",
                "type": {
                  "vec": "u32"
                }
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "max_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "edge_per_million",
                "type": "u64"
              },
              {
                "name": "rounding_denominator",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "reel_idx",
                "type": "u8"
              },
              {
                "name": "reel",
                "type": "bytes"
              },
              {
                "name": "symbol_multipliers_per_million",
                "type": {
                  "vec": "u32"
                }
              },
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameInstanceRisk",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "house_liability_grid",
                "type": {
                  "array": [
                    "u64",
                    38
                  ]
                }
              },
              {
                "name": "grid_maximum_liability",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Jackpot"
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameInstanceResult",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "num_zeros",
                "type": "u8"
              },
              {
                "name": "num_ones",
                "type": "u8"
              },
              {
                "name": "result_vector",
                "type": "bytes"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "dealers_hand",
                "type": {
                  "array": [
                    "u8",
                    11
                  ]
                }
              },
              {
                "name": "dealers_total",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "slot_idxs",
                "type": "bytes"
              },
              {
                "name": "paths",
                "type": {
                  "vec": "bytes"
                }
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "multipliers_per_million",
                "type": {
                  "vec": "u64"
                }
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "rolls",
                "type": {
                  "vec": "u16"
                }
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "grids",
                "type": {
                  "vec": {
                    "array": [
                      {
                        "array": [
                          "u8",
                          3
                        ]
                      },
                      3
                    ]
                  }
                }
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "hands",
                "type": {
                  "vec": {
                    "defined": "BaccaratHandResult"
                  }
                }
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "slot",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "grid",
                "type": {
                  "vec": "bytes"
                }
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "winning_numbers",
                "type": {
                  "vec": "u64"
                }
              },
              {
                "name": "gross_payouts",
                "type": {
                  "vec": "u64"
                }
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "drawn_numbers",
                "type": {
                  "vec": "bytes"
                }
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "selected_slot_idxs",
                "type": "bytes"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "BetState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "side",
                "type": "u8"
              },
              {
                "name": "num_correct",
                "type": "u8"
              },
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "hand",
                "type": {
                  "array": [
                    "u8",
                    11
                  ]
                }
              },
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "action",
                "type": {
                  "defined": "BlackjackAction"
                }
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "ball_idx",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              },
              {
                "name": "spin_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "roll_idx",
                "type": "u8"
              },
              {
                "name": "line",
                "type": "u16"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "spin_idx",
                "type": "u8"
              },
              {
                "name": "num_lines",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "hand_idx",
                "type": "u8"
              },
              {
                "name": "side",
                "type": {
                  "defined": "BaccaratSide"
                }
              },
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "selection",
                "type": {
                  "defined": "RouletteSelection"
                }
              },
              {
                "name": "potential_payout",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "steps",
                "type": "bytes"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "lower_incl",
                "type": "u64"
              },
              {
                "name": "upper_excl",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "bet_idx",
                "type": "u8"
              },
              {
                "name": "chosen_numbers",
                "type": {
                  "array": [
                    "u8",
                    10
                  ]
                }
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "spin_idx",
                "type": "u8"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameInstanceRequest",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "num_coins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Blackjack"
          },
          {
            "name": "Slide"
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "num_rows",
                "type": "u8"
              },
              {
                "name": "difficulty_level",
                "type": "u8"
              },
              {
                "name": "num_balls",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "num_rolls",
                "type": "u8"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "num_hands",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Roulette"
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "risk_idx",
                "type": "u8"
              },
              {
                "name": "row_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Jackpot"
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "risk_level",
                "type": "u8"
              },
              {
                "name": "num_selected",
                "type": "u8"
              },
              {
                "name": "num_bets",
                "type": "u8"
              }
            ]
          },
          {
            "name": "CrashMulti"
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "reel_idx",
                "type": "u8"
              },
              {
                "name": "num_spins",
                "type": "u8"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "BetRequest",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip",
            "fields": [
              {
                "name": "side",
                "type": "u8"
              },
              {
                "name": "num_correct",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Slide",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Plinko",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Limbo",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Dice",
            "fields": [
              {
                "name": "line",
                "type": "u16"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "SlotsThree",
            "fields": [
              {
                "name": "num_lines",
                "type": "u8"
              },
              {
                "name": "wager_per_spin",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Baccarat",
            "fields": [
              {
                "name": "hand_idx",
                "type": "u8"
              },
              {
                "name": "side",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Roulette",
            "fields": [
              {
                "name": "selection",
                "type": {
                  "defined": "RouletteSelection"
                }
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "chosen_step_idx",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Jackpot",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Keno",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "chosen_numbers",
                "type": "bytes"
              }
            ]
          },
          {
            "name": "CrashMulti",
            "fields": [
              {
                "name": "wager",
                "type": "u64"
              },
              {
                "name": "target_multiplier_per_million",
                "type": "u64"
              }
            ]
          },
          {
            "name": "Wheel",
            "fields": [
              {
                "name": "spin_idx",
                "type": "u8"
              },
              {
                "name": "wager",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "ActionRequest",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "CoinFlip"
          },
          {
            "name": "Blackjack",
            "fields": [
              {
                "name": "bet_idx",
                "type": "u16"
              },
              {
                "name": "action",
                "type": {
                  "defined": "BlackjackAction"
                }
              },
              {
                "name": "insurance",
                "type": {
                  "option": "bool"
                }
              }
            ]
          },
          {
            "name": "Slide"
          },
          {
            "name": "Plinko"
          },
          {
            "name": "Limbo"
          },
          {
            "name": "Dice"
          },
          {
            "name": "SlotsThree"
          },
          {
            "name": "Baccarat"
          },
          {
            "name": "Roulette"
          },
          {
            "name": "Tower",
            "fields": [
              {
                "name": "chosen_step_idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Jackpot"
          },
          {
            "name": "Keno"
          },
          {
            "name": "CrashMulti"
          },
          {
            "name": "Wheel"
          }
        ]
      }
    },
    {
      "name": "BlackjackAction",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "Hit"
          },
          {
            "name": "Stand"
          },
          {
            "name": "Split"
          },
          {
            "name": "Double"
          }
        ]
      }
    },
    {
      "name": "BaccaratSide",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Player"
          },
          {
            "name": "Tie"
          },
          {
            "name": "Banker"
          }
        ]
      }
    },
    {
      "name": "PlayingCardSuit",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Clubs"
          },
          {
            "name": "Diamonds"
          },
          {
            "name": "Hearts"
          },
          {
            "name": "Spades"
          }
        ]
      }
    },
    {
      "name": "PlayingCardRank",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Ace"
          },
          {
            "name": "Two"
          },
          {
            "name": "Three"
          },
          {
            "name": "Four"
          },
          {
            "name": "Five"
          },
          {
            "name": "Six"
          },
          {
            "name": "Seven"
          },
          {
            "name": "Eight"
          },
          {
            "name": "Nine"
          },
          {
            "name": "Ten"
          },
          {
            "name": "Jack"
          },
          {
            "name": "Queen"
          },
          {
            "name": "King"
          }
        ]
      }
    },
    {
      "name": "RouletteSelection",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Straight",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Street",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Column",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "Dozen",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "RedBlack",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "OddEven",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          },
          {
            "name": "LowHigh",
            "fields": [
              {
                "name": "idx",
                "type": "u8"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "PlayingCardSuit",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Clubs"
          },
          {
            "name": "Diamonds"
          },
          {
            "name": "Hearts"
          },
          {
            "name": "Spades"
          }
        ]
      }
    },
    {
      "name": "PlayingCardRank",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Ace"
          },
          {
            "name": "Two"
          },
          {
            "name": "Three"
          },
          {
            "name": "Four"
          },
          {
            "name": "Five"
          },
          {
            "name": "Six"
          },
          {
            "name": "Seven"
          },
          {
            "name": "Eight"
          },
          {
            "name": "Nine"
          },
          {
            "name": "Ten"
          },
          {
            "name": "Jack"
          },
          {
            "name": "Queen"
          },
          {
            "name": "King"
          }
        ]
      }
    },
    {
      "name": "BetExtension",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Solo"
          },
          {
            "name": "Multi",
            "fields": [
              {
                "name": "player_token",
                "type": "publicKey"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "GameSpecType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "SoloSimple"
          },
          {
            "name": "SoloInteractive"
          },
          {
            "name": "MultiplayerMultiToken"
          },
          {
            "name": "MultiplayerOneToken"
          }
        ]
      }
    },
    {
      "name": "GameSpecInterval",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Undefined"
          },
          {
            "name": "OnDemand"
          },
          {
            "name": "TimeCycle",
            "fields": [
              {
                "name": "offset",
                "type": "i64"
              },
              {
                "name": "interval",
                "type": "u32"
              },
              {
                "name": "min_remaining",
                "type": "u32"
              }
            ]
          },
          {
            "name": "SlotCycle",
            "fields": [
              {
                "name": "offset",
                "type": "i64"
              },
              {
                "name": "interval",
                "type": "u32"
              },
              {
                "name": "min_remaining",
                "type": "u32"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "ResponseTiming",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Asap"
          },
          {
            "name": "Timestamp",
            "fields": [
              {
                "name": "timestamp",
                "type": "i64"
              }
            ]
          },
          {
            "name": "Slot",
            "fields": [
              {
                "name": "slot",
                "type": "u64"
              }
            ]
          }
        ]
      }
    },
    {
      "name": "OracleInfo",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "RandomnessRequest",
            "fields": [
              {
                "name": "hash",
                "type": "publicKey"
              },
              {
                "name": "slot",
                "type": "u64"
              },
              {
                "name": "interaction_nonce",
                "type": "u16"
              },
              {
                "name": "client_seed",
                "type": {
                  "array": [
                    "u8",
                    4
                  ]
                }
              },
              {
                "name": "timing",
                "type": {
                  "defined": "ResponseTiming"
                }
              },
              {
                "name": "padding",
                "type": {
                  "array": [
                    "u8",
                    9
                  ]
                }
              }
            ]
          },
          {
            "name": "RandomnessResult",
            "fields": [
              {
                "name": "result",
                "type": {
                  "array": [
                    "u8",
                    64
                  ]
                }
              }
            ]
          },
          {
            "name": "InactivityVoidRequest",
            "fields": [
              {
                "name": "timing",
                "type": {
                  "defined": "ResponseTiming"
                }
              },
              {
                "name": "padding",
                "type": {
                  "array": [
                    "u8",
                    55
                  ]
                }
              }
            ]
          }
        ]
      }
    },
    {
      "name": "OracleResponse",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Randomness",
            "fields": [
              {
                "name": "randomness",
                "type": {
                  "array": [
                    "u8",
                    64
                  ]
                }
              }
            ]
          },
          {
            "name": "InactivityVoid"
          }
        ]
      }
    },
    {
      "name": "CallbackAccountType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "ReadOnly"
          },
          {
            "name": "Writeable"
          },
          {
            "name": "Program"
          }
        ]
      }
    },
    {
      "name": "Update",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "LpDeposit",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "liquidity_provider",
                "type": "publicKey"
              },
              {
                "name": "additional_balance",
                "type": "u64"
              },
              {
                "name": "additional_lp_tokens",
                "type": "u64"
              }
            ]
          },
          {
            "name": "LpWithdraw",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "liquidity_provider",
                "type": "publicKey"
              },
              {
                "name": "reduced_balance",
                "type": "u64"
              },
              {
                "name": "reduced_lp_tokens",
                "type": "u64"
              }
            ]
          },
          {
            "name": "PlayerTokenDeposit",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "player_token",
                "type": "publicKey"
              },
              {
                "name": "amount",
                "type": "u64"
              }
            ]
          },
          {
            "name": "PlayerTokenWithdraw",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "player_token",
                "type": "publicKey"
              },
              {
                "name": "amount",
                "type": "u64"
              }
            ]
          },
          {
            "name": "HouseTokenStateUpdate",
            "fields": [
              {
                "name": "house_token",
                "type": "publicKey"
              },
              {
                "name": "status",
                "type": {
                  "defined": "HouseTokenStatus"
                }
              },
              {
                "name": "increment_unit",
                "type": "u64"
              }
            ]
          }
        ]
      }
    }
  ],
  "events": [
    {
      "name": "OracleRequest",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "request",
          "type": {
            "defined": "OracleInfo"
          },
          "index": false
        },
        {
          "name": "callback",
          "type": {
            "defined": "CallbackInfo"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "GameInstanceCreated",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "request",
          "type": {
            "defined": "GameInstanceRequest"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "GameInstanceUpdate",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "interactionNonce",
          "type": "u16",
          "index": false
        },
        {
          "name": "state",
          "type": {
            "defined": "GameInstanceState"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "status",
          "type": {
            "defined": "InstanceStatus"
          },
          "index": false
        },
        {
          "name": "risk",
          "type": {
            "defined": "GameInstanceRisk"
          },
          "index": false
        },
        {
          "name": "openBets",
          "type": "u16",
          "index": false
        }
      ]
    },
    {
      "name": "GameInstanceResulted",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "randomness",
          "type": {
            "array": [
              "u8",
              64
            ]
          },
          "index": false
        },
        {
          "name": "state",
          "type": {
            "defined": "GameInstanceState"
          },
          "index": false
        },
        {
          "name": "result",
          "type": {
            "defined": "GameInstanceResult"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "status",
          "type": {
            "defined": "InstanceStatus"
          },
          "index": false
        },
        {
          "name": "risk",
          "type": {
            "defined": "GameInstanceRisk"
          },
          "index": false
        },
        {
          "name": "openBets",
          "type": "u16",
          "index": false
        }
      ]
    },
    {
      "name": "GameInstanceClosed",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "BetSoloCreated",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenMint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "owner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "betIdx",
          "type": "u16",
          "index": false
        },
        {
          "name": "bet",
          "type": {
            "defined": "BetState"
          },
          "index": false
        },
        {
          "name": "wager",
          "type": "u64",
          "index": false
        },
        {
          "name": "liability",
          "type": "u64",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "platform",
          "type": "publicKey",
          "index": false
        }
      ]
    },
    {
      "name": "BetSoloUpdate",
      "fields": [
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "interactionNonce",
          "type": "u16",
          "index": false
        },
        {
          "name": "betIdx",
          "type": "u16",
          "index": false
        },
        {
          "name": "status",
          "type": {
            "defined": "BetStatus"
          },
          "index": false
        },
        {
          "name": "state",
          "type": {
            "defined": "BetState"
          },
          "index": false
        },
        {
          "name": "playerLiability",
          "type": "u64",
          "index": false
        },
        {
          "name": "houseLiability",
          "type": "u64",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "BetSoloSettled",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenMint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "gameSpec",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "instance",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "owner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "identifier",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "game",
          "type": {
            "defined": "Game"
          },
          "index": false
        },
        {
          "name": "betIdx",
          "type": "u16",
          "index": false
        },
        {
          "name": "bet",
          "type": {
            "defined": "BetState"
          },
          "index": false
        },
        {
          "name": "status",
          "type": {
            "defined": "BetStatus"
          },
          "index": false
        },
        {
          "name": "wager",
          "type": "u64",
          "index": false
        },
        {
          "name": "payout",
          "type": "u64",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        },
        {
          "name": "platform",
          "type": "publicKey",
          "index": false
        }
      ]
    },
    {
      "name": "LpInteraction",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenMint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "liquidityProvider",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "owner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenChange",
          "type": "i128",
          "index": false
        },
        {
          "name": "lpShareChange",
          "type": "i128",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "PlayerTokenInteraction",
      "fields": [
        {
          "name": "house",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "houseToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokenMint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "playerToken",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "owner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "tokens",
          "type": "i128",
          "index": false
        },
        {
          "name": "closed",
          "type": "bool",
          "index": false
        },
        {
          "name": "timestamp",
          "type": "i64",
          "index": false
        }
      ]
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "UnspecifiedError",
      "msg": "Unspecified Error"
    },
    {
      "code": 6001,
      "name": "DelegationStatusDoesNotPermitAction",
      "msg": "DelegationStatus does not permit this action"
    },
    {
      "code": 6002,
      "name": "StatusDoesNotPermitAction",
      "msg": "Status does not permit this action"
    },
    {
      "code": 6003,
      "name": "AccountNotOwnedByDelegationProgram",
      "msg": "Account is not owned by the Delegation Program"
    },
    {
      "code": 6004,
      "name": "AccountNotOwnedByDelegationProgramOrThisProgram",
      "msg": "Account is not owned by the Delegation Program or This Program"
    },
    {
      "code": 6005,
      "name": "IncorrectUpdateNonceProvided",
      "msg": "Update Nonce provided is not the next in sequence"
    },
    {
      "code": 6006,
      "name": "OutOfSequenceUpdateProvided",
      "msg": "An out of sequence update was provided"
    },
    {
      "code": 6007,
      "name": "AccountContextMismatch",
      "msg": "The account context provided contains a mismatch"
    },
    {
      "code": 6008,
      "name": "SessionDurationBelowMinimumPermitted",
      "msg": "The session duration is below the minimum permitted"
    },
    {
      "code": 6009,
      "name": "UnauthorizedAction",
      "msg": "The ixn was not signed by the player_token's owner or session_authority"
    },
    {
      "code": 6010,
      "name": "CannotUndelegateInCurrentState",
      "msg": "The account cannot be undelegated in it's current state"
    },
    {
      "code": 6011,
      "name": "InsufficientAvailableBalance",
      "msg": "Insufficient available balance to support this action"
    },
    {
      "code": 6012,
      "name": "ReleaseExceedsExistingLockedAmount",
      "msg": "The amount to be released exceeds the amount previously locked"
    },
    {
      "code": 6013,
      "name": "InconsistentNumberOfAllocatedAccountsUndelegated",
      "msg": "The number of allocated accounts for undelegation was not consistent with the record"
    },
    {
      "code": 6014,
      "name": "AlreadyInList",
      "msg": "The record is already in the list"
    },
    {
      "code": 6015,
      "name": "NotInList",
      "msg": "The record is not in the list"
    },
    {
      "code": 6016,
      "name": "InvalidPermissions",
      "msg": "The appropriate permissions were not present"
    },
    {
      "code": 6017,
      "name": "ValidationFailed",
      "msg": "Validations failed"
    },
    {
      "code": 6018,
      "name": "MissingMethodForGame",
      "msg": "Missing a method for this game"
    },
    {
      "code": 6019,
      "name": "MaximumBetsPerInstructionExceeded",
      "msg": "Maximum number of bets per instruction exceeded"
    },
    {
      "code": 6020,
      "name": "InconsistentIxnBetCount",
      "msg": "Specified number of bets and count of bets do not align"
    },
    {
      "code": 6021,
      "name": "OracleNotOnApprovedList",
      "msg": "The responding oracle is not on the approved list"
    },
    {
      "code": 6022,
      "name": "CannotDelegateInCurrentState",
      "msg": "Cannot delegate the account while there are open interactions to be settled against it"
    },
    {
      "code": 6023,
      "name": "RanomnessHasHDoesNotMatch",
      "msg": "The randomness request hash does not match the account pubkey provided"
    },
    {
      "code": 6024,
      "name": "CannotCloseInstanceWithUnsettledBets",
      "msg": "Cannot close an instance with unsettled bets"
    },
    {
      "code": 6025,
      "name": "OutOfSequenceInteraction",
      "msg": "Response from oracle or player out of sequence"
    },
    {
      "code": 6026,
      "name": "FollowUpActionsNotProvider",
      "msg": "The appropriate follow-up actions were not provided"
    },
    {
      "code": 6027,
      "name": "MethodDoesNotApplyToThisTypeOfGame",
      "msg": "This method does not apply to this type of game"
    },
    {
      "code": 6028,
      "name": "BelowMinimumTransactionValue",
      "msg": "The transaction has not reached the minimum value required"
    },
    {
      "code": 6029,
      "name": "BetAlreadySettled",
      "msg": "This bet has already been settled"
    },
    {
      "code": 6030,
      "name": "MaximumTokensPerInstanceReached",
      "msg": "The instance has reached it's maximum number of tokens"
    },
    {
      "code": 6031,
      "name": "MaximumBetsPerInstanceReached",
      "msg": "The instance has reached it's maximum number of bets"
    },
    {
      "code": 6032,
      "name": "MaximumBetsPerInstanceTokenReached",
      "msg": "The instance_token has reached it's maximum number of bets"
    },
    {
      "code": 6033,
      "name": "InstanceActiveWindowEnded",
      "msg": "The instance's active window has ended"
    },
    {
      "code": 6034,
      "name": "InvalidOrPrematureOracleResponse",
      "msg": "Invalid or premature oracle response"
    },
    {
      "code": 6035,
      "name": "AccountNotProvided",
      "msg": "A required account was not provided"
    },
    {
      "code": 6036,
      "name": "TransferAccountsIssue",
      "msg": "Issue with the accounts for transfers"
    },
    {
      "code": 6037,
      "name": "BetOutsideRiskTolerance",
      "msg": "Bet outside risk tolerance"
    },
    {
      "code": 6038,
      "name": "DelegatedInstanceAccountNotEmpty",
      "msg": "The delegated instance account must be empty before undelegation"
    },
    {
      "code": 6039,
      "name": "InvalidIxnForUpdateType",
      "msg": "This it the wrong instruction for this update type"
    },
    {
      "code": 6040,
      "name": "PlayerTokenAccountCannotBeClosed",
      "msg": "The PlayerToken account cannot be closed"
    },
    {
      "code": 6041,
      "name": "InstanceAccountNotEmpty",
      "msg": "The instance account is not empty"
    }
  ]
};
