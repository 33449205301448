import { FC, PropsWithChildren, ReactNode, useState } from "react";
import { ImageStatic } from "../../common/image/ImageStatic";
import { Disclosure, Transition } from "@headlessui/react";
import { IconFont } from "../../common";
import { twMerge } from "tailwind-merge";

export const DisplayNumber = ({ value }: { value: number | string | ReactNode }) => {
  return (
    <div className="font-numeric text-[28px] leading-[120%] -mb-[12px]">
      {value}
    </div>
  );
};
export interface SingleRewardProps {
  title: string;
  value: string | ReactNode;
  iconUrl: string;
  showNotification?: boolean;
  classes?: {
    button?: string;
    title?: string;
  };
  onClick?: () => void;
}
export interface MultiRewardBadgeProps extends SingleRewardProps {
  secondValue?: string;
  secondIconUrl?: string;
}

export const MultiRewardBadge: FC<MultiRewardBadgeProps> = ({
  title,
  value,
  iconUrl,
  secondValue,
  secondIconUrl,
  showNotification,
  classes,
  onClick,
}) => {
  const commonCardClasses =
    "p-2 rounded-lg border flex justify-between items-end";
  const commonTitleClasses = "uppercase font-normal";
  const commonIconClasses = { wrapper: "w-6 h-6" };
  return (
    <button
      onClick={() => onClick?.()}
      className={twMerge(commonCardClasses, classes?.button)}
    >
      <div className="flex flex-col gap-1">
        <div className={twMerge(commonTitleClasses, classes?.title)}>
          {title}
        </div>
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1">
            <ImageStatic url={iconUrl} classes={commonIconClasses} />
            <DisplayNumber value={value} />
          </div>
          {secondValue && secondIconUrl ? (
            <div className="flex items-center gap-1">
              <ImageStatic url={secondIconUrl} classes={commonIconClasses} />
              <DisplayNumber value={secondValue} />
            </div>
          ) : null}
        </div>
      </div>
      {showNotification ? (
        <IconFont
          size="xl"
          name="bell"
          className={twMerge(
            "drop-shadow-[0_0_8px_currentColor]",
            classes?.title
          )}
        />
      ) : null}
    </button>
  );
};

export const RewordBadge = ({
  value,
  iconUrl,
  title,
  classes,
}: SingleRewardProps) => {
  const commonCardClasses =
    "p-3 rounded-lg border flex justify-between items-center gap-1";
  const commonTitleClasses = "uppercase font-normal";
  const commonIconClasses = { wrapper: "md:w-15 md:h-15 w-6 h-6" };
  const DisplayNumber = ({ value }: { value: number | string | ReactNode }) => {
    return (
      <div className="font-numeric md:text-[60px] text-[28px] leading-[120%] -mb-[10%]">
        {value}
      </div>
    );
  };
  return (
    <div className={twMerge(commonCardClasses, classes?.button, "")}>
      <div className="flex flex-col gap-3">
        <div className={twMerge(commonTitleClasses, classes?.title)}>
          {title}
        </div>
        <DisplayNumber value={value} />
      </div>
      <ImageStatic url={iconUrl} classes={commonIconClasses} />
    </div>
  );
};

export const Card: FC<
  PropsWithChildren<{
    title: ReactNode;
    subtitle?: ReactNode;
  }>
> = ({ title, subtitle, children }) => {
  return (
    <div className="w-full bg-gray-600 rounded-lg flex flex-col gap-5 p-5 relative">
      <div className="flex justify-between items-center">
        {title}
        {subtitle}
      </div>
      {children}
    </div>
  );
};
export const FoldingCard = ({
  title,
  content,
}: {
  title: string;
  content: ReactNode;
}) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <Disclosure as="div" className="w-full bg-gray-600 rounded-lg" defaultOpen>
      {({ open }) => (
        <>
          <dt>
            <Disclosure.Button
              className="flex w-full items-start justify-between text-left text-white p-5"
              onClick={toggleOpen}
            >
              <span className="text-xl font-bold flex items-center flex-col my-auto">
                {title}
              </span>
              <span className="flex items-center">
                <IconFont
                  size="xxl"
                  name="arrow_down"
                  className={`transition-transform ${open ? "rotate-180" : ""}`}
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel as="dd" className="p-5 pt-0">
              <>{content}</>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export const LoadingOverlay = ({ isLoading }: { isLoading: boolean }) => (
  <>
    {
      isLoading
      && (
        <div className="flex w-full h-full absolute bg-animated-gradient-gray-700-gray-800 left-0 top-0 opacity-70 z-10">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-pulse">Loading</div>
        </div>
      )
    }
  </>
);
