import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import base58 from "bs58";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { getData, getJwt, setData } from "../utils/supabase/supabase";
import { IGetDataResp, ISetDataResp } from "../utils/supabase/types";

interface IJwt {
    jwt: string
    expiry: Date
    walletPubkey: string
}

export interface ICachedDataInput {
    username?: string
    emailAddress?: string
    acceptPlatformNotifications?: boolean,
    acceptProjectUpdateNotifications?: boolean,
}

export interface IDataCachingContext {
    cachedData: IGetDataResp | undefined
    jwt: IJwt | undefined
    getCachedData: () => Promise<IGetDataResp | undefined>
    updateCachedData: (data: ICachedDataInput) => Promise<ISetDataResp | undefined>
}

export const DataCachingContext = createContext<IDataCachingContext>({} as IDataCachingContext);

interface Props {
    children: any;
}

export const DataCachingProvider = ({ children }: Props) => {
    // STATE VARS
    const [jwt, setJwt] = useLocalStorage('zeebit-caching-acccess', undefined)
    const [cachedData, setCachedData] = useLocalStorage('zeebit-cached-data', undefined)

    // CONTEXT
    const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext)

    const loadJwt = useCallback(async () => {
        if (solanaRpc == null || walletPubkey == null) {
            console.error('Wallet pubkey null, or solana client issue when loading access.')
            return undefined
        }

        const currentTime = new Date()

        const jwtMeta = jwt || {}

        if (jwtMeta == null || jwtMeta.expiry < currentTime || jwtMeta.walletPubkey != walletPubkey?.toString()) {
            const currentTime = Math.floor(Date.now() / 1000);

            const message = `sign in at: ${currentTime}`;
            const signature = await solanaRpc.signMessage!(new TextEncoder().encode(message));
            const encodedSignature = base58.encode(signature);
            const newJwt = await getJwt({
                message,
                signature: encodedSignature,
                wallet: walletPubkey?.toBase58(),
            })
            const expiry = new Date()
            expiry.setHours(expiry.getHours() + 1)
            const updatedJwt = {
                jwt: newJwt.jwt,
                expiry: expiry,
                walletPubkey: walletPubkey.toString()
            }

            setJwt(updatedJwt)

            return updatedJwt
        }

        return jwtMeta
    }, [walletPubkey, solanaRpc, jwt, setJwt])

    const getCachedData = useCallback(async () => {
        if (walletPubkey == null) {
            console.error('Wallet pubkey null when fetching data')
            return
        }

        try {
            const jwt = await loadJwt()

            if (jwt == null) {
                return
            }

            const newData = await getData({
                isPrivate: true,
                wallet: walletPubkey.toBase58(),
                jwt: jwt.jwt
            })

            if (newData != null) {
                setCachedData(newData)
            }

            return newData
        } catch (err) {
            console.error("Issue loading cached data", err)
            return
        }
    }, [walletPubkey, jwt, loadJwt])

    const updateCachedData = useCallback(async (data: ICachedDataInput) => {
        if (walletPubkey == null) {
            console.error('Wallet pubkey null when setting data')
            return
        }

        try {
            const jwt = await loadJwt()

            if (jwt == null) {
                return
            }

            const dataPayload = {
                ...data,
                isPrivate: true,
                wallet: walletPubkey.toBase58(),
                jwt: jwt.jwt
            }


            return await setData(dataPayload);
        } catch (err) {
            console.error("Issue setting cached data", err)
            return
        }
    }, [walletPubkey, jwt, loadJwt])

    useEffect(() => {
        console.log({
            cachedData,
            jwt
        })
    }, [cachedData, jwt])
    
    return (
        <DataCachingContext.Provider
            value={useMemo(
                () => ({
                    cachedData: cachedData,
                    jwt: jwt,
                    getCachedData: getCachedData,
                    updateCachedData: updateCachedData
                }),
                [cachedData, jwt, getCachedData, updateCachedData],
            )}
        >
            {children}
        </DataCachingContext.Provider>
    );
};
