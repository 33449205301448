import * as anchor from "@coral-xyz/anchor";
import { Commitment, PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerToken from "../playerToken";

export default class Limbo extends Game {
  constructor(
    house: House,
    gameSpecPubkey: PublicKey,
  ) {
    super(house, gameSpecPubkey);
  }

  static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateUpdates: boolean = false) {
    const game = new Limbo(house, gameSpecPubkey);
    await game.loadAllState(commitmentLevel, loadChildState, trackStateUpdates)
    return game;
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: object,
    wager: number,
    clientSeed: number[]
  ) {
    const numberOfBets = inputs.bets.length;
    const instanceRequest = {
      limbo: {
        numSpins: numberOfBets,
      },
    };

    const betRequests = inputs.bets?.map(({ wagerBasis, targetMultiplier }) => ({
      limbo: {
        targetMultiplierPerMillion: new anchor.BN(targetMultiplier * 1_000_000),
        wager: new anchor.BN(wagerBasis),
      },
    }));


    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      null,
      0,
      clientSeed
    );
  }
  get state() {
    return this.baseState
  }
  get gameConfig() {
    return this.state ? this.state.config.limbo : null;
  }
  get maxMultiplier() {
    return this.gameConfig != null
      ? Number(this.gameConfig.maxMultiplierPerMillion) / 1_000_000
      : null;
  }

  get houseEdge() {
    return this.gameConfig ? Number(this.gameConfig.edgePerMillion) / 1_000_000 : null;
  }

  get multiplierRoundingUnit() {
    return this.gameConfig ? Number(this.gameConfig.roundingDenominator) / 1_000_000 : null;
  }

  getMultiplier(inputs: object) {
    if (this.multiplierRoundingUnit == null) {
      return inputs.targetMultiplier;
    }

    return (inputs.targetMultiplier / this.multiplierRoundingUnit) * this.multiplierRoundingUnit;
  }

  getProbability(inputs: object) {
    if (this.houseEdge == null) {
      return 1 / inputs.targetMultiplier;
    }

    return (1 - this.houseEdge) / inputs.targetMultiplier;
  }

  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;

    bets.forEach((bet) => {
      const multiplier = this.getMultiplier(bet);

      const payoutOnBet = multiplier * bet.wager;
      const probability = this.getProbability(bet);

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wager;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      edgePercentage: edgeDollar / totalWager,
    };
  }
}
