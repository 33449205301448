import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NetworkContext } from "./NetworkContext";
import { APP_NETWORK_TYPE } from "../types/chain";
import { Button, IconFont } from "../components/common";
import { useLocalStorage } from "../hooks/useLocalStorage";

export interface ITopBannerContext {
  message: ReactNode;
}

export const TopBannerContext = createContext<ITopBannerContext>(
  {} as ITopBannerContext
);

interface Props {
  children: any;
}

export const IS_SONIC_TOP_BANNER_CLOSED = "zeebit-is-sonic-top-banner-closed"

export const TopBannerProvider = ({ children }: Props) => {
  const [message, setMessage] = useState<null | ReactNode>(null);
  const { chain } = useContext(NetworkContext);
  const [isSonicTopBannerClosed, setIsSonicTopBannerClosed] = useLocalStorage(IS_SONIC_TOP_BANNER_CLOSED, false);

  useEffect(() => {
    if (chain == APP_NETWORK_TYPE.SONIC && !isSonicTopBannerClosed) {
      setMessage(
        <>
          <div className="text-sm font-normal text-gray-50 uppercase">
            New to Zeebit on Sonic Odyssey? Read the guide to get started.
          </div>
          <a
            href="https://zeebit.gitbook.io/zeebit-sonic"
            target="_blank"
            className="no-underline"
          >
            <Button
              size="xxs"
              variant="primary"
              className="text-xs font-normal"
            >{`Read Guide`}</Button>
          </a>
          <button
            className="text-xs font-normal absolute right-4"
            onClick={() => {
              setIsSonicTopBannerClosed(true);
            }}
          >
            <IconFont name="close_md" />
          </button>
        </>
      );
    } else {
      setMessage(null);
    }
  }, [chain, isSonicTopBannerClosed]);

  return (
    <TopBannerContext.Provider
      value={useMemo(
        () => ({
          message: message,
        }),
        [message]
      )}
    >
      {children}
    </TopBannerContext.Provider>
  );
};
