import { useContext, useEffect, useState } from "react";

import { useModal } from "react-modal-hook";
import { RegistrationModal } from "../modals/RegistrationModal";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";
import {PlayerTokenContext} from "../contexts";

export function useOnboardingHandler() {
  const { walletPubkey } = useContext(WrappedWalletContext)
  const {
    playerToken, playerMeta, setPlayerMeta, hasNoPlayerTokenState
  } = useContext(PlayerTokenContext);
  const [currentStep, setCurrentStep] = useState(0);

  const [showRegistrationModal, hideRegistrationModal] = useModal(
    ({ in: open }) => (
      <RegistrationModal
        open={open}
        closeModal={() => {
          hideRegistrationModal();
          setCurrentStep(0);
        }}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
    ),
    [currentStep],
  );

  useEffect(() => {
    // CHECK WALLET CONNCTED AND THAT PLAYER TOKEN OWNER IS EQUAL TO WALLET
    if (walletPubkey == null || playerToken == null || walletPubkey.toString() != playerToken.ownerPubkey.toString()) {
      return
    }

    if (hasNoPlayerTokenState == true && !playerMeta?.signedTerms) {
      setCurrentStep(0);
      showRegistrationModal();
    }
  }, [hasNoPlayerTokenState, playerMeta, walletPubkey, playerToken]);

  // WIPE LOCAL STORAGE VALUES IF NEW WALLET IS CONNECTED
  useEffect(() => {
    const wallet = walletPubkey?.toString();

    if (wallet == null) {
      return;
    }

    if (playerMeta?.walletPubkey == null || playerMeta?.walletPubkey != wallet) {
      setPlayerMeta?.({
        walletPubkey: wallet,
        signedTerms: false
      });
    }
  }, [walletPubkey]);
}
