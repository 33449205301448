import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { ZeebitV2 } from "./program-types/solana_zeebit_v2"; 
import { PublicKey, AccountMeta, Keypair, GetVersionedBlockConfig, MemcmpFilter, sendAndConfirmTransaction, ComputeBudgetProgram, TransactionInstruction } from "@solana/web3.js";
import { listenForTransaction } from "./utils";
import { APPROXIMATE_MS_PER_SLOT } from "./constants";
import nacl from "tweetnacl";
import * as base58 from 'bs58'
import GameSpec from "./gameSpec";
import { toHouseStatus } from "./utils";
import { DELEGATION_PROGRAM_ID, DelegateAccounts, MAGIC_PROGRAM_ID } from "@magicblock-labs/delegation-program";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { MAGIC_CONTEXT_ID } from "@magicblock-labs/ephemeral-rollups-sdk";

export default class House {

    private _erProgram: Program<ZeebitV2>;
    private _baseProgram: Program<ZeebitV2>;
    private _housePubkey: PublicKey;
    private _oracleListPubkey: PublicKey;
    private _erState: anchor.IdlAccounts<ZeebitV2>["house"];
    private _baseState: anchor.IdlAccounts<ZeebitV2>["house"];
    private _oracleList: anchor.IdlAccounts<ZeebitV2>["houseOracleList"] | null;
    private _eventParser: anchor.EventParser;
    private _listenOnRollup: boolean;
    private _listenWebsocketId: number;
    private _keypair: Keypair;

    constructor(
        baseProgram: anchor.Program<ZeebitV2>,
        erProgram: anchor.Program<ZeebitV2>,
        housePubkey: PublicKey,
        keypair?: Keypair
    ) {
        this._baseProgram = baseProgram;
        this._erProgram = erProgram;
        this._housePubkey = housePubkey;
        this._oracleListPubkey = House.deriveHouseOracleListPubkey(this._housePubkey, this.baseProgram.programId);
        this._eventParser = new anchor.EventParser(
            this._baseProgram.programId,
            new anchor.BorshCoder(this._baseProgram.idl)
        );
        this._keypair = keypair;
    };

    static async load(
        baseProgram: anchor.Program<ZeebitV2>,
        erProgram: anchor.Program<ZeebitV2>,
        housePubkey: PublicKey,
        keypair?: Keypair,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const houseToken = new House(
            baseProgram,
            erProgram,
            housePubkey,
            keypair
        )
        await houseToken.loadBaseState(commitmentLevel);
        // await houseToken.loadOracleList(commitmentLevel);
        // if (houseToken.isDelegated) {
        //     houseToken.loadErState(commitmentLevel);
        // }
        return houseToken
    };

    async loadBaseState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.baseProgram.account.house.fetchNullable(
            this._housePubkey,
            commitmentLevel
        );
        if (state) {
            this._baseState = state;
        } else {
            throw new Error(`A valid account was not found at the pubkey provided: ${this.publicKey}`)
        }
        return
    }

    async loadErState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.erProgram.account.house.fetchNullable(
            this._housePubkey,
            commitmentLevel
        );
        if (state) {
            this._erState = state;
        } else {
            // throw new Error(`A valid account was not found at the pubkey provided: ${this.publicKey}`)
        }
        return
    }

    async loadOracleList(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.baseProgram.account.houseOracleList.fetchNullable(
            this.oracleListPubKey,
            commitmentLevel
        );
        if (state) {
            this._oracleList = state;
        } else {
            throw new Error(`A valid account was not found at the pubkey provided: ${this.oracleListPubKey}`)
        }
        return
    }

    static deriveHousePubkey(
        houseId: number,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house"),
                new anchor.BN(houseId).toArrayLike(Buffer, 'le', 8)
            ],
            programId
        );
        return pk
    };

    static deriveHouseOracleListPubkey(
        housePubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("oracle_list"),
                housePubkey.toBuffer(),
            ],
            programId
        );
        return pk
    };

    static deriverPermissionPubkey(
        housePubkey: PublicKey,
        authorityPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("permission"),
                housePubkey.toBuffer(),
                authorityPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    housePayerPubkey(): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house_payer"),
                this.publicKey.toBuffer()
            ],
            this.programId
        );
        return pk
    };

    async predelegateUpdateSlipIx(
        payer: PublicKey,
        updateSlipPubkey: PublicKey,
        relatedAccountPubkey: PublicKey
    ): Promise<TransactionInstruction> {
            const {
                delegationPda,
                delegationMetadata,
                bufferPda,
                commitStateRecordPda,
                commitStatePda,
            } = DelegateAccounts(
                updateSlipPubkey, 
                this._baseProgram.programId
            );

            return await this.baseProgram.methods.updateSlipPredelegate(
                {}
            ).accounts({
                payer: payer,
                relatedAccount: relatedAccountPubkey,
                updateSlip: updateSlipPubkey,
                buffer: bufferPda,
                delegationRecord: delegationPda,
                delegationMetadata: delegationMetadata,
                ownerProgram: this.baseProgram.programId,
                delegationProgram: DELEGATION_PROGRAM_ID,
                systemProgram: anchor.web3.SystemProgram.programId
            }).instruction()
    };

    async applyPlayerTokenDepositIxn(
        payerPubkey: PublicKey,
        updateSlipPubkey: PublicKey,
        playerTokenPubkey: PublicKey,
        houseToken: PublicKey,
    ): Promise<TransactionInstruction> {  
        return await this.erProgram.methods.playerTokenDepositApply(
            {}
        ).accounts({
            payer: payerPubkey,
            updateSlip: updateSlipPubkey,
            playerToken: playerTokenPubkey,
            houseToken: houseToken,
            magicProgram: MAGIC_PROGRAM_ID,
            magicContext: MAGIC_CONTEXT_ID
        }).instruction();
    };

    async applyPlayerTokenWithdrawIxn(
        updateSlipPubkey: PublicKey,
        rentRecipientPubkey: PublicKey,
        ownerPubkey: PublicKey,
        playerTokenPubkey: PublicKey,
        houseTokenPubkey: PublicKey,
        houseTokenBankPubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        vaultPubkey: PublicKey,
        tokenAccountPubkey: PublicKey
    ): Promise<TransactionInstruction> {  
        return await this.erProgram.methods.playerTokenWithdrawApply(
            {}
        ).accounts({
            updateSlip: updateSlipPubkey,
            rentRecipient: rentRecipientPubkey,
            owner: ownerPubkey,
            playerToken: playerTokenPubkey,
            houseToken: houseTokenPubkey,
            houseTokenBank: houseTokenBankPubkey,
            tokenMint: tokenMintPubkey,
            vault: vaultPubkey,
            tokenAccount: tokenAccountPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();
    };

    async closeUpdateSlipIxn(
        updateSlipPubkey: PublicKey,
        rentRecipientPubkey: PublicKey,
    ): Promise<TransactionInstruction> {  
        return await this.baseProgram.methods.updateSlipClose(
            {}
        ).accounts({
            updateSlip: updateSlipPubkey,
            rentRecipient: rentRecipientPubkey
        }).instruction();
    };

    get oracleListPubkey() {
        return House.deriveHouseOracleListPubkey(this.publicKey, this.programId)
    }

    get baseProgram() {
        return this._baseProgram
    }

    get erProgram() {
        return this._erProgram
    }

    get programId() {
        return this._baseProgram.programId
    }

    get publicKey() {
        return this._housePubkey
    }

    get oracleListPubKey() {
        return this._oracleListPubkey
    }

    get eventParser() {
        return this._eventParser
    }

    get baseState() {
        return this._baseState
    }

    get erState() {
        return this._erState
    }

    get oracelList() {
        return this._oracleList
    }

    get status() {
        return this.baseState ? toHouseStatus(this.baseState.status) : null;
    }

    get isDelegated() {
        // TODO - GET DELEGATED STATUS
        return this.baseState != null && Object.keys(this.baseState.status)[0] != "active"
    }
    async tryGetBlockhash(
        program: anchor.Program,
        slotNumber: number
    ): Promise<Buffer | null> {
        // TODO: Retry logic
        try {
            const blockInfo = await program.provider.connection.getBlock(
                slotNumber,
                {
                    commitment: "confirmed",
                    transactionDetails: "none",
                    maxSupportedTransactionVersion: 0,
                    rewards: false
                } as GetVersionedBlockConfig
            );
            const blockhashString = blockInfo.blockhash;
            const blockhashBytes = blockhashString ? base58.decode(blockhashString) : null;
            return Buffer.from(blockhashBytes)
        } catch {
            return null
        }
    };

    async waitForBlockhash(
        program: anchor.Program,
        slotNumber: number
    ): Promise<[number, Buffer]> {
        var blockash: Buffer = null;
        var currentSlot: number;
        var slotUsed = slotNumber;
        do {
            currentSlot = await program.provider.connection.getSlot("confirmed");
            console.log(`[${(new Date()).toISOString().slice(11, 19)}] Waiting for blockhash at slot ${slotNumber} (Current slot: ${currentSlot})`)
            const slotAway = slotNumber - currentSlot;
            const waitTimeMs = APPROXIMATE_MS_PER_SLOT * slotAway * 0.9; // Be a little aggressive in rechecking
            if (waitTimeMs > 0) {
                await new Promise(f => setTimeout(f, waitTimeMs));
            }

            blockash = await this.tryGetBlockhash(program, slotNumber)

            if (currentSlot > slotNumber && blockash == null) {
                // OTHERWISE
                if (blockash == null) {
                    // TODO: Fix this -- temporary solution only
                    return [slotNumber, Buffer.from("nHJBQHj6KRQ8Jcm98iMxBX5Sb2yE25xKhEX4SRBNynY")]
                    // return Promise.reject(`Blockhash not found for slot currentSlot: ${currentSlot}, slot we want: ${slotNumber}`)
                }
            }
        } while (blockash == null);
        console.log(`[${(new Date()).toISOString().slice(11, 19)}] Got blockhash for ${slotUsed} `)
        return [slotUsed, blockash];
    };



    async getRandomness(
        keypair: Keypair,
        program: anchor.Program,
        slotNumber: number,
        clientSeed: Buffer,
        interactionNonce: number,
    ): Promise<[Buffer, anchor.BN, Buffer]> {

        const [slotUsed, blockhashUsed] = await this.waitForBlockhash(
            program,
            slotNumber
        );

        const messageBytes = Buffer.concat(
            [
                clientSeed, // 4
                new anchor.BN(interactionNonce).toBuffer("le", 2), // 2
                blockhashUsed // 32
            ],
            38
        );
        const responseData = nacl.sign.detached(
            messageBytes,
            keypair.secretKey
        );
        return [
            Buffer.from(responseData),
            new anchor.BN(slotUsed),
            blockhashUsed
        ]
    }

    async prepareRandomnessResponseIxn(
        keypair: Keypair,
        program: anchor.Program,
        slot: anchor.BN,
        clientSeed: number[],
        interactionNonce: number,
        timing: any,
        callbackDiscriminator: number[],
        callbackProgramId: PublicKey,
        callbackAccounts: anchor.IdlTypes<ZeebitV2>["CallbackAccount"][],
    ): Promise<anchor.web3.TransactionInstruction> {
        try {
            console.log('prepareRandomnessResponseIxn');
            console.log('timing.timestamp:', timing.timestamp);
            console.log('Number(timing.timestamp?.timestamp):', Number(timing.timestamp?.timestamp));
            console.log('(Date.now()/1000):', (Date.now() / 1000));

            if ((Date.now() / 1000) < Number(timing.timestamp?.timestamp)) {
                // Sleep for this period
                const waitSeconds = Number(timing.timestamp?.timestamp) - (Date.now() / 1000);
                console.log(`Waiting ${waitSeconds.toFixed(1)} seconds...`)
                await new Promise(() => setTimeout(() => { }, waitSeconds * 1000));
            } else {
                // TODO: Handle Slot delay
            }

            const [randomness, slotUsed, blockhash] = await this.getRandomness(
                keypair,
                program,
                Number(slot),
                Buffer.from(clientSeed),
                interactionNonce
            )
            const responseBuffer = Buffer.concat([
                Buffer.from(callbackDiscriminator),
                new anchor.BN(0).toBuffer("le", 1), // 0 = OracleResponse::Randomness
                randomness
            ]);

            let responseIxn = new anchor.web3.TransactionInstruction({
                data: responseBuffer,
                keys: [
                    { pubkey: program.provider.publicKey, isSigner: true, isWritable: false } as AccountMeta
                ].concat(
                    callbackAccounts.map((x) => {
                        return { pubkey: x.address, isSigner: false, isWritable: Object.keys(x.info)[0] == "writeable" } as AccountMeta
                    })
                ),
                programId: callbackProgramId
            });
            return responseIxn
        } catch (e) {
            console.error(e)
        }
    }

    async prepareInactivityVoidResponseIxn(
        program: anchor.Program,
        callbackDiscriminator: number[],
        callbackProgramId: PublicKey,
        callbackAccounts: anchor.IdlTypes<ZeebitV2>["CallbackAccount"][],
    ): Promise<anchor.web3.TransactionInstruction> {
        try {
            const responseBuffer = Buffer.from(callbackDiscriminator);
            let responseIxn = new anchor.web3.TransactionInstruction({
                data: responseBuffer,
                keys: [
                    { pubkey: program.provider.publicKey, isSigner: true, isWritable: false } as AccountMeta
                ].concat(
                    callbackAccounts.map((x) => {
                        return { pubkey: x.address, isSigner: false, isWritable: Object.keys(x.info)[0] == "writeable" } as AccountMeta
                    })
                ),
                programId: callbackProgramId
            });
            return responseIxn
        } catch (e) {
            console.error(e)
        }
    }



    async respondToRequestEvent(
        eventData: anchor.IdlEvents<ZeebitV2>["OracleRequest"],
        commitmentLevel
    ) {
        try {
            const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
            // console.log('respondToRequestEvent: ', this._listenOnRollup, program.provider.connection.rpcEndpoint)     
            var responseIxn: anchor.web3.TransactionInstruction;
            switch (Object.keys(eventData.request)[0]) {
                case "randomnessRequest": {
                    responseIxn = await this.prepareRandomnessResponseIxn(
                        this._keypair,
                        program,
                        eventData.request.randomnessRequest?.slot,
                        eventData.request.randomnessRequest?.clientSeed,
                        eventData.request.randomnessRequest?.interactionNonce,
                        eventData.request.randomnessRequest?.timing,
                        eventData.callback.discriminator,
                        eventData.callback.program,
                        eventData.callback.callbackAccounts
                    );
                }
            }
            if (responseIxn != undefined) {
                let responseTxn = new anchor.web3.Transaction().add(responseIxn);
                responseTxn.feePayer = program.provider.publicKey;
                responseTxn.recentBlockhash = (
                    await program.provider.connection.getLatestBlockhash()
                ).blockhash;
                const txHash = await program.provider.sendAndConfirm(
                    responseTxn,
                    [this._keypair],
                    { skipPreflight: true }
                );
                console.log('RESPONSE:', txHash);
                // if (onSuccessfulSendCallback) {
                //     onSuccessfulSendCallback(txHash);
                // };
                listenForTransaction(
                    program.provider.connection,
                    txHash,
                    commitmentLevel, //confirmationLevel,
                    console.log, //onSuccessfulConfirmCallback,
                    console.log, //onErrorCallback
                )
            }
        } catch (e) {
            console.error(e)
        }
    }

    async respondToInstanceSolo(
        instancePubkey: PublicKey,
        instanceSolo: anchor.IdlAccounts<ZeebitV2>["instanceSolo"],
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        try {
            const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
            // console.log('respondToRequestEvent: ', this._listenOnRollup, program.provider.connection.rpcEndpoint)  
            const houseToken = await program.account.houseToken.fetchNullable(instanceSolo.houseToken);
            const tokenMintPubkey = houseToken.tokenMint;
            var responseIxn: anchor.web3.TransactionInstruction;

            const playerToken = await program.account.playerToken.fetchNullable(instanceSolo.playerToken);

            const payer = this._listenOnRollup ? playerToken.owner: this.housePayerPubkey()

            switch (Object.keys(instanceSolo.oracleInfo)[0]) {
                case "randomnessRequest": {
                    responseIxn = await this.prepareRandomnessResponseIxn(
                        this._keypair,
                        program,
                        instanceSolo.oracleInfo.randomnessRequest?.slot,
                        instanceSolo.oracleInfo.randomnessRequest?.clientSeed,
                        instanceSolo.oracleInfo.randomnessRequest?.interactionNonce,
                        instanceSolo.oracleInfo.randomnessRequest?.timing,
                        GameSpec.deriveDisciminatorForResponse("soloSimple"),
                        program.programId,
                        [
                            { address: this.publicKey, info: { readOnly: {} } },
                            { address: this.oracleListPubKey, info: { readOnly: {} } },
                            { address: instanceSolo.identifier, info: { readOnly: {} } },
                            { address: instanceSolo.gameSpec, info: { readOnly: {} } },
                            { address: GameSpec.deriveGameSpecTokenPubkey(instanceSolo.gameSpec, tokenMintPubkey, program.programId), info: { readOnly: {} } },
                            { address: instanceSolo.playerToken, info: { writeable: {} } },
                            { address: instanceSolo.houseToken, info: { writeable: {} } },
                            { address: instancePubkey, info: { writeable: {} } },
                            { address: payer, info: { writeable: {}} },
                            { address: anchor.web3.SystemProgram.programId, info: { readOnly: {} } },
                        ]
                    );
                };
                case "inactivityVoidRequest": {
                    if (
                        Number(instanceSolo.oracleInfo.inactivityVoidRequest?.timing.timestamp?.timestamp) < (Date.now() / 1000)
                        // TODO: Add slot based condition
                    ) {
                        responseIxn = await this.prepareInactivityVoidResponseIxn(
                            program,
                            GameSpec.deriveDisciminatorForExpire("soloSimple"),
                            program.programId,
                            [
                                { address: this.publicKey, info: { readOnly: {} } },
                                { address: this.oracleListPubKey, info: { readOnly: {} } },
                                { address: instanceSolo.identifier, info: { readOnly: {} } },
                                { address: instanceSolo.gameSpec, info: { readOnly: {} } },
                                { address: GameSpec.deriveGameSpecTokenPubkey(instanceSolo.gameSpec, tokenMintPubkey, program.programId), info: { readOnly: {} } },
                                { address: instanceSolo.playerToken, info: { writeable: {} } },
                                { address: instanceSolo.houseToken, info: { writeable: {} } },
                                { address: instancePubkey, info: { writeable: {} } },
                                { address: anchor.web3.SystemProgram.programId, info: { readOnly: {} } },
                            ]
                        );
                    }
                };
            }

            if (responseIxn != undefined) {
                let responseTxn = new anchor.web3.Transaction().add(responseIxn);
                responseTxn.feePayer = program.provider.publicKey;
                responseTxn.recentBlockhash = (
                    await program.provider.connection.getLatestBlockhash()
                ).blockhash;

                const txHash = await sendAndConfirmTransaction(program.provider.connection, responseTxn,
                    [this._keypair],
                    { skipPreflight: true })

                // const txHash = await program.provider.sendAndConfirm(
                //     responseTxn,
                //     [this._keypair],
                //     {skipPreflight: true}
                // );
                console.log('RESPONSE:', txHash);
                // if (onSuccessfulSendCallback) {
                //     onSuccessfulSendCallback(txHash);
                // };
                listenForTransaction(
                    program.provider.connection,
                    txHash,
                    commitmentLevel, //confirmationLevel,
                    console.log, //onSuccessfulConfirmCallback,
                    console.log, //onErrorCallback
                )
            }

        } catch (e) {
            console.error(e)
        }
    }

    async respondToInstanceMulti(
        instancePubkey: PublicKey,
        instanceMulti: anchor.IdlAccounts<ZeebitV2>["instanceMulti"],
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        try {
            console.log('respondToInstanceMulti');
            const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
            var responseIxn: anchor.web3.TransactionInstruction;
            console.log(instanceMulti.status);
            if (instanceMulti.status?.awaitingOracleResponse || instanceMulti.status?.active) {
                switch (Object.keys(instanceMulti.oracleInfo)[0]) {
                    case "randomnessRequest": {
                        responseIxn = await this.prepareRandomnessResponseIxn(
                            this._keypair,
                            program,
                            instanceMulti.oracleInfo.randomnessRequest?.slot,
                            instanceMulti.oracleInfo.randomnessRequest?.clientSeed,
                            instanceMulti.oracleInfo.randomnessRequest?.interactionNonce,
                            instanceMulti.oracleInfo.randomnessRequest?.timing,
                            GameSpec.deriveDisciminatorForResponse("multiplayerMultiToken"),
                            program.programId,
                            [
                                { address: this.publicKey, info: { readOnly: {} } },
                                { address: this.oracleListPubKey, info: { readOnly: {} } },
                                { address: instanceMulti.identifier, info: { readOnly: {} } },
                                { address: instanceMulti.gameSpec, info: { readOnly: {} } },
                                { address: instancePubkey, info: { writeable: {} } },
                                { address: anchor.web3.SystemProgram.programId, info: { readOnly: {} } },
                            ]
                        );
                    };
                }


                if (responseIxn != undefined) {
                    let responseTxn = new anchor.web3.Transaction().add(responseIxn);
                    responseTxn.feePayer = program.provider.publicKey;
                    responseTxn.recentBlockhash = (
                        await program.provider.connection.getLatestBlockhash()
                    ).blockhash;

                    const txHash = await program.provider.sendAndConfirm(
                        responseTxn,
                        [this._keypair],
                        { skipPreflight: true }
                    );
                    console.log('RESPONSE:', txHash);
                    // if (onSuccessfulSendCallback) {
                    //     onSuccessfulSendCallback(txHash);
                    // };
                    listenForTransaction(
                        program.provider.connection,
                        txHash,
                        commitmentLevel, //confirmationLevel,
                        console.log, //onSuccessfulConfirmCallback,
                        console.log, //onErrorCallback
                    )
                }
            }

            await this.settleInstanceTokenMultis(
                instancePubkey,
                instanceMulti,
                commitmentLevel
            );

        } catch (e) {
            console.error(e)
        }
    }

    async settleInstanceTokenMultis(
        instancePubkey: PublicKey,
        instanceMulti: anchor.IdlAccounts<ZeebitV2>["instanceMulti"],
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        try {
            console.log('settleInstanceMulti');
            const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
            var settleIxns: anchor.web3.TransactionInstruction[] = [];
            const instanceTokensMultis = await this.getOpenMultiInstanceTokens(instancePubkey);
            console.log('instanceTokensMultis');
            console.log(instanceTokensMultis);

            for (let i = 0; i < instanceTokensMultis.length; i++) {

                const instanceTokensMultiState = instanceTokensMultis[i].account;
                const instanceTokenPubkey = instanceTokensMultis[i].key;
                const playerTokensPubkeys: string[] = instanceTokensMultiState.bets.map(
                    (b) => (b.extension.multi.playerToken.toString())
                );
                // TODO: Chunking playerTokens into ixns
                const remainingAccounts = [...new Set(playerTokensPubkeys)].map((pk) => (
                    { pubkey: new PublicKey(pk), isSigner: false, isWritable: true } as AccountMeta
                ));
                var keys = [
                    { pubkey: this.publicKey, isSigner: false, isWritable: false } as AccountMeta,              // house
                    { pubkey: instanceMulti.identifier, isSigner: false, isWritable: false } as AccountMeta,     //identifier
                    { pubkey: instanceMulti.gameSpec, isSigner: false, isWritable: true } as AccountMeta,      // gameSpec
                    { pubkey: instanceTokensMultiState.gameSpecToken, isSigner: false, isWritable: true } as AccountMeta,         // gameSpecToken
                    { pubkey: instanceTokensMultiState.houseToken, isSigner: false, isWritable: true } as AccountMeta,    // houseToken
                    { pubkey: instancePubkey, isSigner: false, isWritable: true } as AccountMeta,    // instance
                    { pubkey: instanceTokenPubkey, isSigner: false, isWritable: true } as AccountMeta,    // instanceToken
                    { pubkey: anchor.web3.SystemProgram.programId, isSigner: false, isWritable: false } as AccountMeta,    // systemProgram
                ];
                keys = keys.concat(remainingAccounts);
                settleIxns.push(
                    new anchor.web3.TransactionInstruction({
                        data: Buffer.from(GameSpec.deriveDisciminatorForSettle("multiplayerMultiToken")),
                        keys: keys,
                        programId: this.programId
                    })
                );
            }
            console.log('settleIxns');
            console.log(settleIxns);

            // TODO: Chunking ixns per txn

            settleIxns.forEach(async (si) => {

                let settleTxn = new anchor.web3.Transaction();
                settleTxn.add(ComputeBudgetProgram.setComputeUnitLimit({ units: 400_000 }));
                settleTxn.add(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1_000 }));
                settleTxn.add(si);
                settleTxn.feePayer = program.provider.publicKey;
                settleTxn.recentBlockhash = (
                    await program.provider.connection.getLatestBlockhash()
                ).blockhash;

                const txHash = await program.provider.sendAndConfirm(
                    settleTxn,
                    [this._keypair],
                    { skipPreflight: true }
                );
                console.log('SETTLE:', txHash);
                // if (onSuccessfulSendCallback) {
                //     onSuccessfulSendCallback(txHash);
                // };
                listenForTransaction(
                    program.provider.connection,
                    txHash,
                    commitmentLevel, //confirmationLevel,
                    console.log, //onSuccessfulConfirmCallback,
                    console.log, //onErrorCallback
                )
            })

        } catch (e) {
            console.error(e)
        }
    }

    async actionRequestEventCallback(
        logs: any,
        context: any,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        if (logs.err) {
            // Skip error'd transactions
        } else {
            const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
            const events = this.eventParser.parseLogs(
                logs.logs
            );
            for (let event of events) {
                if (event.name == "RandomnessRequest") {
                    console.log(`[${(new Date()).toISOString().slice(11, 19)}] Request Event Received:  ${event.name}`)
                    await this.respondToRequestEvent(
                        event.data as anchor.IdlEvents<ZeebitV2>["OracleRequest"],
                        commitmentLevel,
                        // undefined,
                        // undefined,
                        // handleRandomnessError
                    );
                } else {
                    console.log(event)
                }
            };
        };
    }

    async closeListenForLogs() {
        const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
        if (this._listenWebsocketId != undefined) {
            await program.provider.connection.removeOnLogsListener(
                this._listenWebsocketId
            );
            this._listenWebsocketId = undefined;
        }
    }
    async listenForLogs(
        callbackFunction: (logs: any, context: any) => void,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
        await this.closeListenForLogs();
        const websocketId = await program.provider.connection.onLogs(
            this.programId,
            callbackFunction,
            commitmentLevel,
        );
        this._listenWebsocketId = websocketId;
    };

    async getOpenSoloInstances(
        commitmentLevel: anchor.web3.Commitment = "processed",
    ): Promise<{ key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceSolo"] }[]> {
        const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
        let f = await program.account.instanceSolo.all(
            // [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as MemcmpFilter ],     // For this GameSpec
        );
        var instanceList: { key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceSolo"] }[] = [];
        console.log(f);
        f.forEach((pb) => {
            instanceList.push(
                { key: pb.publicKey, account: pb.account }
            )
        })
        return instanceList
    }

    async getOpenMultiInstances(
        commitmentLevel: anchor.web3.Commitment = "processed",
    ): Promise<{ key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceMulti"] }[]> {
        console.log('getOpenMultiInstances');
        const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
        let f = await program.account.instanceMulti.all(
            // [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as MemcmpFilter ],     // For this GameSpec
        );
        var instanceList: { key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceMulti"] }[] = [];
        f.forEach((pb) => {
            instanceList.push(
                { key: pb.publicKey, account: pb.account }
            )
        })
        return instanceList
    }

    async getOpenMultiInstanceTokens(
        instanceMultiPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed",
    ): Promise<{ key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceTokenMulti"] }[]> {
        const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
        let f = await program.account.instanceTokenMulti.all(
            [{ memcmp: { offset: 8, bytes: instanceMultiPubkey.toBase58() } } as MemcmpFilter],     // For this InstanceMulti
        );
        var instanceTokenList: { key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceTokenMulti"] }[] = [];
        f.forEach((pb) => {
            instanceTokenList.push(
                { key: pb.publicKey, account: pb.account }
            )
        })
        return instanceTokenList
    }

    async startRandomnessOracle(
        onRollUp?: boolean,
        commitmentLevel: anchor.web3.Commitment = "processed",
    ) {
        this._listenOnRollup = onRollUp ? true : false;
        this.listenForLogs(
            await this.actionRequestEventCallback.bind(this),
            commitmentLevel
        );
    }

    async respondToAllOpenSoloInstance(
        onRollUp?: boolean,
        commitmentLevel: anchor.web3.Commitment = "processed",
    ) {
        this._listenOnRollup = onRollUp ? true : false;
        try {
            const openSoloInstances = await this.getOpenSoloInstances();
            console.log(openSoloInstances)
            openSoloInstances.forEach(async (si) => {
                await this.respondToInstanceSolo(
                    si.key,
                    si.account,
                    commitmentLevel
                )
            });
        } catch (e) {
            console.error(e)
        }
    }

    async respondToAllOpenMultiInstance(
        onRollUp?: boolean,
        commitmentLevel: anchor.web3.Commitment = "processed",
    ) {
        console.log('respondToAllOpenMultiInstance');
        this._listenOnRollup = onRollUp ? true : false;
        try {
            const openSoloInstances = await this.getOpenMultiInstances();
            console.log(openSoloInstances)
            openSoloInstances.forEach(async (sm) => {
                await this.respondToInstanceMulti(
                    sm.key,
                    sm.account,
                    commitmentLevel
                )
            });
        } catch (e) {
            console.error(e)
        }
    }


    static async initializeHouse(
        baseProgram: anchor.Program<ZeebitV2>,
        erProgram: anchor.Program<ZeebitV2>,
        houseId: number,
        confirmationLevel: anchor.web3.Commitment = "processed",
        onSuccessfulSendCallback?: Function,
        onSuccessfulConfirmCallback?: Function,
        onErrorCallback?: Function,
    ): Promise<House> {
        try {
            const housePubkey = House.deriveHousePubkey(
                houseId,
                baseProgram.programId
            );
            const permissionPubkey = House.deriverPermissionPubkey(
                housePubkey,
                baseProgram.provider.publicKey,
                baseProgram.programId
            );
            const oracleListPubkey = House.deriveHouseOracleListPubkey(
                housePubkey,
                baseProgram.programId
            );

            const tx = await baseProgram.methods.houseInitialize({
                id: new anchor.BN(houseId)
            }).accounts({
                payer: baseProgram.provider.publicKey,
                house: housePubkey,
                authority: baseProgram.provider.publicKey,
                permission: permissionPubkey,
                oracleList: oracleListPubkey,
                systemProgram: anchor.web3.SystemProgram.programId
            }).signers(
                []
            ).rpc(
                { skipPreflight: true }
            );

            console.log(tx);

            if (onSuccessfulSendCallback) {
                onSuccessfulSendCallback(tx);
            };

            listenForTransaction(
                baseProgram.provider.connection,
                tx,
                confirmationLevel,
                onSuccessfulConfirmCallback,
                onErrorCallback
            )

            return await House.load(
                baseProgram,
                erProgram,
                housePubkey
            );

        } catch (err) {
            if (onErrorCallback) {
                onErrorCallback(err);
            } else {
                console.error(err);
            }
        }
    };


    async addOrUpdateOracleList(
        oraclePubkey: PublicKey,
        remove: boolean,
        confirmationLevel: anchor.web3.Commitment = "processed",
        onSuccessfulSendCallback?: Function,
        onSuccessfulConfirmCallback?: Function,
        onErrorCallback?: Function,
    ) {
        try {

            const permissionPubkey = House.deriverPermissionPubkey(
                this.publicKey,
                this.baseProgram.provider.publicKey,
                this.baseProgram.programId
            );

            const tx = await this.baseProgram.methods.houseOracleListAddOrUpdate({
                remove: remove
            }).accounts({
                authority: this.baseProgram.provider.publicKey,
                permission: permissionPubkey,
                house: this.publicKey,
                oracleList: this.oracleListPubkey,
                oracle: oraclePubkey,
                systemProgram: anchor.web3.SystemProgram.programId
            }).signers(
                []
            ).rpc(
                { skipPreflight: true }
            );

            console.log(tx);

            if (onSuccessfulSendCallback) {
                onSuccessfulSendCallback(tx);
            };

            listenForTransaction(
                this.baseProgram.provider.connection,
                tx,
                confirmationLevel,
                onSuccessfulConfirmCallback,
                onErrorCallback
            )

        } catch (err) {
            if (onErrorCallback) {
                onErrorCallback(err);
            } else {
                console.error(err);
            }
        }

    };

}



