import * as anchor from "@coral-xyz/anchor";
import { Commitment, PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerToken from "../playerToken";

export enum PlinkoDifficultyLevel {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export type PlinkoBet = {
  wager: number;
  wagerBasis: number;
  maxMultiplier: number;
  houseEdge: number | undefined;
}
export type PlinkoInputs = {
  bets: PlinkoBet[],
  numberOfRows: number,
  difficultyLevel: number;
  numberOfBalls: number;
  maxMultiplier: number;
}
export default class Plinko extends Game {
  constructor(
    house: House,
    // hseTkn: HouseToken,
    gameSpecPubkey: PublicKey
  ) {
    super(house, gameSpecPubkey);
  }

  static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateUpdates: boolean = false) {
    const game = new Plinko(house, gameSpecPubkey);
    await game.loadAllState(commitmentLevel, loadChildState, trackStateUpdates)
    return game;
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: PlinkoInputs,
    wager: number,
    clientSeed: number[]
  ) {
    const instanceRequest = {
      plinko: {
        numRows: inputs.numberOfRows,
        difficultyLevel: inputs.difficultyLevel,
        numBalls: inputs.numberOfBalls,
      },
    };

    const betRequests = inputs.bets.map((bet) => (
      {
        plinko: {
          wager: new anchor.BN(bet.wagerBasis),
        }
      }
    ));
    const numberOfBets = inputs.numberOfBalls;

    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      null,
      0,
      clientSeed
    );
  }
  get state() {
    return this.baseState
  }
  get gameConfig() {
    return this.state ? this.state.config.plinko : null;
  }
  get maxMultiplier() {
    return this.gameConfig
      ? Number(this.gameConfig.maxMultiplierPerMillion) / 1_000_000
      : null;
  }

  get houseEdge() {
    return this.gameConfig ? Number(this.gameConfig.edgePerMillion) / 1_000_000 : null;
  }

  get multiplierRoundingUnit() {
    return this.gameConfig ? Number(this.gameConfig.roundingDenominator) / 1_000_000 : null;
  }

  getMultiplier(inputs: PlinkoBet) {
    // TODO - CHECK ON ROUNDING UNIT
    // if (this.multiplierRoundingUnit == null) {
    //   return inputs.maxMultiplier;
    // }

    // return (inputs.maxMultiplier / this.multiplierRoundingUnit) * this.multiplierRoundingUnit;

    return inputs.maxMultiplier;
  }

  getHouseEdge(inputs: PlinkoBet) {
    // TODO - CHECK ON ROUNDING UNIT
    // if (this.multiplierRoundingUnit == null) {
    //   return inputs.maxMultiplier;
    // }

    // return (inputs.maxMultiplier / this.multiplierRoundingUnit) * this.multiplierRoundingUnit;
    if (inputs == null || "houseEdge" in inputs == false) {
      return;
    }

    return inputs.houseEdge;
  }

  getProbability(inputs: PlinkoBet) {
    const multiplier = this.getMultiplier(inputs);
    const houseEdge = this.getHouseEdge(inputs);
    if (houseEdge == null) {
      return 1 / multiplier;
    }
    return (1 - houseEdge) / multiplier;
  }

  getBetMetas(bets: PlinkoBet[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollars = 0;

    bets.forEach((bet) => {
      console.log({ bet });

      const multiplier = this.getMultiplier(bet);
      const payoutOnBet = multiplier * bet.wager;
      const probability = this.getProbability(bet);
      console.log({ payoutOnBet, multiplier, probability });

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollars += (1 - multiplier * probability) * bet.wager;
    });

    console.log({
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollars: edgeDollars,
      edgePercentage: edgeDollars / totalWager,
    });


    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollars: edgeDollars,
      edgePercentage: edgeDollars / totalWager,
    };
  }
}
