import { APP_NETWORK_TYPE } from "../types/chain"

// WALLETS ALLOWED ON EACH CHAIN
export const ENABLED_SOLANA_WALLETS = ['Phantom', 'Backpack', 'Solflare', 'MetaMask']
export const ENABLED_SONIC_WALLETS = ['OKX Wallet', 'Backpack']
export const ENABLED_MB_AND_SOLANA = ['Phantom', 'Backpack', 'Solflare', 'MetaMask'] // VERIFY THIS WHEN TESTING MAGIC BLOCK

export const getEnabledWalletsForChain = (chain: APP_NETWORK_TYPE) => {
    switch(chain) {
        case APP_NETWORK_TYPE.SOLANA:
            return ENABLED_SOLANA_WALLETS;
        case APP_NETWORK_TYPE.SONIC:
            return ENABLED_SONIC_WALLETS;
        case APP_NETWORK_TYPE.MB_AND_SOLANA:
            return ENABLED_MB_AND_SOLANA;
    }
}