import React, { FC, useContext, useMemo } from "react";

import { ModalProps } from "./types";
import { BaseModal } from "../common/modal/Modal";
import { truncatePubkey } from "../../utils/string/string";
import IconByUrl from "../common/icon/Icon";
import Player, {IPlayerToken} from "../../sdk/playerAccount";
import { formatCurrency } from "../../utils/currency/formatting";
import { loadPlayerAvatar, getPlayerUsername, getPlayerWageredMeta } from "../../utils/player";
import PentagonIcon from "../../assets/icons/pentagon.svg";
import Table from "../common/table/Table";
import { useRankMeta } from "../../hooks/useRankMeta";
import { AggregatedBalancesContext } from "../../contexts/AggregatedBalancesContext";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import Icon from "../common/icon/Icon";
import CopyIcon from "../../assets/icons/copy.svg";
import Scrollbar from "../common/scrollbar/Scrollbar";
import { RankIcon } from "../rewards/RankIcon";
import { CurrentPlayerAvatar, PlayerAvatar } from "../common";
import Tooltip from "../common/tooltip/Tooltip";
import { RankTabs } from "./UserRankModal";
import { NavigateFunction } from "react-router-dom";
import { NetworkContext } from "../../contexts/NetworkContext";

export interface IUserOverlayModal extends ModalProps {
  player: Player | undefined;
  navigate: NavigateFunction
}

interface IWagerTableItem {
  id: string;
  asset: {
    value: string | undefined;
    prefix: JSX.Element;
  };
  bets: string;
  won: string;
  lost: string;
  wagered: {
    value: string;
    suffix: JSX.Element;
  };
}

const UserOverlayModal: FC<IUserOverlayModal> = ({
  visible,
  hideModal,
  player,
  navigate
}) => {
  const { mergedTokens } = useContext(AggregatedBalancesContext);

  const isHiddenAccount = false

  const { rankMeta } = useRankMeta(player);
  const { isCopiedToClipboard, copyToClipboard } = useCopyToClipboard("Successfully copied wallet public key to clipboard.");
  const { platformTokens } = useContext(NetworkContext)

  const baseDecimals = useMemo(() => {
    return platformTokens.find((token) => {
      return Boolean(token.isBase) == true
    })
  }, [platformTokens]);
  const playerData = useMemo(() => {

    return {
      playerPublicKey: player?.ownerPubkey.toString(),
      truncatedPlayerPublicKey: truncatePubkey(
        player?.ownerPubkey.toString() as string,
        6
      ).toLowerCase(),
      playerName: getPlayerUsername(player),
      playerAvatar: loadPlayerAvatar(player)
    }
  }, [player])

  const wagerTableItem: IWagerTableItem[] = useMemo(() => {
    if (!mergedTokens) {
      return [];
    }

    const playerTokenByIdentifier = player?.tokens.reduce((result, item) => (
      result.set(item.pubkey.toString(), item)
    ), new Map<string, IPlayerToken>());

    return mergedTokens?.map((token, index) => {
      const playerToken = playerTokenByIdentifier?.get(token.context?.pubkey || "");
      const tokenDecimals = token.house?.decimals || 6;
      const wagered = formatCurrency(
        (playerToken?.pastWagered || 0) / Math.pow(10, tokenDecimals),
        tokenDecimals,
        8,
      );

      return {
        id: `wager-token-${index}`,
        asset: {
          prefix: (
            <img
              src={token?.context?.imageDarkPng || ""}
              className="w-[22px] h-[22px] border-solid border-4 border-gray-900 rounded-full"
              alt="token"
            />
          ),
          value: token?.context?.symbol,
        },
        bets: isHiddenAccount ? "--" : `${(playerToken?.pastLossCount || 0) + (playerToken?.pastWinCount || 0)}`,
        won: isHiddenAccount ? "--" : `${playerToken?.pastWinCount || 0}`,
        wagered: {
          value: isHiddenAccount ? "--" : `${wagered}`,
          suffix: (
            <img
              src={token?.context?.imageDarkPng || ""}
              className="flex w-[14px] h-[14px] justify-center items-center ms-1"
            />
          ),
        },
        rowStyles: "[&]:odd:bg-gray-600",
      };
    });
  }, [player?.tokens]);

  const playerWageredMeta = useMemo(() => (
    getPlayerWageredMeta(player)
  ), [player]);

  const playerTotalStyles = {
    totalBlock: "flex flex-col w-full items-center gap-y-1",
    totalBlockTitle: "text-gray-400 uppercase font-semibold text-xs"
  };

  const AvatarComponent = player?.ownerPubkey.toString() === playerAccount?.ownerPubkey.toString()
    ? CurrentPlayerAvatar
    : PlayerAvatar

  return (
    <BaseModal
      title="Player Details"
      open={visible}
      onClose={hideModal}
      classes={{
        dialog: `w-[342px] sm:max-w-[520px] bg-gray-800`
      }}
    >
      <Scrollbar handleColor="var(--gray-600)">
        <div
          data-id="user-overlay-modal"
          className={`
            flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative
          `}
        >
          <div data-id="user-info" className="flex flex-row w-full items-center">
            <div
              data-id="user-avatar"
              className="flex w-[64px] mr-3 rounded relative text-gray-300"
              style={{ ...(isHiddenAccount && {filter: "grayscale()"}) }}>
              <AvatarComponent
                className="w-full rounded aspect-square"
                playerPublicKey={player?.ownerPubkey}
                playerAvatarKey={player?.avatar}
              />
            </div>
            <div className="flex flex-col w-full">
              <div
                data-id="user-name"
                className={`text-xl font-semibold ${isHiddenAccount ? "text-gray-400" : ""}`}
              >
                {isHiddenAccount ? "Hidden Profile" : playerData.playerName}
              </div>
              <div
                data-id="user-pub-key"
                className="text-gray-400 flex gap-1 items-center text-sm font-semibold"
              >
                <button
                  className="flex flex-row gap-1"
                  onClick={() => copyToClipboard(playerData.playerPublicKey as string)}
                  disabled={isCopiedToClipboard}
                >
                  {playerData.truncatedPlayerPublicKey}
                  <Icon size={"lg"} icon={<CopyIcon />} />
                </button>
              </div>
              <div data-id="player-rank-container" className="flex flex-col mt-3 text-gray-400 text-xs">
                <div className="h-1.5 rounded-full overflow-hidden bg-gray-900 w-full">
                  <div
                    className={`rounded-full h-full bg-brand-mixed-gradient`}
                    style={{ width: `${isHiddenAccount ? 0 : rankMeta?.percentage}%` }}
                  />
                </div>
                <div
                  data-id="player-rank"
                  className="flex flex-row w-full justify-between uppercase mt-2 font-semibold"
                >
                  <div data-id="player-current-rank">
                    {
                      isHiddenAccount
                        ? "Unknown"
                        : (
                          <button
                            className="flex"
                            onClick={() => {
                              navigate(
                                window.location.pathname,
                                {
                                  state:
                                    {
                                      isUserRankModalOpen: true,
                                      selectedRankModalTab: RankTabs.RANK_LIST
                                    }
                                }
                              );
                            }}
                          >
                            <div className="flex gap-0.5 items-center">
                              <RankIcon
                              rankName={rankMeta?.currentRank.name}
                              size="md"
                              defaultIcon={
                                <IconByUrl
                                  size="sm"
                                  inheritFill
                                  inheritStroke={false}
                                  icon={<PentagonIcon/>}
                                />
                              }
                            />
                              {rankMeta?.currentRank.name}
                            </div>
                          </button>
                        )
                    }
                  </div>
                  {
                    !isHiddenAccount
                    && <div data-id="player-next-rank">
                          <div className="flex items-center ">
                              <div className="capitalize font-normal mr-1.5">Next:</div>
                              <button
                                  className="flex"
                                  onClick={() => {
                                    navigate(
                                      window.location.pathname,
                                      {
                                        state:
                                          {
                                        isUserRankModalOpen: true,
                                        selectedRankModalTab: RankTabs.RANK_LIST
                                      }
                                  }
                                );
                              }}
                          >
                            <RankIcon
                              rankName={rankMeta?.nextRank.name}
                              size="md"
                              defaultIcon={
                                <IconByUrl
                                  inheritFill
                                  inheritStroke={false}
                                  size="sm"
                                  icon={<PentagonIcon />}
                                />
                              }
                            />
                            &nbsp;{rankMeta?.nextRank.name}
                          </button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div
            data-id="player-total"
            className="flex flex-col w-full p-4 text-lg font-semibold bg-gray-700 rounded"
          >
            <div className="flex flex-row">
              <div className={playerTotalStyles.totalBlock}>
                <div className={playerTotalStyles.totalBlockTitle}>Total Bets</div>
                <div>{isHiddenAccount ? "--" : (player?.pastBetCount ?? 0)}</div>
              </div>
              <div className={playerTotalStyles.totalBlock}>
                <div className={playerTotalStyles.totalBlockTitle}>Won</div>
                <div>{isHiddenAccount ? "--" : (player?.pastWinCount ?? 0)}</div>
              </div>
              <div className={playerTotalStyles.totalBlock}>
                <div className={playerTotalStyles.totalBlockTitle}>Lost</div>
                <div>{isHiddenAccount ? "--" : (player?.pastLossCount ?? 0)}</div>
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <div className={playerTotalStyles.totalBlock}>
                <div className={playerTotalStyles.totalBlockTitle}>Wagered</div>
                <div>{
                  isHiddenAccount
                    ? "--"
                    : `
                      $${formatCurrency(
                        Math.round((player?.pastWageredInBase ?? 0) / Math.pow(10, baseDecimals)),
                        2,
                      )}`
                }</div>
              </div>
              <div className={playerTotalStyles.totalBlock}>
                <div className={playerTotalStyles.totalBlockTitle}>7 days streak</div>
                <div>{isHiddenAccount ? "--" : playerWageredMeta?.wageredUi}</div>
                <div className="flex flex-row gap-x-1.5">
                  {
                    playerWageredMeta?.wageredEachDay.map((day) => (
                      <Tooltip
                        className={`flex w-full`}
                        content={
                          <>
                            {day.date} - {day?.wageredUi}
                          </>
                        }
                      >
                        <div
                          className={`
                            w-2 h-2 self-stretch rounded-full
                            ${day?.dailyMeta.backgroundColor}
                            ${day?.dailyMeta.glow}
                          `}
                        />
                      </Tooltip>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>

          <div
            data-id="player-tokens-table"
            className="flex flex-col w-full bg-gray-700 rounded px-2 py-3"
          >
            <Table
              items={wagerTableItem}
              padding="0"
              header={[
                {
                  value: "Assets",
                  cellStyles: "pl-3"
                },
                "Bets",
                "Won",
                {
                  value: "Wagered",
                  cellStyles: "pr-3"
                }
              ]}
              classes={{
                tableBodyStyles: "bg-gray-700"
              }}
            />
          </div>
        </div>
      </Scrollbar>
    </BaseModal>
  );
};

export default UserOverlayModal;
