import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PublicKey } from "@solana/web3.js";
import { BN } from "@coral-xyz/anchor";

import PlayerAccount from "../sdk/playerAccount";
import { ProgramContext } from "./ProgramContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { NetworkContext } from "./NetworkContext";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { HouseContext } from "./HouseContext";
import { WrappedWalletContext } from "./WrappedWalletContext";
import PlayerToken from "../sdk/playerToken";
import HouseToken from "../sdk/houseToken";

export interface IPlayerMeta {
  // USED TO TRACK IF NEW WALLET CONNECTED, WIPE IF SO
  walletPubkey?: string;

  username?: string;
  latestPlatformTerms?: number;
  latestHouseTerms?: number;
  signedTerms?: boolean;
  showUsername?: boolean;

  // EMAILS AND PREFERENCES
  email?: string;
  accountRelatedEmails?: boolean;
  promotionalEmails?: boolean;

  // REFERRALS
  referralAccount?: string;
}

export interface IPlayerContext {
  playerAccount: PlayerAccount | undefined;
  setPlayerAccount: React.Dispatch<React.SetStateAction<PlayerAccount | undefined>>;
  createPlayerAccount: (username: string) => Promise<PlayerAccount | undefined>;
  loadedPlayerAccount: boolean;
  playerMeta: IPlayerMeta | undefined;
  setPlayerMeta: React.Dispatch<React.SetStateAction<IPlayerMeta | undefined>>;
  createPlayerAccountAndAirdropTokens: (
    mint: PublicKey,
    amount: number,
  ) => Promise<PlayerAccount | undefined>;
  clientSeed: string;
  setClientSeed: any;
  counter: number;
  loadPlayerAccountAndStartWs: () => Promise<void>;
  updatePlayer: (
    newUsername: string,
    hidden: boolean | null,
    excludeUntil: BN | null,
  ) => Promise<void>;
  isLevelUpModalClosed: boolean;
  isNewNotificationsAvailable: boolean;
  setIsNewNotificationsAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  changeIsLevelUpModalState: (state: boolean) => void;
  rewardsMeta: any | undefined
  handleClaimRewardsAndResponse: () => Promise<any | undefined>
  claims: any[] | undefined
  collects: any[] | undefined
}

export const PlayerContext = createContext<IPlayerContext>({} as IPlayerContext);

interface Props {
  children: any;
}

export const PlayerProvider = ({ children }: Props) => {
  const [playerAccount, setPlayerAccount] = useState<PlayerAccount>();
  const [counter, setCounter] = useState<number>(0);
  const [loadedPlayerAccount, setLoadedPlayerAccount] = useState(false);
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext);
  const { meta } = useContext(ProgramContext);
  const [playerToken, setPlayerToken] = useState<PlayerToken>()

  // USED TO HOLD ANY DATA NOT HELD ON THE PLAYER ACC STATE
  const [playerMeta, setPlayerMeta] = useLocalStorage("zeebit-player-meta", "");
  const [clientSeed, setClientSeed] = useLocalStorage("zeebit-client-seed", "1234");
  const [ isLevelUpModalClosed, setIsLevelUpModalClosed ] = useLocalStorage("zeebit-is-level-up-modal-closed", false);
  const [ isLevelUpModalClosedState, setIsLevelUpModalClosedState ] = useState<boolean>(isLevelUpModalClosed);

  // WS
  const playerAccSub = useRef<number>();
  const { client, recentBlockhash, networkCounter } = useContext(NetworkContext);

  const setPlayerAccountWithCounter = () => {}

  const setPlayerMetaWithCounter = () => {}

  const loadPlayerAccountAndStartWs = () => {}

  const createPlayerAccount = () => {}

  const createPlayerAccountAndAirdropTokens = () => {}

  const updatePlayer = () => {}

  // LOAD THE PLAYER TOKEN
  const { houseToken } = useContext(HouseContext)
  useEffect(() => {
    async function loadPlayerToken(ht: HouseToken, wallet: PublicKey) {
      const playerToken = await PlayerToken.load(ht, wallet)
      
      setPlayerToken(playerToken)
    }

    if (houseToken == null || walletPubkey == null) {
      return
    }

    loadPlayerToken(houseToken, walletPubkey)
  }, [houseToken, walletPubkey])

  // VALIDATE THE PLAYER FOR SELF EXCLUSION, TIME OUT AND UNSIGNED TERMS AND CONDITIONS
  const { playerValidation } = useContext(ErrorHandlingContext);

  return (
    <PlayerContext.Provider
      value={useMemo(
        () => ({
          playerAccount: playerAccount,
          setPlayerAccount: setPlayerAccountWithCounter,
          createPlayerAccount: createPlayerAccount,
          loadedPlayerAccount: loadedPlayerAccount,
          playerMeta: playerMeta,
          setPlayerMeta: setPlayerMetaWithCounter,
          createPlayerAccountAndAirdropTokens: createPlayerAccountAndAirdropTokens,
          clientSeed: clientSeed,
          setClientSeed: setClientSeed,
          counter: counter,
          loadPlayerAccountAndStartWs: loadPlayerAccountAndStartWs,
          updatePlayer: updatePlayer,
          isLevelUpModalClosed: isLevelUpModalClosedState,
          changeIsLevelUpModalState: undefined,
          rewardsMeta: undefined,
          handleClaimRewardsAndResponse: undefined,
          claims: undefined,
          collects: undefined,
          isNewNotificationsAvailable: undefined,
          setIsNewNotificationsAvailable: undefined
        }),
        [
          playerAccount,
          createPlayerAccount,
          loadedPlayerAccount,
          playerMeta,
          createPlayerAccountAndAirdropTokens,
          clientSeed,
          setPlayerMetaWithCounter,
          counter,
          setPlayerAccountWithCounter,
          playerValidation,
          loadPlayerAccountAndStartWs,
          updatePlayer
        ],
      )}
    >
      {children}
    </PlayerContext.Provider>
  );
};
