import { APP_NETWORK_TYPE } from "../../types/chain";

export const ENV_NAME: string | undefined = process.env.ENV_NAME?.toUpperCase();
export const WEB3_AUTH_REDIRECT: string = process.env.WEB3_AUTH_REDIRECT || "https://dev.zeebit.io";
export const RPC_READ_ENDPOINT_SOLANA: string | undefined = process.env.RPC_READ_ENDPOINT || "https://devnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79"
export const RPC_WRITE_ENDPOINTS_SOLANA: string | undefined = process.env.RPC_WRITE_ENDPOINTS || "https://devnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79,https://black-sparkling-hill.solana-devnet.quiknode.pro/140c5f0950de18bebf55d516e84e889f633b68fe/"

export const RPC_READ_ENDPOINT_SONIC: string | undefined = process.env.RPC_READ_ENDPOINT_SONIC || "https://api.testnet.sonic.game"
export const RPC_WRITE_ENDPOINTS_SONIC: string | undefined = process.env.RPC_WRITE_ENDPOINTS_SONIC || "https://api.testnet.sonic.game"

export const APP_NETWORK: APP_NETWORK_TYPE = process.env.APP_NETWORK || APP_NETWORK_TYPE.SONIC

export const getRpcReadEndpoint = (chain: APP_NETWORK_TYPE) => {
    return chain == APP_NETWORK_TYPE.SONIC ? RPC_READ_ENDPOINT_SONIC: RPC_READ_ENDPOINT_SOLANA
}

export const getRpcWriteEndpoints = (chain: APP_NETWORK_TYPE) => {
    return (chain == APP_NETWORK_TYPE.SONIC ? RPC_WRITE_ENDPOINTS_SONIC: RPC_WRITE_ENDPOINTS_SOLANA)?.split(',')
}

export const RPC_WRITE_ENDPOINTS = APP_NETWORK == APP_NETWORK_TYPE.SONIC ? RPC_WRITE_ENDPOINTS_SONIC: RPC_WRITE_ENDPOINTS_SOLANA

export const ENFORCE_DELEGATION_FLOWS: string = process.env.ENFORCE_DELEGATION_FLOWS || 'false' // USED IF APP_NETWORK == MAGIC_BLOCKS
