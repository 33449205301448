import * as anchor from "@coral-xyz/anchor";
import { Commitment, PublicKey, TransactionInstruction } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";
import PlayerToken from "../playerToken";

export enum BaccaratSide {
    PLAYER = 0,
    TIE = 1,
    BANKER = 2,
};

export const BACCARRAT_NAME = "baccarat";

export type BaccaratBet = {
    playerWagerBasis: number;
    tieWagerBasis: number;
    bankerWagerBasis: number;
    numberOfHands: number;
}
export default class Baccarat extends Game {

    constructor(
        house: House,
        gameSpecPubkey: PublicKey,
    ) {
        super(house, gameSpecPubkey)
    }

    static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateUpdates: boolean = false) {
        const game = new Baccarat(house, gameSpecPubkey);
        await game.loadAllState(commitmentLevel, loadChildState, trackStateUpdates)
        return game;
    }

    async soloBetIx(
        ownerOrAuth: PublicKey,
        playerToken: PlayerToken,
        inputs: BaccaratBet,
        wager: number,
        clientSeed: number[],
    ): Promise<TransactionInstruction> {

        const bets = Array(inputs.numberOfHands).fill("").map((_, idx) => (
            [
                inputs.playerWagerBasis ? {
                    handIdx: idx,
                    side: BaccaratSide.PLAYER,
                    wager: new anchor.BN(inputs.playerWagerBasis)
                } : null,
                inputs.tieWagerBasis > 0 ? {
                    handIdx: idx,
                    side: BaccaratSide.TIE,
                    wager: new anchor.BN(inputs.tieWagerBasis)
                } : null,
                inputs.bankerWagerBasis > 0 ? {
                    handIdx: idx,
                    side: BaccaratSide.BANKER,
                    wager: new anchor.BN(inputs.bankerWagerBasis)
                } : null,
            ].filter((bet) => !!bet)
        ));

        const instanceRequest = {
            baccarat: {
                numHands: inputs.numberOfHands,
            }
        };
        // Baccarat { hand_idx: u8, side: u8, wager: u64 },
        const betRequests = bets.flat().map((bet) => ({
            baccarat: bet
        }));

        const betsPerHand = (inputs.playerWagerBasis ? 1 : 0) + (inputs.tieWagerBasis ? 1 : 0) + (inputs.bankerWagerBasis ? 1 : 0);
        const numberOfBets = inputs.numberOfHands * betsPerHand;

        return await this.soloPlayIx(
            ownerOrAuth,
            playerToken,
            numberOfBets,
            instanceRequest,
            betRequests,
            null,
            0,
            clientSeed,
        );
    }

    get gameConfig() {
        return this.state ? this.state.config.baccarat : null;
    }

    get formattedGameConfig() {
        if (this.gameConfig == null) {
            return
        }

        return {
            ...this.gameConfig,
            bankerEdge: Number(this.gameConfig.bankerEdgePerMillion) / 1_000_000,
            bankerMultiplier: Number(this.gameConfig.bankerMultiplierPerMillion) / 1_000_000,
            playerEdge: Number(this.gameConfig.playerEdgePerMillion) / 1_000_000,
            playerMultiplier: Number(this.gameConfig.playerMultiplierPerMillion) / 1_000_000,
            tieEdge: Number(this.gameConfig.tieEdgePerMillion) / 1_000_000,
            tieMultiplier: Number(this.gameConfig.tieMultiplierPerMillion) / 1_000_000,
        }
    }

    getMultiplier(input: object) {
        return 2
    }

    getProbability(input: object) {
        return 0.5
    }

    // EACH BET HAS WAGER, NUM COINS, NUM CORRECT
    getBetMetas(bets: object[]) {

        let totalPayout = 0;
        let totalProfit = 0;
        let totalWager = 0;
        let edgeDollar = 0;
        let totalWagerBasis = 0;

        const bankerMultiplier = this.formattedGameConfig.bankerMultiplier
        const bankerEdge = this.formattedGameConfig.bankerEdge
        const tieMultiplier = this.formattedGameConfig.tieMultiplier
        const tieEdge = this.formattedGameConfig.tieEdge
        const playerMultiplier = this.formattedGameConfig.playerMultiplier
        const playerEdge = this.formattedGameConfig.playerEdge

        bets.forEach((bet) => {
            // BET HAS PLAYER, TIE, BANKER
            const playerBet = bet.playerWager || 0
            const playerPayout = playerBet * playerMultiplier
            const playerProfit = playerPayout - playerBet
            const tieBet = bet.tieWager || 0
            const tiePayout = tieBet * tieMultiplier
            const tieProfit = tiePayout - tieBet
            const bankerBet = bet.bankerWager || 0
            const bankerPayout = bankerBet * bankerMultiplier
            const bankerProfit = bankerPayout - bankerBet

            const wageredInHand = playerBet + tieBet + bankerBet
            totalWager += wageredInHand
            edgeDollar += (playerBet * playerEdge) + (tieBet * tieEdge) + (bankerBet * bankerEdge)

            const maxPayout = Math.max(playerPayout, tiePayout, bankerPayout)
            totalPayout += maxPayout
            const maxProfit = Math.max(playerProfit, tieProfit, bankerProfit)
            totalProfit += maxProfit
        });

        return {
            payout: totalPayout,
            profit: totalProfit,
            wager: totalWager,
            numberOfBets: bets.length,
            bets: bets,
            edgeDollar: edgeDollar,
            totalWagerBasis: totalWagerBasis,
            edgePercentage: edgeDollar / totalWager, // USED IN CALCULATING MAX BET VIA KELLY
        };
    }
}