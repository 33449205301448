import * as anchor from "@coral-xyz/anchor";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerToken from "../playerToken";

export interface KenoInputs {
  riskLevel: number;
  numberOfBets: number;
  chosenNumbers: number[];
  wagerBasis: number;
  bets: {
    wager: number,
    chosenNumbers: number,
    numSelected: number
  }[]
}

export default class Keno extends Game {

  constructor(
    house: House,
    gameSpecPubkey: PublicKey
  ) {
    super(house, gameSpecPubkey);
  }

  static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateUpdates: boolean = false) {
    const game = new Keno(house, gameSpecPubkey);
    await game.loadAllState(commitmentLevel, loadChildState, trackStateUpdates)
    return game;
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: KenoInputs,
    wager: number,
    clientSeed: number[],


  ): Promise<TransactionInstruction> {
    const instanceRequest = {
      keno: {
        riskLevel: inputs.riskLevel,
        numSelected: inputs.chosenNumbers.length,
        numBets: inputs.numberOfBets
      }
    };

    const betRequests = Array(inputs.numberOfBets).fill({
      keno: {
        chosenNumbers: Buffer.from(inputs.chosenNumbers.sort((a, b) => (a - b))),
        wager: new anchor.BN(inputs.wagerBasis)
      }
    });
    console.log({ betRequests });


    const numberOfBets = inputs.numberOfBets;

    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      null,
      0,
      clientSeed
    );
  }


  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgePercentage = 0


    bets.forEach((bet) => {
      const multiplierMeta = this.getMultiplierForNumberSelected(bet?.numSelected || 1)
      const maxMultiplier = !!multiplierMeta?.multipliers ? multiplierMeta?.multipliers?.[multiplierMeta?.multipliers.length - 1] : 0;
      const edge = multiplierMeta?.edge || 0

      const payoutOnBet = maxMultiplier * bet.wager

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = 0;
      bet.multiplier = maxMultiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgePercentage = edge
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgePercentage: edgePercentage,
    };
  }

  get config() {
    return this?.state?.config?.keno
  }

  get maxBets() {
    return this.config?.maxBets
  }

  get maxNumSelected() {
    return this.config?.maxNumSelected
  }

  get multipliers() {
    return this.config?.multipliers?.[0]
  }

  get formattedMultipliers() {
    return this.multipliers?.map((multiplier) => {
      return {
        ...multiplier,
        edge: multiplier.edgePerMillion / Math.pow(10, 6),
        multipliers: multiplier.multipliersPerMillion?.map((multi: number) => {
          return multi / Math.pow(10, 6)
        })
      }
    })
  }

  getMultiplierForNumberSelected(numberSelected: number) {
    return this.formattedMultipliers?.find((multiplier) => {
      return multiplier.numSelected == numberSelected
    })
  }

  get tableSize() {
    return this.config?.tableSize
  }
}