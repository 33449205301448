import { Fragment, useContext, useMemo } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { UserDetails } from "./UserDetails";
import { truncatePubkey } from "../../utils/string/string";

// ICONS
import CopyIcon from "../../assets/icons/copy.svg";
import Icon from "../common/icon/Icon";
import UserIcon from "../../assets/icons/user.svg";
import SettingsIcon from "../../assets/icons/settings.svg";
import LinkIcon from "../../assets/icons/link.svg";
import SignOutIcon from "../../assets/icons/sign-out.svg";

import { PlayerContext } from "../../contexts/PlayerContext";
import { BrowserPreferenceContext } from "../../contexts/BrowserPreferenceContext";
import { NavContext, ProfileTab } from "../../contexts/NavContext";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";

import IconSvg from '../common/icon/Icon'
// import { ZeebrosPageTabs } from "../../pages/ZeebrosPage";
// import { NftStakingContext } from "../../contexts/NftStakingContext";
import IconFont from "../common/iconFont/IconFont";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";
import { SonicContext } from "../../contexts/SonicContext";

// TODO - NAVIGATE ON CLICK, READ CONFIG FROM JSON FILE
export const UserDropdown = ({ }) => {
  // const { setCurrentTab } = useContext(NftStakingContext)
  const { language } = useContext(BrowserPreferenceContext);
  const navigate = useNavigate();
  const { setProfileTab } = useContext(NavContext);
  const { logout } = useContext(SonicContext);
  const { isWeb3AuthWallet } = useContext(WrappedWalletContext);

  const NAVIGATIONS = useMemo(() => {
    return [
      {
        icon: <UserIcon />,
        name: "Profile",
        onClick: () => {
          setProfileTab(ProfileTab.DASHBOARD);
          navigate(`/profile`);
        },
        active: true,
      },
      {
        icon: <IconFont size="lg" name="calendar" className="" />,
        name: "Rewards",
        onClick: () => {
          setProfileTab(ProfileTab.REWARDS_CALENDAR);
          navigate(`/profile`);
        },
        active: true,
      },
      {
        icon: <LinkIcon />,
        name: "Referrals",
        onClick: () => {
          setProfileTab(ProfileTab.REFERRALS);
          navigate(`/profile`);
        },
        active: true,
      },
      {
        icon: <SettingsIcon />,
        name: "Settings",
        onClick: () => {
          setProfileTab(ProfileTab.SETTINGS);
          navigate(`/profile`);
        },
        active: true,
      },
    ];
  }, [language]);

  const { disconnect, walletPubkey } = useContext(WrappedWalletContext);
  const { loadedPlayerAccount } = useContext(PlayerContext);

  const { isCopiedToClipboard, copyToClipboard } = useCopyToClipboard("Successfully copied wallet public key to clipboard.");

  return (
    <Menu as="div" className="relative flex z-20">
      <Menu.Button as="div">
        <UserDetails />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items as="div" className="absolute min-w-[200px] top-[50px] right-0 ring-0 outline-0">
          <div
            data-id="user-dropdown"
            className="min-w-full inline-flex flex-col pt-3 pb-1.5 px-0 items-start gap-y-1.5 rounded-lg bg-gray-600 shadow-brand-regular"
          >
            {/* COPY PUBKEY */}
            <div className="flex px-3 py-0 flex-col w-full items-start">
              <button
                className={`
                  flex self-stretch h-9 py-[10px] px-4 justify-center 
                  items-center gap-x-1 flex-1 bg-gray-500 rounded-md font-sm
                  ${isCopiedToClipboard ? "" : "cursor-pointer"}
                `}
                disabled={isCopiedToClipboard}
                onClick={() => (copyToClipboard(walletPubkey?.toString() || ""))}
              >
                <div className="text-sm">
                  {truncatePubkey(walletPubkey?.toString() || "", 4)}
                </div>
                <div>{<Icon size={"lg"} icon={<CopyIcon />} />}</div>
              </button>
            </div>

            {/* NAVIGATIONS WITHIN APP */}
            {/*<div className="flex flex-col items-start self-stretch">*/}
            {/*  {NAVIGATIONS.map((navigation, index) => {*/}
            {/*    return (*/}
            {/*      <Menu.Item*/}
            {/*        as="div"*/}
            {/*        key={navigation.name}*/}
            {/*        className="flex items-start self-stretch"*/}
            {/*      >*/}
            {/*        <div*/}
            {/*          key={index}*/}
            {/*          className={`*/}
            {/*            flex px-3 py-2 items-center gap-x-1.5 flex-1 */}
            {/*            ${!navigation.active ? "text-gray-400 cursor-not-allowed" : "cursor-pointer"}*/}
            {/*            hover:bg-gray-500*/}
            {/*          `}*/}
            {/*          onClick={() => {*/}
            {/*            if (navigation.onClick != null) {*/}
            {/*              navigation.onClick();*/}
            {/*            }*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <Icon icon={navigation.icon} size="lg" />*/}
            {/*          <div className="leading-[18px]">{navigation.name}</div>*/}
            {/*        </div>*/}
            {/*      </Menu.Item>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*  <Menu.Item*/}
            {/*    as="div"*/}
            {/*    key={'zeebros-nft'}*/}
            {/*    className="flex items-start self-stretch"*/}
            {/*  >*/}
            {/*    <div*/}
            {/*      className={`*/}
            {/*        flex px-3 py-2 items-center gap-x-1.5 flex-1 */}
            {/*        cursor-pointer*/}
            {/*        hover:bg-gray-500*/}
            {/*      `}*/}
            {/*      onClick={() => {*/}
            {/*        // setCurrentTab(ZeebrosPageTabs.MyZeebros)*/}
            {/*        navigate(`/zeebros`)*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <IconSvg icon={<img src={ZeebrosIcon} className="drop-shadow-white-glow" />} size="lg" />*/}
            {/*      <div className="leading-[18px]">My Zeebros</div>*/}
            {/*    </div>*/}
            {/*  </Menu.Item>*/}
            {/*</div>*/}

            <div className="flex w-full h-[1px] bg-gray-400" />

            {/* SIGN OUT */}
            <Menu.Item as="div" className="w-full flex">
              <div
                className="flex px-3 py-2 items-center gap-x-1.5 cursor-pointer"
                onClick={() => {
                  disconnect();

                  if(!isWeb3AuthWallet) {
                    // TODO: Sonic rewards logout should be here
                    // logout?.();
                  }
                }}
              >
                <div>
                  <Icon icon={<SignOutIcon />} size="lg" />
                </div>{" "}
                <div className="leading-[18px]">Sign Out</div>
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
