import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import HouseToken from "../sdk/houseToken";
import { Commitment, ComputeBudgetProgram, Connection, Keypair, PublicKey, Transaction, VersionedTransaction, sendAndConfirmTransaction } from "@solana/web3.js";
import PlayerToken from "../sdk/playerToken";
import { HouseContext } from "./HouseContext";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { NetworkContext } from "./NetworkContext";
import { ProgramContext } from "./ProgramContext";
import { confirmTransaction, toVersionedTransaction } from "../utils/solana/utils";
import { useLocalStorage } from "@solana/wallet-adapter-react";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { createCommitInstruction } from "@magicblock-labs/delegation-program";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { SessionAuthorityContext } from "./SessionAuthorityContext";
import { IPlayerMeta } from "./PlayerContext";
import { MIN_LAMPORTS_AUTO_SIGNER } from "../sdk/constants";


export interface IPlayerTokenContext {
  playerToken: PlayerToken | undefined;
  playerTokenLoaded: boolean;
  playerTokens: PlayerToken[] | undefined;
  playerTokenByMint: Map<string, PlayerToken> | undefined
  loadPlayerToken: (houseToken?: HouseToken, wallet?: PublicKey) => Promise<void>
  loadPlayerTokens: () => Promise<void>
  initAndDeposit: (sessionAuthority?: Keypair, sessionAuthorityLamports?: number, depositAmount?: number) => Promise<string | undefined>
  initAndDelegate: (maxSessionLengthSeconds: number, instanceToDelegate: number, sessionAuthority?: Keypair, sessionAuthorityLamports?: number, depositAmount?: number) => Promise<string | undefined>
  processWithdrawal: (amountBasis: number) => Promise<string | undefined>
  updateSessionAuthority: (maxSessionLengthSeconds: number, sessionAuthority: PublicKey, sessionAuthorityLamports: number) => Promise<string | undefined>
  setPlayerMeta: Function;
  playerMeta: IPlayerMeta | undefined;
  hasNoPlayerTokenState: boolean;
}

export const PlayerTokenContext = createContext<IPlayerTokenContext>({} as IPlayerTokenContext);

interface Props {
  children: any;
}

export const PlayerTokenProvider = ({ children }: Props) => {

  const { solanaRpc } = useContext(WrappedWalletContext)
  const { client, recentBlockhash, erClient } = useContext(NetworkContext)
  const { meta } = useContext(ProgramContext)
  const { signerKp, allowsAutoSigning, allowsAutoDeposit, setAllowsAutoSigning, lamportBalance, signerPublicKey } = useContext(SessionAuthorityContext)

  const { houseToken, houseTokens } = useContext(HouseContext)
  const { walletPubkey } = useContext(WrappedWalletContext)

  // USED TO HOLD ANY DATA NOT HELD ON THE PLAYER ACC STATE
  const [playerMeta, setPlayerMeta] = useLocalStorage("zeebit-player-meta", "");
  const [counter, setCounter] = useState<number>(0);

  const setPlayerMetaWithCounter = useCallback(
    (newPlayerMeta: IPlayerMeta) => {
      setPlayerMeta({...newPlayerMeta, ...playerMeta});
      setCounter(counter + 1);
    },
    [setPlayerMeta, counter, playerMeta],
  );

  // ANY PLAYER TOKENS THEY HAVE
  const [playerTokens, setPlayerTokens] = useState<PlayerToken[]>()
  const [playerTokenLoaded, setPlayerTokenLoaded] = useState<boolean>(false)

  // PLAYER TOKEN FOR SELECTED TOKEN MINT
  const [playerToken, setPlayerToken] = useState<PlayerToken>()
  const [playerTokensLoaded, setPlayerTokensLoaded] = useState<boolean>(false)

  // WS LOGIC TO KEEP PLAYER TOKEN UP TO DATE
  const playerTokenWsId = useRef<number>()

  // TO BE USED TO CHECK IF THEY NEED TO SEE REGISTRATION
  // NO PLAYER TOKEN STATE ACCROSS ALL HOUSE TOKENS
  const hasNoPlayerTokenState = useMemo(() => {
    // FIRST CHECK ON PLAYER TOKEN
    if (playerTokenLoaded == false) {
      return false
    }

    const hasPlayerTokenState = playerToken?.stateLoaded && playerToken?.baseState != null

    if (hasPlayerTokenState) {
      return false
    }

    // NEXT CHECK ON ANY OTHER PLAYER TOKENS THEY MAY HAVE
    if (playerTokensLoaded == false) {
      return false
    }

    if (playerTokens == null) {
      return true
    }

    // Check the other player tokens too
    let playerTokensLength = playerTokens.length
    for(let i = 0; i < playerTokensLength; i++) {
      const token = playerTokens[i]

      if (token.stateLoaded == false || token.baseState != null) {
        return false
      }
    }

    return true
  }, [playerToken, playerTokens, playerTokensLoaded, playerTokenLoaded])

  const playerTokenByMint: Map<string, PlayerToken> | undefined = useMemo(() => {
    const tokenMap = playerTokens?.reduce((result, item) => {
      result.set(item.houseToken.tokenMintPubkey.toString(), item)
      return result
    }, new Map<string, PlayerToken>)

    if (playerToken != null && playerToken?.houseToken != null) {
      tokenMap?.set(playerToken?.houseToken.tokenMintPubkey.toString(), playerToken)
    }

    return tokenMap
  }, [playerTokens, playerToken])

  const loadPlayerToken = useCallback(async (hToken?: HouseToken, wallet?: PublicKey) => {
    try {
      const hseToken = hToken || houseToken
      const walletPkey = wallet || walletPubkey
      const pToken = await PlayerToken.load(hseToken, walletPkey);
      setPlayerTokenLoaded(true)
      setPlayerToken(pToken)
    } catch (e) {
      console.warn(`Issue loading the house from chain.`, e);
    } finally {
      setPlayerTokenLoaded(true);
    }
  }, [houseToken, walletPubkey])

  useEffect(() => {
    async function loadPlayerTokenAndStartWsHandler(wallet: PublicKey, hseToken: HouseToken, loadFx: (hToken?: HouseToken | undefined, wallet?: PublicKey | undefined) => Promise<void>, connection: Connection, commitment?: Commitment = "processed") {
      await loadFx(hseToken, wallet)

      if (playerTokenWsId.current != null) {
        try {
          await connection.removeAccountChangeListener(playerTokenWsId.current)
        } catch(err) {
          console.warn({
            err
          })
        }
      }

      const playerTokenPubkey = PlayerToken.derivePlayerTokenPubkey(hseToken.publicKey, wallet, hseToken.programId)

      playerTokenWsId.current = connection.onAccountChange(playerTokenPubkey, (accInfo, context) => {
        const newPlayerToken = PlayerToken.loadFromBuffer(hseToken, wallet, Buffer.from(accInfo.data))
        setPlayerToken(newPlayerToken)
      }, { commitment: commitment})
    }

    if (walletPubkey == null) {
      setPlayerToken(undefined)

      if (playerTokenWsId.current != null && client != null) {
        try {
          client.removeAccountChangeListener(playerTokenWsId.current)
        } catch(err) {
          console.warn({
            err
          })
        }
      }

      return
    }

    if (houseToken == null || walletPubkey == null || client == null) {
      return;
    }

    loadPlayerTokenAndStartWsHandler(walletPubkey, houseToken, loadPlayerToken, client)
  }, [houseToken, walletPubkey, loadPlayerToken, client]);

  const loadPlayerTokens = useCallback(async () => {
    if (houseTokens == null || walletPubkey == null) {
      throw new Error("Missing wallet or house tokens")
    }

    const playerTokenResults = await Promise.allSettled(houseTokens.map((hToken) => {
      return PlayerToken.load(hToken, walletPubkey)
    }))

    const pTokens: PlayerToken[] = []
    playerTokenResults.forEach((result) => {
      if (result.status == "fulfilled") {
        const value = result.value
        const hasState = !!value.baseState || !!value.erState

        if (hasState) {
          pTokens.push(value)
        }
      }
    })

    setPlayerTokens(pTokens)
    setPlayerTokensLoaded(true)
  }, [houseTokens, walletPubkey])

  useEffect(() => {
    if (houseTokens == null) {
      return
    }

    if (walletPubkey == null) {
      setPlayerTokens(undefined)
      return
    }

    loadPlayerTokens()
  }, [loadPlayerTokens, houseTokens, walletPubkey])

  // METHOD TO CREATE A PLAYER TOKEN AND DEPOSIT FUNDS
  const initAndDeposit = useCallback(
    async (
      sessionAuthority?: Keypair,
      sessionAuthorityLamports?: number,
      depositAmount?: number
    ) => {
      if (
        walletPubkey == null
        || playerToken == null
        || solanaRpc == null
      ) {
        console.warn(
          "Issue with wallet pubkey or solana rpc.",
          walletPubkey
        );
        throw new Error("Wallet not connected")
      }
      const tx = new Transaction();

      // INIT PLAYER TOKEN IF DOESNT EXIST
      const playerTokenExists = playerToken?.baseState != null

      if (!playerTokenExists) {
        console.log(`initializeIxn`)
        const initPlayerToken = await playerToken?.initializeIxn();
        tx.add(initPlayerToken);
      }

      if (depositAmount != null && depositAmount > 0) {
        console.log(`depositIxn`)
        const depositIx = await playerToken?.depositIxn(depositAmount)
        tx.add(depositIx);
      }


      if (sessionAuthority != undefined) {
        console.log(`updateSessionAuthorityIxn`)
        tx.add(
          await playerToken.updateSessionAuthorityIxn(
            sessionAuthority.publicKey,
            new Date(Date.now() + 86_400_000),
            sessionAuthorityLamports || 10_000
          )
        );
      }

      // SET FEE PAYER AND RECENT BLOCK
      tx.feePayer = walletPubkey
      tx.recentBlockhash = recentBlockhash.blockhash

      // SIGN WALLET
      let versionedTransaction = await toVersionedTransaction(tx, client, walletPubkey, recentBlockhash)

      versionedTransaction = await solanaRpc.signTransaction(versionedTransaction)

      const sig = await client?.sendRawTransaction(versionedTransaction.serialize(), { skipPreflight: true })

      console.log({
        sig
      })

      // NOW LOAD THE NEW PLAYER ACCOUNT
      if (playerTokenExists == false) {
        // NEED TO CONFIRM THE TX BEFORE LOADING STATE
        await confirmTransaction(sig, client, recentBlockhash);
      }

      await loadPlayerToken();

      return sig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      loadPlayerToken
    ],
  );

  const endSessionOnEr = useCallback(async () => {
      const signer = allowsAutoSigning == true ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined
      const feePayer = signer != null ? signer.publicKey : walletPubkey

      let recentBlock = await erClient?.getLatestBlockhash("confirmed")
      const tx = new Transaction()

      const endSessionIxn = await playerToken?.endSessionOnErIxn(signer)
      tx.add(endSessionIxn)

      tx.feePayer = feePayer
      tx.recentBlockhash = recentBlock?.blockhash

      const endSessionSig = signer != null ? await sendAndConfirmTransaction(erClient, tx, [signer]) : await solanaRpc?.sendAndConfirmTransaction(tx, erClient, walletPubkey, meta?.errorByCodeByProgram, recentBlock)

      console.log(`END SESSION ON ER -> `, {
        endSessionSig
      })

      return endSessionSig;
    },
    [
      solanaRpc,
      walletPubkey,
      erClient,
      meta,
      signerKp,
      recentBlockhash,
      playerToken,
      loadPlayerTokens,
      allowsAutoSigning
    ],
  );

  // DELEGATE - USED FOR THE ER FLOW
  const initAndDelegate = useCallback(
    async (
      maxSessionLengthSeconds: number,
      instanceToDelegate: number,
      sessionAuthority?: Keypair,
      sessionAuthorityLamports?: number,
      depositAmount?: number
    ) => {
      const tx = new Transaction();

      const setHeapLimitIx = ComputeBudgetProgram.requestHeapFrame({
        bytes: 8 * 32 * 1024
      })

      tx.add(setHeapLimitIx)

      // INIT PLAYER TOKEN IF DOESNT EXIST
      const playerTokenExists = playerToken?.baseState != null

      if (!playerTokenExists) {

        const initPlayerToken = await playerToken?.initializeIxn();
        tx.add(initPlayerToken);
      }

      if (depositAmount != null && depositAmount > 0) {
        const depositIx = await playerToken?.depositIxn(depositAmount)
        tx.add(depositIx);
      }

      if (sessionAuthority != undefined) {
        const updateSessionAuthIx = await playerToken.updateSessionAuthorityIxn(
          sessionAuthority.publicKey,
          new Date(Date.now() + 86_400_000),
          sessionAuthorityLamports || 10_000
        )

        tx.add(
          updateSessionAuthIx
        );
      }

      const delegateIxns = await playerToken?.delegateIxns(maxSessionLengthSeconds, instanceToDelegate)
      tx.add(...delegateIxns)

      tx.feePayer = walletPubkey
      tx.recentBlockhash = recentBlockhash.blockhash

      let versionedTx: VersionedTransaction | undefined = await toVersionedTransaction(tx, client, walletPubkey, recentBlockhash)

      versionedTx = await solanaRpc.signTransaction(versionedTx)

      const sig = await client?.sendRawTransaction(versionedTx.serialize(), { skipPreflight: true })

      // NOW LOAD THE NEW PLAYER ACCOUNT
      if (playerTokenExists == false) {
        // NEED TO CONFIRM THE TX BEFORE LOADING STATE
        await confirmTransaction(sig, client, recentBlockhash);
      }

      await loadPlayerToken();

      return sig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      loadPlayerToken
    ],
  );

  const updateSessionAuthority = useCallback(
    async (
      maxSessionLengthSeconds: number,
      sessionAuthority: PublicKey,
      sessionAuthorityLamports: number
    ) => {

      const tx = new Transaction();

      const setHeapLimitIx = ComputeBudgetProgram.requestHeapFrame({
        bytes: 8 * 32 * 1024
      })

      tx.add(setHeapLimitIx)


      tx.add(
        await playerToken.updateSessionAuthorityIxn(
          sessionAuthority,
          new Date(Date.now() + Math.round(maxSessionLengthSeconds * 1000)),
          sessionAuthorityLamports || 0
        )
      );

      const sig = await solanaRpc.sendTransaction(
        tx,
        client,
        walletPubkey,
        meta?.errorByCodeByProgram,
        recentBlockhash
      );

      return sig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken
    ],
  );

  // UNDELEGATE
  const undelegate = useCallback(async () => {

      const signer = allowsAutoSigning == true ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined
      const feePayer = signer != null ? signer.publicKey : walletPubkey
      let recentBlock = await client?.getLatestBlockhash("confirmed")

      const tx = new Transaction()

      const useLocalKeypair = signer != null

      if (useLocalKeypair == false) {
        const undelegateIxns = await playerToken.undelegateIxns()
        tx.add(...undelegateIxns)
        tx.feePayer = feePayer
        tx.recentBlockhash = recentBlock?.blockhash

        let versionedTransaction = await toVersionedTransaction(tx, client, walletPubkey, recentBlock)
        versionedTransaction = await solanaRpc?.signTransaction(versionedTransaction)

        const undelegateSig = await client?.sendRawTransaction(versionedTransaction.serialize(), { skipPreflight: true })

        console.log({
          undelegateSig
        })

        await confirmTransaction(undelegateSig, client, recentBlock)

        return undelegateSig
      } else {
        const undelegateIxns = await playerToken.undelegateIxns(signer)
        tx.add(...undelegateIxns)
        tx.feePayer = signer.publicKey
        tx.recentBlockhash = recentBlock?.blockhash

        const undelegateSig = await sendAndConfirmTransaction(client, tx, [signer], { skipPreflight: true })
        console.log({
          undelegateSig
        })
        return undelegateSig
      }
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      allowsAutoSigning,
      signerKp
    ],
  );

  const withdraw = useCallback(
    async (
      amountBasis: number
    ) => {
      let recentBlock = await client?.getLatestBlockhash("confirmed")

      const tx = new Transaction()

      if (allowsAutoSigning == true && signerPublicKey != null) {
        // CHECK IF NEEDS AN UPDATE
        const needsUpdate = await playerToken?.needsSessionAuthorityUpdate(signerPublicKey, MIN_LAMPORTS_AUTO_SIGNER, lamportBalance)
        console.log(`updateSessionAuthorityIxn`)

        if (needsUpdate == true) {
          const topUpLamports = lamportBalance - MIN_LAMPORTS_AUTO_SIGNER
          tx.add(
            await playerToken.updateSessionAuthorityIxn(
              signerPublicKey,
              new Date(Date.now() + 86_400_000),
              topUpLamports > 0 ? topUpLamports: 0
            )
          );
        }
      } else if (allowsAutoSigning == false) {
        const needsUpdate = playerToken?.sessionAuthority.toString() != walletPubkey?.toString()

        if (needsUpdate == true) {
          tx.add(
            await playerToken.updateSessionAuthorityIxn(
              walletPubkey,
              new Date(),
              0
            )
          );
        }
      }

      const withdrawIxn = await playerToken.withdrawIxn(amountBasis)

      tx.add(withdrawIxn)
      tx.feePayer = walletPubkey
      tx.recentBlockhash = recentBlock?.blockhash

      let versionedTransaction = await toVersionedTransaction(tx, client, walletPubkey, recentBlock)
      versionedTransaction = await solanaRpc?.signTransaction(versionedTransaction)

      const withdrawSig = await client?.sendRawTransaction(versionedTransaction.serialize(), { skipPreflight: true })

      await confirmTransaction(withdrawSig, client, recentBlockhash);

      return withdrawSig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      allowsAutoSigning,
      signerPublicKey
    ],
  );

  const triggerCommit = useCallback(async () => {
    const signer = allowsAutoSigning == true ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined
    const feePayer = signer != null ? signer.publicKey : walletPubkey

    let recentBlock = await erClient?.getLatestBlockhash("confirmed")

    const tx = new Transaction()
    const manualCommitIxn = createCommitInstruction({
      payer: feePayer,
      delegatedAccount: playerToken.publicKey,
    });
    tx.add(manualCommitIxn)

    tx.feePayer = feePayer
    tx.recentBlockhash = recentBlock?.blockhash

    const triggerCommitSig = signer != null
      ? await sendAndConfirmTransaction(erClient, tx, [signer])
      : await solanaRpc?.sendAndConfirmTransaction(tx, erClient, walletPubkey, meta?.errorByCodeByProgram, recentBlock)

    console.log({
      triggerCommitSig
    })

    return triggerCommitSig
  }, [playerToken, erClient, walletPubkey, solanaRpc, signerKp, allowsAutoSigning])

  const processWithdrawal = useCallback(async (amountBasis: number) => {
    if (playerToken?.isDelegated == true) {
      // END SESSION ON ER - FROZEN FOR UNDELEGATION OR READY FOR UNDELEGATION
      // CHECK IF THEY HAVE OPEN BETS
      console.log(`endSessionOnEr`)
      await endSessionOnEr()

      // TRIGGER COMMIT - CHECK IF STATES ARE THE SAME
      console.log(`triggerCommit`)
      await triggerCommit()

      // UNDELEGATE
      console.log(`undelegate`)
      await undelegate()
    } else if (playerToken?.isReadyToUndelegate == true) {
      console.log(`undelegate`)
      await undelegate()
    }

    // TODO - PUT IN ONLOGS LISTENER HERE ON PLAYER TOKEN BASE STATE
    // OWNER

    // WITHDRAW
    if (playerToken?.isDelegated || playerToken?.isReadyToUndelegate) {
      console.log('Sleeping 30 seconds (to see if ER updates)...')
      await new Promise(resolve => setTimeout(resolve, 30_000));
    }

    console.log(`withdraw ${amountBasis}`)
    const withdrawSig = await withdraw(amountBasis)

    await loadPlayerToken()

    return withdrawSig
  }, [playerToken, endSessionOnEr, undelegate, triggerCommit, withdraw, loadPlayerToken])

  const { playerValidation } = useContext(ErrorHandlingContext);

  // MINIMUM LAMPORTS CHECK
  useEffect(() => {
    if (allowsAutoDeposit == false && (playerToken == null || playerToken.baseState == null)) {
      playerValidation.addErrorMessage({
        type: ErrorType.NO_PLAYER_TOKEN,
        title: "No Player Token",
        message: "Please start a session and deposit tokens to play.",
      });
    } else {
      playerValidation.removeErrorMessage(ErrorType.NO_PLAYER_TOKEN);
    }
  }, [playerToken, allowsAutoDeposit]);

  // WHEN WE LOAD THE PLAYER TOKEN, WE NEED TO CHECK THE allowsAutoSigning flag is in line with the state
  const loadedPlayerTokenRef = useRef<string>()
  useEffect(() => {
    if (loadedPlayerTokenRef.current == playerToken?.publicKey?.toString()) {
      return
    }

    // IF THERE IS NOTHING ON CHAIN, DO NOTHING
    if (playerToken != null && playerToken?.baseState == null) {
      loadedPlayerTokenRef.current = playerToken?.publicKey.toString()
      return
    }

    const sessionAuthOnChain = playerToken?.sessionAuthority?.toString()
    const walletString = walletPubkey?.toString()

    if (sessionAuthOnChain == null || sessionAuthOnChain == walletString) {
      setAllowsAutoSigning(false)
    } else if (sessionAuthOnChain != null && sessionAuthOnChain != walletString) {
      setAllowsAutoSigning(true)
    }

    loadedPlayerTokenRef.current = playerToken?.publicKey.toString()
  }, [playerToken])

  return (
    <PlayerTokenContext.Provider
      value={useMemo(
        () => ({
          playerToken: playerToken,
          playerTokens: playerTokens,
          playerTokenByMint: playerTokenByMint,
          playerTokenLoaded: playerTokenLoaded,
          loadPlayerToken: loadPlayerToken,
          loadPlayerTokens: loadPlayerTokens,
          initAndDeposit: initAndDeposit,
          initAndDelegate: initAndDelegate,
          processWithdrawal: processWithdrawal,
          updateSessionAuthority: updateSessionAuthority,
          setPlayerMeta: setPlayerMetaWithCounter,
          playerMeta: playerMeta,
          hasNoPlayerTokenState: hasNoPlayerTokenState
        }),
        [
          playerToken,
          playerTokenLoaded,
          loadPlayerToken,
          loadPlayerTokens,
          initAndDeposit,
          initAndDelegate,
          playerTokens,
          playerTokenByMint,
          processWithdrawal,
          updateSessionAuthority,
          setPlayerMeta,
          playerMeta,
          hasNoPlayerTokenState
        ],
      )}
    >
      {children}
    </PlayerTokenContext.Provider>
  );
};
