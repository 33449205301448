import { ENV_NAME } from "../env/env";

export enum NetworkType {
  DEVNET = "DEVNET",
  TESTNET = "TESTNET",
  MAINNET = "MAINNET",
  LOCALNET = "LOCALNET",
}

export const defaultNetwork: NetworkType = NetworkType[ENV_NAME as keyof typeof NetworkType];
