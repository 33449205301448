import React from "react";

import { IAvatarProps, PlayerAvatar } from "./PlayerAvatar";

export const CurrentPlayerAvatar: React.FC<IAvatarProps> = ({
  shouldGenerateAvatar,
  ...avatarProps
}) => {
  return (
    <PlayerAvatar
      {...avatarProps}
      shouldGenerateAvatar={true}
    />
  );
};